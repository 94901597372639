import { Directive, AfterViewInit, HostListener } from '@angular/core';
import { DeviceDetectorService } from '../../../services/device-detector/device-detector.service';

@Directive({ selector: '[sizeDetector]' })
export class SizeDetectorDirective implements AfterViewInit {
  constructor(private deviceDetector: DeviceDetectorService) {}
  @HostListener('window:resize', [])
  private onResize() {
    this.detectScreenSize();
  }
  ngAfterViewInit() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    this.deviceDetector.onResize();
  }
}
