import { createReducer, on } from '@ngrx/store';
import { setTaxPaymentFormData, setTaxPaymentReceiptData } from '../actions/taxPayment.action';
import { TaxPaymentState } from '../state/taxPayment.state';

const taxPaymentInitState = null;

const setTaxPaymentInitState = null;

const taxPaymentData: TaxPaymentState = {
  taxPaymentModel: taxPaymentInitState,
  taxPaymentReceiptData: setTaxPaymentInitState,
};
export const taxPaymentReducer = createReducer(
  taxPaymentData,
  on(setTaxPaymentFormData, (state, { taxPaymentModel }) => ({
    ...state,
    taxPaymentModel,
  })),

  on(setTaxPaymentReceiptData, (state, { taxPaymentReceiptData }) => ({
    ...state,
    taxPaymentReceiptData,
  }))
);
