<ng-container *ngIf="!preLoginSmeMode; then notSmeMode; else smeMode"></ng-container>
<ng-template #notSmeMode>
  <div
    class="column large-12 medium-12 small-12 medium-centered large-centered tc-boundary-border--center ng-star-inserted tc-wrap"
  >
    <div class="conditions">
      <span class="conditiom-heading">Self-service banking profile</span>
      <span class="conditiom-heading">What is a self-service banking profile?</span>
      <span class="condition-desc"
        >It is a single registration that gives you access to our Internet Banking service.</span
      >
    </div>
    <div class="conditions">
      <span class="conditiom-heading">Who needs a self-service banking profile?</span>
      <span class="condition-desc"
        >All new and existing Stanbic Bank Botswana Limited customers without Internet Banking access.
      </span>
    </div>
    <div class="conditions">
      <span class="conditiom-heading">How do I create my self-service profile?</span>
      <span class="condition-desc"
        ><ul>
          <li>Step 1: Click on the “Register for Internet Banking” link on our Internet Banking website</li>

          <li>Step 2: Provide your Account Number</li>
          <li>Step 3: Select and provide answers to secret questions</li>
          <li>Step 4: Log into Internet Banking with the password sent to your mobile phone</li>
          <li>Step 5: Visit the nearest branch and apply for full Internet Banking transfer functionality</li>
        </ul>
      </span>
      <span>For more clarification on this process, you can reach our Customer Contact Center.</span>
      <ul class="condition-desc" style="list-style-type: disc">
        <li>
          E-mail:
          <a href="mailto:cccBW@stanbic.com" tabindex="-1" class="need-help-mail">cccBW@stanbic.com</a>
        </li>
        <li>Contact number for local dialers: +(267) 3987801</li>
        <li>Contact number for international dialers: +(267) 39878</li>
      </ul>
    </div>
    <div class="conditions">
      <span class="conditiom-heading"
        >Must I have a valid Stanbic Bank transactional account in order to register for self-service
        banking?</span
      >
      <span class="condition-desc"
        >Yes. It's easy to open an account with Stanbic Bank. Just visit any of our branches or call our
        Customer Contact Centre and your account will be opened within 2 days.</span
      >
    </div>
    <div class="conditions">
      <span class="conditiom-heading">Why is it necessary to create an Internet banking profile?</span>
      <span class="condition-desc"
        >It provides you an easy and secured access to your funds on a 24 by 7 basis.</span
      >
    </div>
    <div class="conditions">
      <span class="conditiom-heading">What if I am an existing Instant Money customer?</span>
      <span class="condition-desc"
        >If you use the Stanbic Bank Instant Money solution on your mobile, you will need to open a bank
        account before you can create an Internet banking profile.
      </span>
    </div>
    <div class="conditions">
      <span class="conditiom-heading"
        >Can I create my Internet banking profile at any Stanbic Bank branch?</span
      >
      <span class="condition-desc"
        >Yes, you can visit your nearest branch and request to be activated for internet banking.</span
      >
    </div>
    <div class="conditions">
      <span class="conditiom-heading"
        >What services will I be able to use once I have created my internet banking profile?</span
      >
      <span class="condition-desc"
        >You will be able access and use virtually all account management and payment services.</span
      >
    </div>
    <div class="conditions">
      <span class="conditiom-heading">Where can I get a copy of the Electronic Banking Agreement?</span>
      <span class="condition-desc">The Electronic Banking Agreement is available here:</span>
      <ul class="condition-desc" style="list-style-type: disc">
        <li>
          E-mail:
          <a href="mailto:cccBW@stanbic.com" tabindex="-1" class="need-help-mail"> cccBW@stanbic.com </a>
        </li>
        <li>Contact number for local dialers: +(267) 3987801</li>
        <li>Contact number for international dialers: +(267) 39878</li>
      </ul>
    </div>
    <div class="conditions">
      <span class="conditiom-heading">Do I need to re-register for the new Internet Banking?</span>
      <span class="condition-desc"
        >No, if you are an existing Internet Banking customer please log into Internet Banking using your
        existing Internet banking account using your username and Password.
      </span>
    </div>
    <div class="conditions">
      <span class="conditiom-heading">How many login attempts do I have?</span>
      <span class="condition-desc"
        >You are allowed three login attempts before the system will lock your Internet Banking profile.
        Should your profile get locked, please click the “forgot Password” link and follow the steps provided
        to reset your password. Alternatively, visit your nearest branch or call our Customer Contact Centre
        for assistance.
      </span>
    </div>
    <div class="conditions">
      <span class="conditiom-heading"
        >As an existing Internet Banking user, will I be able to log into the new Internet Banking with my
        existing credentials?</span
      >
      <span class="condition-desc"
        >Yes, you can log into Internet Banking using your existing login details.</span
      >
    </div>
    <div class="conditions">
      <span class="conditiom-heading"
        >Who do I contact when I need help to log into my Internet Banking profile?</span
      >
      <span class="condition-desc">Please contact the customer contact center:</span><br />
      <ul class="condition-desc" style="list-style-type: disc">
        <li>
          E-mail:
          <a href="mailto:cccBW@stanbic.com" tabindex="-1" class="need-help-mail"> cccBW@stanbic.com </a>
        </li>
        <li>Contact number for local dialers: +(267) 3987801</li>
        <li>Contact number for international dialers: +(267) 39878</li>
      </ul>
    </div>
    <div class="conditions">
      <span class="conditiom-heading">What do I do if I have forgotten my password?</span>
      <span class="condition-desc"
        >Click the “Forgot Password?” link on the Internet Banking website and follow the steps to reset your
        password.
      </span>
    </div>
    <div class="conditions">
      <span class="conditiom-heading"
        >I am an existing Stanbic Bank customer, but not an Internet Banking customer what must I do to get
        Internet Banking?</span
      >
      <span class="condition-desc"
        >Click on the Register for Internet Banking” link on the Internet Banking website or visit the nearest
        Stanbic bank Branch and register for Internet Banking.</span
      >
    </div>
    <div class="conditions">
      <span class="conditiom-heading"
        >I am not a Stanbic bank customer but would like to have Internet Banking.</span
      >
      <span class="condition-desc"
        >In order to have an Internet Banking profile, you need a Stanbic bank transactional account. Please
        visit the nearest Stanbic bank branch to open a transactional account.</span
      >
    </div>
    <div class="conditions">
      <span class="conditiom-heading">How secure is Stanbic Bank Internet Banking? </span>
      <span class="condition-desc"
        >Our internet banking is designed with the latest electronic encryption technology. This technology
        ensures the secure transfer of information over the Internet thus making the platform very safe.
        Because your account security is important to us, we have also built other safety measures into the
        Stanbic Bank website to protect the information you supply on our internet banking platform . You'll
        be allowed to change your own password, and all information sent and received is encrypted.</span
      >
    </div>
    <div class="conditions">
      <span class="conditiom-heading">Does Stanbic Bank charge me to use the Internet Banking service? </span>
      <span class="condition-desc"
        >Registration is free. However, some financial transactions may attract fees. All charges are
        displayed to you on the internet banking platform before you commit to the financial
        transaction.</span
      >
    </div>
    <div class="conditions">
      <span class="conditiom-heading">What are the criteria for creating a password? </span>
      <ul class="condition-desc" style="list-style-type: disc">
        <li class="each-condition-desc-li">Your password must be between 6 and 16 characters long.</li>
        <li class="each-condition-desc-li">
          Your password must contain at least 1 alphabetical character or 1 number.
        </li>
        <li class="each-condition-desc-li">
          Your password may not be the same as your ATM PIN or related to any of your names.
        </li>
        <li class="each-condition-desc-li">
          Your password must also include at least one special characters, for example, $%@.
        </li>
        <li class="each-condition-desc-li">The password is case-sensitive.</li>
      </ul>
    </div>
    <div class="conditions">
      <span class="conditiom-heading"
        >I am having problems accessing the Internet Banking application. What could be the problem?</span
      >
      <span class="condition-desc"
        >Be sure you are connected to the Internet and you are currently logged on to the Internet banking
        application. If you are on a dial-up connection, viewing statements may be very slow due to the poor
        Internet connection. However, we encourage you to have patience.</span
      >
    </div>
    <div class="conditions">
      <span class="conditiom-heading"
        >Internet Banking security tips
        <!-- <span class="need-help-mail" ng-click="scrollToZero();">back to top</span> -->
      </span>
      <ul class="condition-desc" style="list-style-type: disc">
        <li class="each-condition-desc-li">
          Always access our Internet Banking service by entering the URL (<a
            target="_blank"
            href="http://www.stanbicbank.co.bw"
            tabindex="-1"
            class="need-help-mail"
            target="_blank"
          >
            http://www.stanbicbank.co.bw </a
          >) into your browser.
        </li>
        <li class="each-condition-desc-li">
          Never click on a link in an email that takes you to a website that asks you to enter your personal
          details either in the email or website.
        </li>
        <li class="each-condition-desc-li">Use up-to-date anti-virus software.</li>
        <li class="each-condition-desc-li">
          Make use of the additional security features such as SMS & Email Notifications and one-time
          passwords.
        </li>
        <li class="each-condition-desc-li">
          Be cautious if you use Internet cafes or a computer that is not your own.
        </li>
        <li class="each-condition-desc-li">
          Keep your Microsoft security up-to-date. Go to Genuine Microsoft Software now.
        </li>
      </ul>
      <br />
      <div class="conditions">
        <span class="condition-desc"
          >If in doubt, please (For Local Dialers) CALL STANBIC (267) 3987801 or (For International Dialers)
          (267) 3987801</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading">One-time password (OTP) </span>
        <span class="condition-desc"
          >A one-time password (OTP) is a unique and time-sensitive password used as an added security measure
          on Internet Banking. The password will be sent to you by SMS and is valid for one Internet Banking
          session.
        </span>
      </div>
      <div class="conditions">
        <span class="conditiom-heading"
          >This password will be sent to you whenever you perform the following functions on Internet
          Banking:</span
        >
        <ul class="condition-desc" style="list-style-type: disc">
          <li class="">Add Beneficiary</li>
          <li class="">Modify Beneficiary</li>
          <li class="">One-Off Transfers / Payments</li>
          <li class="">International (SWIFT) Transfers</li>
          <li class="">Western Union Redemption</li>
          <li class="">Airtime Top-Up</li>
          <li class="">Forgot Password</li>
          <li class="">Change Login Password</li>
          <li class="">Service Requests</li>
          <li class="">Add Biller</li>
          <li class="">Modify Biller</li>
        </ul>
      </div>
      <div class="conditions">
        <span class="condition-desc"
          >The benefit of using an OTP is that you authorize the transactions on your accounts using a unique
          password. If you do not have an OTP you will not be able to perform any of the above activities.
          There is no charge for using the service.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading">Phishing </span>
        <span class="conditiom-heading">What is phishing?</span>
        <span class="condition-desc"
          >Criminals use phishing to try to get you to give them your banking and other personal
          information.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading">Don't get caught through phishing </span>
        <span class="condition-desc"
          >We're all used to getting some pretty strange emails in our inboxes asking us to "click here" or
          send information about ourselves, or even making promises of great wins. While Internet offers
          convenient access to services such as shopping and banking, it also holds risks to the security of
          your personal information and money. One such risk is "phishing", used by criminals in ever-changing
          ways to ultimately take your money.
        </span>
      </div>
      <div class="conditions">
        <span class="conditiom-heading">How is it done?</span>
        <span class="condition-desc"
          >Usually, you will receive an email from the fraudsters that appears to be from us with a link to a
          site that is very similar to ours. In most cases you would find it very difficult to tell that the
          site is not ours. The email will ask you to provide your login and password, card details or account
          numbers. Another way criminals use is to send you an email claiming to be from your Internet service
          provider (ISP) that includes links to their genuine site. Once you are linked to the site a pop-up
          window appears requesting your credit card information.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading">What we are doing to protect you?</span>
        <span class="condition-desc"
          >We are committed to protecting the confidentiality of your banking details. We are always looking
          out for sites that pretend to represent Stanbic bank or any of our subsidiaries. When we find one,
          we take measures to close them down as soon as we can. We also do our best to make sure our
          customers are told of new ways criminals may try get information out of you.</span
        >
      </div>
      <div class="conditions">
        <span class="condition-desc"
          >To make online transacting safer, you should make use of our security and authentication
          services.<br />
          These include:
        </span>
      </div>
      <ul class="condition-desc" style="list-style-type: disc">
        <li>
          <span class="condition-desc"
            >One-time password is a unique, compulsory and time-sensitive password used as added security on
            selected Internet Banking transactions. The password will be sent to you by email or SMS and is
            valid for one Internet Banking session. This service is free.
          </span>
        </li>
        <li>
          <span class="condition-desc"
            >My Notification is an email or SMS service that informs you when profile amendments, new
            beneficiary additions, amendments to existing beneficiaries and once-off payments are carried out
            on Internet Banking.
          </span>
        </li>
        <li>
          <span class="condition-desc"
            >Payment confirmation is a message that informs both you and the person or company you are paying
            that a transfer or payment has been successfully completed.
          </span>
        </li>
      </ul>
      <br />
      <div class="conditions">
        <span class="conditiom-heading">How you can protect yourself?</span>
        <span class="condition-desc"
          >Never give your personal details to anyone without making sure that they are who they say they are.
          A Stanbic bank representative will never ask you for personal or banking information in an email.
          You should view emails and pop-up windows asking for your personal information with the same amount
          of suspicion you would the person behind you in an ATM queue. Treat emails that appear to be from us
          asking for personal details with suspicion. Never reply to their email or get into a conversation
          with them.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading">What you can do if you suspect phishing?</span>
        <span class="condition-desc"
          >Let us know if you are not sure about an email or website saying it’e Stanbic BANK. Please forward
          the email to
          <a href="mailto:CCCBW@stanbic.com" tabindex="-1" class="need-help-mail"> CCCBW@stanbic.com </a> and
          we'll investigate. Do not respond to that email. We will get back to you in a few hours after we've
          investigated it. By sending it to us you may help us close down an illegal site and help save others
          from falling victim to these criminals.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading">If you've given out your details</span>
        <span class="condition-desc"
          >Call us immediately on (For Local Dialers) CALL STANBIC (267) 3987801 or (For International
          Dialers) (267) 3987801 if you think you have in one way or another given your banking details to a
          criminal. This line is open 24 hours a day.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading">Payments </span>
        <span class="conditiom-heading">How do I make a payment?</span>
        <span class="condition-desc"
          >Log on to Internet Banking, click on Pay & Transfer, select the required payment option, for
          example, Multiple payments, Self Account transfer, Beneficiary payments, Bills payments, One off
          payment, Recurring payments.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading">What is an electronic account payment limit?</span>
        <span class="condition-desc"
          >An electronic account payment (EAP) limit is a monthly limit that you load to your profile that
          allows you to make electronic account payments using Internet banking. The limit is reduced every
          time you make an electronic payment. If you have loaded future-dated payments, the limit will be
          reduced on the day of payment. The EAP Limit is currently 1Million Naira per day on all
          transactions.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading"
          >What are the maximum amounts that I can transfer between my accounts?</span
        >
        <span class="condition-desc"
          >Transferring of funds between accounts is limited by the amount of money in your account.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading"
          >What is the difference between the business date and the transaction date?</span
        >
        <span class="condition-desc"
          >The business date is reflected on your statement as the date the transaction was done. The business
          date changes daily at 7pm to reflect the next business day's date. Transactions done after 7pm on
          Saturday will show the following Monday's date on your statement, provided Monday is a business day
          and not a public holiday.The actual date on which the transaction was done is called the transaction
          date, regardless of whether it was a business day or the time.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading"
          >How long do payments take to reflect on the beneficiary's account?</span
        >
        <span class="condition-desc"
          >If the beneficiary is a Stanbic bank account holder, payments reflect in real-time in the
          beneficiary account. Payments to beneficiaries in other banks are also instant provided the
          beneficiary bank is on the NIBSS instant payment platform otherwise it may take about 24 hours for
          funds to reflect to on the beneficiary's statement.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading">What are one-off payments?</span>
        <span class="condition-desc"
          >It's a payment option that lets you pay both companies and individuals without loading their
          details to your list of beneficiaries. To make one-off payment, click on Pay & Transfer on the
          dashboard, select one-off payments, select the transaction type, supply your payment parameters and
          submit your transaction.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading">What are Self Account transfers?</span>
        <span class="condition-desc"
          >You can transfer funds between your accounts - provided that your available credit balances are
          sufficient to cover the transfers. For example, you are able to transfer money from your current to
          your savings account. Select self account transfer from the pay& transfer menu on the dashboard,
          complete your transfer details and submit your transaction.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading">What are multiple payments?</span>
        <span class="condition-desc"
          >You can make multiple payments to beneficiaries in a single instance. Select multiple payments from
          the pay & transfer menu on the dashboard. Assign payment amount to any of the beneficiaries
          displayed on the payment page and submit your transaction.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading">What are beneficiary payments?</span>
        <span class="condition-desc"
          >You can select and pay a single beneficiary in your list. Select beneficiary payments from the pay
          & transfer menu on the dashboard, select your transaction category, complete your transfer details
          and submit your transaction.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading">What are bill payments?</span>
        <span class="condition-desc"
          >You can register for billers on Internet Banking and make quick and easy payments to these billers.
          Select bill payments from the pay & transfer menu on the dashboard, select your transaction
          category, complete your payment detail and submit your transaction.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading">Can I perform a multi-currency transaction?</span>
        <span class="condition-desc">You can perform multi-currency transactions when you perform a:</span>
        <ul class="condition-desc" style="list-style-type: disc">
          <li class="">Own-Fund Transfer</li>
          <li class="">Beneficiary Payment</li>
          <li class="">Once-off Payment</li>
          <li class="">International Transfers</li>
        </ul>
      </div>
      <div class="conditions">
        <span class="conditiom-heading">Can I save my transactions and perform them at a later stage?</span>
        <span class="condition-desc"
          >Yes you can save your transactions and perform them at a later stage.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading">Can I view my completed transactions?</span>
        <span class="condition-desc"
          >Yes you can view your completed transactions. Transactions with the following statuses will be
          displayed to the customer:</span
        >
        <ul class="condition-desc" style="list-style-type: disc">
          <li class="">Successful transactions</li>
          <li class="">Stopped transactions</li>
          <li class="">Failed transactions</li>
          <li class="">Rejected bill payments</li>
        </ul>
      </div>
      <div class="conditions">
        <span class="conditiom-heading">Beneficiary Management </span>
        <span class="conditiom-heading">How do I add beneficiaries to my Internet Banking profile?</span>
        <span class="condition-desc"
          >Access Internet Banking, select Pay & Transfer on the dashboard and select the function button Add
          beneficiary, select the beneficiary bank parameter, complete the beneficiary details and update the
          record.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading"
          >How many beneficiaries can I link to my Internet Banking profile?</span
        >
        <span class="condition-desc">There is no limit to the number of beneficiaries at present.</span>
      </div>
      <div class="conditions">
        <span class="conditiom-heading">What do I need to add a beneficiary?</span>
        <span class="condition-desc"
          >You need the beneficiary's details: name, bank, and the beneficiary account number.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading">Can I create both Stanbic bank and other bank beneficiaries?</span>
        <span class="condition-desc">Yes</span>
      </div>
      <div class="conditions">
        <span class="conditiom-heading"
          >Will my Stanbic bank and other bank beneficiaries be saved in one list?</span
        >
        <span class="condition-desc">Yes</span>
      </div>
      <div class="conditions">
        <span class="conditiom-heading">Can I modify an existing beneficiary?</span>
        <span class="condition-desc">Yes you can modify an existing beneficiary.</span>
      </div>
      <div class="conditions">
        <span class="conditiom-heading">Biller Management </span>
        <span class="conditiom-heading">What are billers?</span>
        <span class="condition-desc"
          >The billers displayed in Internet Banking are the standard payees who have existing agreements with
          Stanbic bank to facilitate their bill payments.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading">How do I register for a biller?</span>
        <span class="condition-desc">You can register for a biller following the following steps:</span>
        <span class="otp-pages">Step 1: Select Pay & Transfer tab from the dashboard page</span>
        <span class="otp-pages"> Step 2: Select Register Biller </span>
        <span class="otp-pages"
          >Step 3: Click on the Register button from the table displayed to register a selected Biller</span
        >
      </div>
    </div>
  </div>
</ng-template>
<ng-template #smeMode>
  <div class="column large-12 medium-12 small-12 medium-centered large-centered tc-wrap">
    <div class="conditions">
      <span class="conditiom-heading">Browser Compatibility</span>
      <span class="conditiom-heading">Online banking can only be accessed on the understated browsers:</span>
      <span class="condition-desc">
        <li>Google Chrome ? Latest 3 Version</li>
        <li>Safari ? Version 9.0 and above</li>
        <li>Internet Explorer 11 and Edge</li>
        <li>Mozilla ? Latest 3 version</li>
        <li>
          Mobile: Platform default browsers and latest 3 versions of Chrome, Safari and Firefox ( Supported
          platforms : Android 4.0 and above, iOS 8 and above)
        </li>
      </span>
    </div>
    <div class="conditions">
      <span class="conditiom-heading">How do I check my browser version?</span>
      <span class="conditiom-heading">Chrome</span>
      <span class="condition-desc">
        <li>In the top?right, click the Menu</li>
        <li>Click?Help >?About Google Chrome</li>
        <li>In the dialog that appears, you should see your Version number</li>
      </span>
    </div>
    <div class="conditions">
      <span class="conditiom-heading">Firefox</span>
      <span class="condition-desc">
        <li>From the?Help menu in the menu tool bar at the top of the screen, select?About Firefox</li>
        <li>If the menu bar is hidden, press?Alt?to make it visible</li>
        <li>In the dialog that appears, you should see your Version number</li>
      </span>
    </div>
    <div class="conditions">
      <span class="conditiom-heading">Internet Explorer</span>
      <span class="condition-desc">
        <li>Select?Tools?(via the Gear Icon) >?About Internet Explorer</li>
        <li>In the dialog that appears, you should see your Version number</li>
      </span>
    </div>
    <div class="conditions">
      <span class="conditiom-heading">Safari 8 and above</span>
      <span class="condition-desc">
        <li>Click on the Safari item on the top menu-bar (next to the Apple logo)</li>
        <li>Click on the About Safari menu item</li>
        <li>A small window should now show your current version of Safari</li>
      </span>
    </div>
    <div class="conditions">
      <span class="conditiom-heading"
        >My browser is compatible and I still cannot access the Online banking site ?
        <a href="http://www.stanbicbank.co.bw" tabindex="-1" class="need-help-mail"
          >http://www.stanbicbank.co.bw</a
        >
        - why?</span
      >
      <span class="condition-desc">
        Browsers run in memory or from history. Please clear your cache to access Online banking:
      </span>
    </div>
    <div class="conditions">
      <span class="conditiom-heading">Chrome</span>
      <span class="condition-desc">
        <li>In the top?right, click the Menu</li>
        <li>Click?More tools?>?Clear browsing data</li>
        <li>
          In the dialog that appears, select the checkboxes for the types of information that you want to
          remove. (Browsing History, Cached images and files, Cookies and other site and plugin data)
        </li>
        <li>
          Use the menu at the top to select the amount of data you want to delete. Select?beginning of time?to
          delete everything.
        </li>
        <li>Click?Clear browsing data</li>
      </span>
    </div>
    <div class="conditions">
      <span class="conditiom-heading">Firefox</span>
      <span class="condition-desc">
        <li>
          From the?History?menu in the menu tool bar at the top of the screen, select?Clear Recent History
        </li>
        <li>If the menu bar is hidden, press?Alt?to make it visible.</li>
        <li>Select ?Everything? ?in the Time Range to clear drop down to clear your entire cache</li>
        <li>Click?Clear Now.</li>
        <li>Exit/quit all browser windows and re-open the browser.</li>
      </span>
    </div>
    <div class="conditions">
      <span class="conditiom-heading">Internet Explorer</span>
      <span class="condition-desc">
        <li>Select?Tools?(via the Gear Icon) >?Safety?>?Delete browsing history</li>
        <li>If the menu bar is hidden, press?Alt?to make it visible.</li>
        <li>Deselect?Preserve Favorites website data, and select:</li>
        <li>Temporary Internet files?or?Temporary Internet files and website files</li>
        <li>Cookies?or?Cookies and website data</li>
        <li>History</li>
        <li>
          Click?Delete. You will see a confirmation at the bottom of the window when the process is complete.
        </li>
        <li>Exit/quit all browser windows and re-open the browser</li>
      </span>
    </div>
    <div class="conditions">
      <span class="conditiom-heading">Safari 8 and above</span>
      <span class="condition-desc">
        <li>From the?Safari?menu, select?Clear History and Website Data</li>
        <li>Select the desired time range, and then click?Clear History</li>
        <li>Go to?Safari?>?Quit Safari?or press?Command-Q?to exit the browser completely</li>
      </span>
    </div>
    <div class="conditions">
      <span class="conditiom-heading">Microsoft Edge</span>
      <span class="condition-desc">
        <li>In the top right, click the Hub icon (looks like three horizontal lines)</li>
        <li>Click the History icon, and then select?Clear all history</li>
        <li>
          Select?Browsing history, then?Cookies and saved website data, and then?Cached data and files. Click
          Clear.
        </li>
        <li>
          After the "All Clear!" message appears, exit/quit all browser windows and re-open the browser.
        </li>
      </span>
    </div>
    <div class="conditions">
      <span class="conditiom-heading">Online banking profile</span>
      <span class="conditiom-heading">What is an Online banking profile?</span>
      <span class="condition-desc"
        >It is a single registration that gives you access to our Online banking service.</span
      >
    </div>
    <div class="conditions">
      <span class="conditiom-heading">Who needs an online banking profile?</span>
      <span class="condition-desc"
        >All new and existing Stanbic Bank customers without Online banking access.
      </span>
    </div>
    <div class="conditions">
      <span class="conditiom-heading">How do I register for a online banking profile?</span>
      <span class="condition-desc">
        <ul>
          <li>Step 1: You are on the Sign in page for Online banking</li>
          <li>
            Step 2: Select the register for Online banking link on the page to download the registration form
          </li>
          <li>Step 3: Complete and sign the registration form</li>
          <li>
            Step 4: Scan the completed form and email to
            <a href="mailto:@EnterpriseBW@stanbic.com" tabindex="-1" class="need-help-mail">
              EnterpriseBW@stanbic.com
            </a>
            or submit it to your nearest branch
          </li>
        </ul>
      </span>
      <span>For more clarification on this process, you can reach the My Business Contact Center.</span>
      <ul class="condition-desc" style="list-style-type: disc">
        <li>
          E-mail:
          <a href="mailto:EnterpriseBW@stanbic.com" tabindex="-1" class="need-help-mail">
            EnterpriseBW@stanbic.com
          </a>
        </li>
        <li>Contact Number: +267 3987820</li>
      </ul>
    </div>
    <div class="conditions">
      <span class="conditiom-heading"
        >Must I have a valid Stanbic Bank transactional account in order to register for self-service
        banking?</span
      >
      <span class="condition-desc"
        >Yes. It's easy to open an account with Stanbic. Just visit any of our branches or call our Customer
        Contact Centre and your account will be opened within 2 days.</span
      >
    </div>
    <div class="conditions">
      <span class="conditiom-heading">Why is it necessary to create an online banking profile?</span>
      <span class="condition-desc"
        >It provides you an easy and secured access to your funds on a 24 by 7 basis.</span
      >
    </div>
    <div class="conditions">
      <span class="conditiom-heading"
        >Can I create my online banking profile at any Standard Bank branch?</span
      >
      <span class="condition-desc"
        >Yes, you can visit your nearest branch and request to be activated for online banking.</span
      >
    </div>
    <div class="conditions">
      <span class="conditiom-heading"
        >What services will I be able to use once I have created my online banking profile?</span
      >
      <span class="condition-desc"
        >You will be able access and use virtually all account management and payment services.</span
      >
    </div>
    <div class="conditions">
      <span class="conditiom-heading">Where can I get a copy of the Electronic Banking Agreement?</span>
      <span class="condition-desc">The Electronic Banking Agreement is available here:</span>
      <ul class="condition-desc" style="list-style-type: disc">
        <li>
          E-mail:
          <a href="mailto:cccBW@stanbic.com" tabindex="-1" class="need-help-mail"> cccBW@stanbic.com </a>
        </li>
        <li>Contact number for local dialers: +(267) 3987801</li>
        <li>Contact number for international dialers: +(267) 39878</li>
      </ul>
    </div>
    <div class="conditions">
      <span class="conditiom-heading">Do I need to re-register for the new Online banking?</span>
      <span class="condition-desc"
        >No, if you are an existing Online banking customer please log into
        <a href="http://www.stanbicbank.co.bw" tabindex="-1" class="need-help-mail" target="_blank">
          http://www.stanbicbank.co.bw</a
        >
        using your existing username and Password.
      </span>
    </div>
    <div class="conditions">
      <span class="conditiom-heading">How many login attempts do I have?</span>
      <span class="condition-desc"
        >You are allowed three login attempts before the system will lock your Online banking profile. Should
        your profile get locked, please click the ?forgot Password? link and follow the steps provided to
        reset your password. Alternatively, visit your nearest branch or call our Customer Contact Centre for
        assistance.
      </span>
    </div>
    <div class="conditions">
      <span class="conditiom-heading"
        >As an existing Online banking user, will I be able to log into the new Online banking with my
        existing credentials?</span
      >
      <span class="condition-desc"
        >Yes, you can log into Online banking using your existing login details.</span
      >
    </div>
    <div class="conditions">
      <span class="conditiom-heading"
        >Who do I contact when I need help to log into my Online banking profile?</span
      >
      <span class="condition-desc">Please contact the customer contact center:</span><br />
      <ul class="condition-desc" style="list-style-type: disc">
        <li>
          E-mail:
          <a href="mailto:EnterpriseBW@stanbic.com" tabindex="-1" class="need-help-mail">
            EnterpriseBW@stanbic.com
          </a>
        </li>
        <li>Contact Number: +267 3987820</li>
      </ul>
    </div>
    <div class="conditions">
      <span class="conditiom-heading">What do I do if I have forgotten my password?</span>
      <span class="condition-desc"
        >Click the ?Forgot Password?? link on the Online banking website and follow the steps to reset your
        password.
      </span>
    </div>
    <div class="conditions">
      <span class="conditiom-heading"
        >I am an existing Stanbic Bank customer, but not an Online banking customer what must I do to get
        Online banking?</span
      >
      <span class="condition-desc"
        >Click on the Register for Online banking link on
        <a href="http://www.stanbicbank.co.bw" tabindex="-1" class="need-help-mail" target="_blank">
          http://www.stanbicbank.co.bw</a
        >, visit the nearest Stanbic Bank Branch or call the CCC to register for Online banking.</span
      >
    </div>
    <div class="conditions">
      <span class="conditiom-heading"
        >I am not a Stanbic Bank customer but would like to have Online banking.</span
      >
      <span class="condition-desc"
        >In order to have an Online banking profile, you need a Stanbic Bank transactional account. Please
        visit the nearest Stanbic Bank branch to open a transactional account.</span
      >
    </div>
    <div class="conditions">
      <span class="conditiom-heading">How secure is Stanbic Online banking? </span>
      <span class="condition-desc"
        >Our online banking is designed with the latest electronic encryption technology. This technology
        ensures the secure transfer of information over the Internet thus making the platform very safe.
        Because your account security is important to us, we have also built other safety measures into the
        Stanbic website to protect the information you supply on our online banking platform . You'll be
        allowed to change your own password, and all information sent and received is encrypted.</span
      >
    </div>
    <div class="conditions">
      <span class="conditiom-heading">Does Stanbic charge me to use the Online banking service? </span>
      <span class="condition-desc"
        >Registration is free. However, some financial transactions or requests may attract fees.</span
      >
    </div>
    <div class="conditions">
      <span class="conditiom-heading">What are the criteria for creating a password? </span>
      <ul class="condition-desc" style="list-style-type: disc">
        <li class="each-condition-desc-li">Your password must be between 6 and 16 characters long.</li>
        <li class="each-condition-desc-li">
          Your password must contain at least 1 alphabetical character or 1 number.
        </li>
        <li class="each-condition-desc-li">
          Your password may not be the same as your ATM PIN or related to any of your names.
        </li>
        <li class="each-condition-desc-li">
          Your password must also include at least one special characters, for example, $%@.
        </li>
        <li class="each-condition-desc-li">The password is case-sensitive.</li>
      </ul>
    </div>
    <div class="conditions">
      <span class="conditiom-heading"
        >I am having problems accessing the Online banking application. What could be the problem?</span
      >
      <span class="condition-desc"
        >Be sure you are connected to the Internet and you are currently logged on to the Internet banking
        application. Ensure you are on this site https://ibanking.stanbic.com.gh or alternatively, please
        contact the customer contact centre:</span
      ><br />
      <ul class="condition-desc" style="list-style-type: disc">
        <li>
          E-mail:
          <a href="mailto:EnterpriseBW@stanbic.com" tabindex="-1" class="need-help-mail">
            EnterpriseBW@stanbic.com
          </a>
        </li>
        <li>Contact Number: +267 3987820</li>
      </ul>
    </div>
    <div class="conditions">
      <span class="conditiom-heading"
        >Online banking security tips
        <!-- <span class="need-help-mail" ng-click="scrollToZero();">back to top</span> -->
      </span>
      <ul class="condition-desc" style="list-style-type: disc">
        <li class="each-condition-desc-li">
          Always access our Online banking service by entering the URL (<a
            target="_blank"
            href="http://www.stanbicbank.co.bw"
            tabindex="-1"
            class="need-help-mail"
            target="_blank"
          >
            http://www.stanbicbank.co.bw </a
          >) into your browser.
        </li>
        <li class="each-condition-desc-li">
          Never click on a link in an email that takes you to a website that asks you to enter your personal
          details either in the email or website.
        </li>
        <li class="each-condition-desc-li">Use up-to-date anti-virus software.</li>
        <li class="each-condition-desc-li">
          Make use of the additional security features such as SMS & Email Notifications and one-time
          passwords.
        </li>
        <li class="each-condition-desc-li">
          Be cautious if you use Internet cafes or a computer that is not your own.
        </li>
        <li class="each-condition-desc-li">
          Keep your Microsoft security up-to-date. Go to Genuine Microsoft Software now.
        </li>
      </ul>
      <br />
      <div class="conditions">
        <span class="condition-desc">If in doubt, please call on Contact Number: +267 3987820</span>
      </div>
      <div class="conditions">
        <span class="conditiom-heading">One-time password (OTP) </span>
        <span class="condition-desc"
          >A one-time password (OTP) is a unique and time-sensitive password used as an added security measure
          on Online banking. The password will be sent to you by SMS and is valid for one Online banking
          session.
        </span>
      </div>
      <div class="conditions">
        <span class="conditiom-heading"
          >This password will be sent to you whenever you perform the following functions on Online
          banking:</span
        >
        <ul class="condition-desc" style="list-style-type: disc">
          <li class="">Add Beneficiary</li>
          <li class="">Modify Beneficiary</li>
          <li class="">One-Off Transfers / Payments</li>
          <li class="">Airtime Top-Up</li>
          <li class="">Forgot Password</li>
          <li class="">Change Login Password</li>
          <li class="">Service Requests</li>
        </ul>
      </div>
      <div class="conditions">
        <span class="condition-desc"
          >The benefit of using an OTP is that you authorize the transactions on your accounts using a unique
          password. If you do not have an OTP you will not be able to perform any of the above activities.
          There is no charge for using the service.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading">Phishing </span>
        <span class="conditiom-heading">What is phishing?</span>
        <span class="condition-desc"
          >Criminals use phishing to try to get you to give them your banking and other personal
          information.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading">Don't get caught through phishing </span>
        <span class="condition-desc"
          >We're all used to getting some pretty strange emails in our inboxes asking us to "click here" or
          send information about ourselves, or even making promises of great wins. While Internet offers
          convenient access to services such as shopping and banking, it also holds risks to the security of
          your personal information and money. One such risk is "phishing", used by criminals in ever-changing
          ways to ultimately take your money.
        </span>
      </div>
      <div class="conditions">
        <span class="conditiom-heading">How is it done?</span>
        <span class="condition-desc"
          >Usually, you will receive an email from the fraudsters that appears to be from us with a link to a
          site that is very similar to ours. In most cases you would find it very difficult to tell that the
          site is not ours. The email will ask you to provide your login and password, card details or account
          numbers. Another way criminals use is to send you an email claiming to be from your Internet service
          provider (ISP) that includes links to their genuine site. Once you are linked to the site a pop-up
          window appears requesting your credit card information.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading">What we are doing to protect you?</span>
        <span class="condition-desc"
          >We are committed to protecting the confidentiality of your banking details. We are always looking
          out for sites that pretend to represent Stanbic Bank or any of our subsidiaries. When we find one,
          we take measures to close them down as soon as we can. We also do our best to make sure our
          customers are told of new ways criminals may try get information out of you.</span
        >
      </div>
      <div class="conditions">
        <span class="condition-desc"
          >To make online transacting safer, you should make use of our security and authentication
          services.<br />
          These include:
        </span>
      </div>
      <ul class="condition-desc" style="list-style-type: disc">
        <li>
          <span class="condition-desc"
            >One-time password is a unique, compulsory and time-sensitive password used as added security on
            selected Online banking transactions. The password will be sent to you by SMS and is valid for one
            Online banking session. This service is free.
          </span>
        </li>
        <li>
          <span class="condition-desc"
            >? MyUpdates is an email or SMS service that informs you when profile amendments, new beneficiary
            additions, amendments to existing beneficiaries and once-off payments are carried out on Online
            banking.
          </span>
        </li>
        <li>
          <span class="condition-desc"
            >Payment confirmation is a message that informs both you and the person or company you are paying
            that a transfer or payment has been successfully completed.
          </span>
        </li>
      </ul>
      <br />
      <div class="conditions">
        <span class="conditiom-heading">How you can protect yourself?</span>
        <span class="condition-desc"
          >Never give your personal details to anyone without making sure that they are who they say they are.
          A Stanbic Bank representative will never ask you for personal or banking information in an email.
          You should view emails and pop-up windows asking for your personal information with the same amount
          of suspicion you would the person behind you in an ATM queue. Treat emails that appear to be from us
          asking for personal details with suspicion. Never reply to their email or get into a conversation
          with them.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading">What you can do if you suspect phishing?</span>
        <span class="condition-desc"
          >Let us know if you are not sure about an email or website saying it is Stanbic Bank. Please forward
          the email to
          <a href="mailto:EnterpriseBW@stanbic.com" tabindex="-1" class="need-help-mail">
            EnterpriseBW@stanbic.com
          </a>
          and we'll investigate. Do not respond to that email. We will get back to you in a few hours after
          we've investigated it. By sending it to us you may help us close down an illegal site and help save
          others from falling victim to these criminals.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading"
          >If you've given out your details or if you think you have in one way or another given your banking
          details to a criminal</span
        >
        <span class="condition-desc">Call us immediately on Contact Number: +267 3987820</span>
      </div>
      <div class="conditions">
        <span class="conditiom-heading">Payments </span>
        <span class="conditiom-heading">How do I make a payment?</span>
        <span class="condition-desc"
          >Log on to Online banking, click on Pay & Transfer, select the required payment option, for example,
          Multiple payments, Self Account transfer, Beneficiary payments, Once off payment, Recurring
          payments.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading">What is an electronic account payment limit?</span>
        <span class="condition-desc"
          >An electronic account payment (EAP) limit is a monthly limit that you load to your profile that
          allows you to make electronic account payments using Internet banking. The limit is reduced every
          time you make an electronic payment. If you have loaded future-dated payments, the limit will be
          reduced on the day of payment.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading"
          >What are the maximum amounts that I can transfer between my accounts?</span
        >
        <span class="condition-desc">There is no limit for transfer of funds between your accounts.</span>
      </div>
      <div class="conditions">
        <span class="conditiom-heading"
          >How long do payments take to reflect on the beneficiary's account?</span
        >
        <span class="condition-desc"
          >If the beneficiary is a Stanbic bank account holder, payments reflect in real-time in the
          beneficiary account. Payments to beneficiaries in other banks may take 2 to 3 days if it is a normal
          transaction or will hit the beneficiary?s account same day if it is an express transaction.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading">What are once-off payments?</span>
        <span class="condition-desc"
          >It's a payment option that lets you pay both companies and individuals without loading their
          details to your list of beneficiaries. To make once-off payment, click on Pay & Transfer on the
          dashboard, select once-off payments, select the transaction type, supply your payment parameters and
          submit your transaction.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading">What are Self Account transfers?</span>
        <span class="condition-desc"
          >You can transfer funds between your accounts - provided that your available credit balances are
          sufficient to cover the transfers. For example, you are able to transfer money from your current to
          your savings account. Select self account transfer from the pay& transfer menu on the dashboard,
          complete your transfer details and submit your transaction.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading">What are multiple payments?</span>
        <span class="condition-desc">You can make payments to 10 beneficiaries in a single instance.</span>
      </div>
      <div class="conditions">
        <span class="conditiom-heading">What are beneficiary payments?</span>
        <span class="condition-desc"
          >You can select and pay a single beneficiary in your list. Select beneficiary payments from the pay
          & transfer menu on the dashboard, select your transaction category, complete your transfer details
          and submit your transaction.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading">Can I perform a multi-currency transaction?</span>
        <span class="condition-desc"
          >Currently, this functionality is not on Online banking. We are working to provide this service
          soon.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading">Beneficiary Management </span>
        <span class="conditiom-heading">How do I add beneficiaries to my Online banking profile?</span>
        <span class="condition-desc"
          >Access Online banking, select Pay & Transfer on the dashboard and select the function button Add
          beneficiary, select the beneficiary bank parameter, complete the beneficiary details and update the
          record.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading">How many beneficiaries can I link to my Online banking profile?</span>
        <span class="condition-desc">There is no limit to the number of beneficiaries at present.</span>
      </div>
      <div class="conditions">
        <span class="conditiom-heading">What do I need to add a beneficiary?</span>
        <span class="condition-desc"
          >You need the beneficiary's details: name, bank, bank branch, phone number and/or email address and
          the beneficiary account number.</span
        >
      </div>
      <div class="conditions">
        <span class="conditiom-heading">Can I create both Stanbic bank and other bank beneficiaries?</span>
        <span class="condition-desc">Yes</span>
      </div>
      <div class="conditions">
        <span class="conditiom-heading"
          >Will my Stanbic bank and other bank beneficiaries be saved in one list?</span
        >
        <span class="condition-desc">Yes</span>
      </div>
      <div class="conditions">
        <span class="conditiom-heading">Can I modify an existing beneficiary?</span>
        <span class="condition-desc">Yes you can modify an existing beneficiary.</span>
      </div>
    </div>
  </div>
</ng-template>
