import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatListModule } from '@angular/material/list';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SbgIconModule } from '@sbg/components/sbg-icon';
import { SbgAvatarModule } from '@sbg/components/sbg-avatar';

import { NativeHeaderComponent } from './components/native-header/native-header.component';
import { NativeFromAccountDataComponent } from './components/native-from-account-data/native-from-account-data.component';
import { NativeSelectAccountComponent } from './components/native-select-account/native-select-account.component';
import { HybridFormNotifyByComponent } from './components/hybrid-form-notify-by/hybrid-form-notify-by.component';
import { HybridSchedulePaymentComponent } from './components/hybrid-schedule-payment/hybrid-schedule-payment.component';
import { HybridNameInitialComponent } from './components/hybrid-name-initial/hybrid-name-initial.component';
import { HybridAccountListComponent } from './components/hybrid-account-list/hybrid-account-list.component';
import { HybridReceiptComponent } from './components/hybrid-receipt/hybrid-receipt.component';
import { HybridButtonComponent } from './components/form-elements/hybrid-button/hybrid-button.component';
import { HybridListTableRecipientComponent } from './components/hybrid-list-table-recipient/hybrid-list-table-recipient.component';
import { HybridLabelButtonComponent } from './components/hybrid-label-button/hybrid-label-button.component';

// pipe
import { NativeSearchFilterPipe } from './pipes/native-search-filter/native-search-filter.pipe';
import { HybridSortByPipe } from './pipes/hybrid-sort-by/hybrid-sort-by.pipe';
import { HybridNumberFormatPipe } from './pipes/hybrid-number-format/hybrid-number-format.pipe';
import { HybridAmountFormatPipe } from './pipes/hybrid-amount-format/hybrid-amount-format.pipe';
import { HybridListFilterComponent } from './components/hybrid-list-filter/hybrid-list-filter.component';
import { SecondaryHybridNameIntialComponent } from './components/secondary-hybrid-name-intial/secondary-hybrid-name-intial.component';
import { HybridToAccountDetailsComponent } from './components/hybrid-to-account-details/hybrid-to-account-details.component';
import { HybridLimitDisplayComponent } from './components/hybrid-limit-display/hybrid-limit-display.component';
import { HybridValidationErrorComponent } from './components/hybrid-validation-error/hybrid-validation-error.component';
import { HybridChooseApproverComponent } from './components/hybrid-choose-approver/hybrid-choose-approver/hybrid-choose-approver.component';
import { HybTabsGroupComponent } from './components/hyb-tabs-group/hyb-tabs-group.component';
import { HybridPaymentRepeaterComponent } from './components/hybrid-payment-repeater/hybrid-payment-repeater.component';
import { HybridManageMenuComponent } from './components/hybrid-manage-menu/hybrid-manage-menu.component';
import { HybridBottomNavbarComponent } from './components/hybrid-bottom-navbar/hybrid-bottom-navbar.component';
// import { HybridRemarkModalComponent } from './components/hybrid-remark-modal/hybrid-remark-modal.component';

@NgModule({
  declarations: [
    NativeHeaderComponent,
    NativeFromAccountDataComponent,
    NativeSelectAccountComponent,
    HybridFormNotifyByComponent,
    NativeSearchFilterPipe,
    HybridSortByPipe,
    HybridSchedulePaymentComponent,
    HybridNameInitialComponent,
    HybridAccountListComponent,
    HybridReceiptComponent,
    HybridButtonComponent,
    HybridListTableRecipientComponent,
    HybridNumberFormatPipe,
    HybridAmountFormatPipe,
    HybridListFilterComponent,
    SecondaryHybridNameIntialComponent,
    HybridToAccountDetailsComponent,
    HybridLimitDisplayComponent,
    HybridValidationErrorComponent,
    HybridChooseApproverComponent,
    HybTabsGroupComponent,
    HybridPaymentRepeaterComponent,
    HybridLabelButtonComponent,
    HybridManageMenuComponent,
    HybridBottomNavbarComponent,
    // HybridRemarkModalComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    MatProgressBarModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatRadioModule,
    FormsModule,
    NgxMatSelectSearchModule,
    MatDatepickerModule,
    MatButtonModule,
    MatListModule,
    SbgIconModule,
    SbgAvatarModule,
  ],
  exports: [
    NativeHeaderComponent,
    NativeFromAccountDataComponent,
    NativeSelectAccountComponent,
    HybridFormNotifyByComponent,
    HybridSchedulePaymentComponent,
    HybridNameInitialComponent,
    HybridAccountListComponent,
    HybridReceiptComponent,
    HybridButtonComponent,
    HybridListTableRecipientComponent,
    HybridListFilterComponent,
    SecondaryHybridNameIntialComponent,
    HybridToAccountDetailsComponent,
    HybridLimitDisplayComponent,
    HybridValidationErrorComponent,
    HybridChooseApproverComponent,
    HybTabsGroupComponent,
    HybridPaymentRepeaterComponent,
    HybridLabelButtonComponent,
    HybridManageMenuComponent,
    HybridBottomNavbarComponent,
    // HybridRemarkModalComponent,

    //Modules
    FlexLayoutModule,
    RouterModule,
    MatInputModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatRadioModule,
    FormsModule,
    NgxMatSelectSearchModule,
    MatDatepickerModule,
    MatButtonModule,
    MatListModule,
    SbgIconModule,
    SbgAvatarModule,
    //pipe
    NativeSearchFilterPipe,
    HybridSortByPipe,
    HybridAmountFormatPipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class NativeSharedModule {}
