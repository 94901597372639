<div
  *ngIf="isRaisedButton"
  class="button__wrapper"
  [class.button-close]="closeButton"
  [ngClass]="{ 'masking-fin-serv': maskPayButton && buttonText === 'Pay' }"
>
  <button
    mat-raised-button
    [type]="getButtonType(buttonType)"
    [disableRipple]="disableRipple"
    [disabled]="isDisabled"
    class="button__custom-properties"
    [attr.aria-label]="ariaLabel"
    [ngClass]="getButtonStyle()"
    (click)="!(maskPayButton && buttonText === 'Pay') && clickButton($event)"
    [class.button__icon-left--left-padding]="iconLeft && buttonText"
    [class.button__icon-right--right-padding]="iconRight && buttonText"
    [class.button__without_outline--disabled]="isDisabled && buttonStyle === 'transparent'"
    [class.button-right-border-radius-none]="rightBorderRadiusClear"
    [class.button-xs]="smallButton"
    [class.button-selected]="buttonSelected"
    [class.button-fixed-width]="fixedWidthButton"
  >
    <!-- <fa-icon *ngIf="showIconLeft" [icon]="showIconLeft" class="leftIcon"></fa-icon> -->
    <!-- <i *ngIf="leftIconFa" class="fa {{leftIconFa}}"></i> -->

    <mat-icon *ngIf="iconLeft" aria-hidden="true" class="material-icons left {{ iconLeft }}">{{
      iconLeft
    }}</mat-icon>
    <span>{{ buttonText }}</span>
    <mat-icon *ngIf="iconRight" aria-hidden="true" class="material-icons right {{ iconRight }}">{{
      iconRight
    }}</mat-icon>

    <!--
    <i *ngIf="rightIconFa" class="fa {{rightIconFa}}"></i>
    <fa-icon *ngIf="showIconRight" [icon]="showIconRight" class="rightIcon"></fa-icon> -->
  </button>
</div>

<div
  *ngIf="!isRaisedButton && !hybButtonFilters"
  class="button__wrapper"
  [class.button-close]="closeButton"
  [ngClass]="{ 'masking-fin-serv': maskPayButton && buttonText === 'Pay' }"
>
  <button
    mat-button
    [type]="getButtonType(buttonType)"
    [disableRipple]="disableRipple"
    [class.button__without_outline--disabled]="isDisabled && buttonStyle === 'transparent'"
    [disabled]="isDisabled"
    class="button__custom-properties"
    [attr.aria-label]="ariaLabel"
    [ngClass]="getButtonStyle()"
    (click)="!(maskPayButton && buttonText === 'Pay') && clickButton($event)"
    [class.button__icon-left--left-padding]="iconLeft && buttonText"
    [class.button__icon-right--right-padding]="iconRight && buttonText"
    [class.button-selected]="buttonSelected"
    [class.button-right-border-radius-none]="rightBorderRadiusClear"
    [class.button-xs]="smallButton"
    [class.button-fixed-width]="fixedWidthButton"
  >
    <!-- <fa-icon *ngIf="showIconLeft" [icon]="showIconLeft" class="leftIcon"></fa-icon> -->
    <!-- <i *ngIf="leftIconFa" class="fa {{leftIconFa}}"></i> -->

    <mat-icon *ngIf="iconLeft" aria-hidden="true" class="material-icons left {{ iconLeft }}">{{
      iconLeft
    }}</mat-icon>
    <span>{{ buttonText }}</span>
    <mat-icon *ngIf="iconRight" aria-hidden="true" class="material-icons right {{ iconRight }}">{{
      iconRight
    }}</mat-icon>

    <!-- <i *ngIf="rightIconFa" class="fa {{rightIconFa}}"></i>
    <fa-icon *ngIf="showIconRight" [icon]="showIconRight" class="rightIcon"></fa-icon> -->
  </button>
</div>

<div
  *ngIf="!isRaisedButton && hybButtonFilters"
  class="hyb-button__wrapper"
  [class.button-close]="closeButton"
  [ngClass]="{ 'masking-fin-serv': maskPayButton && buttonText === 'Pay' }"
>
  <button
    mat-button
    [type]="getButtonType(buttonType)"
    [disableRipple]="disableRipple"
    [class.button__without_outline--disabled]="isDisabled && buttonStyle === 'transparent'"
    [disabled]="isDisabled"
    class="button__custom-properties"
    [attr.aria-label]="ariaLabel"
    [ngClass]="getButtonStyle()"
    (click)="!(maskPayButton && buttonText === 'Pay') && clickButton($event)"
    [class.button__icon-left--left-padding]="iconLeft && buttonText"
    [class.button__icon-right--right-padding]="iconRight && buttonText"
    [class.button-selected]="buttonSelected"
    [class.button-right-border-radius-none]="rightBorderRadiusClear"
    [class.button-xs]="smallButton"
    [class.button-fixed-width]="fixedWidthButton"
  >
    <mat-icon *ngIf="iconLeft" aria-hidden="true" class="material-icons left {{ iconLeft }}">{{
      iconLeft
    }}</mat-icon>
    <span class="hyb-filter-button-text">{{ buttonText }}</span>
    <mat-icon *ngIf="iconRight" aria-hidden="true" class="material-icons right {{ iconRight }}">{{
      iconRight
    }}</mat-icon>
  </button>
</div>
