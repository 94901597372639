export class TransactConstants {
  static AMOUNT_CURRENCY = {
    MAX_VALUE_LIMIT: 999999999.99,
    MIN_VALUE_LIMIT: 0.0,
    AMOUNT_NOT_POSITIVE_VALIDATION_ERROR: 'Please enter an amount',
    AMOUNT_TOO_LARGE_VALIDATION_ERROR: 'Amount too large',
    AMOUNT_EXCEED_YOUR_AVAILABLE_EAP: 'Amount exceeds your available EAP limit',
    AMOUNT_EXCEED_YOUR_AVAILABLE_BALANCE: 'Amount exceeds your available balance',
  };

  static SUB_TEXT = {
    BILLER_NAME: 'Enter the company or organization name',
    BILLER_REFERENCE: 'Enter your account, meter or customer number',
    YOUR_REFERENCE: 'This will appear on your statement',
    BRANCH_CODE_OPTIONAL: 'This is optional. This info will make your payment faster and cheaper.',
    FX_FEE_DISCLAIMER: 'Avoid extra FX charges by paying in the local currency of the country selected',
  };

  static ACCOUNTS = {
    FROM_ACCOUNT_FEATURE_TRANSFER: 'TRANSFERFROM',
    FROM_ACCOUNT_FEATURE_PAY: 'PAYMENTFROM',
    FROM_ACCOUNT_FEATURE_BUY: 'VAS_PURCHASE',
    TO_ACCOUNT_FEATURE: 'TRANSFERTO',
  };

  static CHARGETYPES = [
    {
      name: 'Shared',
      description: 'You and the beneficiary share the fees',
      chargeType: 'SHARED',
    },
    {
      name: 'Remitter',
      description: 'You pay all the fees',
      chargeType: 'REMITTER',
    },
    {
      name: 'Beneficiary',
      description: 'Beneficiary pays all the fees',
      chargeType: 'BENEFICIARY',
    },
  ];

  static PAYMENT_OVERFLOW_OPTIONS = [
    {
      label: 'Edit',
      territoryKey: 'ALL',
      icon: 'icn_pencil',
      hidden: false,
      analyticsId: '<<type>>',
      analyticsName: '<<type>> | overflow menu | edit',
    },
    {
      label: 'Delete',
      territoryKey: 'ALL',
      icon: 'icn_bin',
      hidden: false,
      analyticsId: '<<type>>',
      analyticsName: '<<type>> | overflow menu | delete',
    },
    {
      label: 'Add to group',
      territoryKey: 'SBSA',
      icon: 'icn_people_3_add',
      hidden: true,
      analyticsId: '<<type>>',
      analyticsName: '<<type>> | overflow menu | add to group',
    },
    {
      label: 'Duplicate',
      territoryKey: 'SBOG',
      icon: 'icn_people_2',
      hidden: true,
      analyticsId: '<<type>>',
      analyticsName: '<<type>> | overflow menu | duplicate',
    },
  ];

  static BENEFICIARY = 'Beneficiary';
}
