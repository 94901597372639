import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServiceEndpointsService } from '../../core/service-endpoints/service-endpoints.service';
import { Injectable } from '@angular/core';
import { ChangePasswordRequest } from './data/change-password-request';
import { ResetPasswordRequest } from './data/reset-password-request';
import { HttpResponse } from '@angular/common/http';
import { SecurityQuestionsResponse } from './data/security-questions-response';
import { FetchSecurityQuestionsRequest } from './data/fetch-security-questions-request';
import { SetSecurityQuestionsRequest } from './data/set-security-questions-request';
import { McaMessage } from '../../core/data/mca-message';
import { FetchUsernameSuggestionsResponse } from './data/fetch-username-suggestions-response';
import { ChangeUsernameRequest } from './data/change-username-request';

@Injectable()
export class RegistrationService {
  constructor(private serviceEndpointService: ServiceEndpointsService) {}

  fetchRetailSecurityChallenge(
    payload: FetchSecurityQuestionsRequest
  ): Observable<SecurityQuestionsResponse> {
    return this.serviceEndpointService
      .post<FetchSecurityQuestionsRequest, SecurityQuestionsResponse>('fetchRetailSecurityChallenge', payload)
      .pipe(map((res) => res.body));
  }

  registerIBUser(payload): Observable<any> {
    return this.serviceEndpointService.makeRequest(payload, 'registerIBUser').pipe(
      map((res) => {
        return res;
      })
    );
  }

  completeRegisterIBUser(payload): Observable<any> {
    return this.serviceEndpointService.makeRequest(payload, 'completeRegisterIBUser').pipe(
      map((res) => {
        return res;
      })
    );
  }

  registerSecurityQuestions(payload: SetSecurityQuestionsRequest): Observable<any> {
    return this.serviceEndpointService.makeRequest(payload, 'registerSecurityQuestions').pipe(
      map((res) => {
        return res;
      })
    );
  }

  resetPassword(payload: ResetPasswordRequest): Observable<any> {
    return this.serviceEndpointService.makeRequest(payload, 'IBSecurityChallenge').pipe(
      map((res) => {
        return res;
      })
    );
  }

  fetchSecurityQuestions(payload): Observable<any> {
    return this.serviceEndpointService.makeRequest(payload, 'fetchSecurityQuestions').pipe(
      map((res) => {
        return res;
      })
    );
  }

  changePassword(payload: ChangePasswordRequest): Observable<HttpResponse<McaMessage>> {
    return this.serviceEndpointService
      .post<ChangePasswordRequest, McaMessage>('changeIBPassword', payload)
      .pipe(map((res) => res));
  }
  pingForceChangePassword(payload: ChangePasswordRequest): Observable<HttpResponse<McaMessage>> {
    return this.serviceEndpointService
      .post<ChangePasswordRequest, McaMessage>('IBPasswordChallenge', payload)
      .pipe(map((res) => res));
  }
  setRetailSecurityQuestions(payload: SetSecurityQuestionsRequest): Observable<HttpResponse<McaMessage>> {
    return this.serviceEndpointService
      .post<SetSecurityQuestionsRequest, McaMessage>('setRetailSecurityQuestions', payload)
      .pipe(map((res) => res));
  }

  fetchUsernameSuggestions(): Observable<string[]> {
    return this.serviceEndpointService
      .get<FetchUsernameSuggestionsResponse>('fetchUsernameSuggestions')
      .pipe(map((res) => res.body.usernames));
  }

  changeUsername(payload: ChangeUsernameRequest): Observable<HttpResponse<McaMessage>> {
    return this.serviceEndpointService.post<ChangeUsernameRequest, McaMessage>('changeUsername', payload);
  }

  validateUsername(payload: ChangeUsernameRequest): Observable<HttpResponse<McaMessage>> {
    return this.serviceEndpointService.post<ChangeUsernameRequest, McaMessage>('validateUsername', payload);
  }

  forgotUsername(payload): Observable<HttpResponse<McaMessage>> {
    return this.serviceEndpointService.post('UsernameChanllenge', payload);
  }
}
