import { createAction, props } from '@ngrx/store';
import { SelectedAirtimeData, AirtimeReceiptData } from '../interfaces/buy.model';
import { AirtimeModel } from '../../../components/buy/prepaid-models.module';

export const setSelectedAirtimeData = createAction(
  '[AirtimeList Component] selectAirtime',
  props<{ selectedAirtimeData: SelectedAirtimeData }>()
);

export const setAirtimeReceiptListData = createAction(
  '[AirtimeList Component] setReceiptList',
  props<{ receiptList: Array<any> }>()
);

export const setAirtimeData = createAction(
  '[AirtimeList Component] setAirtimeData',
  props<{ airtimeData: AirtimeModel }>()
);

export const setAirtimeReceiptData = createAction(
  '[AirtimeList Component] setAirtimeReceiptData',
  props<{ airtimeReceiptData: AirtimeReceiptData }>()
);
