import { Component, Input } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AbstractControlDirective, AbstractControl, NgModel } from '@angular/forms';

@Component({
  selector: 'sbg-validation-error',
  templateUrl: './validation-error.component.html',
  styleUrls: ['./validation-error.component.scss'],
})
export class ValidationErrorComponent {
  @Input() placeholder: string;
  @Input() private control: NgModel;

  controls;

  private errorMessages = {
    required: () => (this.placeholder ? this.placeholder + ' is required' : 'Mandatory field'),
    insufficientFunds: this.displayErrorMessage,
    errorMsg: this.displayErrorMessage,
    amountInvalid: this.displayErrorMessage,
    dailyWithdrawalLimitCrossed: this.displayErrorMessage,
    monthlyWithdrawalLimitCrossed: this.displayErrorMessage,
    mobilevalidator: this.displayErrorMessage,
    minlength: this.minLengthErrorMessage,
    email: this.notValidErrorMessage,
    basicValidator: this.displayErrorMessage,
  };

  shouldShowErrors(): boolean {
    if (
      (this.control.dirty && !this.control.touched) ||
      (this.control.errors && this.control.dirty && !this.control.touched)
    ) {
      this.control.control.markAsTouched();
    }

    const errorStatus = this.control && this.control.errors && (this.control.dirty || this.control.touched);

    return errorStatus;
  }

  listOfErrors(): string[] {
    return Object.keys(this.control.errors).map((field) =>
      this.getMessage(field, this.control.errors[field])
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private displayErrorMessage(params, parentParams): string {
    return params.message || params.status;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private minLengthErrorMessage(params, parentParams) {
    return 'Minimum of ' + params.requiredLength + ' digits are required.';
  }

  private notValidErrorMessage(params, parentParams) {
    return parentParams.placeholder + ' is not valid.';
  }

  private getMessage(type: string, params: any) {
    return this.errorMessages[type](params, this);
  }
}
