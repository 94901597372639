// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { RootStore } from '../state-management/state/root-state';
import { ServiceEndpointsService } from '../service-endpoints/service-endpoints.service';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { CardProfile, LimitType } from './data/card-profile';
import { environment } from './../../../environments/environment';
import { AccountListRequest } from './data/account-list-request';
import { GipAccountDetailsRequest } from './data/gip-account-details-request';
import { AccountDetailsResponse } from './data/account-details-response';
import { Amount } from '../data/amount';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';


@Injectable({ providedIn: 'root' })
export class AccountService {
  // data to be received from tile click
  selectedAccount: object;
  window: any;
  cardProfile: any;
  loginData;
  accountsObject;
  accounts: Observable<RootStore>;
  isAccountsCacheValid = false;
  storeState;
  envConfig = environment.config;
  isHybrid: any;

  constructor(
    private serviceEndpoint: ServiceEndpointsService,
    private http: HttpClient,
    @Inject(DOCUMENT) private document: Document,
    public ngrxStore: Store<{ appReducer }>,
    private store: Store<any>
  ) {
    ngrxStore.select('appReducer').subscribe((stateData: any) => {
      this.storeState = stateData;
    });

    // this.storeState = store.getState();
    this.window = this.document.defaultView;

    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      if (Object.prototype.hasOwnProperty.call(value, 'isMobileActive')) {
        this.isHybrid = value.isMobileActive.isHybrid;
      }
    });
  }

  getCardNumber() {
    const principalIdPayload = {};
    return this.serviceEndpoint
      .makeRequest(principalIdPayload, 'cardForSystemPrincipalId')
      .pipe(map((res) => res));
  }

  loadOverdraf(payload) {
    return this.serviceEndpoint.makeRequest(payload, 'loadOverdraft').pipe(map((res) => res));
  }

  fetchAccounts(authData: AccountListRequest): Observable<any> {
    return this.serviceEndpoint.makeRequest(authData, 'accounts').pipe(map((res) => res));
  }
  getCreditCardStatement(creditPayload) {
    return this.serviceEndpoint.makeRequest(creditPayload, 'creditCardStatement').pipe(map((res) => res));
  }

  fetchStatement(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'statement').pipe(map((res) => res));
  }
  viewStatementMini(viewPayload) {
    return this.serviceEndpoint.makeRequest(viewPayload, 'viewStatementMini').pipe(map((res) => res));
  }
  renameAccount(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'renameAccount').pipe(map((res) => res));
  }

  fetchDebitCards(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'debitCards').pipe(map((res) => res));
  }
  fetchDebitCardProducts(): Observable<{}> {
    return this.serviceEndpoint.makeRequest({}, 'debitCardProducts').pipe(map((res) => res));
  }
  issueDebitCard(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'IssueDebitCard').pipe(map((res) => res));
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  stopDebitCard(payload, cardData): Observable<{}> {
    if (this.envConfig.oldDebitCardService) {
      return this.serviceEndpoint.makeRequest(payload, 'stopDebitCards').pipe(map((res) => res));
    } else {
      if (payload.debitCard.cardStatus !== 'ACTIVE') {
        return this.serviceEndpoint.makeRequest(payload, 'stopDebitCardsTZ').pipe(map((res) => res));
      } else {
        return this.serviceEndpoint.makeRequest(payload, 'activateDebitCardsTZ').pipe(map((res) => res));
      }
    }
  }

  updateDebitCardLimits(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'updateLimitDebitCards').pipe(map((res) => res));
  }

  getAccountDetails(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'getAccountDetails').pipe(
      map((res) => {
        return res;
      })
    );
  }

  getServiceRequestTypes(): Observable<{}> {
    return this.serviceEndpoint.makeRequest({}, 'newServiceRequest').pipe(map((res) => res));
  }

  uplOfferDetails(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'uplOfferDetails').pipe(map((res) => res));
  }

  uplandFeeInsurance(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'UplFeeAndInsurance').pipe(map((res) => res));
  }

  redrawLoan(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'RedrawLoan').pipe(map((res) => res));
  }

  fetchOfferApplicationSettings(payload) {
    return this.serviceEndpoint.makeRequest(payload, 'fetchOfferApplicationSettings');
  }
  downloadKfdDocument(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'downloadKfdDocument');
  }
  async downloadKfdPdf(payload): Promise<any> {


    const mediaType = 'application/pdf';
    this.http
      .post('/roa-mobile/rest/AccountService/DownloadKfdDocument', payload, {
        responseType: 'blob',
      })
      .subscribe(
        (response) => {
          const blob = new Blob([response], { type: mediaType });
          // this.window.saveAs(blob, 'Key_Facts_Document.pdf');
          if (this.isHybrid) {
            this.convertToBase64(blob, 'application/pdf', 'Key_Facts_Document.pdf', true);
          }
          else {
            saveAs(blob, 'Key_Facts_Document.pdf');

          }

        },
        (e) => {
          console.error(e);
        }
      );
  }
  fetchGipAccountDetails(request: GipAccountDetailsRequest) {
    return this.serviceEndpoint.post<GipAccountDetailsRequest, AccountDetailsResponse>(
      'gipAccountDetails',
      request
    );
  }

  extractLocalCurrencyLimits(accountSummary) {
    const limitsArray = accountSummary.cardProfile.limits;
    if (limitsArray) {
      for (let i = limitsArray.length - 1; i >= 0; i--) {
        const value = limitsArray[i];
        if (value.amount.currency === this.envConfig.localCurrency) {
          switch (value.type) {
            case 'DAILY':
              accountSummary.cardProfile.dailyWithdrawalLimit = value.amount;
              break;
            case 'DAILY_UTILIZED':
              accountSummary.cardProfile.usedEAPLimit = value.amount;
              break;
            case 'MONTHLY':
              accountSummary.cardProfile.monthlyWithdrawalLimit = value.amount;
              break;
            case 'MONTHLY_UTILIZED':
              accountSummary.cardProfile.monthlyUsedLimit = value.amount;
              break;
            case 'MONTHLY_SELF_FUNDS':
              accountSummary.cardProfile.monthlySFTWithdrawalLimit = value.amount;
              break;
            case 'MONTHLY_SELF_FUNDS_UTILIZED':
              accountSummary.cardProfile.monthlySFTUsedLimit = value.amount;
              break;
            default:
              console.error('Response contains limit ' + value.type + ' which is not accepted');
              break;
          }
        }
      }
    }
    return accountSummary;
  }

  getLimit(type: LimitType, currency?: string): Amount {
    if (this.cardProfile && this.cardProfile.limits) {
      const foundLimit = this.cardProfile.limits.find(
        (limit) => limit.type === type && (currency ? limit.amount.currency === currency : true)
      );
      if (foundLimit) {
        return foundLimit.amount;
      }
    }
  }
  indicativeReinvestmentDetails(payload) {
    return this.serviceEndpoint.makeRequest(payload, 'indicativeReinvestmentDetails').pipe(map((res) => res));
  }

  updateReinvestmentDetails(payload) {
    return this.serviceEndpoint.makeRequest(payload, 'updateReinvestmentDetails').pipe(map((res) => res));
  }
  private updateLoginData(res) {
    this.loginData = res.loginReducer;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  downloadStampedStatement(payload) {
    return this.serviceEndpoint.makeRequest(payload, 'downloadStampedStatement').pipe(map((res) => res));
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  downloadAccountConfirmationLetter(payload) {
    return this.serviceEndpoint
      .makeRequest(payload, 'downloadAccountConfirmationLetter')
      .pipe(map((res) => res));
  }
  convertToBase64(res_or_blobData, mimeType: string, docName: string, blobfile: boolean = false) {
    let dataUrl;
    let blob;
    if (blobfile) {
      blob = res_or_blobData;
    } else {
      blob = new Blob([res_or_blobData], { type: mimeType });
    }
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      dataUrl = reader.result;
      const downloadLink = document.createElement('a');
      downloadLink.href = dataUrl;
      downloadLink.download = docName;
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      // Clean up the download link element
      document.body.removeChild(downloadLink);
    };
  }
}
