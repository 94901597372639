import { createReducer, on } from '@ngrx/store';
import { setUnayoFormData, setUnayoReceiptData, setSelectedReceipientsData } from '../actions/unayo.action';
import { UnayoState } from '../state/unayo.state';

const UnayoInitState = {};

const selectedBeneInitState = {};
const setUnayoInitState = {};

const unayoData: UnayoState = {
  selectedReceipientsData: selectedBeneInitState,
  unayoFormData: UnayoInitState,
  unayoReceiptData: setUnayoInitState,
};

export const unayoReducer = createReducer(
  unayoData,

  on(setSelectedReceipientsData, (state, { selectedReceipientsData }) => ({
    ...state,
    selectedReceipientsData,
  })),

  on(setUnayoFormData, (state, { unayoFormData }) => ({
    ...state,
    unayoFormData,
  })),

  on(setUnayoReceiptData, (state, { unayoReceiptData }) => ({
    ...state,
    unayoReceiptData,
  }))
);
