import { Store } from '@ngrx/store';
import { DataSharingService } from './../../../core/data-sharing/data-sharing.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sbg-display-approval-rules',
  templateUrl: './display-approval-rules.component.html',
  styleUrls: ['./display-approval-rules.component.scss'],
})
export class DisplayApprovalRulesComponent implements OnInit {
  selectedApprover;
  isHybrid: any;
  constructor(private dataSharingService: DataSharingService, private store: Store<{ appReducer }>) {
    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      if (Object.prototype.hasOwnProperty.call(value, 'isMobileActive')) {
        this.isHybrid = value.isMobileActive.isHybrid;
      }
    });
  }

  ngOnInit() {
    this.selectedApprover = this.dataSharingService.selectedApprover
      ? this.dataSharingService.selectedApprover
      : undefined;
  }
}
