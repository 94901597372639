import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sbg-flash-screen',
  templateUrl: '../../../../cms/templates/flashScreen.html',
  styleUrls: ['./flash-screen.component.scss'],
})
export class FlashScreenComponent {
  @Input() preHybrid: string;
  @Input() loginMode: string;
  @Output() isLogin: EventEmitter<any> = new EventEmitter();
  @Output() register: EventEmitter<any> = new EventEmitter();

  goTologin() {
    this.isLogin.emit(true);
    // this.isLogin = true;
  }
  goToRegistration() {
    this.register.emit(true);
  }
}
