import { Injectable } from '@angular/core';
import { ServiceEndpointsService } from '../service-endpoints/service-endpoints.service';
import { PaginatedRequest } from '../data/paginated-request';
import { Observable } from 'rxjs';
import { RecipientsResponse } from './data/recipients-response';
import { map } from 'rxjs/operators';
import { RecipientGroupsResponse } from './data/recipient-groups-response';
import { RecipientManagementRequest } from './data/recipient-management-request';
import { RecipientGroup } from './data/recipient-group';
import { RecipientGroupsRequest } from './data/recipient-groups-request';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class CmaRecipientService {
  constructor(private backendService: ServiceEndpointsService) {}

  getCmaRecipients(request: PaginatedRequest): Observable<RecipientsResponse> {
    return this.backendService
      .post<PaginatedRequest, RecipientsResponse>('getCmaRecipients', request)
      .pipe(map((response) => response.body));
  }

  getCmaGroups(): Observable<RecipientGroupsResponse> {
    return this.backendService
      .post<any, RecipientGroupsResponse>('getCmaGroups', {})
      .pipe(map((response) => response.body));
  }

  addCmaGroup(group: RecipientGroup): Observable<HttpResponse<RecipientGroupsResponse>> {
    return this.backendService.post<RecipientGroupsRequest, RecipientGroupsResponse>('addCmaGroup', {
      groups: [group],
    });
  }

  editCmaGroup(group: RecipientGroup): Observable<HttpResponse<RecipientGroupsResponse>> {
    return this.backendService.post<RecipientGroupsRequest, RecipientGroupsResponse>('editCmaGroups', {
      groups: [group],
    });
  }

  deleteCmaGroup(group: RecipientGroup): Observable<HttpResponse<RecipientGroupsResponse>> {
    return this.backendService.post<RecipientGroupsRequest, RecipientGroupsResponse>('deleteCMAGroups', {
      groups: [group],
    });
  }

  addCmaRecipient(request: RecipientManagementRequest): Observable<HttpResponse<RecipientsResponse>> {
    return this.backendService.post('addCmaBeneficiary', request);
  }

  editCmaRecipient(request: RecipientManagementRequest): Observable<HttpResponse<RecipientsResponse>> {
    return this.backendService.post<RecipientManagementRequest, RecipientsResponse>(
      'editCmaRecipients',
      request
    );
  }
}
