import { createReducer, on } from '@ngrx/store';
import * as borderlessPaymentAction from '../actions/borderlessPayment.action';
import { BorderlessPaymentState } from '../state/borderlessPayment.state';

const borderLessInitialState: BorderlessPaymentState = {
  flow: '',
  exchangeRateData: {},
  exchangeRateType: {},
  beneDetails: {},
  beneData: {},
  transactionDetails: {},
  receiptData: {},
  countryCodes: [],
};
export const borderlessPaymentReducer = createReducer(
  borderLessInitialState,
  on(borderlessPaymentAction.flow, (state, { flow }) => ({
    ...state,
    flow,
  })),
  on(borderlessPaymentAction.exchangeRateData, (state, { exchangeRateData }) => ({
    ...state,
    exchangeRateData,
  })),
  on(borderlessPaymentAction.exchangeRateType, (state, { exchangeRateType }) => ({
    ...state,
    exchangeRateType,
  })),
  on(borderlessPaymentAction.beneDetails, (state, { beneDetails }) => ({
    ...state,
    beneDetails,
  })),
  on(borderlessPaymentAction.beneData, (state, { beneData }) => ({
    ...state,
    beneData,
  })),
  on(borderlessPaymentAction.transactionDetails, (state, { transactionDetails }) => ({
    ...state,
    transactionDetails,
  })),
  on(borderlessPaymentAction.receiptData, (state, { receiptData }) => ({
    ...state,
    receiptData,
  })),
  on(borderlessPaymentAction.countryCodesData, (state, { countryCodes }) => ({
    ...state,
    countryCodes,
  }))
);
