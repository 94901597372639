import { createReducer, on } from '@ngrx/store';

import * as airtimeAction from '../actions/airtime.action';
import { AirtimeState } from '../state/airtime.state';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AirtimeData } from '../interfaces/buy.model';
// {setSelectedAirtimeData, setReceiptListData, setAirtimeData}

const selectedAirtimeInitState = {
  amount: {},
  basePrepaidProvider: {},
  date: null,
  description: '',
  fileSequenceNumber: '',
  initiatorAccount: '',
  rechargeNumber: '',
  referenceId: '',
  status: '',
  transactionId: '',
  type: '',
};

const airtimeInitData: AirtimeState = {
  selectedAirtimeData: selectedAirtimeInitState,
  receiptList: [{}],
  airtimeData: null,
  airtimeReceiptData: {},
};
export const airtimeReducer = createReducer(
  airtimeInitData,
  on(airtimeAction.setSelectedAirtimeData, (state, { selectedAirtimeData }) => ({
    ...state,
    selectedAirtimeData,
  })),
  on(airtimeAction.setAirtimeReceiptListData, (state, { receiptList }) => {
    return { ...state, receiptList };
  }),
  on(airtimeAction.setAirtimeData, (state, { airtimeData }) => {
    // state.airtimeData = airtimeData;
    // return state;
    return { ...state, airtimeData };
  }),
  on(airtimeAction.setAirtimeReceiptData, (state, { airtimeReceiptData }) => {
    // state.airtimeData = airtimeData;
    // return state;
    return { ...state, airtimeReceiptData };
  })
);
