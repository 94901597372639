import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ServiceEndpointsService } from './../../core/service-endpoints/service-endpoints.service';
import { Injectable } from '@angular/core';
@Injectable()
export class OtpService {
  constructor(private serviceEndpoint: ServiceEndpointsService) {}
  getOtpConfirm(transPayloadWithOtp): Observable<{}> {
    return this.serviceEndpoint.makeRequest(transPayloadWithOtp, 'transactions').pipe(map((res) => res));
  }

  resendOtp(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'resendOtp').pipe(map((res) => res));
  }
}
