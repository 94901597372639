import { Pipe, PipeTransform } from '@angular/core';
import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';

@Pipe({
  name: 'sortBy',
})
export class SortByPipe implements PipeTransform {
  transform(value: any[], order, column = ''): any[] {
    // order : 'asc' or 'desc'
    if (!value || order === '' || !order) {
      return value;
    } // no array
    if (!column || column === '') {
      return sortBy(value);
    } // sort 1d array
    if (value.length <= 1) {
      return value;
    } // array with only one item
    return orderBy(value, [column], [order]);
  }

  /*  transform(value: any, args?: any): any {
     return null;
   } */
}
