import { Injectable } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import { Store } from '@ngrx/store';
import * as dashboardActions from '../../shared/store-utilities/actions/dashboard.action';
import { environment } from './../../../environments/environment';
import { DateUtil } from '../utility-classes/date.util';
import { McaHeader } from '../data/mca-header';
import { McaResponseType } from '../data/mca-response-type';

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'dddd, DD MMM YY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Injectable({
  providedIn: 'root',
})
export class CommonServiceService {
  isSmeMode;
  accountsList;
  dashboardData;
  config = environment.config;
  constructor(private store: Store<{ appReducer }>) {
    this.store.select('appReducer', 'loginReducer').subscribe((data) => {
      if (data) {
        this.isSmeMode = data.isSmeMode;
      }
    });
  }

  /* This function is added by Hardik : Please confirm with @hardik if you have any doubt in this function */
  // moved to masking-service
  /*removeMaskedFeatures(listOfMenu, module): void {
    let menuItems = [] as any;
    let featureMask = this.isSmeMode ? this.config.isFeatureHide[module].sme : this.config.isFeatureHide[module].retail;

    menuItems = listOfMenu.filter((menu) => {
      if (featureMask.find((x) => x === menu.module)) return false;
      else return true;
    });

    return menuItems;
  }*/

  getDateRange(requiredDate, currentDateSet?) {
    const dateRange = {} as any;

    if (!currentDateSet) {
      dateRange.dateTo = DateUtil.format(new Date(), DateUtil.ISO_YYYY_MM_DD);
      dateRange.dateFrom = DateUtil.format(
        DateUtil.subtract(dateRange.dateTo, requiredDate - 1, 'days'),
        DateUtil.ISO_YYYY_MM_DD
      );
    } /* else {
      dateRange.dateTo = moment(DataCache.returnData('dateRange').dateFrom).subtract(1, 'days').format('YYYY-MM-DD');
      dateRange.dateFrom = moment(dateRange.dateTo).subtract(((requiredDate - 1) - currentDateSet), 'days').format('YYYY-MM-DD');
    } */
    return dateRange;
  }

  goToAccountUpdate(fromAccountUpdatedData) {
    this.store.select('appReducer', 'dashboardReducer').subscribe((stateData) => {
      this.dashboardData = cloneDeep(stateData.accounts);
      this.accountsList = stateData.accounts.body.accounts;
    });

    const index = findIndex(this.accountsList, {
      number: fromAccountUpdatedData.number,
    });
    const updatedAccountsArray = this.accountsList.slice(0);
    updatedAccountsArray[index] = fromAccountUpdatedData;
    this.dashboardData.body.accounts = cloneDeep(updatedAccountsArray);

    this.store.dispatch(dashboardActions.setAccountData({ accounts: this.dashboardData }));
  }

  isResponseTypeSuccess(responseObj) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const a = responseObj.headers.get(McaHeader.X_SBG_RESPONSE_TYPE);
    return responseObj.headers.get(McaHeader.X_SBG_RESPONSE_TYPE) === McaResponseType.SUCCESS;
    // return responseObj.headers('x-sbg-response-type') === 'OK' || responseObj.headers('x-sbg-response-type') === 'SUCCESS';
  }
}
