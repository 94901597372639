import { createAction, props } from '@ngrx/store';
import { SelfAccountFormData, SetSelfAccTransferReceiptData } from '../interfaces/selfTransfer.model';

export const setSelfAccountFormData = createAction(
  '[SelfAccountTransfer Component] setSelfAccountFormData',
  props<{ selfAccountFormData: SelfAccountFormData }>()
);

export const setSelfTransferReceiptData = createAction(
  '[SelfAccountTransfer Component] setSelfTransferReceiptData',
  props<{ setSelfAccTransferReceiptData: SetSelfAccTransferReceiptData }>()
);
