import { createAction, props } from '@ngrx/store';
import {
  MobileWalletFormData,
  MobileWalletReviewData,
  MobileVerifiedData,
} from '../interfaces/mobileWallet.model';

export const setMobileWalletDetailsData = createAction(
  '[setMobileWalletDetailsData Component] setMobileWalletDetailsData',
  props<{ mobileWalletFormData: MobileWalletFormData }>()
);

export const setMobileWalletReceiptData = createAction(
  '[setMobileWalletReceiptData Component] setMobileWalletReceiptData',
  props<{ mobileWalletReviewData: MobileWalletReviewData }>()
);

export const setMobileVerifiedDetails = createAction(
  '[setMobileVerified COmponent] setMobileVerifiedDetails',
  props<{ mobileVerifiedData: MobileVerifiedData }>()
);

export const setMobileWalletReceiptListData = createAction(
  '[MobileWalletList Component] setReceiptList',
  props<{ receiptList: Array<any> }>()
);
