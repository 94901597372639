import { LoaderState } from './loader.model';
import { Subscription } from 'rxjs';
import { LoaderService } from './loader.service';
import { OnInit, Component, OnDestroy } from '@angular/core';
import { DataSharingService } from '../../core/data-sharing/data-sharing.service';
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit, OnDestroy {
  show = false;
  moduleData;
  subscriptions: Subscription[] = [];
  subs1$: Subscription;
  subs2$: Subscription;
  constructor(private loaderService: LoaderService, private dataSharingService: DataSharingService) {}
  ngOnInit() {
    this.subs1$ = this.loaderService.loaderState.subscribe((state: LoaderState) => {
      this.show = state.show;
    });

    this.subs2$ = this.dataSharingService.getCloseCurrentModule().subscribe((value) => {
      this.moduleData = value;
    });
    this.subscriptions.push(this.subs1$, this.subs2$);
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((item) => {
      if (item !== undefined) {
        item.unsubscribe();
      }
    });
  }
}
