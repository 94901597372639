// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { UplInsurance } from './../interfaces/uplInsurance.interface';
import { SetUplConfirm, SetUplDetailsInfo, SetNewUplDetailsInfo } from './../actions/uplConfirmInfo.action';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
export class UplConfirmModel {
  uplDetails: any;
  uplInsurance: any;
  uplnewDetails: any;
}
@Injectable()
@State<UplConfirmModel>({
  name: 'uplConfirm',
  defaults: {
    uplDetails: [],
    uplInsurance: [],
    uplnewDetails: [],
  },
})
export class UplConfirmState {
  @Selector()
  static getUplConfirm(state: UplConfirmModel) {
    return state.uplDetails;
  }
  @Selector()
  static getUplInsuranceDetails(state: UplConfirmModel) {
    return state.uplInsurance;
  }
  @Selector()
  static getNewUplInsuranceDetails(state: UplConfirmModel) {
    return state.uplnewDetails;
  }
  @Action(SetUplConfirm)
  setUplConfirm({ getState, setState }: StateContext<UplConfirmModel>, { payload }: SetUplConfirm) {
    const state = getState();
    setState({
      ...state,
      uplDetails: payload,
    });
  }
  @Action(SetUplDetailsInfo)
  setUplDetailsInfo({ getState, setState }: StateContext<UplConfirmModel>, { payload }: SetUplDetailsInfo) {
    const state = getState();
    setState({
      ...state,
      uplInsurance: payload,
    });
  }
  @Action(SetNewUplDetailsInfo)
  setNewUplDetailsInfo(
    { getState, setState }: StateContext<UplConfirmModel>,
    { payload }: SetNewUplDetailsInfo
  ) {
    const state = getState();
    setState({
      ...state,
      uplnewDetails: payload,
    });
  }
}
