export class PingConstants {
  static SCOPES = {
    OPEN_ID: 'openid',
    PROFILE: 'profile',
    EMAIL: 'email',
    DIRECTORY_API: 'urn:pingidentity:directory-api',
    PLATFORM_ALL: 'sbg-platform.all',
    CORE_CUSTOMER_PLATFORM: 'sbg-corecustomerplatform.all',
    PAYMENTS: 'sbg-payment.all',
    SECURITY: 'securemessaging.*.post',
    WEALTH_INTERNATIONAL_SERVICES: 'wealthInternationalServices.*.*',
    BANKING_PBBI: 'banking-pbbi.*.*',
    CARDS: 'card-domain.*.*',
  };

  static LOCAL_IDENTITY_PROFILE_ID = 'j8P3DXlgTg20Yhic';
  static USERTYPE = 'password';
  static ENDPOINTS = {
    SIGN_UP: '/as/authorization.oauth2',
    USER_PROFILE: '/pf/idprofile.ping',
    PING_LOGOUT: '/idp/startSLO.ping',
  };
}
