<!-- eslint-disable prettier/prettier -->
<ng-container *ngIf="!isHybrid">
  <sbg-header></sbg-header>
  <section class="module-wrap">
    <sbg-head-pagination></sbg-head-pagination>

    <div class="container-side otpContainer" #otp>
      <div class="row" class="router-container">
        <ng-container class="columns details-container">
          <form (ngSubmit)="goNext(); ngform.reset()" #ngform="ngForm" #oref>
            <div class="details-container__body">
              <div class="text-center">
                <div
                  *ngIf="receiptModel.type[0] === 'SUCCESS' || receiptModel.type[0] === 'OK'"
                  class="display-block"
                >
                  {{ message }}
                </div>
                <div class="otp-error-message" *ngIf="errorMsg">
                  {{ errorMsg }}
                </div>
                <span class="otp-desc display-block" *ngIf="!showBothContacts && !errorMsg"
                  >A One-time PIN has been sent to the following

                  {{ detailsReceived?.stepUp?.contactMethod }}
                </span>
                <span class="otp-desc display-block" *ngIf="showBothContacts"
                  >A One-time PIN has been sent to the following number & Email ID
                </span>
                <!-- <span class="otp-desc display-block" *ngIf="singleOtpBox"
                  >A One-time Password has been sent to your registered mobile
                  number</span
                > -->

                <span *ngIf="env.emailOtp" class="user-mobile-no display-block">
                  {{ detailsReceived?.stepUp?.maskedAddress }}
                </span>
                <span *ngIf="!env.emailOtp" class="user-mobile-no display-block">
                  {{ detailsReceived?.stepUp?.maskedAddress }}
                </span>
                <!-- <span class="user-mobile-no display-block" *ngIf="showBothContacts">{{maskedMail}}</span> -->
                <span class="enter-otp display-block"
                  >Please enter One-time&nbsp;<span>PIN</span>
                  <!-- <span *ngIf="singleOtpBox">Password</span> -->
                </span>
                <div class="resend-pin center-aligned">
                  <div class="opt-input">
                    <mat-form-field>
                      <input
                        matInput
                        type="tel"
                        #firstInput
                        required
                        name="input1"
                        id="input1"
                        [(ngModel)]="otpInputFields.intakeMasked[0]"
                        maxlength="1"
                        (keyup)="tabTrigger($event, 0)"
                      />
                    </mat-form-field>
                    <mat-form-field>
                      <input
                        matInput
                        type="tel"
                        maxlength="1"
                        required
                        name="input2"
                        id="input2"
                        [(ngModel)]="otpInputFields.intakeMasked[1]"
                        (keyup)="tabTrigger($event, 1)"
                      />
                    </mat-form-field>
                    <mat-form-field>
                      <input
                        matInput
                        type="tel"
                        maxlength="1"
                        required
                        name="input3"
                        id="input3"
                        [(ngModel)]="otpInputFields.intakeMasked[2]"
                        (keyup)="tabTrigger($event, 2)"
                      />
                    </mat-form-field>
                    <mat-form-field>
                      <input
                        matInput
                        type="tel"
                        maxlength="1"
                        required
                        name="input4"
                        id="input4"
                        [(ngModel)]="otpInputFields.intakeMasked[3]"
                        (keyup)="tabTrigger($event, 3)"
                      />
                    </mat-form-field>
                    <mat-form-field>
                      <input
                        matInput
                        type="tel"
                        maxlength="1"
                        required
                        name="input5"
                        id="input5"
                        [(ngModel)]="otpInputFields.intakeMasked[4]"
                        (keyup)="tabTrigger($event, 4)"
                      />
                    </mat-form-field>
                    <!-- <div [hidden]="!ngform.form.valid" class="alert alert-danger">
                    Otp is Required
                  </div> -->
                  </div>

                  <div *ngIf="showMailLink" class="below-otp-text" (click)="reSendOtp(false)">
                    Resend One-time PIN
                  </div>
                  <div *ngIf="env.emailOtp" class="below-otp-text email-otp-link" (click)="reSendOtp(true)">
                    Resend One Time Pin to my registered Email Address
                  </div>
                </div>
              </div>

              <section class="details-footer mt-16 mb-16">
                <sbg-button
                  class="details-footer__next"
                  [isRaisedButton]="true"
                  buttonType="primary"
                  [disableRipple]="false"
                  buttonStyle="basic"
                  [isDisabled]="!ngform.form.valid"
                  buttonText="VERIFY"
                  iconRight="check_circle"
                  ariaLabel="Review details"
                ></sbg-button>
              </section>
            </div>
          </form>
        </ng-container>
      </div>
      <!-- ExchangeRateTimer  needs to be confirm -->
      <!-- <div class="otp-not-recieved-wrap" ng-if='ExchangeRateTimer'>
      <div class="otp-not-recieved">Please note that the indicative exchange rate is only valid for 90 Seconds</div>
      <div class="otp-not-recieved">Please complete the transaction before <span
          class="otp-not-recieved-num">ExchangeRateTimerDuration</span> Seconds</div>
    </div> -->
      <div class="otp-not-recieved-wrap">
        <div class="otp-not-recieved">In case you did not receive your</div>
        <div class="otp-not-recieved-num-wrap">
          <span *ngIf="singleOtpBox" class="otp-not-recieved"> One-time Password please call</span>
          <span *ngIf="!singleOtpBox" class="otp-not-recieved">One-time Pin please call</span>
          <span class="otp-not-recieved-num" *ngIf="!isSmeMode"
            >{{ env.localOtpCustomerCare
            }}<span *ngIf="env.localCurrency === 'NAD'">{{ env['altCustomerCare'] }}</span></span
          >
          <span class="otp-not-recieved-num" *ngIf="isSmeMode">{{ env.localSMEcontactNumber }}</span>
        </div>
      </div>
    </div>
  </section>
</ng-container>
<!--********************************************** hybrid app**************************************************** -->
<ng-container *ngIf="isHybrid">
  <sbg-native-header
    [nextButtonDisable]="!ngform.form.valid"
    nextButtonText="VERIFY"
    (nextFunction)="goNext(); ngform.reset()"
  ></sbg-native-header>
  <div *ngIf="errorMsg" class="hyb-error-block">
    <div class="hyb-otp-error-message">
      <span class="pt-14 pl-8">
        <mat-icon class="hybrid-error-icon" svgIcon="icn_alert_circle_solid"></mat-icon>
      </span>
      <span class="hyb-otp-error"> {{ errorMsg }}</span>
    </div>
  </div>
  <section class="hybrid-card-container full-height">
    <div class="container-side hyb-otpContainer" #otp>
      <div class="row" class="router-container">
        <section class="columns details-container">
          <ng-container class="details-container__body">
            <form #ngform="ngForm" #oref>
              <div class="text-center">
                <div
                  *ngIf="receiptModel.type[0] === 'SUCCESS' || receiptModel.type[0] === 'OK'"
                  class="hyb-otp-error-message display-block"
                >
                  {{ message }}
                </div>
                <span class="hyb-otp-desc display-block" *ngIf="!showBothContacts && !errorMsg"
                  >A One-time PIN has been sent to the following
                  {{ detailsReceived?.stepUp?.contactMethod }}
                  <ng-container *ngIf="env.emailOtp">
                    {{ detailsReceived?.stepUp?.maskedAddress }}
                  </ng-container>
                </span>
                <span class="otp-desc hyb-otp-desc display-block" *ngIf="!singleOtpBox && showBothContacts"
                  >A One-time PIN has been sent to the following number & Email ID
                </span>
                <span class="hyb-otp-desc display-block" *ngIf="singleOtpBox"
                  >A One-time Password has been sent to your registered mobile number</span
                >
                <span *ngIf="env.emailOtp && showBothContacts" class="user-mobile-no">{{
                  detailsReceived?.stepUp?.maskedAddress
                }}</span>
                <span class="enter-otp display-block"
                  >Please enter one-time&nbsp;<span *ngIf="!singleOtpBox">PIN</span
                  ><span *ngIf="singleOtpBox">Password</span>
                </span>
                <div class="resend-pin center-aligned">
                  <div class="hyb-opt-input">
                    <mat-form-field>
                      <input
                        matInput
                        type="tel"
                        #firstInput
                        required
                        name="input1"
                        id="input1"
                        [(ngModel)]="otpInputFields.intakeMasked[0]"
                        maxlength="1"
                        (keyup)="tabTrigger($event, 0)"
                      />
                    </mat-form-field>
                    <mat-form-field>
                      <input
                        matInput
                        type="tel"
                        maxlength="1"
                        required
                        name="input2"
                        id="input2"
                        [(ngModel)]="otpInputFields.intakeMasked[1]"
                        (keyup)="tabTrigger($event, 1)"
                      />
                    </mat-form-field>
                    <mat-form-field>
                      <input
                        matInput
                        type="tel"
                        maxlength="1"
                        required
                        name="input3"
                        id="input3"
                        [(ngModel)]="otpInputFields.intakeMasked[2]"
                        (keyup)="tabTrigger($event, 2)"
                      />
                    </mat-form-field>
                    <mat-form-field>
                      <input
                        matInput
                        type="tel"
                        maxlength="1"
                        required
                        name="input4"
                        id="input4"
                        [(ngModel)]="otpInputFields.intakeMasked[3]"
                        (keyup)="tabTrigger($event, 3)"
                      />
                    </mat-form-field>
                    <mat-form-field>
                      <input
                        matInput
                        type="tel"
                        maxlength="1"
                        required
                        name="input5"
                        id="input5"
                        [(ngModel)]="otpInputFields.intakeMasked[4]"
                        (keyup)="tabTrigger($event, 4)"
                      />
                    </mat-form-field>
                    <!-- <div [hidden]="!ngform.form.valid" class="alert alert-danger">
                    Otp is Required
                  </div> -->
                  </div>

                  <div class="hyb-resend-Otp flex-container align-middle align-justify mt-2">
                    <div
                      *ngIf="showMailLink"
                      class="below-otp-text"
                      (click)="reSendOtp(false)"
                      [ngClass]="{ timerON: timer !== 0 }"
                    >
                      RESEND OTP
                    </div>
                    <div class="timer" [ngClass]="{ timerOFF: timer === 0 }">
                      00:{{
                        timer.toLocaleString('en-US', {
                          minimumIntegerDigits: 2,
                          useGrouping: false
                        })
                      }}
                    </div>
                  </div>
                  <div *ngIf="env.emailOtp" class="email-otp-link" (click)="reSendOtp(true)">
                    Resend OTP to my registered email address.
                  </div>
                  <div class="trouble">
                    Having touble receiving the OTP. <span class="">Please call</span>

                    <div class="mt-2">
                      <!-- <span *ngIf="!singleOtpBox" class="">One-time Pin please call</span> -->
                      <span class="" *ngIf="!isSmeMode"
                        >{{ env.localOtpCustomerCare
                        }}<span *ngIf="env.localCurrency === 'NAD'">{{ env['altCustomerCare'] }}</span></span
                      >
                      <span class="" *ngIf="isSmeMode">{{ env.localSMEcontactNumber }}</span>
                    </div>
                    <!-- <span class="help">Help</span> -->
                  </div>
                </div>
              </div>
            </form>
          </ng-container>
        </section>
      </div>
    </div>
  </section>
</ng-container>
