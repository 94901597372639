import { createReducer, on } from '@ngrx/store';
import { setSelfAccountFormData, setSelfTransferReceiptData } from '../actions/selfTransfer.action';
import { SelfTransferState } from '../state/selfTransfer.state';

const selfTransferInitState = {};

const setSelfAccTransferInitState = {};

const selfAccData: SelfTransferState = {
  selfAccountFormData: selfTransferInitState,
  setSelfAccTransferReceiptData: setSelfAccTransferInitState,
};
export const selfTransferReducer = createReducer(
  selfAccData,
  on(setSelfAccountFormData, (state, { selfAccountFormData }) => ({
    ...state,
    selfAccountFormData,
  })),

  on(setSelfTransferReceiptData, (state, { setSelfAccTransferReceiptData }) => ({
    ...state,
    setSelfAccTransferReceiptData,
  }))
);
