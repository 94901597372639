export class SbgAccountTransactionsConstants {
  static DESKTOP_BREAK_POINT = 1024;
  static POSTING_DATE_COL_WIDTH_LARGE = '69';
  static NARRATIVE_COL_WIDTH = '*';
  static AMOUNT_COL_WIDTH_LARGE = '19%';
  static RUNNING_BAL_DATE_COL_WIDTH = '19%';
  static AMOUNT_COL_WIDTH_SMALL = '30%';
  static EXPANDED_ROW_HEIGHT = 162;
  static EXPANDED_ROW_HEIGHT_ADDITIONAL = 50;
  static MOBILE_MODAL_TEMPLATE = 'mobileRowExpansion';
  static INIT_TRANSACTIONS = 30;
  static MORE_TRANSACTIONS = 'ONEHUNDREDEIGHTY';
  static MORE_TRANSACTIONS_LOYALTY = 'ONE_EIGHTY';
  static PBBI_MORE_TRANSACTIONS = 'DATERANGE';
  static PBBI_TERRITORY_KEY = 'SBOG';
  static PBBI_MORE_TRANSACTIONS_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
  static PBBI_MORE_TRANSACTIONS_DAYS = 365;
  static CHAR_WIDTH_MULTIPLIER = 12;
  static DEFAULT_ROW_HEIGHT = 80;
  static EXPANDED_TRANSACTION_STATE = 'dashboard.account.transaction';
  static AMOUNT_CLASS = 'account-views-transactions__amount';
  static AMOUNT_COLUMN = 0;
  static RUNNING_BALANCE_COLUMN = 1;
  static INACTIVE_CLAIM_STATUS = [
    'Finalised',
    'Settled',
    'Claim Settled',
    'Rejected',
    'Claim Withdrawn',
    'Partially Paid',
    'Closed',
    'Closed - Outstanding Requirements',
    'Cancelled',
    'Canceled',
    'Claim Under Review',
  ];

  static TRANSACTIONS_THIRTY = 'THIRTY';
  static TRANSACTIONS_NINETY = 'NINETY';
  static TRANSACTIONS_ONEHUNDRED_EIGHTY = 'ONEHUNDREDEIGHTY';
  static TRANSACTIONS_THREEHUNDRED_SIXTY = 'THREEHUNDREDSIXTY';
  static TRANSACTIONS_SEVENHUNDRED_TWENTY = 'SEVENHUNDREDTWENTY';
  static TRANSACTIONS_CUSTOM_DATERANGE = 'DATERANGE';

  static TRANSACTIONS_CALENDAR_MONTH = 'TRANSACTIONSCALENDARMONTH';
  static TRANSACTIONS_PREVIOUS_CALENDAR_MONTH = 'TRANSACTIONSPREVIOUSCALENDARMONTH';
}

export type TransactionTypeFilterConstants = 'ALL' | 'IN' | 'OUT';
