<header>
  <mat-sidenav-container class="sidenav" (backdropClick)="close()">
    <mat-sidenav #sidenav (keydown.escape)="close()" disableClose>
      <div class="menu-header flex-container align-middle">
        <!-- *************** -->
        <!-- <span class="icon-bandage"> -->
        <i class="material-icons overlayIco header-menu-icon cursor-pointer" aria-hidden="true">menu</i>
        <span class="overlay header__menu--title ml-8 hide-for-small-only"> Menu </span>
        <!-- </span> -->
        <!-- *********** -->
      </div>
      <div class="menu-listing">
        <ul class="sidenav__options--list">
          <li class="static-bar sidenav__options--list-item main-label">
            <span>My <span *ngIf="isSmeMode">Business</span> Dashboard </span>
          </li>

          <li
            class="sidenav__options--list-item"
            [ngClass]="link.classes"
            *ngFor="let link of sideNavLinks"
            (click)="link.onClickNavFunction()"
          >
            <span *ngIf="!(link.name === 'Open a bank account' && maskOpenAcc)">{{ link.name }}</span>
          </li>
          <!--
          <li class="sidenav__options--list-item" (click)="goToReceipts()">Receipts</li>
          <li class="sidenav__options--list-item" (click)="goToRecipient()">Recipients</li>
          <li class="sidenav__options--list-item" (click)="debitCardsNav()" *ngIf="!isSmeMode">Debit Cards</li>
          <li class="sidenav__options--list-item" (click)="goToServiceRequests()" *ngIf="!isSmeMode">Service Requests</li>
          <li class="sidenav__options--list-item" (click)="goToProfile_Settings()">Profile & Settings</li>
          <li class="sidenav__options--list-item" (click)="goToScheduteTxn()">Scheduled Payments</li>
          <li *ngIf="isSmeMode" class="sidenav__options--list-item" (click)="goToApprovalList()">Approval List</li>
          <li class="sidenav__options--list-item" (click)="handleSignout('Feedback')">Give Feedback</li>
          <li class="sidenav__options--list-item margin-top-1" *ngIf="hasAccountOpening" (click)="goToOpenBankAccount()">Open a bank account</li> -->
          <!-- <li class="sidenav__options--list-item">
              Open Bank Account
            </li> -->
        </ul>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <div
        class="header__wrap flex-container align-middle"
        [ngClass]="{
          'header__wrap--transparent align-center': hideHeaderForPopupScreen,
          'align-justify header_wrap--height': !hideHeaderForPopupScreen,
          'header__wrap--padding': !isSmall && hideHeaderForPopupScreen
        }"
      >
        <div
          *ngIf="!headerTitle.status"
          class="column shrink header__menu flex-container align-center align-middle"
          [ngClass]="{
            'display-none': hideHeaderForPopupScreen,
            hidden: readOnlyMode
          }"
        >
          <!-- <mat-icon class="header-menu-icon" aria-label="menu" (click)="open()">menu </mat-icon> -->
          <i class="material-icons header-menu-icon cursor-pointer" aria-hidden="true" (click)="open()">
            menu
          </i>
          <div class="header__menu--title ml-8 hide-for-small-only" (click)="open()">Menu</div>
        </div>
        <div
          class="column shrink"
          *ngIf="headerTitle.status"
          [ngClass]="{
            'display-none': hideHeaderForPopupScreen,
            hidden: readOnlyMode
          }"
        >
          <i class="material-icons cursor-pointer" aria-hidden="true" (click)="goBack()"> chevron_left </i>
        </div>

        <div *ngIf="!headerTitle.status" class="header__logo">
          <div class="header__logo--size banklogo3d"></div>
        </div>
        <div class="column text-center" *ngIf="headerTitle.status">
          <div>
            {{ headerTitle.header }}
          </div>
          <div *ngIf="headerTitle.subHeader">
            {{ headerTitle.subHeader }}
          </div>
        </div>
        <div
          class="column shrink header__signout cursor-pointer"
          [ngClass]="{ 'display-none': hideHeaderForPopupScreen }"
          (click)="handleSignout('logout')"
        >
          Sign Out
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</header>
