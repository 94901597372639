import { createAction, props } from '@ngrx/store';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LoginData {}
export const isMobileAction = createAction(
  '[Activate isMobile view]',
  props<{ isHybrid: boolean; dashboard: boolean }>()
);

export const setLoginData = createAction('[LoginComponent] setLoginData', props<{ login: LoginData }>());
export const setLogoutData = createAction('[LoginComponent] setLogoutData', props<{ login: LoginData }>());
export const setSessionData = createAction(
  '[LoginComponent] setSessionData',
  props<{ sessionData: LoginData }>()
);
export const isSmeMode = createAction('[LoginComponent] isSmeMode', props<{ isSmeMode: LoginData }>());
export const isfirstTimeLogin = createAction(
  '[LoginComponent] isfirstTimeLogin',
  props<{ isfirstTimeLogin: LoginData }>()
);
export const forcedSecurityQuestionReset = createAction(
  '[LoginComponent] forcedSecurityQuestionReset',
  props<{ forcedSecurityQuestionReset: LoginData }>()
);
export const userName = createAction(
  '[LoginComponent] userName',
  props<{ userName: LoginData; errorMessage?: string }>()
);

export const eapLimitScheme = createAction(
  '[LoginComponent] eapLimitScheme',
  props<{ eapLimitScheme: LoginData }>()
);
