<!-- Temporary fix -->
<div *ngIf="!is_ping">
  <article class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <section class="login-content-wrapper">
      <div class="cell login-content" [ngClass]="{ 'login-content__error--shake': shake }">
        <div class="login-content__logo" fxLayout="row" fxLayoutAlign="center center">
          <div class="banklogo3d"></div>
        </div>
        <form
          (ngSubmit)="login(model, loginForm.form.valid)"
          #loginForm="ngForm"
          class="login-content__form padding-vertical-24"
          autocomplete="off"
        >
          <h1 class="login-content__form--heading font-regular-20-gray mt-30 mb-18">
            Sign In to
            <span *ngIf="loginMode === 'SME'">{{
              config.countryName === 'Tanzania' ? 'Biashara' : 'Enterprise'
            }}</span>
            Online Banking
          </h1>
          <div class="login-content__form--error">{{ errorMsg }}</div>
          <mat-form-field class="full-width login-content__form--input">
            <input
              type="text"
              id="userId"
              name="userId"
              matInput
              placeholder="username"
              [(ngModel)]="model.userId"
              #userId="ngModel"
              (focus)="callTag()"
              required
            />
            <mat-error>
              <sbg-validation-error [control]="userId" placeholder="username"></sbg-validation-error>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width login-content__form--input">
            <input
              type="password"
              id="password"
              name="password"
              matInput
              placeholder="Password"
              [(ngModel)]="model.password"
              #password="ngModel"
              required
            />
            <mat-error>
              <sbg-validation-error [control]="password" placeholder="Password"></sbg-validation-error>
            </mat-error>
          </mat-form-field>
          <div class="login-content__form--link" (click)="goToForgotPassword()">
            Have you forgotten your password?
          </div>
          <div class="login-content__form--button padding-vertical-20">
            <button mat-raised-button color="primary" [disabled]="!(loginForm.form.valid && allowLogin)">
              <span class="hyb_buttons_sfpro">Sign In</span>
            </button>
          </div>
          <div class="login-content__form--link" (click)="goToRegistration()" *ngIf="loginMode !== 'SME'">
            Register for online banking
          </div>
          <a
            target="_blank"
            href="{{ registrationFormFilePath }}"
            class="login-content__form--link"
            *ngIf="loginMode === 'SME'"
            >Download Online Banking Registration Form</a
          >
          <div class="login-content__form--link" *ngIf="config.showDemoLink">
            <a (click)="goDemo()"> Experience the demo</a>
          </div>
        </form>
        <div class="login-content__footer">
          <!-- <div class="login-content__footer--tnc">
            <span class="login-content__footer--lang" (click)="setLang('en')">English</span>
            <span class="login-content__footer--lang" (click)="setLang('mn')">普通话</span>
          </div> -->
          <div class="login-content__footer--tnc">
            By signing in I acknowledge that I have read and understood the
            <span
              class="login-content__footer--link"
              (click)="openDialog('Terms and Conditions', 'newAccount')"
              >terms and conditions</span
            >
            when using Online Banking
          </div>
          <div class="login-content__footer--email">
            <span class="login-content__footer--link" (click)="openDialog('Need Help', 'help')"
              >Need help?</span
            >&nbsp;Call us on : {{ contactNumber }} or
            <a class="login-content__footer--link remove-link-underline" href="mailto:{{ mailId }}"
              >Email us</a
            >
          </div>
          <div class="login-content__footer--disclaimer">
            <div class="login-content__footer--link" (click)="openDialog('Privacy and Security', 'privacy')">
              Privacy and Security
            </div>
            <div class="login-content__footer--link" (click)="openDialog('Disclaimer', 'disclaim')">
              Disclaimer
            </div>
          </div>
        </div>
      </div>
    </section>
  </article>
</div>

<!-- ****************************************************Hybrid App********************************************************** -->

<div *ngIf="is_ping">
  <div class="hybrid-login-section">
    <article class="login-wrapper">
      <section class="login-content-wrapper">
        <div class="cell login-content" [ngClass]="{ 'login-content__error--shake': shake }">
          <div class="login-content__logo" fxLayout="row" fxLayoutAlign="center center">
            <div class="banklogo3d"></div>
          </div>
          <div *ngIf="!isLogin" class="login-update">
            <sbg-flash-screen
              [preHybrid]="preHybrid"
              (isLogin)="goTologin()"
              [loginMode]="loginMode"
              (register)="goToRegistration()"
            ></sbg-flash-screen>
          </div>

          <div *ngIf="isLogin">
            <form
              (ngSubmit)="login(model, loginForm.form.valid)"
              #loginForm="ngForm"
              class="login-content__form padding-vertical-24"
              autocomplete="off"
            >
              <h1 class="login-content__form--heading font-regular-20-gray">
                <span *ngIf="loginMode === 'SME'">
                  Sign In to
                  {{ config.countryName === 'Tanzania' ? 'Biashara' : 'Enterprise' }}
                  Online Banking</span
                >

                <span *ngIf="loginMode !== 'SME'"> Sign in to Online Banking </span>
              </h1>
              <div class="login-content__form--error">{{ errorMsg }}</div>
              <mat-form-field class="full-width login-content__form--input">
                <input
                  type="text"
                  id="userId"
                  name="userId"
                  matInput
                  placeholder="Username"
                  [(ngModel)]="model.userId"
                  #userId="ngModel"
                  (focus)="callTag()"
                  required
                />
                <mat-error>
                  <sbg-validation-error [control]="userId" placeholder="Username"></sbg-validation-error>
                </mat-error>
              </mat-form-field>
              <mat-form-field class="full-width login-content__form--input">
                <input
                  [type]="show ? 'text' : 'password'"
                  id="password"
                  name="password"
                  matInput
                  placeholder="Password"
                  [(ngModel)]="model.password"
                  #password="ngModel"
                  required
                />
                <div
                  [ngClass]="!model.password ? 'toggle-disabled' : 'toggle-enabled'"
                  [attr.disabled]="!loginForm.form.valid"
                  (click)="model.password ? passwordToggle() : ''"
                >
                  {{ show ? 'HIDE' : 'SHOW' }}
                </div>
                <mat-error>
                  <sbg-validation-error [control]="password" placeholder="Password"></sbg-validation-error>
                </mat-error>
              </mat-form-field>

              <div class="hybrid-button-container">
                <sbg-button
                  buttonText="SIGN IN"
                  mat-raised-button
                  class="hybrid-login-sign-in"
                  [isDisabled]="!(loginForm.form.valid && allowLogin)"
                  (clickFunction)="login(model, loginForm.form.valid)"
                ></sbg-button>
                <!-- <button
                  mat-raised-button
                  [disabled]="!(loginForm.form.valid && allowLogin)"
                  class="hybrid-sign-in"
                >
                  <span class="hyb_buttons_sfpro">SIGN IN</span>
                </button> -->
              </div>
              <div class="hybrid-terms">
                By signing in, I agree to the
                <span class="form-link" (click)="openDialog('Terms and Conditions', 'newAccount')">T&Cs</span>
              </div>
              <div class="forgot-passowrd">
                Forgot
                <span class="form-link" *ngIf="config.countryName !== 'Uganda'" (click)="goToForgotUserName()"
                  >Username |
                </span>
                <span class="form-link" (click)="goToForgotPassword()">Password </span>
              </div>
              <!-- <div
                  class="login-content__form--link"
                  (click)="goToRegistration()"
                  *ngIf="loginMode !== 'SME'"
                >
                  Register for online banking
                </div> -->
              <div *ngIf="loginMode === 'SME'" class="login-eol__downloadlink">
                <span> Enterprise Online Registration Form.</span>
                <a target="_blank" href="{{ registrationFormFilePath }}" class="login-content__form--link"
                  >Download</a
                >
              </div>
              <div class="login-content__form--link" *ngIf="config.showDemoLink">
                <a (click)="goDemo()"> Experience the demo</a>
              </div>
            </form>
            <div class="hybrid-login-content__footer mt-10">
              <!-- <div class="login-content__footer--tnc">
                <span class="login-content__footer--lang" (click)="setLang('en')">English</span>
                <span class="login-content__footer--lang" (click)="setLang('mn')">普通话</span>
              </div> -->
              <div class="hybrid-button-container" *ngIf="loginMode !== 'SME' && config.localCurrency !== 'UGX'">
                <div class="hyb_register_text">New to Online banking?</div>
                <button
                  (click)="goToRegistration()"
                  class="hybrid-register-button login-content__footer--link mb-0 pt-8"
                >
                  Register here
                </button>
              </div>
              <div class="login-content__footer--email">
                <div class="help_text">
                  <span class="login-content__footer--link" (click)="openDialog('Need Help', 'help')">
                    Need help?
                  </span>
                  <span class="terms_condition_call">Call or Drop us an Email</span>
                </div>
                <div
                  *ngIf="config.retailCustomer !== '' && loginMode !== 'SME'"
                  class="terms_condition_retail pt-8"
                >
                  <div class="terms_condition_retail pb-4">Retail Customers</div>

                  <a
                    href="tel:{{ config.retailCustomer }}"
                    class="login-content__footer--link login-contact"
                    >{{ config.retailCustomer }}</a
                  >
                  <span class="padding-horizontal-8">|</span>
                  <a
                    class="login-content__footer--link remove-link-underline"
                    href="mailto:{{ config.retailCustomerEmail }}"
                    >Email</a
                  >
                </div>
                <div
                  *ngIf="config.affuentCustomer !== '' && loginMode === 'SME'"
                  class="hybrid-customer-care pt-8"
                >
                  <div class="terms_condition_affluent pb-4">Affluent Customers</div>
                  <a
                    href="tel:{{ config.affuentCustomer }}"
                    class="login-content__footer--link login-contact"
                    >{{ config.affuentCustomer }}</a
                  >
                  <span class="padding-horizontal-8">|</span>
                  <a
                    class="login-content__footer--link remove-link-underline"
                    href="mailto:{{ config.affuentCustomerEmail }}"
                    >Email</a
                  >
                </div>
                <div
                  *ngIf="config.enterPriseCustomerEmail !== '' && loginMode === 'SME'"
                  class="hybrid-customer-care pt-8"
                >
                  <div class="terms_condition_affluent pb-4">Enterprise Customers</div>
                  <a
                    href="tel:{{ config.enterPriseCustomer }}"
                    class="login-content__footer--link login-contact"
                    >{{ config.enterPriseCustomer }}</a
                  >
                  <span class="padding-horizontal-8">|</span>
                  <a
                    class="login-content__footer--link remove-link-underline"
                    href="mailto:{{ config.enterPriseCustomerEmail }}"
                    >Email</a
                  >
                </div>
              </div>
              <div class="hybrid-privacy">
                <div
                  class="login-content__footer--link"
                  (click)="openDialog('Privacy and Security', 'privacy')"
                >
                  Privacy and Security
                </div>
                <div class="login-content__footer--link mb-20" (click)="openDialog('Disclaimer', 'disclaim')">
                  Disclaimer
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
  </div>
</div>
