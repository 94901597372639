import { createAction, props } from '@ngrx/store';

export const addBene = createAction('Payment Component] AddBene');

export const onceOffBeneficiaryPayments = createAction(
  '[Payments Component] onceOffBeneficiaryPayments',
  props<{ onceOffData: Array<{}> }>()
);
export const selectBillerData = createAction(
  '[Payments Component] selectBillerData',
  props<{ selectedBiller: Array<any> }>()
);
export const setPayBillerData = createAction(
  '[Payments Component] PayBillerData',
  props<{ payBillerData: Array<any> }>()
);

export const setPayBillerRecieptData = createAction(
  '[Payments Component] PayBillerRecieptData',
  props<{ payBillerRecieptData: Array<any> }>()
);

export const setSelectedTab = createAction(
  '[Payments Component] setSelectedTab',
  props<{ selectedTab: number }>()
);
export const setSelectedVasType = createAction(
  '[Payments Component] setSelectedVasType',
  props<{
    selectedVasType: { type: string; description: string; subIndexTab: number };
  }>()
);
export const setExchangeRateData = createAction(
  '[Payment Component] exchangeRateData',
  props<{ exchangeRate: string }>()
);
