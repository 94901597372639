import { createReducer, on } from '@ngrx/store';

import * as ratesandtaxesAction from '../actions/buy-ratesandtaxes.action';
import { BuyRatesAndTaxesState } from '../state/buy-ratesandtaxes.state';

const ratesandtaxesInitData: BuyRatesAndTaxesState = {
  ratesandtaxesModel: null,
  selectedProvider: null,
  providers: null,
  receiptData: null,
};
export const buyRatesAndTaxesReducer = createReducer(
  ratesandtaxesInitData,
  on(ratesandtaxesAction.setRatesAndTaxesModel, (state, { ratesandtaxesModel }) => ({
    ...state,
    ratesandtaxesModel,
  })),
  on(ratesandtaxesAction.setProvider, (state, { selectedProvider }) => ({
    ...state,
    selectedProvider,
  })),
  on(ratesandtaxesAction.setProviders, (state, { providers }) => ({
    ...state,
    providers,
  })),
  on(ratesandtaxesAction.setRatesAndTaxesReceiptData, (state, { receiptData }) => ({
    ...state,
    receiptData,
  })),
  on(ratesandtaxesAction.resetRatesAndTaxesModel, (state) => ({
    ...state,
    ratesandtaxesModel: null,
  })),
  on(ratesandtaxesAction.resetProvider, (state) => ({
    ...state,
    selectedProvider: null,
  })),
  on(ratesandtaxesAction.resetRatesAndTaxesReceiptData, (state) => ({
    ...state,
    receiptData: null,
  }))
);
