export class AccountConstants {
  static defaultView = {
    DESKTOP: 'sbg-account-transactions',
    MOBILE: 'sbg-account-recent-transactions',
  };

  static ucountView = {
    DESKTOP: 'sbg-account-transactions',
    MOBILE: 'span',
  };
  static defaultState = {
    defaultState: 'dashboard.account',
  };

  static ACCOUNT_TYPES = {
    CURRENT: {
      name: 'CURRENT',
      friendlyName: 'Current',
      image: 'assets/images/svg/accounts/icn-current-acc.svg',
      tileTemplate: 'sbg-account-tile',
      tileGradientStyle: 'sbg-gradient-secondary',
    },
    TERM_LOAN: {
      name: 'TERM_LOAN',
      friendlyName: 'TermLoan',
      image: 'assets/images/svg/accounts/icn-loan-acc.svg',
      tileTemplate: 'sbg-account-tile',
      tileGradientStyle: 'sbg-gradient-notification',
    },
    SAVINGS: {
      name: 'SAVINGS',
      friendlyName: 'Savings',
      image: 'assets/images/svg/accounts/icn-savings-acc.svg',
      tileTemplate: 'sbg-account-tile',
      tileGradientStyle: 'sbg-gradient-positive',
    },
    FIXED_TERM_INVESTMENT: {
      name: 'FIXED_TERM_INVESTMENT',
      friendlyName: 'Investment',
      image: 'assets/images/svg/accounts/icn-investment-acc.svg',
      tileTemplate: 'sbg-account-tile',
      tileGradientStyle: 'sbg-gradient-positive',
    },
    NOTICE: {
      name: 'NOTICE',
      friendlyName: 'NoticeDeposit',
      image: 'assets/images/svg/accounts/icn-savings-acc.svg',
      tileTemplate: 'sbg-account-tile',
      tileGradientStyle: 'sbg-gradient-positive',
    },
    RCP: {
      name: 'RCP',
      friendlyName: 'Rcp',
      image: 'assets/images/svg/accounts/icn-loan-acc.svg',
      tileTemplate: 'sbg-account-tile',
      tileGradientStyle: 'sbg-gradient-notification',
    },
    CREDIT_CARD: {
      name: 'CREDIT_CARD',
      friendlyName: 'Credit',
      image: 'assets/images/svg/accounts/icn-credit-card-acc.svg',
      tileTemplate: 'sbg-account-tile',
      tileGradientStyle: 'sbg-gradient-notification',
    },
    HOME_LOAN: {
      name: 'HOME_LOAN',
      friendlyName: 'HomeLoan',
      image: 'assets/images/svg/accounts/icn-homeloan-acc.svg',
      tileTemplate: 'sbg-account-tile',
      tileGradientStyle: 'sbg-gradient-notification',
    },
    VAF: {
      name: 'VAF',
      friendlyName: 'Vaf',
      image: 'assets/images/svg/accounts/icn-vaf-acc.svg',
      tileTemplate: 'sbg-vaf-tile',
      tileGradientStyle: 'sbg-gradient-notification',
    },
    INSURANCE: {
      name: 'INSURANCE',
      friendlyName: 'Insurance',
      image: 'assets/images/svg/accounts/icn-insurance-acc.svg',
      tileTemplate: 'sbg-insurance-tile',
      tileGradientStyle: 'sbg-gradient-accent',
    },
    LOYALTY: {
      name: 'LOYALTY',
      friendlyName: 'Loyalty',
      image: 'assets/images/svg/accounts/icn-ucount-acc.svg',
      tileTemplate: 'sbg-ucount-tile',
      tileGradientStyle: 'sbg-gradient-identifier',
    },
  };
}
