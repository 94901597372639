import { LogoutReset } from './../actions/logout.action';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';

export class LogoutSessionModel {
  session: any;
}
@Injectable()
@State<LogoutSessionModel>({
  name: 'logoutSession',
  defaults: {
    session: null,
  },
})
export class LogoutState {
  @Selector()
  static getLogoutSession(state: LogoutSessionModel) {
    return state.session;
  }
  @Action(LogoutReset)
  getLoutout({ getState, setState }: StateContext<LogoutSessionModel>, { payload }: LogoutReset) {
    const state = getState();
    setState({
      ...state,
      session: payload,
    });
  }
}
