import { createReducer, on } from '@ngrx/store';
import {
  setMobileWalletDetailsData,
  setMobileWalletReceiptData,
  setMobileVerifiedDetails,
  setMobileWalletReceiptListData,
} from '../actions/mobileWallet.action';
import { MobileWalletState } from '../state/mobileWallet.state';

const mobileWalletInitState = {};

const mobileWalletReviewInitState = {};

const mobileVerifiedData = {};

const mobileWalletData: MobileWalletState = {
  mobileVerifiedData: mobileVerifiedData,
  mobileWalletFormData: mobileWalletInitState,
  mobileWalletReviewData: mobileWalletReviewInitState,
};
export const mobileWalletReducer = createReducer(
  mobileWalletData,
  on(setMobileWalletDetailsData, (state, { mobileWalletFormData }) => ({
    ...state,
    mobileWalletFormData,
  })),

  on(setMobileWalletReceiptData, (state, { mobileWalletReviewData }) => ({
    ...state,
    mobileWalletReviewData,
  })),

  on(setMobileVerifiedDetails, (state, { mobileVerifiedData }) => ({
    ...state,
    mobileVerifiedData,
  })),

  on(setMobileWalletReceiptListData, (state, { receiptList }) => {
    // console.log(receiptList);
    return { ...state, receiptList };
  })
);
