import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, shareReplay } from 'rxjs/operators';
import { ToolbarButton } from 'src/app/native/lib/interfaces';

@Injectable({
  providedIn: 'root',
})
export class SbgToolbarService {
  buttons$: Observable<ToolbarButton[]>;
  buttonClick$: Observable<ToolbarButton>;
  hideToolBarActionWatcher_shared$: Observable<boolean>;

  private buttonSubject = new BehaviorSubject<ToolbarButton[]>([]);
  private hideToolBarAction = new BehaviorSubject<boolean>(false);
  private buttonClickSubject = new Subject<ToolbarButton>();
  private buttons: ToolbarButton[];

  constructor() {
    this.buttons = [];
    this.buttons$ = this.buttonSubject.pipe(debounceTime(25));
    this.buttonClick$ = this.buttonClickSubject.asObservable();
    this.hideToolBarActionWatcher_shared$ = this.hideToolBarAction.pipe(shareReplay({ refCount: true }));
  }

  hide(): void {
    this.hideToolBarAction.next(true);
  }

  show(): void {
    this.hideToolBarAction.next(false);
  }

  addButton(text: string, cy_identifier: string): void {
    this.buttons.push({ text, cy_identifier });
    this.buttonSubject.next(this.buttons);
  }

  click(button: string): void {
    this.buttonClickSubject.next(this.buttons.find((t: ToolbarButton) => t.text === button));
  }

  clear(): void {
    if (this.buttons.length > 0) {
      this.buttons = [];
      this.buttonSubject.next(this.buttons);
    }
  }
}
