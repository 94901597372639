<app-error-modal> </app-error-modal>
<app-close-current-module> </app-close-current-module>
<app-idle-timeout-module> </app-idle-timeout-module>
<div sizeDetector class="animateLoader" [ngClass]="{ blurbg: show }">
  <div>
    <router-outlet></router-outlet>
    <router-outlet name="print"></router-outlet>
    <span id="print"></span>
  </div>
</div>
<app-loader> </app-loader>
