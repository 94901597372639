import { SelectedAccountSummary } from './../actions/accountSummary.action';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';

export class AccountSummaryStateModel {
  accSumm: any;
}
@Injectable()
@State<AccountSummaryStateModel>({
  name: 'accountSumm',
  defaults: {
    accSumm: null,
  },
})
export class AccountSummaryState {
  @Selector()
  static getSelectedAccountSum(state: AccountSummaryStateModel) {
    return state.accSumm;
  }
  @Action(SelectedAccountSummary)
  setSelectedAccountSum(
    { getState, setState }: StateContext<AccountSummaryStateModel>,
    { payload }: SelectedAccountSummary
  ) {
    const state = getState();
    setState({
      ...state,
      accSumm: payload,
    });
  }
}
