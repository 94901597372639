import { createReducer, on } from '@ngrx/store';
import {
  setSelectedDebitCardData,
  setSelectedDebitCardConfirmData,
  setSelectedDebitCardReceiptData,
  setDebitCardDetails,
  setSelectedCardDetails,
  setConfirmationData,
} from '../actions/debitCard.action';
import { DebitCardState } from '../state/debitCard.state';

const selectedDebitCardDataInitState = {};
const selectedDebitCardConfirmDataInitState = {};
const selectedDebitCardReceiptDataInitState = {};
const debitCardDetailsInitState = {};

const debitCardData: DebitCardState = {
  selectedDebitCardData: selectedDebitCardDataInitState,
  selectedDebitCardConfirmData: selectedDebitCardConfirmDataInitState,
  selectedDebitCardReceiptData: selectedDebitCardReceiptDataInitState,
  selectedDebitCardDetails: debitCardDetailsInitState,
};
export const debitCardReducer = createReducer(
  debitCardData,

  on(setSelectedDebitCardData, (state, { selectedDebitCardData }) => ({
    ...state,
    selectedDebitCardData,
  })),
  on(setSelectedDebitCardConfirmData, (state, { selectedDebitCardConfirmData }) => ({
    ...state,
    selectedDebitCardConfirmData,
  })),
  on(setSelectedDebitCardReceiptData, (state, { selectedDebitCardReceiptData }) => ({
    ...state,
    selectedDebitCardReceiptData,
  })),
  on(setDebitCardDetails, (state, { selectedDebitCardDetails }) => ({
    ...state,
    selectedDebitCardDetails,
  })),
  on(setSelectedCardDetails, (state, { activeDebitCardDetails }) => ({
    ...state,
    activeDebitCardDetails,
  })),
  on(setConfirmationData, (state, { confirmationData }) => ({
    ...state,
    confirmationData,
  }))
);
