import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { ServiceEndpointsService } from '../service-endpoints/service-endpoints.service';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import reject from 'lodash/reject';
import filter from 'lodash/filter';
import { HttpResponse } from '@angular/common/http';
import { GenericResponse } from '../data/generic-response';
import { RecipientGroupsRequest } from './data/recipient-groups-request';
import { RecipientGroupsResponse } from './data/recipient-groups-response';
import { RecipientManagementRequest } from './data/recipient-management-request';
import { RecipientsResponse, RecipientsResponses } from './data/recipients-response';
import { McaMessage } from '../data/mca-message';
import { PaginatedRequest } from '../data/paginated-request';
import { ReceiptPaginatedRequest } from '../data/receipt-paginated-request';
import { RecipientRequest } from './data/recipient-request';
import { RecipientResponse } from './data/recipient-response';
import { BillersResponse } from './data/billers/billers-response';

@Injectable({
  providedIn: 'root',
})
export class RecipientService {
  recipientsData;
  config = environment.config;

  constructor(private serviceEndpoint: ServiceEndpointsService, private store: Store<any>) {
    // store.select('recipientReducer')
    //   .subscribe((data: RootStore) => {
    //     this.updateRecipientData(data);
    //   });
    store.select('appReducer', 'recipientReducer').subscribe((stateData) => {
      this.updateRecipientData(stateData);
    });
  }

  public getGroups(): Observable<RecipientGroupsResponse> {
    return this.serviceEndpoint.post('groups', {}).pipe(map((response) => response.body));
  }

  public callRecipientForPayment(payload): Observable<any> {
    return this.serviceEndpoint.makeRequest(payload, 'recipient').pipe(map((res) => res));
  }

  public putGroups(payload: RecipientGroupsRequest): Observable<HttpResponse<RecipientGroupsResponse>> {
    return this.serviceEndpoint.put<RecipientGroupsRequest, RecipientGroupsResponse>('groupsPUT', payload);
  }

  public putRecipient(payload: RecipientManagementRequest): Observable<HttpResponse<RecipientsResponse>> {
    return this.serviceEndpoint.put<RecipientManagementRequest, RecipientsResponse>('recipientsPUT', payload);
  }

  public putRecipientGroup(payload): Observable<any> {
    return this.serviceEndpoint.makeRequest(payload, 'recipientsgroupPUT').pipe(map((res) => res));
  }

  public addBeneficiary(payload): Observable<any> {
    return this.serviceEndpoint.makeRequest(payload, 'recipients').pipe(map((res) => res));
  }

  public getRecipient(payload: PaginatedRequest): Observable<HttpResponse<RecipientsResponse>> {
    return this.serviceEndpoint.post<PaginatedRequest, RecipientsResponse>('recipients', payload);
  }

  public getReceiptRecipient(
    payload: ReceiptPaginatedRequest
  ): Observable<HttpResponse<RecipientsResponses>> {
    return this.serviceEndpoint.post<ReceiptPaginatedRequest, RecipientsResponses>(
      'fetchVASReceipts',
      payload
    );
  }

  // public editBeneficiary(payload): Observable<any> {
  //   return this.serviceEndpoint.makeRequest(payload, 'editRecipients').pipe(map(res => res));
  // }

  public getCMARecipient(payload): Observable<any> {
    return this.serviceEndpoint.makeRequest(payload, 'cmarecipients').pipe(map((res) => res));
  }

  public getBillers(payload): Observable<any> {
    const requestType = this.config.isBillerCDI ? 'registeredCdiBiller' : 'registeredBiller';
    return this.serviceEndpoint.post(requestType, payload);
  }

  public makeBillers(): Observable<BillersResponse> {
    const requestType = this.config.isBillerCDI ? 'registeredCdiBiller' : 'registeredBiller';
    return this.serviceEndpoint
      .post<{}, BillersResponse>(requestType, {})
      .pipe(map((response) => response.body));
  }

  public getBillerGroups(payload): Observable<any> {
    const requestType = this.config.isBillerCDI ? 'GetCdiGroups' : 'billerGroup';
    return this.serviceEndpoint.post(requestType, payload);
  }

  public deleteRecipients(payload: RecipientManagementRequest): Observable<HttpResponse<McaMessage>> {
    return this.serviceEndpoint.post<RecipientManagementRequest, McaMessage>('deleteRecipients', payload);
  }

  public deleteCMARecipients(payload) {
    return this.serviceEndpoint.makeRequest(payload, 'deleteCMARecipients').pipe(map((res) => res));
  }

  public deleteBiller(payload) {
    const requestType = this.config.isBillerCDI ? 'deleteCdiBiller' : 'deleteBiller';
    return this.serviceEndpoint.post(requestType, payload);
  }

  public deleteGroups(payload: RecipientGroupsRequest) {
    return this.serviceEndpoint.post<RecipientGroupsRequest, McaMessage>('deleteGroups', payload);
  }

  public deleteCMAGroups(payload) {
    return this.serviceEndpoint.makeRequest(payload, 'deleteCMAGroups').pipe(map((res) => res));
    // return ServiceEndpoint.deleteGroups.makeRequest( Payload ).then( function( response ) {
    //     return response.data;
    // } );
  }

  public getBillerGroup(endpoint): Observable<any> {
    endpoint = this.config.isBillerCDI ? 'GetCdiGroups' : endpoint;
    // if (this.recipientsData && this.recipientsData.billerGroups || this.recipientsData.cdiBillerGroups) {
    //   return of(this.recipientsData.billerGroups) || of(this.recipientsData.cdiBillerGroups);
    // } else {
    //   // country specific endpoint config pending here. (isCDI or not)
    //   // this to be replaced by country constant value later
    //   const isCDIBiller = false;
    //   const requestType = isCDIBiller ? 'GetCdiGroups' : 'billerGroup';
    //   return this.serviceEndpoint.makeRequest(payload, requestType).pipe(map(res => res));
    // }
    return this.serviceEndpoint.makeRequest({}, endpoint).pipe(map((res) => res));
  }

  public callRecipientForBeneficiary(recipient): Observable<any> {
    // To Do - add conditions for CMA bene for Namibia later
    const benefPayment = {
      recipientPayments: [
        {
          recipientType: 'BENEFICIARY',
          recipientId: recipient.recipientId,
        },
      ],
    };
    return this.serviceEndpoint.makeRequest(benefPayment, 'recipient').pipe(map((res) => res));
  }

  public callRecipient(payload: RecipientRequest): Observable<HttpResponse<RecipientResponse>> {
    return this.serviceEndpoint.post('recipient', payload);
  }

  public callCMARecipient(payload): Observable<any> {
    return this.serviceEndpoint.makeRequest(payload, 'cmarecipient').pipe(map((res) => res));
  }

  public getAllBillers(): Observable<any> {
    const cdiBillerPayload = {
      pagination: true,
    };
    if (this.recipientsData && Object.keys(this.recipientsData.allBillers).length) {
      return of(this.recipientsData.allBillers);
    } else {
      const requestType = this.config.isBillerCDI ? 'allCdiBiller' : 'allBiller';
      return this.serviceEndpoint
        .makeRequest(cdiBillerPayload, requestType)
        .pipe(map((res: any) => (this.config.isBillerCDI ? res : res.body.billers)));
    }
  }

  public getAllBillersLazyLoad(): Observable<any> {
    const requestType = this.config.isBillerCDI ? 'allCdiBiller' : 'allBiller';
    const payload = this.config.isBillerCDI ? { pagination: false } : {};
    if (this.recipientsData && !this.recipientsData.allBillers) {
      return of(this.recipientsData.allBillers);
    } else {
      return this.serviceEndpoint.makeRequest(payload, requestType).pipe(map((res) => res));
    }
  }

  public editBillerGroup(payload): Observable<any> {
    const requestType = this.config.isBillerCDI ? 'editCdiBillerGroup' : 'editBillerGroup';
    return this.serviceEndpoint.makeRequest(payload, requestType).pipe(map((res) => res));
  }

  public addBillerGroup(payload) {
    const requestType = this.config.isBillerCDI ? 'addCdiBillerGroup' : 'addBillerGroup';
    return this.serviceEndpoint.makeRequest(payload, requestType).pipe(map((res) => res));
  }

  public editBiller(payload): Observable<HttpResponse<GenericResponse>> {
    const requestType = this.config.isBillerCDI ? 'editCdiBiller' : 'editBiller';
    return this.serviceEndpoint.post(requestType, payload);
  }

  public addBiller(payload): Observable<HttpResponse<GenericResponse>> {
    const requestType = this.config.isBillerCDI ? 'addCdiBiller' : 'addBiller';
    return this.serviceEndpoint.post(requestType, payload);
  }

  public deleteBillerGroups(payload: RecipientGroupsRequest): Observable<HttpResponse<McaMessage>> {
    const requestType = this.config.isBillerCDI ? 'deleteCdiGroup' : 'deleteBillerGroup';
    return this.serviceEndpoint.post(requestType, payload);
  }

  public getGepgBillers() {
    return this.serviceEndpoint.get('getAllGepgBillers').pipe(map((response) => response.body));
  }

  public fetchRecipientsFromService(recipientType: any) {
    const benificiaryOnloadPayload = {
      pagination: true,
    };

    return this.serviceEndpoint.makeRequest(benificiaryOnloadPayload, 'recipients').pipe(
      map((res: any) => {
        if (res.statusText === 'OK') {
          let beneficiaries = res.body.beneficiaries;

          /* let beneficiaries = filter(res.body.beneficiaries, (itm: any) => {
            if (itm.keyValueMetadata && itm.keyValueMetadata.length) {
              let isKeyValMatched = false;
              itm.keyValueMetadata.forEach((metaObj) => {
                if (metaObj.key === 'CUSTOM_BEN_TYPE' && metaObj.value === 'BANK_ACCOUNT') {
                  isKeyValMatched = true;
                }
              });

              if (isKeyValMatched) {
                return true;
              }
            }
          }); */

          beneficiaries = reject(beneficiaries, (itm: any) => {
            return itm.route.networkType === 'GIP';
          });

          let walletBeneficiaries = filter(res.body.beneficiaries, (itm: any) => {
            if (itm.keyValueMetadata && itm.keyValueMetadata.length) {
              const isKeyValMatched = false;
              itm.keyValueMetadata.forEach((metaObj) => {
                if (metaObj.key === 'CUSTOM_BEN_TYPE' && metaObj.value === 'MOBILE_WALLET') {
                  return true;
                }
              });

              if (isKeyValMatched) {
                return true;
              }
            }
          });

          walletBeneficiaries = reject(walletBeneficiaries, (itm: any) => {
            return itm.route.networkType === 'GIP';
          });

          const beneficiariesMaxData = {
            beneficiaries,
            hasMoreElements: res.body.hasMoreElements,
          };

          if (recipientType === 'BANK_ACCOUNT') {
            return beneficiariesMaxData;
          } else {
            return walletBeneficiaries;
          }
        }
      })
    );
  }

  filterFunc(obj: any) {
    return obj.route.networkType === 'GIP';
  }

  public getRecipients(recipientType: any) {
    if (recipientType === 'BANK_ACCOUNT') {
      return this.fetchRecipientsFromService(recipientType);
    }
  }

  private updateRecipientData(response) {
    this.recipientsData = response;
  }
}
