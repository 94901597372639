import { createReducer, on } from '@ngrx/store';
import {
  setCmaBeneficiaryTypesData,
  setCmaCountryCodesData,
  setCmaPurposeCodesData,
  setCmaSubPurposeCodesData,
  setCountryCodesData,
  setExchangeRatesData,
  setPublicHolidaysData,
  setStampedStatementPayload,
  setStampedStatementPreviousStepData,
  setStampedStatementPreviousStepFormData,
  setStampedStatementReceiptData,
  setStatementType,
} from '../actions/banking-metadata.action';
import { BankingMetadataState } from '../state/banking-metadata.state';

const bankingMetadataInitState: BankingMetadataState = {
  exchangeRates: [],
  publicHolidays: [],
  countryCodes: [],
  cmaPurposeCodes: [],
  cmaSubPurposeCodes: {},
  cmaCountryCodes: [],
  cmaBeneficiaryTypes: [],
  stampedStatementPayload: [],
  stampedStatementPreviousStepData: [],
  stampedStatementPreviousStepFormData: [],
  stampedStatementReceiptData: [],
  statementType: '',
};

export const bankingMetadataReducer = createReducer(
  bankingMetadataInitState,
  on(setCountryCodesData, (state, { countryCodes }) => {
    return { ...state, countryCodes };
  }),
  on(setPublicHolidaysData, (state, { publicHolidays }) => {
    return { ...state, publicHolidays };
  }),
  on(setExchangeRatesData, (state, { exchangeRates }) => {
    return { ...state, exchangeRates };
  }),
  on(setCmaPurposeCodesData, (state, { cmaPurposeCodes }) => {
    return { ...state, cmaPurposeCodes };
  }),
  on(setCmaSubPurposeCodesData, (state, { cmaSubPurposeCodes }) => {
    return {
      ...state,
      cmaSubPurposeCodes: {
        ...state.cmaSubPurposeCodes,
        ...cmaSubPurposeCodes,
      },
    };
  }),
  on(setCmaCountryCodesData, (state, { countryCodes }) => {
    return { ...state, cmaCountryCodes: countryCodes };
  }),
  on(setCmaBeneficiaryTypesData, (state, { cmaBeneficiaryTypes }) => {
    return { ...state, cmaBeneficiaryTypes };
  }),
  on(setStampedStatementPayload, (state, { stampedStatementPayload }) => {
    return { ...state, stampedStatementPayload };
  }),
  on(setStampedStatementPreviousStepData, (state, { stampedStatementPreviousStepData }) => {
    return { ...state, stampedStatementPreviousStepData };
  }),
  on(setStampedStatementPreviousStepFormData, (state, { stampedStatementPreviousStepFormData }) => {
    return { ...state, stampedStatementPreviousStepFormData };
  }),
  on(setStampedStatementReceiptData, (state, { stampedStatementReceiptData }) => {
    return { ...state, stampedStatementReceiptData };
  }),
  on(setStatementType, (state, { statementType }) => {
    return { ...state, statementType };
  })
);
