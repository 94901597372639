import { createReducer, on } from '@ngrx/store';
import {
  setSelectedReceipientsData,
  setPayBeneDetailData,
  setFinalRecieptData,
  setPayBeneReviewData,
} from '../actions/pay-beneficiary.action';
import { SelectedBeneState } from '../state/pay-beneficiary.state';

const selectedBeneInitState = {};
const payBeneDetailDataInitState = {};
const payBeneReviewDataInitState = {};
const finalRecieptDataInitState = {};

const selectedBeneData: SelectedBeneState = {
  selectedReceipientsData: selectedBeneInitState,
  payBeneDetailData: payBeneDetailDataInitState,
  finalRecieptData: finalRecieptDataInitState,
  payBeneReviewData: payBeneReviewDataInitState,
};

export const payBeneficiaryReducer = createReducer(
  selectedBeneData,
  on(setSelectedReceipientsData, (state, { selectedReceipientsData }) => ({
    ...state,
    selectedReceipientsData,
  })),

  on(setPayBeneDetailData, (state, { payBeneDetailData }) => ({
    ...state,
    payBeneDetailData,
  })),
  on(setFinalRecieptData, (state, { finalRecieptData }) => ({
    ...state,
    finalRecieptData,
  })),

  on(setPayBeneReviewData, (state, { payBeneReviewData }) => ({
    ...state,
    payBeneReviewData,
  }))
);
