import { AfterViewInit, Component } from '@angular/core';
import { SbgApplicationService } from '../../services/sbg-application/sbg-application.service';
import { Store as Ngxs } from '@ngxs/store';
import { TearDownBridge } from '../../lib/store/actions/mAuthAction';

@Component({
  selector: 'sbg-web-error-overlay',
  templateUrl: './sbg-error-overlay.component.html',
  styleUrls: ['./sbg-error-overlay.component.scss'],
})
export class SbgErrorOverlayComponent implements AfterViewInit {
  constructor(private applicationService: SbgApplicationService, private ngxsStore: Ngxs) {}

  ngAfterViewInit(): void {
    // NB: to stop the main app spinner from loading
    // need to happen here - if the bridge service failed - and tear down has occured
    this.applicationService.appHasLoaded();
  }

  teardown() {
    this.ngxsStore.dispatch(new TearDownBridge({ isError: true }));
  }
}
