<ng-container *ngIf="isSmeMode && !isSmeSoleProprietor && !ishybridEOLModel">
  <section class="hybrid-approve-container mt-24" *ngIf="jsonData.length && !errorMessage">
    <section class="hybrid-to-section">
      <div class="hybrid-approve-header">Approver</div>
      <p class="customer__details--balance">choose Approver</p>
      <section class="">
        <div class="hybrid-radio-button">
          <mat-radio-group [(ngModel)]="selectedApprover">
            <mat-radio-button
              *ngFor="let rule of jsonData"
              [value]="rule"
              (change)="setSelectedApprover($event)"
              class="unselctable"
            >
              <div class="hybrid-radio-label pl-8" *ngFor="let role of rule.definedRoles; let i = index">
                <span>{{ role.count }} {{ role.role.designation }}</span>
                <span *ngIf="rule.definedRoles[i + 1]">+</span>
              </div>

              <div class="customer__details--balance pl-8 pt-8 pb-16">
                {{ rule.hierarchyEnabled ? 'With hierarchy' : 'Without hierarchy' }}
              </div>
            </mat-radio-button>

            <!-- <mat-radio-button value="2">Option 2</mat-radio-button> -->
          </mat-radio-group>
        </div>
        <mat-form-field class="form-field pt-8">
          <input
            type="text"
            matInput
            placeholder="Notes for approvers"
            [(ngModel)]="approverNote"
            name="approverNote"
            (change)="approverNoteChange()"
          />
        </mat-form-field>
      </section>
    </section>
  </section>
</ng-container>
<div *ngIf="isSmeMode && !isSmeSoleProprietor && ishybridEOLModel">
  <section class="hybrid-approve-container" *ngIf="jsonData.length && !errorMessage">
    <div class="hybrid-eol-to-section">
      <span class="userDetails--bankDetails"
        >Please select an approver and write a note to approver to save recipient as a beneficiary</span
      >
    </div>
    <div class="eol_outside_corner pb-0">
      <span class="hybrid-divider-label--secondary">Select Approver</span>
      <div class="hybrid-radio-button">
        <mat-radio-group [(ngModel)]="selectedApprover" class="">
          <mat-radio-button
            *ngFor="let rule of jsonData"
            [value]="rule"
            (change)="setSelectedApprover($event)"
          >
            <div class="hybrid-radio-label pl-8" *ngFor="let role of rule.definedRoles; let i = index">
              <span class="hybrid-invoice-block">{{ role.count }} {{ role.role.designation }}</span>
              <span *ngIf="rule.definedRoles[i + 1]">+</span>
            </div>

            <div class="customer__details--balance pl-8 pt-8 pb-8">
              {{ rule.hierarchyEnabled ? 'With hierarchy' : 'Without hierarchy' }}
            </div>
          </mat-radio-button>
        </mat-radio-group>
        <mat-form-field class="form-field pt-8">
          <input
            type="text"
            matInput
            placeholder="Note for approvers"
            [(ngModel)]="approverNote"
            name="approverNote"
            (change)="approverNoteChange()"
          />
        </mat-form-field>
      </div>
    </div>
  </section>
  <section class="hybrid-detaild-card_model" *ngIf="!jsonData.length && !errorMessage">
    <p class="mat-hint">No approvers found for this transaction</p>
  </section>
</div>
