import { Injectable } from '@angular/core';
// import { Router, NavigationEnd} from '@angular/router';
// import { filter} from 'rxjs/operators';

@Injectable()
export class ArrayFilterService {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public filterByKeyValueMetadata(obj): void {
    // try {
    //   for (let i = obj.keyValueMetadata.length - 1; i >= 0; i--) {
    //     let val = obj.keyValueMetadata[i];
    //     if (val.key === this.filterKey && val.value === this.filterValue) {
    //       return true;
    //     }
    //   }
    //   return false;
    // } catch (ex) {
    //   return false;
    // }
  }
  public sortBy(property) {
    let sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    if (property) {
      return (a, b) => {
        const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
      };
    } else {
      return (a, b) => {
        const result = a < b ? -1 : a > b ? 1 : 0;
        return result * sortOrder;
      };
    }
  }
  public searchAnObjectFromArray(key, value, array) {
    return array.filter((obj) => {
      return obj[key] === value;
    })[0];
  }
}
