import { DataSharingService } from './../../core/data-sharing/data-sharing.service';
import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SmeHelperService } from 'src/app/services/sme-helper/sme-helper.service';

@Component({
  selector: 'sbg-information',
  styleUrls: ['./information.component.scss'],
  templateUrl: '../templates/ModelInformation.html',
})
export class InformationComponent implements OnInit {
  title = ' Information template';
  viewMvpTcFirst = false;
  preLoginSmeMode = this.document['preLoginMode'] === 'SME';
  isSme = false;
  isSoleProp = false;
  newFeatureDetails: any;
  infoType: any;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private dataShararing: DataSharingService,
    private smeService: SmeHelperService
  ) {}
  ngOnInit() {
    if (this.dataShararing.modelDetails === 'mvp2') {
      this.viewMvpTcFirst = true;
    }
    this.isSme = this.smeService.isSmeMode();
    this.isSoleProp = this.smeService.isUserSmeSoleProprietor();
  }
}
