import { createReducer, on } from '@ngrx/store';
import { setProviderDetails, setSelectedBuy } from '../actions/buy.action';
import { BuyState } from '../interfaces/buy.model';

const buyInitState: BuyState = {
  providerDetails: [],
  selectedIndex: 0,
};
export const buyReducer = createReducer(
  buyInitState,
  on(setProviderDetails, (state, { providerDetails }) => ({
    ...state,
    providerDetails,
  })),
  on(setSelectedBuy, (state, { selectedIndex }) => ({
    ...state,
    selectedIndex,
  }))
);
