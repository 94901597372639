import { Injectable } from '@angular/core';
import { WindowRefService } from '../window-ref/window-ref.service';

@Injectable()
export class BrowserDetectorService {
  UserAgent: string;
  constructor(windowRef: WindowRefService) {
    this.UserAgent = windowRef.nativeWindow.navigator.userAgent;
  }

  isIEBrowser(): boolean {
    return this.UserAgent.indexOf('MSIE') > -1 || this.UserAgent.indexOf('Trident') > -1;
  }
  isEdgeBrowser(): boolean {
    return this.UserAgent.indexOf('Edge') > -1;
  }
  isSafariBrowser(): boolean {
    return this.UserAgent.indexOf('Chrome') < 0 && this.UserAgent.indexOf('Safari') > -1;
  }
  isFirefoxBrowser(): boolean {
    return this.UserAgent.indexOf('Firefox') > -1;
  }
}
