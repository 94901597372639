import { createReducer, on } from '@ngrx/store';
import * as LoginActions from '../actions/login.action';
const loginInitData = {
  loggedIn: false,
};

export const loginReducer = createReducer(
  loginInitData,
  on(LoginActions.setLoginData, (state, loginData) => {
    const login = loginData.login;
    return {
      ...state,
      loggedIn: true,
      ...login,
    };
  }),
  on(LoginActions.setLogoutData, (state, loginData) => {
    const login = loginData.login;
    return {
      ...state,
      loggedIn: false,
      ...login,
    };
  }),
  on(LoginActions.setSessionData, (state, session) => {
    const sessionData = session.sessionData;
    return {
      ...state,
      sessionData,
    };
  }),
  on(LoginActions.isSmeMode, (state, { isSmeMode }) => {
    return {
      ...state,
      isSmeMode,
    };
  }),
  on(LoginActions.isfirstTimeLogin, (state, firstTimeLogin) => {
    const isfirstTimeLogin = firstTimeLogin;
    return {
      ...state,
      isfirstTimeLogin,
    };
  }),
  on(LoginActions.forcedSecurityQuestionReset, (state, securityQuestionReset) => {
    const forcedSecurityQuestionReset = securityQuestionReset;
    return {
      ...state,
      forcedSecurityQuestionReset,
    };
  }),
  on(LoginActions.userName, (state, userID) => {
    const userName = userID;
    return {
      ...state,
      userName,
    };
  }),
  on(LoginActions.eapLimitScheme, (state, eapLimitScheme) => {
    // const eapLimitScheme = newScheme;
    return {
      ...state,
      eapLimitScheme,
    };
  }),
  on(LoginActions.isMobileAction, (state, isMobileActive) => {
    return {
      ...state,
      isMobileActive,
    };
  })
);
