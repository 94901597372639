import { borderlessPaymentReducer } from './../../../shared/store-utilities/reducers/borderlessPayment.reducer';
import { approvalReducer } from './../../../shared/store-utilities/reducers/approval.reducer';
import { payBeneficiaryReducer } from './../../../shared/store-utilities/reducers/pay-beneficiary.reducer';
import { onceOffPayBeneficiaryReducer } from './../../../shared/store-utilities/reducers/once-off-pay-beneficiary.reducer';

import { combineReducers } from 'redux';
//  unused so commented
// import { dashboardReducer } from "./dashboard-reducer";
// import { loginReducer } from "./login-reducer";
// import { bankingMetadataReducer } from "./banking-metadata-reducer"; // redux
import { airtimeReducer } from '../../../shared/store-utilities/reducers/airtime.reducer';
import { buyReducer } from '../../../shared/store-utilities/reducers/buy.reducer';
import { tvSubReducer } from '../../../shared/store-utilities/reducers/tvsubscription.reducer';
import { refreshReducer } from '../../../shared/store-utilities/reducers/refresh.reducer';
import { electricityReducer } from '../../../shared/store-utilities/reducers/electricity.reducer';
import { selfTransferReducer } from '../../../shared/store-utilities/reducers/selfTransfer.reducer';
import { unayoReducer } from '../../../shared/store-utilities/reducers/unayo.reducer';
import { taxPaymentReducer } from '../../../shared/store-utilities/reducers/taxPayment.reducer';
import { recipientReducer } from '../../../shared/store-utilities/reducers/recipient.reducer';
import { accSummaryReducer } from '../../../shared/store-utilities/reducers/accSummary.reducer';
import { instantMoenyReducer } from '../../../shared/store-utilities/reducers/instantMoney.reducer';
import { bankingMetadataReducer } from '../../../shared/store-utilities/reducers/banking-metadata.reducer';
import { dashboardReducer } from '../../../shared/store-utilities/reducers/dashboard.reducer';
import { loginReducer } from '../../../shared/store-utilities/reducers/login.reducer';
import { Action } from '@ngrx/store';

import { scheduleTxnReducer } from '../../../shared/store-utilities/reducers/txn-receipt.reducer';
import { registrationReducer } from '../../../shared/store-utilities/reducers/registration.reducer';
import { onceOffPaymentsReducer } from '../../../shared/store-utilities/reducers/payment.reducer';
import { intPaymentReducer } from '../../../shared/store-utilities/reducers/internationalPayment.reducer';
import { editTxnScheduleReducer } from '../../../shared/store-utilities/reducers/edit-txn-schedule.reducer';
import { debitCardReducer } from '../../../shared/store-utilities/reducers/debitCard.reducer';
import { mobileWalletReducer } from '../../../shared/store-utilities/reducers/mobileWallet.reducer';
import {
  billerPaymentsReducer,
  selectedPaymentsTabReducer,
  selectedVasTypeReducer,
} from './../../../shared/store-utilities/reducers/payment.reducer';
import { qrPaymentsReducer } from '../../../shared/store-utilities/reducers/qrPayments.reducer';
import { buyPrepaidWaterReducer } from '../../../shared/store-utilities/reducers/buy-prepaid-water.reducer';
import { buyRatesAndTaxesReducer } from '../../../shared/store-utilities/reducers/buy-ratesandtaxes.reducer';
import { vasPaymentReducer } from '../../../shared/store-utilities/reducers/vasPayment.reducer';
import { selectedTabReducer } from '../../../shared/store-utilities/reducers/active-tab.reducer';

const reducer = (state, action) =>
  combineReducers({
    recipientReducer,
    airtimeReducer,
    buyReducer,
    tvSubReducer,
    refreshReducer,
    electricityReducer,
    selfTransferReducer,
    unayoReducer,
    taxPaymentReducer,
    accSummaryReducer,
    instantMoenyReducer,
    dashboardReducer,
    loginReducer,
    bankingMetadataReducer,
    scheduleTxnReducer,
    registrationReducer,
    onceOffPaymentsReducer,
    intPaymentReducer,
    editTxnScheduleReducer,
    billerPaymentsReducer,
    payBeneficiaryReducer,
    onceOffPayBeneficiaryReducer,
    approvalReducer,
    selectedPaymentsTabReducer,
    debitCardReducer,
    mobileWalletReducer,
    selectedVasTypeReducer,
    qrPaymentsReducer,
    buyPrepaidWaterReducer,
    vasPaymentReducer,
    buyRatesAndTaxesReducer,
    selectedTabReducer,
    borderlessPaymentReducer,
  })(action.type === 'USER_LOGOUT' ? undefined : state, action);

export function appReducer(state: any, action: Action) {
  return reducer(state, action);
}

// export default appReducer;
