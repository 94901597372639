import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RouterStateService {
  private history = [];
  private referenceNoFlow: boolean;

  constructor(private router: Router) {}

  public loadRouting(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
        this.history = [...this.history, urlAfterRedirects];
      });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/index';
  }

  public getCurrentUrl(): string {
    return this.history[this.history.length - 1] || '/index';
  }
  public setReferenceFlow(value: boolean): void {
    this.referenceNoFlow = value;
  }
  public getReferenceFlow(): boolean {
    return this.referenceNoFlow;
  }
}
