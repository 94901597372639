<div class="close-current-module-container idle-timeout-container" *ngIf="showIdleModal">
  <div class="close-current-module-content idle-timeout-content">
    <img class="idle-timeout-img" src="/assets/images/success-notification.png" />
    <div class="idle-timeout-heading">Are you still banking?</div>
    <div class="idle-timeout-description">
      You have not interacted with this website for a while. <br />
      To protect your internet banking account, you will be automatically logged out in :
    </div>
    <div class="idle-timeout-timer">{{ timer }} Seconds</div>
    <div class="idle-timeout-buttons">
      <sbg-button
        class="payment-details-footer__back-button left"
        [isRaisedButton]="true"
        buttonType="secondary"
        (clickFunction)="logOut()"
        [disableRipple]="false"
        buttonStyle="secondary"
        buttonText="Log Out"
        ariaLabel="logout"
      ></sbg-button>
      <sbg-button
        class="right"
        [isRaisedButton]="true"
        buttonType="primary"
        [disableRipple]="false"
        buttonStyle="basic"
        buttonText="Continue"
        ariaLabel="stay"
        (clickFunction)="closeIdleTimeoutModal()"
      ></sbg-button>
    </div>
  </div>
</div>
