export const environment = {
  dev: true,
  sit: false,
  uat: false,
  production: false,
  log: true,

  flags: {
    useNewHeader: true,
  },
};
