export class SbgPDFContentPBBI {
  static HEADER_CONTENT = {
    content: ['Standard Bank Offshore Group', 'Website: www.international.standardbank.com/'],
  };
  static FOOTER_CONTENT = {
    title: 'Important Information',
    content: [
      {
        text:
          'Standard Bank Jersey Limited is regulated by the Jersey Financial Services Commission. Registered in Jersey No 12999.' +
          'Standard Bank Jersey Limited is a participant in the ',
        break: 3,
      },
      {
        text:
          'Jersey Bank Depositors Compensation Scheme. The Scheme offers protection for eligible deposits of up to £50,000.' +
          'The maximum total amount of compensation is capped ',
        break: 3,
      },
      {
        textBeforeLink:
          'at £100,000,000 in any 5 year period. Full details of the Scheme and ' +
          'banking groups covered are available on the States of Jersey website ',
        linkText: 'www.gov.je/dcs',
        textAfterLink: ', or on request. ',
        break: 5,
      },
      {
        text:
          'Standard Bank Isle of Man Limited is licensed by the Isle of Man Financial Services Authority. Registered in the Isle of Man No.4713C.' +
          'Deposits made with Standard Bank Isle ',
        break: 3,
      },
      {
        text: 'of Man Limited are covered by the Depositors Compensation Scheme as set out in the Depositors Compensation Scheme Regulations 2010.',
        break: 5,
      },
      {
        textBeforeLink:
          'Telephone calls may be recorded. ' +
          'Please refer to the Standard Bank Offshore Group Client Privacy Statement available from: ',
        linkText: 'https://international.standardbank.com/',
        textAfterLink: '',
        break: 1,
      },
    ],
  };
}

export class SbgPDFContentSA {
  static HEADER_CONTENT = {
    content: ['Customer Care: 0860 123 000', 'Website: www.standardbank.co.za'],
  };

  static FOOTER_CONTENT = {
    title: 'Important Information',
    content: [
      `The Standard Bank of South Africa Limited (Reg. No. 1962/000738/06. Authorised financial services provider.
            VAT Reg No. 4100105461 Registered credit provider (NCRCP15). We subscribe to the Code of Banking Practice
            of the Banking Association South Africa and, for unresolved disputes, support resolution through the
            Ombudsman for Banking Services.`,
    ],
  };
}

export class SbgPDFConfiguration {
  static NORMAL_FONT_SIZE = 10;

  //BODY
  static BODY_BREAK_HEIGHT = 10;
  static BODY_LINE_HEIGHT = 5;

  //HEADER
  static HEADER_FONT_SIZE = 8;
  static HEADER_LINE_HEIGHT = 4;

  //FOOTER

  static FOOTER_FONT_SIZE = 6;
  static FOOTER_LINE_HEIGHT = 3;
  static FOOTER_BREAK_HEIGHT = 2;

  //GENERAL
  static PRIMARY_BLUE = {
    R: 0,
    G: 51,
    B: 170,
  };

  static WHITE_COLOR = {
    R: 255,
    G: 255,
    B: 255,
  };

  static BLACK = {
    R: 0,
    G: 0,
    B: 0,
  };

  static FONT_MEDIUM_COLOR = {
    R: 102,
    G: 102,
    B: 102,
  };

  static NEGATIVE_COLOR = {
    R: 231,
    G: 0,
    B: 17,
  };

  static POSITIVE_COLOR = {
    R: 48,
    G: 170,
    B: 34,
  };

  static TABLE_LINE_HEIGHT = 5;
  static TABLE_HEADER_LINE_HEIGHT = 9;
  static TABLE_DEFAULT_PADDING = 1;
  static TABLE_LIGHT_PADDING = { top: 4, bottom: 4, left: 2, right: 0 };
  static TABLE_ZERO_PADDING = { top: 0, bottom: 0, left: 0, right: 0 };

  static PAGE_ORIENTATION = 'p';
  static MEASUREMENT_UNIT = 'mm';
  static A4_DIMENSIONS = 'a4';
  static LEFT_MARGIN = 10;
  static CENTER_MARGIN = 110;
  static BREAK_HEIGHT = 10;
  static NORMAL_FONT_STYLE = 'normal';
  static BOLD_FONT_STYLE = 'bold';
  static PAGE_WIDTH = 190;
}
