import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ServiceEndpointsService } from '../service-endpoints/service-endpoints.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticateService {
  public isLoggedIn = false;
  constructor(private serviceEndpoint: ServiceEndpointsService) {}

  startSession(): Observable<any> {
    return this.serviceEndpoint.makeGetRequest(null, 'startSession').pipe(
      map((res) => {
        return res;
      })
    );
  }

  login(model: any): Observable<{}> {
    let payload;
    if (!model.stepUp) {
      payload = {
        digitalId: {
          password: btoa(model.digitalId.password),
          username: model.digitalId.username,
        },
      };
    } else {
      payload = model;
    }
    return this.serviceEndpoint.makeRequest(payload, 'authenticate').pipe(
      map((res) => {
        this.isLoggedIn = true;
        return res;
      })
    );
  }
  fetchOffer(): Observable<{}> {
    return this.serviceEndpoint.makeRequest({}, 'fetchOffers');
  }

  logout(payload?: any): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'logout').pipe(
      map((res) => {
        return res;
      })
    );
  }

  reIssueToken(): Observable<{}> {
    return this.serviceEndpoint.makeRequest({}, 'reIssueToken');
  }
}
