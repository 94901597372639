import { Injectable, NgZone } from '@angular/core';
import { environment } from '@environment';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
// import { filter, map, take } from 'rxjs/operators';
// import { BridgeContext } from '../../lib/interfaces';
@Injectable({ providedIn: 'root' })
export class BridgeService {
  // sessionContext$: Observable<BridgeContext | null>;
  sessionToken$: Observable<string | null>;
  // sessionReady$: Observable<boolean>;
  // sessionNotReady$: Observable<boolean>;
  env = environment;
  private sessionToken = new BehaviorSubject<string | null>(null);
  // private sessionContext = new BehaviorSubject<BridgeContext | null>(null);
  private hasToken = new BehaviorSubject<boolean>(false);
  // private hasContext = new BehaviorSubject<boolean>(false);
  private bridgeIsSetup = false;
  private nativeWindow = window as any;
  phoneNumber: any;
  nonZeroPhoneNumber: Array<string>;
  otpValue: string;
  protected nativeData = new ReplaySubject<string>(1);
  nativeData$ = this.nativeData.asObservable();
  private otpSubject = new Subject<string>();
  otp$: Observable<string> = this.otpSubject.asObservable();
  constructor(private ngZone: NgZone) {
    // this.sessionContext$ = this.sessionContext.asObservable();
    this.sessionToken$ = this.sessionToken.asObservable();
    // const hasTokenAndContext: Observable<boolean> = combineLatest([this.hasToken, this.hasContext]).pipe(
    //   map(([a, b]) => a && b)
    // );
    // this.sessionReady$ = hasTokenAndContext.pipe(filter((t) => t === true));
    // this.sessionNotReady$ = hasTokenAndContext.pipe(filter((t) => t === false));
  }

  registerBridge(): void {
    if (!this.bridgeIsSetup) {
      if (this.setupBridge()) {
        this.bridgeIsSetup = true;
        this.requestNewToken();
        // this.requestNewContext();
      }
      // this.checkForSessionTimeout();
    }
  }

  requestNewToken(displayNativeProgressSpinner = false): void {
    const shouldShowProgress = {
      displayNativeProgress: displayNativeProgressSpinner,
    };
    this.sessionToken.next(null);

    if (this.nativeWindow.webkit && this.nativeWindow.webkit.messageHandlers.SessionBridge) {
      this.nativeWindow.webkit.messageHandlers.SessionBridge.postMessage(JSON.stringify(shouldShowProgress));
    } else if (this.nativeWindow.SessionBridge) {
      this.nativeWindow.SessionBridge.postMessage(JSON.stringify(shouldShowProgress));
    }
  }

  // requestNewContext(): void {
  //   this.sessionContext.next(null);

  //   if (this.nativeWindow.webkit && this.nativeWindow.webkit.messageHandlers.ContextBridge) {
  //     this.nativeWindow.webkit.messageHandlers.ContextBridge.postMessage(null);
  //   } else if (this.nativeWindow.ContextBridge) {
  //     this.nativeWindow.ContextBridge.postMessage(null);
  //   }
  // }
  openContactPicker(data) {
    // const postMessage = {actionType: 'contact',metadata: {type: data.type}};
    const messageAction = { type: data.type };
    if (this.nativeWindow.webkit && this.nativeWindow.webkit.messageHandlers.SelectContactBridge) {
      this.nativeWindow.webkit.messageHandlers.SelectContactBridge.postMessage(JSON.stringify(messageAction));
    } else if (this.nativeWindow.SelectContactBridge) {
      this.nativeWindow.SelectContactBridge.postMessage(JSON.stringify(messageAction));
    }
    this.setNativeDataCallback();
  }

  // On my test, I called this function on the ngOnInit of the OTP Component
  readSmsBridge(): void {
    if (this.nativeWindow.webkit && this.nativeWindow.webkit.messageHandlers.ReadSmsBridge) {
      // IB - iOS Bridge / Use the name of the Bridge provided by Moses
      this.nativeWindow.webkit.messageHandlers.ReadSmsBridge.postMessage(null);
    } else if (this.nativeWindow.ReadSmsBridge) {
      // IB - Android Bridge / Use ReadSmsBridge for Android
      this.nativeWindow.ReadSmsBridge.postMessage(null);
    }
    this.setReadSmsCallback();
  }

  setReadSmsCallback(): void {
    // IB - allback for Android
    this.nativeWindow.readSmsCallback = (data: string) => {
      this.ngZone.run(() => {
        // Send data back to screen
        // data is the retrieved OTP on Android
        this.otpSubject.next(data);
      });
    };
  }

  protected setNativeDataCallback() {
    this.nativeWindow.nativeDataCallback = (data: string) => {
      this.ngZone.run(() => {
        // this.nativeData.next(data);
        this.handleContactPickerData(data);
      });
    };
  }

  private handleContactPickerData(data: any) {
    let contact: any;
    let number;
    let countryCode;
    if (this.nativeWindow.webkit && this.nativeWindow.webkit.messageHandlers.SessionBridge) {
      if (data.data['value'].startsWith('0')) {
        this.phoneNumber = data.data['value'].slice(1);
        number = this.phoneNumber.replace(/\s|-/g, '');
        contact.push(number);
      } else if (data.data['value'].startsWith('+')) {
        this.nonZeroPhoneNumber = data.data['value'].match(/^(\+\d+)\s(.+)/);
        countryCode = '+' + this.nonZeroPhoneNumber[1];
        number = this.nonZeroPhoneNumber[2].replace(/\s|-/g, '');
        contact = [countryCode, number];
      } else {
        this.phoneNumber = `${this.env.config.mobileCountryCode}${(this.phoneNumber = data.data['value'])}`;
        this.nonZeroPhoneNumber = this.phoneNumber.match(/^\+(\d{3})(\d{7,10})$/);
        countryCode = '+' + this.nonZeroPhoneNumber[1];
        number = this.nonZeroPhoneNumber[2].replace(/\s|-/g, '');
        contact = [countryCode, number];
      }
    } else {
      data.data['value'] = data.data['value'].replace(/\s|-/g, '');
      if (data.data['value'].startsWith('0')) {
        // if(this.env.config.countryName === "Tanzania"){
        //   this.phoneNumber = data.data["value"].slice(1);
        //   number = this.phoneNumber.replace(/\s|-/g, '')
        //   contact.push(number);
        // }else{
        this.phoneNumber = `${this.env.config.mobileCountryCode}${(this.phoneNumber =
          data.data['value'].slice(1))}`;
        this.nonZeroPhoneNumber = this.phoneNumber.match(/^\+(\d{3})(\d{7,10})$/);
        countryCode = '+' + this.nonZeroPhoneNumber[1];
        number = this.nonZeroPhoneNumber[2].replace(/\s|-/g, '');
        contact = [countryCode, number];
        // }
      } else if (data.data['value'].startsWith('+')) {
        // if(this.env.config.countryName === "Tanzania"){
        //   this.nonZeroPhoneNumber = data.data["value"].match(/^\+(\d{1,4})(\d{9,10})$/);
        // countryCode = "+" + this.nonZeroPhoneNumber[1];
        // number = this.nonZeroPhoneNumber[2].replace(/\s|-/g, '')
        // contact = [countryCode,number];
        // }else{
        this.nonZeroPhoneNumber = data.data['value'].match(/^(\+\d{1,3})(\d{6,14})$/);
        countryCode = '+' + this.nonZeroPhoneNumber[1];
        number = this.nonZeroPhoneNumber[2].replace(/\s|-/g, '');
        contact = [countryCode, number];
        // }
      } else {
        this.phoneNumber = `${this.env.config.mobileCountryCode}${(this.phoneNumber = data.data['value'])}`;
        this.nonZeroPhoneNumber = this.phoneNumber.match(/^\+(\d{3})(\d{7,10})$/);
        countryCode = '+' + this.nonZeroPhoneNumber[1];
        number = this.nonZeroPhoneNumber[2].replace(/\s|-/g, '');
        contact = [countryCode, number];
      }
    }
    this.nativeData.next(contact);
  }
  dialerBridge(numb) {
    const numberObj = {
      actionType: 'dialer',
      metadata: {
        number: numb,
      },
    };
    if (this.nativeWindow.webkit && this.nativeWindow.webkit.messageHandlers.DeepLinkingBridge) {
      this.nativeWindow.webkit.messageHandlers.DeepLinkingBridge.postMessage(JSON.stringify(numberObj));
    } else if (this.nativeWindow.DeepLinkingBridge) {
      this.nativeWindow.DeepLinkingBridge.postMessage(JSON.stringify(numberObj));
    } else {
      this.nativeWindow.open(`tel:${numb}`, '_self');
    }
  }

  notificationBridge(message) {
    const notification = {
      notificationType: 'success',
      metadata: {
        message: JSON.stringify(message),
      },
    };
    if (this.nativeWindow.webkit && this.nativeWindow.webkit.messageHandlers.NotificationBridge) {
      this.nativeWindow.webkit.messageHandlers.NotificationBridge.postMessage(JSON.stringify(notification));
    } else if (this.nativeWindow.NotificationBridge) {
      this.nativeWindow.NotificationBridge.postMessage(JSON.stringify(notification));
    }
  }

  performDeepLink(actionType, link) {
    const deepLinkAction = {
      actionType: actionType,
      metadata: {
        url: link,
      },
    };
    if (this.nativeWindow.webkit && this.nativeWindow.webkit.messageHandlers.DeepLinkingBridge) {
      this.nativeWindow.webkit.messageHandlers.DeepLinkingBridge.postMessage(JSON.stringify(deepLinkAction));
    } else if (this.nativeWindow.DeepLinkingBridge) {
      this.nativeWindow.DeepLinkingBridge.postMessage(JSON.stringify(deepLinkAction));
    }
  }
  simpleDeeplink(actionType, token) {
    const deepLinkAction = {
      actionType: actionType,
      metadata: {
        tokenKey: token,
      },
    };

    if (this.nativeWindow.webkit && this.nativeWindow.webkit.messageHandlers.DeepLinkingBridge) {
      this.nativeWindow.webkit.messageHandlers.DeepLinkingBridge.postMessage(JSON.stringify(deepLinkAction));
    } else if (this.nativeWindow.DeepLinkingBridge) {
      this.nativeWindow.DeepLinkingBridge.postMessage(JSON.stringify(deepLinkAction));
    }
  }
  tearDownBridge(): void {
    if (this.nativeWindow.webkit && this.nativeWindow.webkit.messageHandlers.TearDownBridge) {
      this.nativeWindow.webkit.messageHandlers.TearDownBridge.postMessage(null);
    } else if (this.nativeWindow.TearDownBridge) {
      this.nativeWindow.TearDownBridge.postMessage(null);
    }
  }

  private setupBridge(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    this.nativeWindow.getAccessTokenCallback = function getAccessToken(accessToken): boolean {
      self.sessionToken.next(accessToken.token);
      self.hasToken.next(true);
      return true;
    };

    // this.nativeWindow.getContextCallback = function getContextCallback(context): boolean {
    //   self.sessionContext.next(context);
    //   self.hasContext.next(true);
    //   return true;
    // };

    return true;
  }

  //-----------------------------------------------
  // check if the "sessionReady$" is still "false"
  //-----------------------------------------------
  // private checkForSessionTimeout(): void {
  //   setTimeout(
  //     () => {
  //       this.sessionNotReady$.pipe(take(1)).subscribe(() => {
  //         this.hasContext.next(true);
  //         this.hasToken.next(true);
  //       });
  //     },
  //     this.env.dev ? 500 : 1000
  //   );
  // }
}
