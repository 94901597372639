import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DataSharingService } from '../../core/data-sharing/data-sharing.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Store as Ngxs } from '@ngxs/store';
import { TearDownBridge } from '../../../app/native/lib/store/actions/mAuthAction';

@Component({
  selector: 'app-close-current-module',
  templateUrl: './close-current-module.component.html',
  styleUrls: ['./close-current-module.component.scss'],
})
export class CloseCurrentModuleComponent implements OnInit {
  @Output() backFunction = new EventEmitter<any>();
  moduleData;
  isHybrid: boolean;
  isDashboard: boolean;
  buttonDisabled = false;

  teardown_route_list: string[] = [
    '/sendMoney/instantMoney/details',
    '/payments/internationalPayment/BoPDeclaration',
  ];

  constructor(
    private dataSharingService: DataSharingService,
    private router: Router,
    private store: Store<any>,
    private ngxsStore: Ngxs
  ) {}

  ngOnInit() {
    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      if (Object.prototype.hasOwnProperty.call(value, 'isMobileActive')) {
        this.isHybrid = value.isMobileActive.isHybrid;
        this.isDashboard = value.isMobileActive.dashboard;
      }
    });
    this.dataSharingService.getCloseCurrentModule().subscribe((value) => {
      this.moduleData = value;

      if (this.moduleData.financeType === 'FINANCIAL_RULES') {
        this.moduleData.financeValue = 'financialRules';
      } else if (this.moduleData.financeType === 'NON_FINANCIAL_RULES') {
        this.moduleData.financeValue = 'nonFinancialRules';
      }
    });
  }

  clearModuleData() {
    this.dataSharingService.setCloseCurrentModule({});
  }

  rightButtonClickedFn() {
    if (this.isHybrid) {
      const currentRoute = this.router.url;
      if (this.teardown_route_list.includes(currentRoute) && !this.isDashboard) {
        this.ngxsStore.dispatch(new TearDownBridge({ isError: true }));
      } else if (this.moduleData.routeNext) {
        this.router.navigate([this.moduleData.routeNext]);
        this.clearModuleData();
      }
      this.dataSharingService.setCloseCurrentModule({
        stopScheduleFnCall: true,
      });
    }
    if (!this.isHybrid) {
      if (this.moduleData.routeNext) {
        this.router.navigate([this.moduleData.routeNext]);
        this.clearModuleData();
      }
      this.dataSharingService.setCloseCurrentModule({
        stopScheduleFnCall: true,
      });
    }
  }

  leftButtonClickedFn() {
    if (this.moduleData.routeBackTo) {
      this.router.navigate([this.moduleData.routeBackTo]);
    }
    this.clearModuleData();
  }

  disableStopButton() {
    this.buttonDisabled = true;
  }
}
