import { createAction, props } from '@ngrx/store';
import { VasProvider } from '../../../core/prepaid-service/data/vas-provider';
import { TransactionsResponse } from '../../../core/transaction-service/data/transactions-response';
import { RatesAndTaxesModel } from '../../../components/buy/ratesandtaxes/ratesandtaxes.model';

const reducerHeader = '[Buy Rates And Taxes]';

export const setRatesAndTaxesModel = createAction(
  `${reducerHeader} setRatesAndTaxesModel`,
  props<{ ratesandtaxesModel: RatesAndTaxesModel }>()
);

export const setProvider = createAction(
  `${reducerHeader} setProvider`,
  props<{ selectedProvider: VasProvider }>()
);

export const setProviders = createAction(
  `${reducerHeader} setProviders`,
  props<{ providers: VasProvider[] }>()
);

export const setRatesAndTaxesReceiptData = createAction(
  `${reducerHeader} setRatesAndTaxesReceiptData`,
  props<{ receiptData: TransactionsResponse }>()
);

export const resetRatesAndTaxesModel = createAction(`${reducerHeader} resetRatesAndTaxesModel`);

export const resetProvider = createAction(`${reducerHeader} resetProvider`);

export const resetRatesAndTaxesReceiptData = createAction(`${reducerHeader} resetRatesAndTaxesReceiptData`);
