import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AccountDetailsServiceService {
  getLoanAccDetails() {
    const loanAccDetails = {
      a: 3,
    };
    return loanAccDetails;
  }
}
