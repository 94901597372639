import { createAction, props } from '@ngrx/store';
import {
  SelectedDebitCardData,
  SelectedDebitCardConfirmData,
  SelectedDebitCardReceiptData,
  SelectedDebitCardDetails,
  activeDebitCardDetails,
  confirmationData
} from '../interfaces/debitCard.model';

export const setSelectedDebitCardData = createAction(
  '[SelfAccountTransfer Component] setSelectedDebitCardData',
  props<{ selectedDebitCardData: SelectedDebitCardData }>()
);

export const setSelectedDebitCardConfirmData = createAction(
  '[SelfAccountTransfer Component] setSelectedDebitCardConfirmData',
  props<{ selectedDebitCardConfirmData: SelectedDebitCardConfirmData }>()
);

export const setSelectedDebitCardReceiptData = createAction(
  '[SelfAccountTransfer Component] setSelectedDebitCardReceiptData',
  props<{ selectedDebitCardReceiptData: SelectedDebitCardReceiptData }>()
);

export const setDebitCardDetails = createAction(
  '[SelfAccountTransfer Component] setDebitCardDetails',
  props<{ selectedDebitCardDetails: SelectedDebitCardDetails }>()
)

export const setSelectedCardDetails = createAction(
  '[SelfAccountTransfer Component] setSelectedCardDetails',
  props<{ activeDebitCardDetails: activeDebitCardDetails }>()
)

export const setConfirmationData = createAction(
  '[SelfAccountTransfer Component] setConfirmationData',
  props<{ confirmationData: confirmationData }>()
)

