import { Injectable } from '@angular/core';

@Injectable()
export class TransactionTypeService {
  getDebitCardStatus(cardType: string): string {
    let cardStatus;
    switch (cardType) {
      case 'A':
      case 'ACTIVE':
        cardStatus = 'Active';
        break;
      case 'D':
      case 'INACTIVE':
        cardStatus = 'Inactive';
        break;
      case 'H':
      case 'STOPPED':
        cardStatus = 'Stopped';
        break;
      case 'I':
      case 'ISSUED':
        cardStatus = 'Issued';
        break;
      case 'W':
      case 'BLOCKED':
        cardStatus = 'Temporarily Blocked';
        break;
      default:
        cardStatus = 'Undefined';
    }
    return cardStatus;
  }
}
