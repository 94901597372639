import { createReducer, on } from '@ngrx/store';

import * as waterAction from '../actions/buy-prepaid-water.action';
import { BuyPrepaidWaterState } from '../state/buy-prepaid-water.state';

const waterInitData: BuyPrepaidWaterState = {
  waterModel: null,
  selectedProvider: null,
  providers: null,
  receiptData: null,
};
export const buyPrepaidWaterReducer = createReducer(
  waterInitData,
  on(waterAction.setWaterModel, (state, { waterModel }) => ({
    ...state,
    waterModel,
  })),
  on(waterAction.setProvider, (state, { selectedProvider }) => ({
    ...state,
    selectedProvider,
  })),
  on(waterAction.setProviders, (state, { providers }) => ({
    ...state,
    providers,
  })),
  on(waterAction.setWaterReceiptData, (state, { receiptData }) => ({
    ...state,
    receiptData,
  })),
  on(waterAction.resetWaterModel, (state) => ({ ...state, waterModel: null })),
  on(waterAction.resetProvider, (state) => ({
    ...state,
    selectedProvider: null,
  })),
  on(waterAction.resetWaterReceiptData, (state) => ({
    ...state,
    receiptData: null,
  }))
);
