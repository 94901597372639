<ng-container *ngIf="!preLoginSmeMode; then notSmeMode; else smeMode"></ng-container>
<ng-template #notSmeMode>
    <div class="column large-12 medium-12 small-12 medium-centered large-centered tc-wrap">
        <div class="conditions">
          Although the information provided on this web site has been obtained from sources that Stanbic Bank believes to be reliable not all of the information has been independently verified. The information may be incomplete or condensed. Any and all opinions and estimates specified on Stanbic Bank is web site are based on opinions held or estimates provided based on available information as at the date such opinions / estimates were displayed on the web site and are subject to change without notice. Stanbic Bank does not guarantee the accuracy of such information opinions or estimates and will not in any manner whatsoever be held liable to any person who having visited our web site claims to have relied on any or all of the information opinions or estimates contained in the web site. The contents of this web site may not be published used or reproduced in whole or in part without the prior written consent of Stanbic Bank.
        </div>
    </div>
</ng-template>
<ng-template #smeMode>
    <div class="column large-12 medium-12 small-12 medium-centered large-centered tc-wrap">
        <div class="conditions">Any confirmation of transaction must not be construed as a confirmation of credit into a beneficiary's account, Due to the nature of internet the transactions may be subjected to interruption, transmission blockout, delayed transmission and incorrect data transmission. The bank is not liable for malfunctions in communication facilities not within its control that may affect the accuracy of timeliness of messages and transactions you send. All transactions are subject to our verifications and our normal fraud checks.</div>
    </div>
</ng-template>