import { Injectable } from '@angular/core';

const getWindow = () => {
  return window;
};
// USE THIS IN COMPONENTS TO ACCESS WINDOW OBJECT VIA DEPENDENCY INJECTION
@Injectable()
export class WindowRefService {
  get nativeWindow(): any {
    return getWindow();
  }
}
