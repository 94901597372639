import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServiceEndpointsService } from '../service-endpoints/service-endpoints.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RouterStateService } from '../../services/route-state/route-state.service';
import { environment } from './../../../environments/environment';

@Injectable()
export class ServiceRequestService {
  config = environment.config;
  constructor(
    private serviceEndpoint: ServiceEndpointsService,
    private router: Router,
    private routeState: RouterStateService,
    private activatedRoute: ActivatedRoute
  ) {}

  getServiceRequests(): Observable<{}> {
    return this.serviceEndpoint.makeRequest({}, 'serviceRequests').pipe(map((res) => res));
  }

  newServiceRequest(): Observable<{}> {
    return this.serviceEndpoint.makeRequest(null, 'newServiceRequest').pipe(map((res) => res));
  }

  getIndividualServiceRequests(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'individualServiceRequests').pipe(map((res) => res));
  }

  accountModification(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'accountModification').pipe(map((res) => res));
  }

  contactTimeList(): Observable<{}> {
    return this.serviceEndpoint.makeRequest(null, 'contactTimeList').pipe(map((res) => res));
  }

  loanProductList(): Observable<{}> {
    return this.serviceEndpoint.makeRequest(null, 'loanProductList').pipe(map((res) => res));
  }

  applyForLoan(payload): Observable<{}> {
    if (payload.simpleLoanData) {
      return this.serviceEndpoint.makeRequest(payload, 'applyForSimpleLoan').pipe(map((res) => res));
    } else {
      return this.serviceEndpoint.makeRequest(payload, 'applyForLoan').pipe(map((res) => res));
    }
  }

  depositBook(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'depositBook').pipe(map((res) => res));
  }

  fixedDepositBreakage(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'fixedDepositBreakage').pipe(map((res) => res));
  }

  blueSky(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'blueSky').pipe(map((res) => res));
  }

  availableFixedDepositPeriods(): Observable<{}> {
    return this.serviceEndpoint.makeRequest(null, 'availableFixedDepositPeriods').pipe(map((res) => res));
  }

  renewalInstructions(): Observable<{}> {
    return this.serviceEndpoint.makeRequest(null, 'renewalInstructions').pipe(map((res) => res));
  }

  renewFixedDeposit(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'renewFixedDeposit').pipe(map((res) => res));
  }

  overDraftLimitUpdate(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'overDraftLimitUpdate').pipe(map((res) => res));
  }

  stopChequeReason(): Observable<{}> {
    return this.serviceEndpoint.makeRequest(null, 'stopChequeReason').pipe(map((res) => res));
  }

  stopCheque(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'stopCheque').pipe(map((res) => res));
  }

  chequeBookBranches(): Observable<{}> {
    return this.serviceEndpoint.makeRequest(null, 'chequeBookBranches').pipe(map((res) => res));
  }

  numberOfLeaves(): Observable<{}> {
    return this.serviceEndpoint.makeRequest(null, 'numberOfLeaves').pipe(map((res) => res));
  }

  chequeBookRequest(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'chequeBookRequest').pipe(map((res) => res));
  }

  atmDebitCard(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'atmDebitCard').pipe(map((res) => res));
  }

  ebankingLimitAdjustment(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'ebankingLimitAdjustment').pipe(map((res) => res));
  }

  bankCertifiedCheque(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'bankCertifiedCheque').pipe(map((res) => res));
  }

  collectionBranch(): Observable<{}> {
    return this.serviceEndpoint.makeRequest(null, 'collectionBranch').pipe(map((res) => res));
  }

  atmLimitAdjustment(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'atmLimitAdjustment').pipe(map((res) => res));
  }

  creditCard(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'creditCard').pipe(map((res) => res));
  }

  renewSibekeloAccount(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'renewSibekeloAccount').pipe(map((res) => res));
  }
  requestInsurance(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'requestInsurance').pipe(map((res) => res));
  }
  fixedDepositInterestRate(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'fixedDepositInterestRate').pipe(map((res) => res));
  }
  cancelNoticeGivenAccount(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'cancelNoticeGivenAccount').pipe(map((res) => res));
  }
  giveNoticeOnNoticeDeposit(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'giveNoticeOnNoticeDeposit').pipe(map((res) => res));
  }
  posReversal(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'posReversal');
  }

  goBackHandler() {
    const routeHistory = this.routeState.getPreviousUrl();
    if (routeHistory.search('accountDetails') !== -1) {
      this.router.navigate(['../accountDetails'], {
        relativeTo: this.activatedRoute.parent,
      });
    } else {
      this.router.navigate(['../serviceRequests/newServiceRequest'], {
        relativeTo: this.activatedRoute.parent,
      });
    }
  }

  getMobileNumber(number) {
    const length = this.config.mobileCountryCode.length - 1;
    return number.substr(length);
  }
}
