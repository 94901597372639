<div class="head-pagination" *ngIf="!headerPaginationData.hideHeadPagination && !isHybrid">
  <!-- section 1 -->
  <div class="flex-container align-middle align-justify">
    <!-- Back icon -->
    <div class="ml-16" [ngClass]="{ invisible: !headerPaginationData.showBack || showBack }">
      <mat-icon (click)="goBack()" class="material-icons font-primary back-icon">arrow_back_ios</mat-icon>
    </div>

    <!-- Header title -->
    <div class="main-title">
      <span>
        {{ headerPaginationData.mainTitle }}
      </span>
    </div>

    <div class="" *ngIf="!hideButtons">
      <!-- Close icon -->
      <div *ngIf="!showLogout" class="close__container">
        <mat-icon
          *ngIf="headerPaginationData.showClose"
          (click)="closeModule()"
          class="material-icons font-primary close-icon"
          >close</mat-icon
        >
        <p
          class="font-medium-14-primary cursor-pointer"
          *ngIf="!headerPaginationData.showClose"
          (click)="goHome()"
        >
          DONE
        </p>
      </div>

      <!-- Logout button -->
      <div *ngIf="showLogout" class="close__container">
        <p class="font-medium-14-primary cursor-pointer" (click)="closeModule()">Sign Out</p>
      </div>
    </div>
  </div>

  <!-- section 2 -->
  <div class="sub-title">
    <span> {{ headerPaginationData.subTitle }}</span>
    <span *ngIf="headerPaginationData.showEdit" class="ml-16">
      <i class="material-icons edit-icon cursor-pointer" aria-hidden="true" (click)="editClicked()">create</i>
    </span>
  </div>

  <!-- section 3 -->
  <div>
    <ul class="transaction-steps-widget pagination-bullets-con clearfix" *ngIf="steps.length > 1">
      <li *ngFor="let step of steps; let i = index" class="header-pagination-bullets">
        <div class="transaction-steps-line-con">
          <div class="transaction-steps-line" [ngClass]="{ 'transaction-done': stateIndex >= i }"></div>
        </div>
        <div class="transaction-steps-number-con">
          <div class="transaction-steps-number" [ngClass]="{ 'transaction-done': stateIndex >= i }"></div>
        </div>
      </li>
    </ul>
  </div>
</div>
