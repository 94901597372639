import { createAction, props } from '@ngrx/store';
import { SelectedTVSubData, TVSubReceiptData } from '../interfaces/buy.model';
import { TvModel } from '../../../components/buy/prepaid-models.module';

export const setSelectedTVSubData = createAction(
  '[TVSubscriptionList Component] selectTVSubscription',
  props<{ selectedTVSubData: SelectedTVSubData }>()
);

export const setTVSubReceiptListData = createAction(
  '[TVSubscriptionList Component] setTVSubscriptionReceiptList',
  props<{ receiptList: Array<any> }>()
);

export const setTVSubData = createAction(
  '[TVSubscriptionList Component] setAirtimeData',
  props<{ tvSubData: TvModel }>()
);

export const setTVSubReceiptData = createAction(
  '[TVSubscriptionList Component] setAirtimeReceiptData',
  props<{ tvSubReceiptData: TVSubReceiptData }>()
);
