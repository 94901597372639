import { AppConstantService } from '../../core/app-constant/app-constant.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataSharingService } from '../../core/data-sharing/data-sharing.service';
import { Modules, Payments } from '../../core/masking-service/masking-constants';
import { MaskingService } from '../../core/masking-service/masking.service';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { setSelectedVasType } from '../../shared/store-utilities/actions/payment.action';
import { setSelectedCmaBeneficiaryData } from '@shared/store-utilities/actions/recipient.action';

@Component({
  selector: 'sbg-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  paySubTitles = [
    {
      'Pay bank account beneficiary': 'Pay or Add beneficaries',
      'Unayo Payment': 'Quick Payments',
      'Once off payment': 'Quick pay to Non Beneficiaries',
      'CMA Payments': 'International payments to Beneficiaries',
      'Once-off CMA Payments': 'Quick International payments',
      'Bill Payments': ['Water', 'electricity', 'biller'],
      'Self Account Transfer': 'transfer funds to your other accounts',
    },
  ];
  // paySubTitles:any=[{feature: "beneficiary-payments",name:"Pay or Add beneficaries" }];

  innerWidth: number;
  list;
  listOfApplicableVasPayment;
  subListOpened = false;
  isSmeMode: boolean;
  config = environment.config;
  flows = {
    payments: {
      name: 'paymentFlows',
      goBack: '/dashboard',
      mainTitle: 'Pay',
    },
    buy: {
      name: 'buyFlows',
      goBack: '/dashboard',
      mainTitle: 'Buy',
    },
    debit: {
      name: 'debitCardFlows',
      goBack: '/dashboard',
      mainTitle: 'Debit Cards',
    },
    scheduleTransactions: {
      name: 'scheduleTransactions',
      goBack: '/dashboard',
      mainTitle: 'Schedule Transactions',
    },
  };
  listOfSubBillPayments = [
    {
      feature: Payments.PAY_ELECTRICITY,
      name: 'Electricity',
      type: 'ELECTRICITY',
    },
    {
      feature: Payments.PAY_RATES_AND_TAXES,
      name: 'Rates And Taxes',
      type: 'RATESANDTAXES',
    },
    {
      feature: Payments.PAY_WATER,
      name: this.config.countryName === 'Tanzania' ? 'Government payments (GePG)' : 'Water',
      type: 'WATER',
    },
    {
      feature: Payments.BILLER_PAYMENTS,
      name: 'Biller',
      type: 'BILLER',
    },
    {
      feature: Payments.PAY_GOODS_AND_SERVICES,
      name: 'Goods and Services',
      type: 'GOODSANDSERVICES',
    },
  ];
  selectedFlow;
  isHybrid: boolean;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appConstant: AppConstantService,
    private dataSharing: DataSharingService,
    private maskingService: MaskingService,
    private store: Store<any>
  ) {
    this.store.select('appReducer', 'loginReducer').subscribe((stateData) => {
      this.isSmeMode = stateData.isSmeMode;
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkWidth(event.target.innerWidth);
  }
  ngOnInit() {
    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      if (Object.prototype.hasOwnProperty.call(value, 'isMobileActive')) {
        this.isHybrid = value.isMobileActive.isHybrid;
      }
    });
    const flow = this.activatedRoute.snapshot.paramMap.get('flow');
    this.selectedFlow = this.flows[flow];
    this.list = this.getList(flow);
    // Mask borderless-payments in hybrid app
    if (this.isHybrid) {
      this.list = this.list.filter((menuItem) => {
        return (
          menuItem.feature !== 'borderless-payments' &&
          menuItem.feature !== 'international-payments-new' &&
          menuItem.feature !== 'borderless-payments-ug'
        );
      });
      if (this.config.countryName === 'Tanzania') {
        this.list = this.list.filter((menuItem) => {
          return (
            menuItem.feature !== 'borderless-payments-tz' && menuItem.feature !== 'international-payments-new'
          );
        });
      }
      if (this.config.countryName === 'Uganda') {
        this.list = this.list.filter((menuItem) => {
          return menuItem.feature !== 'borderless-payments-ug';
        });
      }
    }
    this.checkWidth(window.innerWidth);
    this.setHeadPaginationData();
  }
  getList(flow) {
    let list;
    switch (flow) {
      case 'buy':
        list = this.maskingService.removeMaskedFeatures(
          this.appConstant[this.selectedFlow.name],
          Modules.BUY
        );
        break;
      case 'payments':
        this.listOfApplicableVasPayment = this.maskingService.removeMaskedFeatures(
          this.listOfSubBillPayments,
          Modules.PAYMENTS
        );
        if (this.listOfApplicableVasPayment.length === 1) {
          if (
            this.appConstant[this.selectedFlow.name].find((item) => item.feature === Payments.BILLER_PAYMENTS)
          ) {
            this.appConstant[this.selectedFlow.name].find(
              (item) => item.feature === Payments.BILLER_PAYMENTS
            ).name =
              this.listOfApplicableVasPayment[0].feature === Payments.BILLER_PAYMENTS
                ? this.config.isBillerCDI
                  ? 'Companies'
                  : 'Billers'
                : `Pay ${this.listOfApplicableVasPayment[0].name}`;
          }
        }
        // Prevent removal of biller payments menu item in case of vas payments presence
        if (
          this.listOfApplicableVasPayment.length &&
          this.appConstant[this.selectedFlow.name].find((item) => item.feature === Payments.BILLER_PAYMENTS)
        ) {
          const featureItem = this.appConstant[this.selectedFlow.name].find(
            (item) => item.feature === Payments.BILLER_PAYMENTS
          );
          featureItem.feature = undefined;
          featureItem.nav = undefined;
          // featureItem.hasSubMenu = undefined;
        }

        list = this.maskingService.removeMaskedFeatures(
          this.appConstant[this.selectedFlow.name],
          Modules.PAYMENTS
        );

        break;
      case 'debit':
        list = this.maskingService.removeMaskedFeatures(
          this.appConstant[this.selectedFlow.name],
          Modules.DEBIT_CARDS
        );
        break;
    }
    return list;
  }
  checkWidth(width) {
    this.innerWidth = width;
    // check if width is greater than 600 then redirect to first index

    if (!this.isHybrid) {
      if (this.innerWidth > 600) {
        this.listItemClicked(0);
      }
    }
  }
  listItemClicked(index) {
    if (this.list[index].hasSubMenu) {
      if (this.listOfApplicableVasPayment.length == 1) {
        //if in vas payments only biller is present, directly routing it to Billers Route.
        if (this.listOfApplicableVasPayment[0].feature === Payments.BILLER_PAYMENTS) {
          return this.router.navigate(['/payments/biller/list'], { relativeTo: this.activatedRoute });
        }
        this.store.dispatch(
          setSelectedVasType({
            selectedVasType: {
              type: this.listOfApplicableVasPayment[0].type,
              description: this.listOfApplicableVasPayment[0].name,
              subIndexTab: index,
            },
          })
        );
        return this.router.navigateByUrl('/payments/vas', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/payments/vas/list'], {
            relativeTo: this.activatedRoute,
          });
        });
      } else {
        return;
      }
    } else {
      if (this.list[index].nav) {
        if (this.list[index].name === 'Once-off CMA Payments') {
          this.store.dispatch(
            setSelectedCmaBeneficiaryData({
              selectedCmaBeneficiary: undefined,
            })
          );
        }
        this.router.navigate([this.list[index].nav]);
      } else {
        this.selectedBuy(this.list[index]);
      }
    }
  }
  subListItemClicked(index) {
    const type = this.listOfApplicableVasPayment[index];
    if (Payments.BILLER_PAYMENTS === type.feature) {
      return this.router.navigate(['./payments/biller/list']);
    } else {
      this.store.dispatch(
        setSelectedVasType({
          selectedVasType: {
            type: type.type,
            description: type.name,
            subIndexTab: index,
          },
        })
      );
      return this.router.navigateByUrl('/payments/vas', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/payments/vas/list'], {
          relativeTo: this.activatedRoute,
        });
      });
    }
  }
  selectedBuy(buyType: any) {
    if (buyType.name) {
      buyType = buyType.name;
    }
    switch (buyType) {
      case 'Airtime':
        this.router.navigate(['./buy/airtime/list']);
        break;
      case 'Data':
        this.router.navigate(['./buy/data/list']);
        break;
      case 'TV Subscription':
        this.router.navigate(['./buy/tvsubscription/list']);
        break;
      case 'Prepaid Water':
        // this.router.navigate(['./buy/electricity/list']);
        break;
      case 'Prepaid Electricity':
        this.router.navigate(['./buy/electricity/list']);
        break;
    }
  }
  goBack() {
    const flow = this.activatedRoute.snapshot.paramMap.get('flow');
    this.router.navigate([this.flows[flow].goBack]);
  }
  hybGoBack() {
    this.router.navigate(['/dashboard']);
  }
  setHeadPaginationData() {
    this.dataSharing.setHeadPagination({
      mainTitle: this.selectedFlow.mainTitle,
      subTitle: '',
      showBack: false,
      steps: false,
      showBackFn: this.goBack.bind(this),
    });
  }

  getIcon(payment: any) {
    switch (payment.name) {
      case 'Pay bank account beneficiary':
        return 'icn_people_3';
      case 'account-transfers':
        return 'icn_people_1';
      case 'Unayo Payment':
        return 'icn_wallet';
      case 'Once off payment':
        return 'icn_card_pay';
      case 'Self Account Transfer':
        return 'icn_people_1';
      case 'CMA Payments':
        return 'icn_forex_b';
      case 'Once-off CMA Payments':
        return 'icn_forex_b';
      case 'Bill Payments':
        return 'icn_tile';
      case 'Billers':
        return 'icn_tile';
      case 'Airtime':
        return 'icn_phone_coins';
      case 'TV Subscription':
        return 'icn_tv';
      case 'Prepaid Electricity':
        return 'icn_electricity';
      case 'Data':
        return 'icn_server';
      case 'Mobile Wallet':
        return 'icn_phone_coins';
      default:
        return 'icn_forex';
    }
  }

  getSubtitle(payment) {
    switch (payment.name) {
      case 'Pay bank account beneficiary':
        return 'Pay or Add Beneficiary';
      case 'Unayo Payment':
        return 'Quick Payments';
      case 'Once off payment':
        return 'Quick pay to Non Beneficiaries ';
      case 'Self Account Transfer':
        return 'transfer funds to your other accounts ';
      case 'CMA Payments':
        return 'International payments to Beneficiaries';
      case 'Once-off CMA Payments':
        return 'Quick International payments';
      case 'Bill Payments':
        return 'Pay bills : water, electricity , biller';
      case 'Airtime':
        return 'Buy prepaid airtime';
      case 'TV Subscription':
        return 'Buy prepaid TV subscription';
      case 'Prepaid Electricity':
        return 'Buy prepaid electricity';
      case 'Data':
        return 'Buy Data';
      case 'Rates And Taxes':
        return 'Pay rates & taxes';
      case 'International Payment':
        return 'International Transactions & Pay';
      case 'Slydepay Wallet':
        return 'wallet transfer funds';
      case 'GHQR':
        return 'GHQR title';
      case 'Billers':
        return 'Pay bills';
      case 'pay-water':
        return 'water payments';
      case 'Instant Payment':
        return 'payments instantantly';
      case 'Mobile Wallet':
        return 'Add money to mobile wallet';
      case 'Fees Payment':
        return 'Pay fees for tutition, examiniation etc.';
      default:
        return '';
    }
  }
  getSubtileMethods(index) {
    let typeOfKey = typeof this.paySubTitles[0][index];
    if (typeOfKey === 'object') {
      this.paySubTitles[0][index];
    }
    return this.paySubTitles[0][index];
  }
}
