import { createReducer, on } from '@ngrx/store';
import { setTxnReceiptData, setSelectedScheduledTxnData } from '../actions/txn-receipt.action';
import { TxnReceiptState } from '../state/txn-receipt.state';

const transactionReceiptInitState = {};
const selectedScheduledTxnInitState = {};

const scheduleTxnReceiptData: TxnReceiptState = {
  txnReceiptData: transactionReceiptInitState,
  selectedScheduledTxn: selectedScheduledTxnInitState,
};

export const scheduleTxnReducer = createReducer(
  scheduleTxnReceiptData,
  on(setTxnReceiptData, (state, { txnReceiptData }) => ({
    ...state,
    txnReceiptData,
  })),

  on(setSelectedScheduledTxnData, (state, { selectedScheduledTxn }) => ({
    ...state,
    selectedScheduledTxn,
  }))
);
