import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Action, Actions, ofActionSuccessful, Selector, State, StateContext } from '@ngxs/store';

import { SbgErrorOverlayComponent } from 'src/app/native/component/error-overlay/sbg-error-overlay.component';
import { BridgeService } from 'src/app/native/services/bridge/bridge.service';
import { BridgeContext } from '../../interfaces';
import { ChannelProfileSplit, UserProfile } from '../../models/src/lib/user-profile.model';
import {
  GetChannelProfiles,
  GetChannelProfilesFailure,
  GetChannelProfilesSuccess,
  MLoginAndSetUserProfile,
  mLogOut,
  MShowLogoutWarning,
  MStoreUniqueIdentifier,
  Mstore_Auth_Token_Action,
  StepUpCancelled,
  TearDownBridge,
} from '../actions/mAuthAction';
import { StartMobileNativeFlowAction } from '../actions/nativeflow.action';
export class mAuthModel {
  loading: boolean;
  isError: boolean;
  hasChannelProfilesError: boolean;
  loggedIn: boolean;
  showLogoutWarning: boolean;
  userProfile: UserProfile | null;
  hasGroupProfile: boolean;
  hasPersonalProfile: boolean;
  authToken: string | null;
  uniqueId: number | string;
  mobileNativeContext: BridgeContext;
  channelProfiles: ChannelProfileSplit[];
}
@Injectable({
  providedIn: 'root',
})
@State<mAuthModel>({
  name: 'mAuth',
  defaults: {
    loading: false,
    loggedIn: false,
    isError: false,
    hasChannelProfilesError: false,
    showLogoutWarning: false,
    userProfile: {
      defaultProfileId: '',
      digitalId: null,
      lastLoggedIn: '',
    },
    channelProfiles: [],
    hasGroupProfile: false,
    hasPersonalProfile: false,
    authToken: null,
    uniqueId: null,
    mobileNativeContext: null,
  },
})
export class mAuthState {
  constructor(private actions$: Actions, private bridgeService: BridgeService, private dialog: MatDialog) {
    this.actions$.pipe(ofActionSuccessful(TearDownBridge)).subscribe(({ payload }) => {
      this.bridgeService.tearDownBridge();
      if (payload.isError) {
        this.dialog.open(SbgErrorOverlayComponent, {
          width: '100%',
          height: '100%',
          maxWidth: '100vw',
          maxHeight: '100vh',
        });
      }
    });
  }

  @Selector()
  static get_mobile_native_context(state: mAuthModel) {
    return state.mobileNativeContext;
  }
  @Selector()
  static get_AuthToken(state: mAuthModel) {
    return state.authToken;
  }
  @Selector()
  static get_showlogooutWarning(state: mAuthModel) {
    return state.showLogoutWarning;
  }
  @Selector()
  static get_storeUniqueIdentifier(state: mAuthModel) {
    return state.uniqueId;
  }
  @Selector()
  static get_channel_profile(state: mAuthModel) {
    return state.loading;
  }
  @Selector()
  static get_loginAndSetUserProfile(state: mAuthModel) {
    return state.userProfile;
  }

  @Action(StartMobileNativeFlowAction)
  add_mobile_native_context(
    { getState, setState }: StateContext<mAuthModel>,
    { payload }: StartMobileNativeFlowAction
  ) {
    const state = getState();
    setState({
      ...state,
      mobileNativeContext: payload,
    });
  }
  @Action(Mstore_Auth_Token_Action)
  add_auth_token({ getState, setState }: StateContext<mAuthModel>, { payload }: Mstore_Auth_Token_Action) {
    const state = getState();
    setState({
      ...state,
      authToken: payload,
    });
  }
  @Action(MShowLogoutWarning)
  add_showLogoutWarning({ getState, setState }: StateContext<mAuthModel>) {
    const state = getState();
    setState({
      ...state,
      showLogoutWarning: true,
    });
  }
  @Action(MShowLogoutWarning)
  add_hideLogoutWarning({ getState, setState }: StateContext<mAuthModel>) {
    const state = getState();
    setState({
      ...state,
      showLogoutWarning: false,
    });
  }
  @Action(mLogOut)
  resetLogout({ getState, setState }: StateContext<mAuthModel>) {
    const state = getState();
    setState({
      ...state,
    });
  }
  @Action(MStoreUniqueIdentifier)
  add_storeuniqueIdentifer(
    { getState, setState }: StateContext<mAuthModel>,
    { payload }: MStoreUniqueIdentifier
  ) {
    const state = getState();
    setState({
      ...state,
      uniqueId: payload,
    });
  }
  @Action(GetChannelProfiles)
  add_channelProfile({ getState, setState }: StateContext<mAuthModel>) {
    const state = getState();
    setState({
      ...state,
      loading: true,
    });
  }
  @Action(GetChannelProfilesSuccess)
  add_channelProfile_success({ getState, setState }: StateContext<mAuthModel>) {
    const state = getState();
    setState({
      ...state,
      loading: false,
      isError: false,
      hasChannelProfilesError: false,
    });
  }
  @Action(GetChannelProfilesFailure)
  add_channelProfile_failure({ getState, setState }: StateContext<mAuthModel>) {
    const state = getState();
    setState({
      ...state,
      loading: false,
      isError: true,
      hasChannelProfilesError: false,
    });
  }
  @Action(StepUpCancelled)
  add_setupCancelled({ getState, setState }: StateContext<mAuthModel>) {
    const state = getState();
    setState({
      ...state,
      loading: false,
    });
  }
  @Action(MLoginAndSetUserProfile)
  add_Login_SetupUserProfile(
    { getState, setState }: StateContext<mAuthModel>,
    { payload }: MLoginAndSetUserProfile
  ) {
    const state = getState();
    setState({
      ...state,
      userProfile: payload.userProfile,
    });
  }
}
