import { StateContext } from '@ngxs/store';
import { SelectedNotification } from '../../store-utilities/actions/notification.action';
import { State, Selector, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
export class NotificationStateModel {
  offerlist: any;
}
@Injectable()
@State<NotificationStateModel>({
  name: 'Notification',
  defaults: {
    offerlist: null,
  },
})
export class NotificationDetailsState {
  @Selector()
  static getNotificationDetails(state: NotificationStateModel) {
    return state.offerlist;
  }
  @Action(SelectedNotification)
  getNotificationDetails(
    { getState, setState }: StateContext<NotificationStateModel>,
    { payload }: SelectedNotification
  ) {
    const state = getState();
    setState({
      ...state,
      offerlist: payload,
    });
  }
}
