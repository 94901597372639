export class SbgConstants {
  static COUNTRY_CODES = {
    SOUTH_AFRICA: 'SBSA',
    PBBI: 'SBOG',
    NAMIBIA: 'SBNAM',
    UGANDA: 'SBUGA',
    BOTSWANA: 'SBBWA',
    GHANA: 'SBGHA',
    SWAZILAND: 'SBSWZ',
    TANZANIA: 'SBTZA',
    ZIMBABWE: 'SBZWE',
    ZAMBIA: 'SBZMB',
  };

  static SYSTEM_CLOCK_TIMES = {
    APPLICATION_TIMEOUT_TIME: 5000 * 60,
    APPLICATION_SHOW_WARNING_TIMEOUT_TIME: 1000 * 60,
    APPLICATION_TOKEN_RENEWAL_TIME: 3000 * 60,
    MOBILE_NATIVE_APPLICATION_TOKEN_RENEWAL_TIME: 15000,
  };

  static PROFILE_STYLES = {
    BUSINESS: 'BUSINESS',
    PERSONAL: 'PERSONAL',
  };

  static SYSTEM_PRINCIPAL_KEYS = {
    SBSA: 'SBSA_BANKING',
    SBOG: 'BANKING',
    SBOG_GROUP: 'BANKING_GROUP',
    INSURANCE: 'SBIS',
    VAF: 'VAF',
    UCOUNT: 'LOYALTY',
  };

  static INPUT_FIELDS_CONFIG = {
    DASHBOARD_NAME: {
      maxLength: 40,
      minLength: 1,
    },
    PIN_CODE: {
      maxLength: 5,
      minLength: 5,
    },
    CARD_NUMBER: {
      maxLength: 18,
      minLength: 9,
    },
    INTERNET_CLIENT_NUMBER: {
      maxLength: 8,
      minLength: 8,
    },
    USER_PASSWORD: {
      minLength: 8,
    },
  };
  static IS_MOBILE_NATIVE = 'IS_MOBILE_NATIVE';

  static DESKTOP_MEDIA_WIDTH = 740;

  static TERMS_AND_CONDITIONS_URL =
    'https://international.standardbank.com/international/personal/about-us/terms-and-conditions';

  static TERMS_AND_CONDITIONS_HINT = 'We have updated our T&Cs on 19th October 2020';

  static USER_REGISTRATION_FORM = {
    ICN_PASSWORD: {
      PLACEHOLDER: 'Create ICN password',
      TOOLTIP: 'The ICN password that you are capturing will enable you to reset your Digital ID password.',
    },
  };

  static REGEX = {
    NUMBERS_ONLY_REGEX: /[^0-9 ]/g,
    COUNTRY_FIELD_REGEX: /[^A-Za-z0-9 ]/g,
  };
}
