export class WebViewRoutes {
  static PAY = 'PAY';
  static BUY = 'BUY';
  static INSTANTMONEY = 'INSTANTMONEY';
  static TRANSFER = 'TRANSFER';
  static BENEFICIARIES = 'BENEFICIARIES';
  static EOL_APPROVAL = 'EOL_APPROVAL';
  static ACCOUNTS = 'ACCOUNTS';
  static SERVICE_REQUESTS = 'SERVICE_REQUESTS';
  static SCHEDULEDTRANSACTION = 'SCHEDULED_PAYMENTS';
  static CARDS = 'CARDS';
  static EAPLIMITS = 'EAPLIMITS';
  static RECEIPTS = 'RECEIPTS';
  static HYB_DASH = 'HYB_DASH';
  static MANAGE = 'MANAGE';
}
