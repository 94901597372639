<section class="card-container receipt">
  <div class="detail-receipt">
    <div class="print-data" [ngClass]="individual">
      <div class="receipt-card">
        <div class="ben-receipt-background"></div>
        <div class="detail-card border-top-none">
          <div class="detail-info">
            <div class="ben-receipt-div">
              <img
                class="ben-receipt-logo zra-logo"
                *ngIf="paymentType === 'zra' && country === 'Zambia'"
                src="/assets/images/logo-zra.png"
              />
              <img class="ben-receipt-logo" [src]="bankLogo" />
              <hr class="light-solid" />
              <div class="detail-block">
                <mat-icon
                  *ngIf="
                    responseStatus === MCA_RESPONSE_TYPE.SUCCESS || responseStatus === MCA_RESPONSE_TYPE.INFO
                  "
                  matPrefix
                  class="material-icons rec-success-icon"
                >
                  check_circle
                </mat-icon>
                <img
                  class="ben-receipt-status-img"
                  *ngIf="responseStatus === MCA_RESPONSE_TYPE.ERROR"
                  src="/assets/images/error.png"
                />
              </div>
              <p class="detail-block">{{ responseMessage }}</p>
              <div *ngIf="paymentType === 'ipay' || is_ipay">
                <div *ngIf="config.trade !== 'mvp1'">
                  <p class="detail-block">
                    It will reflect in your statement within 48 hours on successful processing.
                  </p>
                </div>
                <div *ngIf="config.trade === 'mvp1'">
                  <div *ngIf="lastApprover || soleProp">
                    <p class="detail-block">
                      <span *ngIf="moreThen5usd">
                        Transaction will reflect in your statement within 48 hours on successful processing at
                        the applicable Exchange Rate.
                      </span>
                      <span *ngIf="!moreThen5usd">
                        It will reflect in your statement within 48 hours on successful processing.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div></div>
              <p *ngIf="showAmount" class="detail-block-amount">
                {{ amount?.currency }}
                <span class="detail-info__amount">
                  {{ amount?.amount | number : '1.2-2' }}
                </span>
              </p>
              <div *ngIf="receiptHeaders" class="mt-8">
                <p class="detail-block" *ngFor="let header of receiptHeaders">
                  {{ header }}
                </p>
              </div>
              <hr class="light-solid" />
              <div class="detail-block" *ngFor="let detail of receiptDetails">
                <p *ngIf="detail.value" class="detail__heading">{{ detail.header }}</p>
                <p>{{ detail.value }}</p>
              </div>
              <sbg-display-approval-rules *ngIf="showApprovalRules"></sbg-display-approval-rules>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="detail-card border-top-none" *ngIf="showDownloadIcon">
      <div class="flex-container align-spaced font-18 font-primary pd-section">
        <span class="horizontal-padding">
          <mat-icon matPrefix (click)="printReceipt()" class="material-icons"> print </mat-icon>
        </span>
        <span class="horizontal-padding">
          <mat-icon matPrefix (click)="downloadReceipt()" class="material-icons"> save_alt </mat-icon>
        </span>
      </div>
    </div>
  </div>
  <div class="detail-card mt-16" *ngIf="fromAccountData">
    <sbg-from-account-data [data]="fromAccountData" showAvailBal="true"></sbg-from-account-data>
  </div>
  <div class="mt-16">
    <ng-content></ng-content>
  </div>
  <div class="detail-card border-top-none" *ngIf="forAllReceipts">
    <span class="forallreceipt">For all receipts</span>
    <div class="flex-container align-spaced font-18 font-primary pd-section">
      <span class="horizontal-padding">
        <mat-icon matPrefix (click)="printAllReceipt()" class="material-icons"> print </mat-icon>
      </span>
      <span class="horizontal-padding">
        <mat-icon matPrefix (click)="downloadAllReceipt()" class="material-icons"> save_alt </mat-icon>
      </span>
    </div>
  </div>
  <div *ngIf="showButton" class="text-center form-footer-row">
    <div class="detail-block">
      <sbg-button
        [isRaisedButton]="true"
        buttonType="primary"
        [disableRipple]="false"
        buttonStyle="basic"
        [buttonText]="textOfButton"
        (clickFunction)="goToNewTransfer()"
        [ariaLabel]="textOfButton"
      >
      </sbg-button>
    </div>
    <div class="detail-block" *ngIf="showSaveButton">
      <sbg-button
        [isRaisedButton]="true"
        buttonType="primary"
        [disableRipple]="false"
        buttonStyle="basic"
        [buttonText]="textOfSaveButton"
        (clickFunction)="showAddBeneficiaryPopup()"
        [ariaLabel]="textOfSaveButton"
      >
      </sbg-button>
    </div>
  </div>
</section>
