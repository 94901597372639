<section *ngIf="!isHybrid">
  <sbg-header></sbg-header>
  <section class="module-wrap notification">
    <sbg-head-pagination></sbg-head-pagination>
    <div class="lendingContainer notification">
      <div class="container-side">
        <div fxLayout="row" class="router-container">
          <section class="details-container">
            <div class="details-container__body">
              <div class="column">
                <div *ngIf="displayOfferList.length !== 0" class="notification-container">
                  <div
                    class="notifaction-bar table pointer"
                    *ngFor="let offer of displayOfferList | slice : 0 : 10"
                    (click)="goToApply(offer)"
                  >
                    <span class="material-icons notification-icon">
                      {{ giftIconInNotiList(offer.type) }}
                    </span>
                    <span
                      class="table-cell notification-desc"
                      [ngClass]="{ 'highlight-item': offer?.showCount === 0 }"
                      >{{ offer?.description }}</span
                    >
                  </div>
                  <div *ngIf="showLoadMore" class="text-center load-more pointer" (click)="removeLimit()">
                    Load More
                  </div>
                </div>
                <div *ngIf="displayOfferList.length === 0" class="no-notification">
                  You don't have any notifications
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</section>

<!-- *************************************************************************** Hybrid App********************************************************** -->
<section *ngIf="isHybrid">
  <sbg-native-header [backIcon]="true" (backFunction)="goBack()"></sbg-native-header>

  <div class="hybrid-card-container">
    <div class="hyb_notification mt-24">
      <div *ngFor="let offer of displayOfferList | slice : 0 : 10" class="table pointer">
        <span class="material-icons notification-icon">
          {{ giftIconInNotiList(offer.type) }}
        </span>
      </div>
    </div>
    <div class="hybrid_tertiary_button--button_text mt-16">
      <div *ngIf="displayOfferList.length !== 0">
        <div class="" *ngFor="let offer of displayOfferList | slice : 0 : 10" (click)="goToApply(offer)">
          <span class="" [ngClass]="{ 'highlight-item': offer?.showCount === 0 }">{{
            offer?.description
          }}</span>
        </div>
        <div *ngIf="showLoadMore" class="text-center load-more pointer" (click)="removeLimit()">
          Load More
        </div>
      </div>
    </div>
    <div *ngIf="displayOfferList.length === 0" class="no-notification">You don't have any notifications</div>
  </div>
</section>
