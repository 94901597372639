import { createAction, props } from '@ngrx/store';
import {
  SelectedReceipientsData,
  PayBeneDetailData,
  PayBeneReviewData,
  FinalRecieptData,
} from '../interfaces/pay-beneficiary.model';

export const setSelectedReceipientsData = createAction(
  '[pay bene Component] setSelectedReceipientsData',
  props<{ selectedReceipientsData: SelectedReceipientsData }>()
);

export const setPayBeneDetailData = createAction(
  '[pay bene Component] setPayBeneDetailData',
  props<{ payBeneDetailData: PayBeneDetailData }>()
);

export const setPayBeneReviewData = createAction(
  '[pay bene Component] setPayBeneReviewData',
  props<{ payBeneReviewData: PayBeneReviewData }>()
);

export const setFinalRecieptData = createAction(
  '[pay bene Component] setFinalRecieptData',
  props<{ finalRecieptData: FinalRecieptData }>()
);
