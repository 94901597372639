export class PageNameConstants {
  static HelpAndServices = 'Help and Services';
  static Profile = 'Profile';
  static Settings = 'Settings';
  static Pay = 'Pay';
  static Transfer = 'Transfer';
  static ContactUs = 'Contact Us';
  static ManageCards = 'Manage Cards';
  static AddDashboard = 'Add Dashboard';
  static LinkAccounts = 'LINK ACCOUNTS';
  static RegisterAndLinkAccounts = 'Register and Account Linking';
  static RegistrationForm = 'Registration Form';
  static Faq = 'Self Help';
  static MyDashboards = 'My Dashboards';
  static MyGroups = 'My Groups';
  static CreateBeneficicary = 'Create a beneficiary';
  static ActivateToken = 'Activate Token';
  static ManageRights = 'Manage User Rights';
  static EditBeneficiary = 'Edit Beneficiary';
  static ResetICNPassword = 'Reset ICN Password';
}
