<div *ngIf="!headerPaginationData.hideHeadPagination" class="stickHeader">
  <!-- section 1 -->
  <div class="flex-container align-middle align-justify head-wrapper">
    <!-- Back icon -->
    <div *ngIf="!backIcon && !isDashboardBackbutton">
      <mat-icon
        svgIcon="icn_chevron_left"
        (click)="goBack()"
        class="material-icons back-icon"
        [ngClass]="{ removeIcon: nextButtonText === 'DONE' }"
      ></mat-icon>
    </div>

    <div class="back-button" *ngIf="backIcon">
      <mat-icon svgIcon="icn_chevron_left" (click)="backButtonClick($event)"></mat-icon>
    </div>

    <!-- Header title -->
    <div class="title">
      <div class="main-title">
        <span>
          {{ headerPaginationData.mainTitle }}
        </span>
      </div>
      <div class="sub-title">
        <span> {{ headerPaginationData.subTitle }}</span>
      </div>
    </div>

    <!-- next button -->
    <!-- <div class="" *ngIf="nextButtonText !== '' && !configure"> -->

    <div class="" *ngIf="!showEdit_Delete && nextButtonText !== ''">
      <button
        class="nextButton unselectable"
        [disabled]="nextButtonDisable"
        (click)="nextButtonText === 'DONE' ? goHome() : nextButtonClick($event)"
        [ngClass]="nextButtonDisable ? 'disable' : ''"
        type="button"
      >
        {{ nextButtonText }}
      </button>
    </div>
    <div *ngIf="showEdit_Delete">
      <mat-icon svgIcon="icn_pencil" (click)="editClicked()"></mat-icon>
      <span *ngIf="!isShowDelete" class="header_bin">
        <mat-icon svgIcon="icn_bin" (click)="nextButtonClick($event)"></mat-icon>
      </span>
      <!-- <mat-icon svgIcon="icn_bin pl-14 pr-4" (click)="nextButtonClick()"></mat-icon> -->
    </div>

    <!-- <div class="configure" *ngIf="configure && nextButtonText == ''">
      <div class="editOptions">
      <mat-icon class="mr-8" svgIcon="icn_pencil" (click)="editClicked()"></mat-icon>
      <mat-icon svgIcon="icn_bin" (click)="deleteClicked()"></mat-icon>
    </div>
    </div> -->
  </div>
  <!-- section 3 -->
  <mat-progress-bar
    class="progressBar"
    mode="determinate"
    [value]="progressValue[stateIndex]"
    *ngIf="steps.length > 1"
  ></mat-progress-bar>
</div>
