import { createReducer, on } from '@ngrx/store';
import * as registerAction from '../actions/registration.action';
import { RegistrationState } from '../state/registration.state';

const registerationInitData: RegistrationState = {
  accountNumberEntered: '',
  registerSecurityQuestions: [],
  userNameRegistered: '',
  oldPassword: '',
  securityQuestionAnswers: [],
};
export const registrationReducer = createReducer(
  registerationInitData,
  on(registerAction.setRegisterSecurityQuestions, (state, { registerSecurityQuestions }) => ({
    ...state,
    registerSecurityQuestions,
  })),
  on(registerAction.accountNumberEntered, (state, { accountNumberEntered }) => ({
    ...state,
    accountNumberEntered,
  })),
  on(registerAction.userNameRegistered, (state, { userNameRegistered }) => ({
    ...state,
    userNameRegistered,
  })),
  on(registerAction.oldPassword, (state, { oldPassword }) => ({
    ...state,
    oldPassword,
  })),
  on(registerAction.securityQuestionAnswers, (state, { securityQuestionAnswers }) => ({
    ...state,
    securityQuestionAnswers,
  })),
  on(registerAction.useNameDetails, (state, { useNameDetails }) => ({
    ...state,
    useNameDetails,
  }))
);
