import { createAction, props } from '@ngrx/store';

export const setFinancialApprovalData = createAction(
  '[ChooseApprovalComponent setFinancialApprovalData]',
  props<{ financial: object }>()
);

export const setNonFinancialApprovalData = createAction(
  '[ChooseApprovalComponent setNonFinancialApprovalData]',
  props<{ nonFinancial: object }>()
);

export const setSelectedApprovalData = createAction(
  '[ChooseApprovalComponent setSelectedApprovalData]',
  props<{ selectedApprovalData: object }>()
);

export const setPostApprovalData = createAction(
  '[ApprovalComponent setPostApprovalData]',
  props<{ approvalPayload: object; postApprovalData: object }>()
);

export const setSelectedApprovalTab = createAction(
  '[ApprovalComponent setSelectedApprovalTab]',
  props<{ selectedApprovalTab: object }>()
);

export const setSelectedApprovalType = createAction(
  '[ApprovalComponent setSelectedApprovalType]',
  props<{ selectedApprovalType: string }>()
);

export const setPreInitiatedInternationalPaymentData = createAction(
  '[ApprovalComponent setPreInitiatedInternationalPaymentData]',
  props<{ preInitiatedInternationalPaymentData: object }>()
);

export const setExchangeRateDataBLB = createAction(
  '[ApprovalComponent setExchangeRateDataBorderlessPayment]',
  props<{ exchangeRateDataBLB: object }>()
);
