<div class="exit-survey-dialog">
  <div
    class="conditions exit-survey-section"
    [ngClass]="{ hidden: !initialView }"
  >
    <div class="exit-survey-user">Hey {{ userName }},</div>
    <div class="exit-survey-help">Help us improve our services.</div>
    <div class="exit-survey-info">
      You will be presented with a survey, it will not take more than a minute
      of your time.
    </div>
    <section class="payment-details-footer mt-20">
      <sbg-button
        class=""
        [isRaisedButton]="true"
        buttonType="primary"
        [disableRipple]="false"
        buttonStyle="basic"
        buttonText="OK"
        ariaLabel="Do Exit Survey"
        (clickFunction)="goToSurveyQuestions()"
      ></sbg-button>
    </section>
    <div
      class="exit-survey-later"
      (click)="signOut('logout')"
      *ngIf="!userRequestedFeedback"
    >
      Maybe later
    </div>
    <div
      class="exit-survey-later"
      (click)="signOut('feedback')"
      *ngIf="userRequestedFeedback"
    >
      Maybe later
    </div>
  </div>
  <div
    class="conditions exit-survey-section"
    [ngClass]="{ hidden: initialView }"
  >
    <div *ngIf="!showOptions">
      <div class="exit-survey-heading">
        Did you enjoy your online banking today?
      </div>
      <section class="payment-details-footer mt-20 exit-survey-button">
        <sbg-button
          class="left"
          [isRaisedButton]="true"
          buttonType="secondary"
          [disableRipple]="false"
          buttonStyle="secondary"
          buttonText="Don't Like It"
          ariaLabel="Dislike"
          (clickFunction)="goToOptions()"
        ></sbg-button>
        <sbg-button
          class="right"
          [isRaisedButton]="true"
          *ngIf="!userRequestedFeedback"
          buttonType="primary"
          [disableRipple]="false"
          buttonStyle="basic"
          buttonText="Like It"
          ariaLabel="Like"
          (clickFunction)="signOut('logout')"
        ></sbg-button>
        <sbg-button
          class="right"
          *ngIf="userRequestedFeedback"
          [isRaisedButton]="true"
          buttonType="primary"
          [disableRipple]="false"
          buttonStyle="basic"
          buttonText="Like It"
          ariaLabel="Like"
          (clickFunction)="signOut('feedback')"
        ></sbg-button>
      </section>
    </div>
    <div *ngIf="showOptions">
      <div class="exit-survey-heading">Help us make banking better</div>
      <div class="exit-survey-improve">What can we improve for you?</div>
      <div class="exit-survey-checkbox">
        <mat-checkbox
          class="survey-check-box"
          color="primary"
          *ngFor="let options of surveyOptions; let i = index"
          [(ngModel)]="checked[i]"
          name="checkBox[i]"
          (click)="checkboxSelected(options.label)"
          >{{ options.label }}</mat-checkbox
        >
      </div>
      <mat-form-field
        class="form-field form-field--margin exit-survey-text-area"
        *ngIf="manualFeedback"
      >
        <input
          type="text"
          class="form-field__input--align-left"
          matInput
          placeholder="Please enter what made your experience of online banking poor"
          [(ngModel)]="feedback"
          name="exitSurveyFeedback"
          maxlength="100"
          #exitSurveyFeedback="ngModel"
        />
        <span matPrefix></span>
        <mat-error>
          <sbg-validation-error
            [control]="accountNumber"
          ></sbg-validation-error>
        </mat-error>
      </mat-form-field>
      <section
        class="payment-details-footer mt-20"
        *ngIf="!userRequestedFeedback"
      >
        <sbg-button
          class=""
          [isRaisedButton]="true"
          buttonType="primary"
          [disableRipple]="false"
          buttonStyle="basic"
          buttonText="Submit & Exit"
          ariaLabel="Like"
          (clickFunction)="signOut('logout')"
        ></sbg-button>
      </section>
      <section
        class="payment-details-footer mt-20"
        *ngIf="userRequestedFeedback"
      >
        <sbg-button
          class=""
          [isRaisedButton]="true"
          buttonType="primary"
          [disableRipple]="false"
          buttonStyle="basic"
          buttonText="Submit"
          ariaLabel="Like"
          (clickFunction)="signOut('feedback')"
        ></sbg-button>
      </section>
    </div>
    <div class="exit-survey-opt-out">
      <div class="exit-survey-footer">
        Don't want to take this survey next time you LogOut?
      </div>
      <div class="exit-survey-footer-link" (click)="optOutOfSurvey()">
        Click here to opt out from Feedback Survey
      </div>
    </div>
  </div>
</div>
