<ng-container>
  <sbg-native-header [backIcon]="true" (backFunction)="goBack()"></sbg-native-header>

  <section class="hybrid-card-container padding-horizontal-0">
    <div>
      <div class="navigation-list">
        <ul>
          <li *ngFor="let feature of sideNavLinks; let i = index" (click)="feature.onClickNavFunction()">
            <div class="hybrid_navigation-list__li">
              <div class="hybrid_navigation-list__li--headIcon">
                <mat-icon [svgIcon]="getIcon(feature)"></mat-icon>
              </div>
              <div class="hybrid_navigation-list__li--title ml-12">
                <div>{{ feature.name }}</div>
                <div class="hybrid_navigation-list__li--subtitle">{{ getSubtitle(feature) }}</div>
              </div>
              <div class="hybrid_navigation-list__li--tailIcon">
                <mat-icon svgIcon="icn_chevron_right"></mat-icon>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</ng-container>
