<div class="footer-navigation__container--header">
  <div class="row small-uncollapse medium-uncollapse">
    <div class="columns medium-11 large-11 small-11">
      <div class="columns medium-12 large-12 small-12 text-center pb-16">
        <div class="banklogo float-center"></div>
      </div>
      <div class="columns medium-12 large-12 small-12 text-center font-regular-24">
        {{ dialogType }}
      </div>
    </div>
    <div class="columns medium-1 large-1 small-1">
      <span class="footer-navigation__container--close right material-icons" (click)="closeDialog()">
        close
      </span>
    </div>
  </div>
</div>
<div class="footer-navigation__container--body">
  <sbg-disclaimer *ngIf="Disclaimer"></sbg-disclaimer>
  <sbg-privacy-and-security *ngIf="SecurityData"></sbg-privacy-and-security>
  <sbg-terms-and-conditions *ngIf="TermsData"></sbg-terms-and-conditions>
  <sbg-need-help *ngIf="HelpData"></sbg-need-help>
  <sbg-exit-survey *ngIf="exitSurvey"></sbg-exit-survey>
  <sbg-information *ngIf="info"></sbg-information>
</div>
