import { createReducer, on } from '@ngrx/store';
import * as recipientAction from '../actions/recipient.action';
import { RecipientState } from '../state/recipient.state';

const recipientInitData: RecipientState = {
  beneficiaries: [],
  beneficiaryGroupDetails: null,
  beneficiaryGroups: null,
  billers: [],
  selectedBeneficiary: {},
  addBeneficiary: {},
  selectedBiller: {},
  addBiller: {},
  activeTab: {},
  allBillers: {},
  billerGroups: [],
  cmaBeneficiaries: null,
  selectedCmaBeneficiary: null,
  cmaBeneficiaryGroups: null,
  selectedCmaGroup: null,
  addCMABeneficiary: {},
};
export const recipientReducer = createReducer(
  recipientInitData,
  // beneficiary
  on(recipientAction.setBeneficiaryRecipientData, (state, { beneficiaries }) => ({
    ...state,
    beneficiaries,
  })),
  on(recipientAction.setBeneficiaryGroupData, (state, { beneficiaryGroups }) => ({
    ...state,
    beneficiaryGroups,
  })),
  on(recipientAction.setSelectedBeneficiaryGroupData, (state, { beneficiaryGroupDetails }) => ({
    ...state,
    beneficiaryGroupDetails,
  })),
  on(recipientAction.setAddBeneficiaryData, (state, { addBeneficiary }) => ({
    ...state,
    addBeneficiary,
  })),
  on(recipientAction.setSelectedBeneficiaryData, (state, { selectedBeneficiary }) => ({
    ...state,
    selectedBeneficiary,
  })),

  // biller
  on(recipientAction.setBillerRecipientData, (state, { billers }) => ({
    ...state,
    billers,
  })),
  on(recipientAction.setAllBillerRecipientData, (state, { allBillers }) => ({
    ...state,
    allBillers,
  })),
  on(recipientAction.setBillerGroupData, (state, { billerGroups }) => ({
    ...state,
    billerGroups,
  })),
  on(recipientAction.setSelectedBillerGroupData, (state, { billerGroupDetails }) => ({
    ...state,
    billerGroupDetails,
  })),
  on(recipientAction.setAddBillerData, (state, { addBiller }) => ({
    ...state,
    addBiller,
  })),
  on(recipientAction.setSelectedBillerData, (state, { selectedBiller }) => ({
    ...state,
    selectedBiller,
  })),
  // active tab
  on(recipientAction.setActiveTabData, (state, { activeTab }) => ({
    ...state,
    activeTab,
  })),

  // CMA-beneficiary
  on(recipientAction.setCmaBeneficiaryRecipientData, (state, { cmaBeneficiaries }) => ({
    ...state,
    cmaBeneficiaries,
  })),
  on(recipientAction.setCmaBeneficiaryGroupData, (state, { cmaBeneficiaryGroups }) => ({
    ...state,
    cmaBeneficiaryGroups,
  })),
  on(recipientAction.setSelectedCmaGroup, (state, { cmaGroup }) => ({
    ...state,
    selectedCmaGroup: cmaGroup,
  })),
  on(recipientAction.setAddCMABeneficiaryData, (state, { addCMABeneficiary }) => ({
    ...state,
    addCMABeneficiary,
  })),
  on(recipientAction.setSelectedCmaBeneficiaryData, (state, { selectedCmaBeneficiary }) => ({
    ...state,
    selectedCmaBeneficiary,
  }))
);
