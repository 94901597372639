import { ServiceEndpointsService } from './../../core/service-endpoints/service-endpoints.service';
import { SnackBarService } from './../../services/snack-bar-service/snackBarService';
import { OtpService } from './../../services/OtpService/otp.service';
import { OtpDetailsState } from './../../shared/store-utilities/state/otp.state';
import { Select } from '@ngxs/store';
import { Store } from '@ngrx/store';
import { environment } from './../../../environments/environment';
import { DataSharingService } from './../../core/data-sharing/data-sharing.service';
import { Observable, Subscription } from 'rxjs';
import { OnInit, Component, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { OtpType } from './otp';
import { McaHeader } from '../../core/data/mca-header';
import { HeaderInterface } from '@shared/store-utilities/interfaces/headerInterface';
import { BridgeService } from '../../native/services/bridge/bridge.service';

@Component({
  selector: 'sbg-app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
})
export class OtpComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('oref', { static: false }) otpRef: ElementRef<HTMLElement>;

  @Select(OtpDetailsState.getOtpDetails) receiptModel$: Observable<any>;

  show = false;
  env = environment.config;
  otpInputFields = {} as any;
  singleOtpBox = false;
  detailsReceived = {} as any;
  isSmeMode;
  showMailLink;
  statusDetails;
  message: string;
  otpModel = new OtpType();
  receiptModel;
  showBothContacts = false;
  subscription: Subscription[] = [];
  subs$1: Subscription;
  subs$2: Subscription;
  subs$3: Subscription;
  otpcode = {} as any;
  contactMethod = '';
  maskedMail;
  timer: number;
  errorMsg = '';
  isHybrid: boolean;
  signalStatus = false;
  downTimer;

  constructor(
    private ngrxstore: Store<any>,
    private dataSharingService: DataSharingService,
    public otpService: OtpService,
    public snack: SnackBarService,
    public serviceEndPoint: ServiceEndpointsService,
    private bridge: BridgeService
  ) {}

  ngOnInit() {
    this.bridge.readSmsBridge();
    this.bridge.otp$.subscribe((otp) => {
      if (otp) {
        this.otpInputFields.intakeReal = [...(otp + '')]; // 12345 => ['1','2','3','4','5']
        this.otpInputFields.intakeMasked = Array(5).fill('*');
        this.otpRef.nativeElement[4].focus();
      }
    });
    let header = '';
    this.ngrxstore.select('appReducer', 'loginReducer').subscribe((value) => {
      if (Object.prototype.hasOwnProperty.call(value, 'isMobileActive')) {
        this.isHybrid = value.isMobileActive.isHybrid;
      }
    });

    //clearing previous errors of error module (if any)
    this.clearPreviousErrors();

    this.dataSharingService.getHeadPagination().subscribe((headrData: HeaderInterface) => {
      header = headrData.mainTitle;
    });

    this.dataSharingService.setHeadPagination({
      mainTitle: header,
      subTitle: 'One Time PIN',
      showBack: false,
      showClose: true,
      steps: false,
    });

    this.subs$1 = this.receiptModel$.subscribe((data) => {
      if (data) {
        this.detailsReceived = { ...data };
        this.statusDetails = this.detailsReceived;
        if (data.event !== undefined) {
          const eventData = data.event;
          let makeData;
          let msgData = '';

          if (eventData.headers.headers.get(McaHeader.X_SBG_RESPONSE_MESSAGE) === undefined) {
            msgData = '';
            makeData = {
              msg: msgData,
              type: eventData.headers.headers.get(McaHeader.X_SBG_RESPONSE_TYPE),
              body: eventData.body,
            };
          } else {
            makeData = {
              msg: eventData.headers.headers.get(McaHeader.X_SBG_RESPONSE_MESSAGE),
              type: eventData.headers.headers.get(McaHeader.X_SBG_RESPONSE_TYPE),
              body: eventData.body,
            };
          }
          if (eventData.status === '204') {
            this.errorMsg = eventData.headers.headers.get(McaHeader.X_SBG_RESPONSE_MESSAGE);
          }
          this.receiptModel = makeData;
          this.message = this.receiptModel.msg[0];
        }
      }
    });

    this.subscription.push(this.subs$1);

    if (this.detailsReceived.stepUp.contactMethod === 'EMAIL') {
      this.showMailLink = false;
    } else {
      this.showMailLink = true;
    }

    this.ngrxstore.select('appReducer', 'loginReducer').subscribe((data) => {
      if (data) {
        this.isSmeMode = data.isSmeMode;
      }
    });

    this.dataSharingService.getErrorMessage().subscribe((err) => {
      if (err.type === 'error') {
        this.errorMsg = err.warningMessage;
      }
    });

    this.resetOtpFields();
    this.defaultDetails();
    this.startTimer();
  }

  ngAfterViewInit() {
    this.otpRef.nativeElement[0].focus();
  }

  getOtpDetails() {
    /* eslint-disable */
  }

  tabTrigger($event, $index) {
    // if the value pressed is "", reset
    // on key-up get input value first
    // input value before transformation

    const inputValue = $event.target.value;

    if (inputValue !== '*') {
      if (inputValue !== '') {
        this.otpInputFields.intakeReal[$index] = inputValue;
        this.otpInputFields.intakeMasked[$index] = '*';
        if ($index < 4) {
          this.otpRef.nativeElement[$index + 1].focus();
        }
      } else {
        // if not backspace
        // we rely on html 5 here to make sure we only get numeric values of single digits [0-9]
        this.resetOtpFields();
        // and focus on the first child
        this.otpRef.nativeElement[0].focus();
      }
    }
  }

  goNext() {
    const takeOtp = {
      ...this.detailsReceived.stepUp,
      code: this.otpInputFields.intakeReal.join(''),
    };
    const preparePayload = {
      ...this.detailsReceived.body,
      stepUp: takeOtp,
    };
    this.subs$2 = this.dataSharingService.getPostService().subscribe((data) => {
      data(preparePayload);
    });
    this.subscription.push(this.subs$2);
    this.resetOtpFields();
    this.otpRef.nativeElement[0].focus();
    this.subs$3 = this.dataSharingService.getErrorMessage().subscribe((err) => {
      if (typeof err !== 'object') {
        this.errorMsg = err;
      }
    });
    this.subscription.push(this.subs$3);
  }

  reSendOtp(type) {
    this.resetOtpFields();
    let wewdataReveived = {} as any;
    if (this.detailsReceived.event !== undefined) {
      wewdataReveived = { ...this.detailsReceived.event.body };
      this.sendDetails(type, wewdataReveived);
    } else {
      wewdataReveived = { ...this.detailsReceived.body };
      this.sendDetails(type, wewdataReveived);
    }
  }

  sendDetails(type, newotpcode) {
    const { stepUp, keyValueMetadata } = newotpcode;
    const modifiedstepUpcode = { ...stepUp, code: '' };
    let resendPayload = {} as any;
    resendPayload = {
      keyValueMetadata,
      stepUp: modifiedstepUpcode,
    };
    if (type) {
      resendPayload.stepUp.contactMethod = 'EMAIL';
      this.showBothContacts = true;
    } else {
      resendPayload.stepUp.contactMethod = 'SMS';
      this.showBothContacts = false;
    }
    this.otpService.resendOtp(resendPayload).subscribe(
      (res) => {
        if (res) {
          const response = this.serviceEndPoint.parseDataToParser(res);
          if (
            response.stepUp.code === 'OTP generated successfully' &&
            response.stepUp.contactMethod === 'EMAIL'
          ) {
            if (response.stepUp.maskedAddress.length === 1) {
              this.maskedMail = response.stepUp.maskedAddress[0];
            } else {
              this.maskedMail = response.stepUp.maskedAddress[1];
            }
            this.snack.open(
              `One-Time PIN sent to your registered E-mail address  ${this.maskedMail}`,
              'success',
              4000
            );
          }

          if (
            response.stepUp.code === 'OTP generated successfully' &&
            (response.stepUp.contactMethod === 'SMS_EMAIL' || response.stepUp.contactMethod === 'SMS')
          ) {
            this.snack.open('One-Time PIN sent to your registered number', 'success');
            this.startTimer();
          }

          if (response.stepUp.code !== 'OTP generated successfully') {
            this.snack.open('One-Time PIN could not be resent', 'error', 4000);
          }
          this.focusFirstInput();
        }
      },
      (err) => {
        if (err) {
          this.snack.open('One-Time PIN could not be resent', 'error', 4000);
          this.focusFirstInput();
        }
      }
    );
  }

  resetOtpFields() {
    const sudoApp = ['', '', '', '', ''];
    this.otpInputFields.intakeReal = [];
    this.otpInputFields.intakeMasked = [];
    sudoApp.forEach((item) => {
      this.otpInputFields.intakeMasked.push(item);
    });
  }

  focusFirstInput() {
    if (this.otpRef !== undefined) {
      this.otpRef.nativeElement[0].focus();
    }
  }

  defaultDetails() {
    if (
      this.detailsReceived.stepUp.contactMethod &&
      this.detailsReceived.stepUp.contactMethod.toLowerCase() === 'email'
    ) {
      this.contactMethod = 'email';
    } else {
      this.contactMethod = 'number';
    }
    this.focusFirstInput();
  }

  startTimer() {
    clearInterval(this.downTimer);
    this.timer = 15;
    this.downTimer = setInterval(() => {
      if (this.timer === 0) {
        clearInterval(this.downTimer);
      }
      if (this.timer !== 0) {
        this.timer = this.timer - 1;
      }
    }, 1000);
  }

  clearPreviousErrors() {
    let headerDetails;
    const sub1 = this.dataSharingService.getHeadPagination().subscribe((data) => {
      headerDetails = data;
    });
    //Since we need current header details, so it is unsubscribed
    sub1.unsubscribe();
    this.dataSharingService.setErrorMessage('');
    this.dataSharingService.setHeadPagination(headerDetails);
  }

  ngOnDestroy(): void {
    this.errorMsg = '';
    this.subscription.forEach((item) => {
      if (item !== undefined) {
        item.unsubscribe();
      }
    });
  }
}
