<ng-container *ngIf="!preLoginSmeMode; then notSmeMode; else smeMode"></ng-container>
<ng-template #notSmeMode>
  <div class="column large-8 medium-9 small-11 medium-centered large-centered" *ngIf="newFeatureDetails && !infoType">
    <div class="conditions">{{newFeatureDetails.transactionTypeDescription.description}}</div>
  </div>
  <div class="column large-10 medium-10 small-12 medium-centered large-centered" *ngIf="!newFeatureDetails && infoType">
    <div class="conditions" *ngIf='infoType==="RULE"'>
      Rule Set-up is as per the approval levels and rules in the Enterprise Online Application Form for Once-off Beneficiary Payment (Financial
      transactions) and non-Financial transaction types. The New Feature functionality only approves the selected feature(s) for use in the enterprise
      and will either apply the Financial or non-Financial approval rule when the related transaction is performed.
    </div>
  </div>
  <div ng-if="offerbar.borrowingType === 'NUPL'" class="tc-boundary-border mvp2 mvp2tnc">
    <div class="conditiom-heading">Before we proceed please read & understand the points below:</div>
    <ol class="condition-desc">
      <li>I am not a minor</li>
      <li>
        I am not married in community of property and do not require spousal consent to apply for or take up this loan facility and I am aware that
        proceeding with this application without spousal consent has legal implications.
      </li>
      <li>I have never been declared mentally unfit by a court of law</li>
      <li>I am not subject to an administration order</li>
      <li>I do not have any current application pending for debt restructuring or alleviation</li>
      <li>I have not previously applied for a debt re-arrangement</li>
      <li>I am not under sequestration</li>
      <li>I can afford the loan and interest payments as well as the costs, fees and charges referred to in the Quotation</li>
      <p>
        You are borrowing money and therefore you need to repay it within the stated period from now. If you don’t the Bank will update their records
        and this will reflect negatively on you and could result in you struggling to get finance again in the future. You may settle the facility at
        any time.
      </p>
    </ol>
  </div>
</ng-template>
<ng-template #smeMode>
  <div class="column large-8 medium-9 small-11 medium-centered large-centered" *ngIf="newFeatureDetails && !infoType">
    <div class="conditions">{{newFeatureDetails.transactionTypeDescription.description}}</div>
  </div>
  <div class="column large-10 medium-10 small-12 medium-centered large-centered" *ngIf="!newFeatureDetails && infoType">
    <div class="conditions" *ngIf='infoType==="RULE"'>
      Rule Set-up is as per the approval levels and rules in the Enterprise Online Application Form for Once-off Beneficiary Payment (Financial
      transactions) and non-Financial transaction types. The New Feature functionality only approves the selected feature(s) for use in the enterprise
      and will either apply the Financial or non-Financial approval rule when the related transaction is performed.
    </div>
  </div>
  <div ng-if="offerbar.borrowingType === 'NUPL'" class="tc-boundary-border mvp2 mvp2tnc">
    <div class="conditiom-heading">Before we proceed please read & understand the points below:</div>
    <ol class="condition-desc">
      <li>I am not a minor</li>
      <li>
        I am not married in community of property and do not require spousal consent to apply for or take up this loan facility and I am aware that
        proceeding with this application without spousal consent has legal implications.
      </li>
      <li>I have never been declared mentally unfit by a court of law</li>
      <li>I am not subject to an administration order</li>
      <li>I do not have any current application pending for debt restructuring or alleviation</li>
      <li>I have not previously applied for a debt re-arrangement</li>
      <li>I am not under sequestration</li>
      <li>I can afford the loan and interest payments as well as the costs, fees and charges referred to in the Quotation</li>
      <p>
        You are borrowing money and therefore you need to repay it within the stated period from now. If you don’t the Bank will update their records
        and this will reflect negatively on you and could result in you struggling to get finance again in the future. You may settle the facility at
        any time.
      </p>
    </ol>
  </div>
</ng-template>
