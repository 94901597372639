import { LoaderService } from './loader.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import includes from 'lodash/includes';

@Injectable({
  providedIn: 'root',
})
export class LoaderInterceptorService implements HttpInterceptor {
  counter = 0;
  constructor(private loaderService: LoaderService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (includes(req.url, '/AccountService/Statement') && req.body.lines) {
      return next.handle(req);
    }
    this.counter++;
    if (includes(req.url, 'file-upload') || includes(req.url, 'UploadInternationalPaymentDocument')) {
      this.loaderService.hide();
    } else {
      this.loaderService.show();
    }

    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.counter--;
            if (this.counter === 0) {
              this.loaderService.hide();
            }
          }
        },
        () => {
          this.counter--;
          if (this.counter === 0) {
            this.loaderService.hide();
          }
          // this.loaderService.hide();
        }
      )
    );
  }
}
