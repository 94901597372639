import { FieldValidationMessage } from './../../constants/fieldValidation/fieldValidationMessage';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FieldValidationService {
  getError(type) {
    let ErrorMessage;
    if (FieldValidationMessage.requiredFeilds.indexOf(type) > -1) {
      ErrorMessage = FieldValidationMessage.messages[type] + ' ' + FieldValidationMessage.errorSuffixes;
    } else if (FieldValidationMessage.validationFields.indexOf(type) > -1) {
      ErrorMessage = FieldValidationMessage.messages[type];
    } else {
      ErrorMessage = type + ' ' + FieldValidationMessage.errorSuffixes;
    }

    return ErrorMessage;
  }
  fieldValidationMessage(type) {
    return this.getError(type);
  }
}
