<ng-container *ngIf="!isHybrid">
  <div class="close-current-module-container" *ngIf="moduleData.showModule">
    <div class="close-current-module-content">
      <div class="font-regular-16-white" *ngIf="moduleData.message && !moduleData.message.line1">
        <div [innerHTML]="moduleData.message | safe : 'html'"></div>
      </div>

      <div class="font-regular-16-white mb-16" *ngIf="moduleData.message && moduleData.message.line1">
        <div [innerHTML]="moduleData.message.line1 | safe : 'html'"></div>
      </div>

      <div class="font-regular-16-white mb-16" *ngIf="moduleData.message && moduleData.message.line2">
        <div [innerHTML]="moduleData.message.line2 | safe : 'html'"></div>
      </div>

      <div class="font-regular-16-white mb-16" *ngIf="moduleData.message && moduleData.message.line3">
        <div [innerHTML]="moduleData.message.line3 | safe : 'html'"></div>
      </div>

      <div class="font-regular-16-white mb-16" *ngIf="moduleData.message && moduleData.message.question">
        <div [innerHTML]="moduleData.message.question | safe : 'html'"></div>
      </div>

      <sbg-choose-approver
        *ngIf="moduleData.smeTransactionType"
        [financeType]="moduleData.financeType"
        [financeValue]="moduleData.financeValue"
        [transactionType]="moduleData.smeTransactionType"
        [currency]="moduleData?.amount?.currency"
        [amountFeild]="moduleData?.amount?.amount"
      >
      </sbg-choose-approver>

      <section [class.align-center]="!moduleData.buttonTwoText" class="row flex-container mt-36">
        <div
          *ngIf="moduleData.buttonOneText"
          [class.medium-text-right]="moduleData.buttonTwoText"
          class="columns small-12 medium-6 text-center padding-8 padding_class"
        >
          <sbg-button
            [isRaisedButton]="true"
            buttonType="primary"
            [disableRipple]="false"
            buttonStyle="outline"
            buttonText="{{ moduleData.buttonOneText }}"
            ariaLabel="go back"
            (clickFunction)="leftButtonClickedFn()"
          ></sbg-button>
        </div>

        <div
          *ngIf="moduleData.buttonTwoText"
          class="columns small-12 medium-6 text-center medium-text-left padding-8"
        >
          <sbg-button
            [isRaisedButton]="true"
            buttonType="primary"
            [disableRipple]="false"
            buttonStyle="{{ moduleData.buttonTwoDanger ? 'danger' : 'outline' }}"
            buttonText="{{ moduleData.buttonTwoText }}"
            ariaLabel="stay"
            (clickFunction)="rightButtonClickedFn()"
          >
          </sbg-button>
        </div>
      </section>
    </div>
  </div>
</ng-container>

<!--*************************************************** Hybrid App********************************************** -->
<ng-container *ngIf="isHybrid">
  <div
    class="hybrid-close-current-module-container"
    *ngIf="moduleData.showModule && moduleData.message.modelMessage && !moduleData.isNewEOLModel"
  >
    <div class="hybrid-close-current-module-content">
      <div class="font-regular-16-white mb-8" *ngIf="moduleData.message && !moduleData.message.modelTitle">
        <div [innerHTML]="moduleData.message | safe : 'html'"></div>
      </div>
      <div class="modelTitle mb-8" *ngIf="moduleData.message && moduleData.message.modelTitle">
        <div [innerHTML]="moduleData.message.modelTitle | safe : 'html'"></div>
      </div>

      <div class="modelMessage mb-24" *ngIf="moduleData.message && moduleData.message.modelMessage">
        <div [innerHTML]="moduleData.message.modelMessage | safe : 'html'"></div>
      </div>

      <div class="modelWarning mb-16" *ngIf="moduleData.message && moduleData.message.modelWarning">
        <div [innerHTML]="moduleData.message.modelWarning | safe : 'html'"></div>
      </div>

      <sbg-hybrid-choose-approver
        *ngIf="moduleData.smeTransactionType"
        [financeType]="moduleData.financeType"
        [financeValue]="moduleData.financeValue"
        [transactionType]="moduleData.smeTransactionType"
        [currency]="moduleData?.amount?.currency"
        [amountFeild]="moduleData?.amount?.amount"
      >
      </sbg-hybrid-choose-approver>
      <section class="actionButtons" *ngIf="moduleData.cancelButtonText">
        <div
          (click)="leftButtonClickedFn()"
          *ngIf="moduleData.cancelButtonText"
          class="actionButton cancelButton unselectable"
        >
          {{ moduleData.cancelButtonText }}
        </div>
        <div
          *ngIf="moduleData.proceedButtonText && moduleData.type !== 'secondary'"
          (click)="rightButtonClickedFn()"
          class="actionButton proceedButton unselectable"
        >
          {{ moduleData.proceedButtonText }}
        </div>
        <div
          *ngIf="moduleData.proceedButtonText && moduleData.type === 'secondary'"
          (click)="rightButtonClickedFn()"
          class="actionButton infoButton unselectable"
        >
          {{ moduleData.proceedButtonText }}
        </div>
      </section>
    </div>
  </div>
  <div *ngIf="moduleData.isNewEOLModel && moduleData">
    <div
      class="hybrid-eol-close-current-module-container"
      *ngIf="moduleData.showModule && moduleData.message.modelMessage"
    >
      <!-- <div class="back_drop" (click)="leftButtonClickedFn()"></div> -->
      <div class="newEolModelcontainer">
        <div class="newEolModelHeader">
          <div></div>
          <div *ngIf="moduleData && moduleData.proceedButtonText">
            <span class="eol_model_title">{{ moduleData.proceedButtonText }}</span>
          </div>
          <div
            [disabled]="!moduleData"
            (click)="moduleData ? leftButtonClickedFn() : ''"
            class="unselectable"
          >
            <mat-icon svgIcon="icn_close" class="hybrid-white-icon mr-8"></mat-icon>
          </div>
        </div>
        <sbg-hybrid-choose-approver
          *ngIf="moduleData.smeTransactionType"
          [financeType]="moduleData.financeType"
          [financeValue]="moduleData.financeValue"
          [transactionType]="moduleData.smeTransactionType"
          [currency]="moduleData?.amount?.currency"
          [amountFeild]="moduleData?.amount?.amount"
          [ishybridEOLModel]="moduleData.isNewEOLModel"
          (isApproversFound)="disableStopButton()"
        >
        </sbg-hybrid-choose-approver>
        <div *ngIf="moduleData?.smeTransactionType" class="eolButton_center pb-24 unselectable">
          <div
            [disabled]="!moduleData"
            class="hybrid_secondary_button eol_button_styles"
            [ngClass]="{ hybrid_disableButton: buttonDisabled }"
            (click)="buttonDisabled ? '' : moduleData ? rightButtonClickedFn() : ''"
          >
            <span class="hybrid_secondary_button--button_text">{{ moduleData.proceedButtonText }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
