<div class="login-update">
  <div class="login-content__form pt-18 mb-24">
    <p class="login-update-topic">Important Update</p>
    <div class="login-update-content mt-16">
      <div class="mb-8">We've upgraded the security of our Internet Banking & Stanbic App experience.</div>
      <!-- You now only need
      <b>one set of credentials</b> to sign into your Internet Banking & Stanbic App. -->
      <div>
        You now only need <b>one set of credentials</b> to sign into your Internet Banking & Stanbic App.
      </div>
    </div>
    <hr class="hybrid-ruler mt-12" />
    <div class="login-scenario">
      Scenarios

      <p class="login-notes">For all Personal & Enterprise Banking customers</p>
    </div>

    <div class="pl-16">
      <ul class="mb-0">
        <li class="login-update-questions mt-6">
          I have both Internet Banking & Standard Bank App sign in credentials.
        </li>
      </ul>
    </div>
    <div class="login-update-content-answer">
      Please use respective Standard Bank App username and password to access your Personal/ Enterprise Online
      accounts.
    </div>
    <div class="login-notes"><i>Username example: johndoe@mail.com</i></div>
    <div class="pl-16">
      <ul class="mb-0">
        <li class="login-update-questions mt-24">I only have Internet Banking sign in credentials.</li>
      </ul>
    </div>
    <div class="login-update-content-answer">
      Please use Personal Internet Banking/ Enterprise Online credentials to sign into your respective
      accounts.
    </div>
    <div class="login-update-content-answer mt-4">
      These credentials can also be used to sign into your Standard Bank App for your respective Personal
      Enterprise accounts.
    </div>
  </div>
  <div *ngIf="!preHybrid" class="hybrid-login-content__footer">
    <!-- <div>I have both Internet Banking & Stanbic App Credentials.</div> -->
    <div>
      <p class="user_options mt-6">
        I have both Internet Banking &<br />
        Standard Bank App Credentials
      </p>
    </div>
    <div class="hybrid-button-container pt-8">
      <sbg-button
        buttonText="SIGN IN"
        class="hybrid-secondary-sign-in"
        (clickFunction)="goTologin()"
      ></sbg-button>
    </div>

    <div class="terms_condition_text">I only have Internet Banking Credentials.</div>
    <div class="hybrid-button-container pt-8">
      <sbg-button
        buttonText="SIGN IN"
        class="hybrid-primary-sign-in"
        (clickFunction)="goTologin()"
      ></sbg-button>
    </div>
    <div class="hybrid-button-container">
      <div>New to Internet banking?</div>
      <button (click)="goToRegistration()" class="hybrid-register-button">Register here</button>
    </div>
  </div>
  <div *ngIf="preHybrid" class="hybrid-login-content__footer">
    <div class="hybrid-button-container">
      <sbg-button
        buttonText="SIGN IN"
        class="hybrid-primary-sign-in"
        (clickFunction)="goTologin()"
      ></sbg-button>
    </div>
    <div class="hybrid-button-container pt-8">
      <div>New to Internet banking?</div>
      <button (click)="goToRegistration()" class="hybrid-register-button">Register here</button>
    </div>
  </div>
</div>
