import { createAction, props } from '@ngrx/store';
import { VasPaymentFormData, VasPaymentReceiptData } from '../interfaces/vasPayment.model';

export const setVasPaymentFormData = createAction(
  '[VasPayment Component] vasPaymentFormData',
  props<{ vasPaymentFormData: VasPaymentFormData }>()
);

export const setVasPaymentReceiptData = createAction(
  '[VasPayment Component] vasPaymentReceiptData',
  props<{ vasPaymentReceiptData: VasPaymentReceiptData }>()
);
