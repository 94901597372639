import { createAction, props } from '@ngrx/store';
import {
  EditBankBeneficiary,
  EditOnceOffBankBeneficiary,
  EditSelfFund,
  EditPayBiller,
} from '../interfaces/edit-txn-schedule.model';

export const setEditScheduleData = createAction(
  '[SelfAccountTransfer Component] setEditScheduleData',
  props<{ editBankBeneficiary: EditBankBeneficiary }>()
);

export const setEditOnceOffScheduleData = createAction(
  '[SelfAccountTransfer Component] setEditOnceOffScheduleData',
  props<{ editOnceOffBankBeneficiary: EditOnceOffBankBeneficiary }>()
);

export const setEditSelfFundScheduleData = createAction(
  '[SelfAccountTransfer Component] setEditSelfFundScheduleData',
  props<{ editSelfFund: EditSelfFund }>()
);

export const setEditPayBillerScheduleData = createAction(
  '[SelfAccountTransfer Component] setEditPayBillerScheduleData',
  props<{ editPayBiller: EditPayBiller }>()
);
