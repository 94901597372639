import { createAction, props } from '@ngrx/store';
import { ElectricityData, SelectedElectricityData, ElectricityReceiptData } from '../interfaces/buy.model';

export const setSelectedElectricityData = createAction(
  '[ElectricityList Component] selectElectricity',
  props<{ selectedElectricityData: SelectedElectricityData }>()
);

export const setElectricityReceiptListData = createAction(
  '[ElectricityList Component] setReceiptList',
  props<{ receiptList: Array<any> }>()
);

export const setElectricityData = createAction(
  '[ElectricityList Component] setElectricityData',
  props<{ electricityData: ElectricityData }>()
);

export const setElectricityReceiptData = createAction(
  '[ElectricityList Component] setElectricityReceiptData',
  props<{ electricityReceiptData: ElectricityReceiptData }>()
);
