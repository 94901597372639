import { EventEmitter, Injectable } from '@angular/core';
import { DataSharingService } from '../data-sharing/data-sharing.service';
import { Router } from '@angular/router';
import { Step } from './step';

interface FlowData {
  flowName: string;
  steps: Step[];
  stepData: any[];
  activeStepId: number;
  returnUrl: string;
}

@Injectable()
export class FlowControllerService {
  flowEnded: EventEmitter<boolean> = new EventEmitter();
  private flowData: FlowData = {
    returnUrl: '/dashboard',
    steps: [],
    flowName: '',
    stepData: [],
    activeStepId: 0,
  };

  constructor(private sharingService: DataSharingService, private router: Router) {
    if (sessionStorage.getItem('flow')) {
      this.flowData = JSON.parse(sessionStorage.getItem('flow'));
      this.initFlow();
    }
  }

  startFlow(steps: Step[], flowName: string, returnUrl?: string) {
    this.flowData = {
      returnUrl,
      steps,
      flowName,
      stepData: [],
      activeStepId: 0,
    };
    this.initFlow();
  }
  getData() {
    return this.flowData.stepData[this.flowData.activeStepId];
  }

  endFlow() {
    this.clearFlow();
    this.flowEnded.emit(true);
    this.router.navigate([this.flowData.returnUrl]);
  }
  proceedToNextStep(data?: any) {
    this.flowData.activeStepId++;
    if (this.flowData.activeStepId > this.flowData.steps.length - 1) {
      this.endFlow();
    } else {
      this.navigateToStep(data);
    }
  }

  returnToPreviousStep(data?: any) {
    this.flowData.activeStepId--;
    this.navigateToStep(data);
  }

  private initFlow() {
    this.updateLocalStorage();
    this.sharingService.setHeaderTitle(this.flowData.flowName);
    this.sharingService.setPaginationSteps(this.flowData.steps.map((step) => step.name));
    this.navigateToStep();
  }

  private navigateToStep(data?: any) {
    this.sharingService.setPaginationActiveStep(this.flowData.steps[this.flowData.activeStepId].name);
    if (data) {
      this.flowData.stepData[this.flowData.activeStepId] = data;
    }
    this.updateLocalStorage();
    if (this.flowData.steps[this.flowData.activeStepId].baseUrl) {
      const activeStep = this.flowData.steps[this.flowData.activeStepId];
      this.router.navigate([activeStep.baseUrl + activeStep.url]);
    } else {
      this.router.navigate([this.flowData.steps[this.flowData.activeStepId].url]);
    }
  }

  private updateLocalStorage() {
    sessionStorage.setItem('flow', JSON.stringify(this.flowData));
  }

  private clearFlow() {
    this.sharingService.setHeaderTitle('');
    sessionStorage.removeItem('flow');
    this.flowData.stepData = [];
    this.flowData.activeStepId = 0;
  }
}
