import { UplInsurance } from '../interfaces/uplInsurance.interface';
export class SetUplConfirm {
  static readonly type = '[upl] Confirm Account';
  constructor(public payload: any) {}
}
export class SetUplDetailsInfo {
  static readonly type = '[upl] upldetailsInfo';
  constructor(public payload: UplInsurance) {}
}
export class SetNewUplDetailsInfo {
  static readonly type = '[upl] new upldetailsInfo';
  constructor(public payload: any) {}
}
