// import { isGroupProfile } from './../../helpers/system-principal-id/system-principal-id';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  NavigationExtras,
} from '@angular/router';
// import { SbgAnalyticsService } from '../sbg-analytics/sbg-analytics.service';
// import { SbgToolbarService } from '../toolbar-service/sbg-toolbar.service';

import { Platform } from '@angular/cdk/platform';
import { delay } from 'rxjs/operators';
// import { ENVIRONMENT } from '@sbg-web/env';
import { PageNameConstants, WebViewRoutes } from '../../lib/constants';
import { SbgToolbarService } from 'src/app/services/toolbar-service/sbg-toolbar.service';
import { environment } from '@environment';

const menuItems = [
  PageNameConstants.RegisterAndLinkAccounts,
  PageNameConstants.LinkAccounts,
  PageNameConstants.ContactUs,
  PageNameConstants.HelpAndServices,
  PageNameConstants.Profile,
  PageNameConstants.Settings,
  PageNameConstants.RegistrationForm,
  PageNameConstants.AddDashboard,
  PageNameConstants.ManageCards,
  PageNameConstants.ManageRights,
  PageNameConstants.ResetICNPassword,
];
type FlowStepNames =
  | 'capture_beneficiary_details_1'
  | 'capture_payment_details_2'
  | 'capture'
  | 'review'
  | 'confirm'
  | 'confirm_beneficiary';
type MobileNativeButtonNames = 'next' | 'review' | 'confirm' | 'done' | 'pay';

@Injectable({
  providedIn: 'root',
})
export class SbgApplicationService {
  stepBackwardsInFlow$: Observable<boolean>;
  lockSideBarActionWatcher$: Observable<boolean>;
  routeChangeWatcher$: Observable<boolean>;
  activeScreenName$: Observable<string>;
  isHeaderBackButtonHidden$: Observable<boolean>;
  isDashboardActive$: Observable<boolean>;
  showDashboardName$: Observable<boolean>;
  isAccountViewActive$: Observable<boolean>;
  flowName$: Observable<string>;

  isAppFullyLoaded$: Observable<boolean>;

  routePrefix = 's';

  //mobile-native exclusive variables
  displayFlowName$: Observable<boolean>;
  displayHeaderTitles$: Observable<boolean>;
  headerTitle$: Observable<string>;
  headerSubtitle$: Observable<string>;
  displayFlowButton$: Observable<boolean>;
  displaySecondaryButton$: Observable<boolean>;
  flowButtonText$: Observable<string>;
  secondaryButtonText$: Observable<string>;
  flowButtonClicked$: Observable<string>;
  flowButtonsDisabled$: Observable<boolean>;

  isMobileNative = false;
  env = environment;
  private isFullyLoaded = new BehaviorSubject<boolean>(false);
  private flowNameSubject = new BehaviorSubject<string>('');
  private activeScreenNameAction = new BehaviorSubject<string>('');
  private isHeaderBackButtonHidden = new BehaviorSubject<boolean>(false);
  private isDashboardActiveSubject = new BehaviorSubject<boolean>(false);
  private showDashboardNameSubject = new BehaviorSubject<boolean>(true);
  private lockSideBarAction = new BehaviorSubject<boolean>(false);
  private startNavigationAction = new BehaviorSubject<boolean>(false);
  private isAccountViewActiveSubject = new BehaviorSubject<boolean>(false);
  private stepBackwardsInFlowAction = new Subject<boolean>();

  //mobile-native exclusive variables
  private displayFlowNameSubject = new BehaviorSubject<boolean>(false);
  private displayHeaderTitlesSubject = new BehaviorSubject<boolean>(false);
  private headerTitleSubject = new BehaviorSubject<string>('');
  private headerSubtitleSubject = new BehaviorSubject<string>('');
  private displaySecondaryButtonSubject = new BehaviorSubject<boolean>(false);
  private displayFlowButtonSubject = new BehaviorSubject<boolean>(false);
  private flowButtonTextSubject = new BehaviorSubject<string>('');
  private secondaryButtonTextSubject = new BehaviorSubject<string>('');
  private headerButtonClickedSubject = new BehaviorSubject<string>('');
  private flowButtonsDisabledSubject = new BehaviorSubject<boolean>(false);
  private mobileNativeTargetRoute: WebViewRoutes;

  private endFlowPath: string;
  private flowPendingToStart = false;
  private flowStarted = false;
  private currentUrl: string;
  private currentStep: FlowStepNames;

  constructor(private router: Router, private topToolBar: SbgToolbarService, private platform: Platform) {
    this.lockSideBarActionWatcher$ = this.lockSideBarAction.pipe(delay(20));
    this.routeChangeWatcher$ = this.startNavigationAction.asObservable();
    this.isDashboardActive$ = this.isDashboardActiveSubject.asObservable();
    this.showDashboardName$ = this.showDashboardNameSubject.asObservable();
    this.isAccountViewActive$ = this.isAccountViewActiveSubject.asObservable();
    this.stepBackwardsInFlow$ = this.stepBackwardsInFlowAction.asObservable();
    this.activeScreenName$ = this.activeScreenNameAction.asObservable();
    this.isHeaderBackButtonHidden$ = this.isHeaderBackButtonHidden.asObservable();
    this.flowName$ = this.flowNameSubject.asObservable();
    this.isAppFullyLoaded$ = this.isFullyLoaded.asObservable();

    //mobile-native exclusive variables
    this.displayFlowName$ = this.displayFlowNameSubject.asObservable();
    this.displayHeaderTitles$ = this.displayHeaderTitlesSubject.asObservable();
    this.headerTitle$ = this.headerTitleSubject.asObservable();
    this.headerSubtitle$ = this.headerSubtitleSubject.asObservable();
    this.displaySecondaryButton$ = this.displaySecondaryButtonSubject.asObservable();
    this.displayFlowButton$ = this.displayFlowButtonSubject.asObservable();
    this.flowButtonText$ = this.flowButtonTextSubject.asObservable();
    this.secondaryButtonText$ = this.secondaryButtonTextSubject.asObservable();
    this.flowButtonClicked$ = this.headerButtonClickedSubject.asObservable();
    this.flowButtonsDisabled$ = this.flowButtonsDisabledSubject.asObservable();

    this.setupFlowManager();
  }

  checkForToolbarVisibility(): void {
    if (!this.flowPendingToStart && !this.flowStarted) {
      return;
    }

    if (this.flowPendingToStart) {
      this.flowPendingToStart = false;
      this.flowStarted = true;
    } else if (this.flowStarted) {
      this.flowStarted = false;
    }

    this.hideTopActionToolbar(this.flowStarted);
  }

  //   setRoutePrefix(dashboard): void {
  //     if (isGroupProfile(dashboard)) {
  //       this.routePrefix = 'g';
  //     } else {
  //       this.routePrefix = 's';
  //     }
  //   }

  setPageName(name: string): void {
    this.activeScreenNameAction.next(name);
    if (menuItems.includes(name)) {
      this.setDashboardVisibility(false);
    } else {
      this.setDashboardVisibility(true);
    }
  }

  setDashboardActive(active: boolean): void {
    this.isDashboardActiveSubject.next(active);
  }

  endFlow(): void {
    if (this.isMobileNative) {
      this.setFlowButtonVisibility(false);
      this.setSecondaryButtonVisibility(false);
    }
    if (this.endFlowPath) {
      setTimeout(() => {
        this.router.navigate([this.endFlowPath]);
      }, 10);
    }
  }

  stepBackwardsInFlow(): void {
    this.stepBackwardsInFlowAction.next(true);
  }

  hideAndLockSidenav(hide: boolean): void {
    this.lockSideBarAction.next(hide);
  }

  hideTopActionToolbar(hide: boolean): void {
    if (hide) {
      this.topToolBar.hide();
    } else {
      this.topToolBar.show();
    }
  }

  startFlow(startFlowPath: string, endFlowPath: string, exitFlowButtonName: string): void {
    if (this.isMobileNative) {
      this.activeScreenNameAction.next('');
    }
    this.flowNameSubject.next(exitFlowButtonName);

    this.flowPendingToStart = true;
    this.endFlowPath = this.addLoginOptionPrefix(endFlowPath);

    this.stepBackwardsInFlowAction.next(false);
    this.routeTo(startFlowPath);
  }

  navigateHome(): void {
    this.hideTopActionToolbar(false);
    this.navigateTo('/dashboard');
  }

  navigateToDashboard(): void {
    this.hideTopActionToolbar(false);
    this.routeTo('/' + this.routePrefix + '/dashboard');
  }

  navigateToEmptyDashboard(hasGroupProfile: boolean): void {
    this.routePrefix = hasGroupProfile ? 'g' : 's';
    this.navigateAndSetPageName('/dashboard/register-and-link', PageNameConstants.RegisterAndLinkAccounts);
  }

  navigateTo(path: string): void {
    this.navigateAndSetPageName(path, '');
  }

  navigateAndSetPageName(path: string, pageName = ''): void {
    if (path !== '/') {
      this.setPageName(pageName);
      this.routeTo(path);
    } else {
      this.setPageName('');
    }
  }

  navigateByUrl(url: string, addPrefix = true): void {
    if (addPrefix) {
      url = this.addLoginOptionPrefix(url);
    }

    this.router.navigateByUrl(url);
  }

  navigate(commands: any[], extras?: NavigationExtras): void {
    commands = commands.map((command) => {
      return this.addLoginOptionPrefix(command);
    });

    this.router.navigate(commands, extras);
  }

  setHeaderButtonsStatus(status: boolean): void {
    if (this.isMobileNative) {
      this.flowButtonsDisabledSubject.next(status);
      if (this.currentStep !== 'capture' && this.mobileNativeTargetRoute !== WebViewRoutes.PAY) {
        this.isHeaderBackButtonHidden.next(status);
      }
    } else {
      this.isHeaderBackButtonHidden.next(status);
    }
  }

  setAccountViewActive(active: boolean): void {
    this.isAccountViewActiveSubject.next(active);
  }

  clearSessionData(): void {
    this.setPageName('');
    this.topToolBar.clear();
  }

  setMobileTargetRoute(targetRoute: WebViewRoutes): void {
    this.mobileNativeTargetRoute = targetRoute;
  }

  isMobileDevice(): boolean {
    return this.platform.ANDROID || this.platform.IOS;
  }

  isIpadDevice(): boolean {
    return navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2;
  }

  //mobile-native functions

  setMobileNative(isMobileNative: boolean): void {
    this.isMobileNative = isMobileNative;
  }

  setHeaderTitle(text: string): void {
    this.headerTitleSubject.next(text);
  }

  setHeaderSubtitle(text: string): void {
    this.headerSubtitleSubject.next(text);
  }

  setHeaderButtonsForFlow(flowStepName: FlowStepNames): void {
    this.currentStep = flowStepName;
    switch (flowStepName) {
      case 'capture_beneficiary_details_1': {
        this.setFlowButtonText('next');
        this.setBackButtonVisibility(true);
        break;
      }
      case 'capture_payment_details_2': {
        this.setFlowButtonText('review');
        this.setBackButtonVisibility(true);
        break;
      }
      case 'capture': {
        this.setBackButtonVisibility(!(this.mobileNativeTargetRoute === WebViewRoutes.PAY));
        this.setSecondaryButtonVisibility(false);
        this.setFlowButtonText('review');
        break;
      }
      case 'review': {
        this.setBackButtonVisibility(true);
        this.setFlowButtonText('confirm');
        break;
      }
      case 'confirm': {
        this.setFlowButtonText('done');
        this.setBackButtonVisibility(false);
        break;
      }
      case 'confirm_beneficiary': {
        this.setFlowButtonText('done');
        this.setSecondaryButtonText('pay');
        this.setBackButtonVisibility(false);
        this.setSecondaryButtonVisibility(true);
        break;
      }
    }

    this.setFlowButtonVisibility(true);
  }
  appHasLoaded(): void {
    this.isFullyLoaded.next(true);
  }
  clickHeaderButton(headerButtonText: string): void {
    this.headerButtonClickedSubject.next(headerButtonText);
    this.headerButtonClickedSubject.next('');
  }
  private setDashboardVisibility(isVisible: boolean): void {
    this.showDashboardNameSubject.next(isVisible);
  }
  private routeTo(path: string): void {
    path = this.addLoginOptionPrefix(path);

    if (this.currentUrl !== path) {
      this.startNavigationAction.next(true);
    }

    setTimeout(() => {
      if (this.env.native) {
        this.router.navigate([path], { replaceUrl: true });
      } else {
        this.router.navigate([path]);
      }
    }, 10);
  }
  private checkForMobileNativeHeaderItemVisibility(): void {
    if (this.router.url.endsWith('/account')) {
      this.displayFlowNameSubject.next(false);
      this.displayHeaderTitlesSubject.next(true);
      this.setBackButtonVisibility(true);
    } else {
      this.displayFlowNameSubject.next(true);
      this.displayHeaderTitlesSubject.next(false);
    }

    if (this.router.url.endsWith('/payments')) {
      this.setBackButtonVisibility(!(this.mobileNativeTargetRoute === WebViewRoutes.PAY));
    }
  }

  private addLoginOptionPrefix(path: string): string {
    if (path !== '/signin') {
      if (!path.startsWith('/' + this.routePrefix + '/')) {
        path = '/' + this.routePrefix + path;
      }

      if (this.isMobileNative && !path.startsWith('/native')) {
        path = '/native' + path;
      } else {
        path = '/web' + path;
      }
    }

    return path;
  }
  private setupFlowManager(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.startNavigationAction.next(true);
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        this.currentUrl = event.url;

        // this.analytics.sendPageView(event.url, event.url);
        this.checkForToolbarVisibility();

        if (this.isMobileNative) {
          this.checkForMobileNativeHeaderItemVisibility();
        }

        this.startNavigationAction.next(false);
      }
    });
  }
  private setBackButtonVisibility(isVisible: boolean): void {
    this.isHeaderBackButtonHidden.next(!isVisible);
  }

  private setFlowButtonText(buttonText: MobileNativeButtonNames): void {
    this.flowButtonTextSubject.next(buttonText);
  }

  private setFlowButtonVisibility(isVisible: boolean): void {
    this.displayFlowButtonSubject.next(isVisible);
  }

  private setSecondaryButtonText(buttonText: MobileNativeButtonNames): void {
    this.secondaryButtonTextSubject.next(buttonText);
  }

  private setSecondaryButtonVisibility(isVisible: boolean): void {
    this.displaySecondaryButtonSubject.next(isVisible);
  }
}
