<ng-container *ngIf="!preLoginSmeMode; then notSmeMode; else smeMode"></ng-container>
<ng-template #notSmeMode>
  <div class="large-12 medium-12 small-12 medium-centered large-centered columns tc-wrap">
    <div *ngIf="newAccount">
      <div class="tc-boundary-border">
        <div class="conditions">
          <span class="conditiom-heading">1. Agreement and acceptance</span>
          <span class="condition-desc"
            >This agreement becomes effective between you and Stanbic Bank Botswana Limited (the bank/Stanbic Bank,we or us) at the time of
            registering for electronic banking or at the time you access electronic banking, whichever occurs first. For purposes of this agreement
            "electronic banking" includes Internet banking, mobile banking and prepaid products bought or used through Internet or mobile
            banking.</span
          >
        </div>
        <div class="conditions">
          <span class="conditiom-heading">2. Incorporated terms</span>
          <span class="condition-desc"
            >In addition to this agreement, the terms and conditions of your debit card (bank card terms) and account or facility terms and conditions
            (other terms) will apply to all transactions you carry out or instruct us to carry out through bank accounts linked to the card or account
            number you select when using electronic banking. If there is a conflict between the provisions of this agreement and the bank card terms
            or any other terms, the provisions of this agreement will apply. Where the bank card terms or other terms require amendments or additions
            to them to be in writing and/or signed, your acceptance of this agreement will be deemed to satisfy such requirements.</span
          >
        </div>
        <div class="conditions">
          <span class="conditiom-heading">3. Defining the device and medium</span>
          <span class="condition-desc">
            The device which you select to access electronic banking may include a computer, cellphone, tablet telephone, television or similar
            technologies (the device) and the medium through which you access electronic banking may include the Internet, wireless application
            protocol (WAP), wireless Internet gateway (WIG), short messaging service (SMS), unstructured supplementary service data (USSD), voice over
            an automated voice recognition system or similar technologies (the medium). We will refer to the device and the medium collectively as
            "the communication system". Where a particular communication system requires contractual provisions different from other communication
            systems, this will be clearly stated in this agreement.</span
          >
        </div>
        <div class="conditions">
          <span class="conditiom-heading">4. Linked text</span>
          <span class="condition-desc">
            For ease of use, we have included automated links (hyperlinks) in this agreement to information elsewhere on the communication system.
            These links are indicated by blue underlined text and your mouse's cursor should change into a hand or other pointer when held over this
            text. You are obliged to view the relevant parts of the hyperlinked information, which information will be regarded as forming part of
            this agreement. If your communication system cannot access the hyperlinks, you must visit our website at - www.stanbic.com.bw to access
            the linked information or call our Customer Care Centre on -+233 302815789.</span
          >
        </div>
        <div class="conditions">
          <span class="conditiom-heading">5. Amendments</span>
          <span class="condition-desc">
            We may amend this agreement from time to time and you are bound by the version of this agreement that exists at the time you access
            electronic banking. The date of the version is shown above clause 1. We will let you know when we change this agreement.
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">6. Requirements and registration <br /> </span>
          <span class="condition-desc">
            Before you can use electronic banking you must follow certain steps.<br /><br />
            <ul class="condition-desc" style="list-style-type: disc">
              <li>
                Internet banking: You must first register at your nearest branch by filling in a registration form. You will be issued with a password
                that you have to change once you have logged on to the Internet site for the first time. You must then create your own password. For
                more information on how to do this you can visit a branch or call our Customer Care Centre (267) 3987801 Verification: You may not
                create a password using somebody else bank details. Before we allow you access to Self-Service Banking we may require information from
                you so that we can verify that you are who you say you are. We will require your prior consent (express or implied), which consent
                will be deemed to have been granted by you at the time that you sign the registration form referred to in 6 above or at the time you
                accept these terms and conditions, to perform credit or other checks on you before we process an instruction.
              </li>
            </ul>
          </span>
          <br />
        </div>
        <div class="conditions">
          <span class="conditiom-heading">7. Fees</span>
          <span class="condition-desc"
            >You will be charged the following fees for using electronic banking:
            <ul>
              <li>
                a. We may charge a transaction fee based on the type of transactions you perform. Details of our standard subscription and transaction
                fees are available on our website and at branches.
              </li>
              <li>b. For mobile banking we may charge you subscription fees as well as transaction fees.</li>
            </ul>
            If you do not pay our fees or if you have insufficient funds in the accounts you have selected for this purpose, we have the right to
            refuse you access to the relevant electronic banking service and to debit all outstanding fees to any other account you have with
            us.</span
          >
        </div>
        <div class="conditions">
          <span class="conditiom-heading">8. We will act on instructions that appear to have been sent by you</span>
          <span class="condition-desc"
            >Use of a communication system means we do not interact face to face. Unless you notify us before we carry out an instruction, you
            authorise us to rely on and perform all instructions that appear to originate from you (even if someone else is impersonating you).
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">9. Sending and processing instructions</span>
          <span class="condition-desc">
            Your instructions to us will be subject to the same turnaround times and processes that apply to your customer profile, the type of
            account you have and type of transaction. More information on the turnaround times for processing of instructions is available on our
            website. An instruction, including purchases of prepaid products, cannot be terminated or revoked after you have sent them to us. You will
            not hold us liable if you send the same instruction more than once and neither instruction will be reversible.
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">10. Confirmation of receipt of your instructions</span>
          <span class="condition-desc">
            An instruction is deemed to have been received by us only once we have confirmed we have received it. If we do not confirm receipt of your
            instruction, do not resend the same instruction before checking your statements or calling our Customer Care Centre. This is because the
            first instruction may still be processed and resending the instruction may lead to a double transaction for which we will not be held
            liable.</span
          >
        </div>
        <div class="conditions">
          <span class="conditiom-heading">11. Access code protection and irregularities</span>
          <span class="condition-desc"
            >An "access code" refers to your password and user name. You must protect and keep your access codes confidential at all times. It is not
            safe to store them on your computer or on a network. We rely on you to report any compromise of your access codes to us without delay. You
            may do this by calling our Customer Care Centre or your branch. We may ask you to verify your identity to prevent someone else from
            impersonating you. Once you ask us to disable an access code we have the right to:
            <ul>
              <li>a. Reject all instructions received after such notification;</li>
              <li>b. Suspend the processing of all instructions not yet executed;</li>
              <li>
                c. Reverse (if possible) all executed transactions with effect from such date and time as we may reasonably determine the unauthorised
                use to have commenced;
              </li>
              <li>d. Deactivate the access code without further notice.</li>
              <li>
                e. There will never be a reason for any person, including a member of our staff or Customer Care Centre, to know or ask for your
                access code. Do not give your access code to these persons, regardless of whether you called them or they called you. You must let us
                know immediately if you discover an error or irregularity. You can report any errors to our Customer Care Centre or at any branch.
              </li>
            </ul>
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">12. One Time Password</span>
          <span class="condition-desc">
            This is a unique and time-sensitive password used as added security on Internet banking. The password does not replace the password you
            use to log on to Internet banking but provides a second layer of security. The password will be sent to you by SMS and is valid for one
            Internet banking session only.</span
          >
          <br />
          A one-time password (OTP) is used for the following:
          <ul class="condition-desc" style="list-style-type: disc">
            <li>Adding beneficiaries/ billers</li>
            <li>Amending beneficiaries/ billers</li>
            <li>Performing once-off payments</li>
          </ul>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">13. Ending a session</span>
          <span class="condition-desc"> You must ensure that you log-off from the Internet banking service. </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">14. Your authority</span>
          <span class="condition-desc">
            You permit us to regard all activities you conduct or instructions sent after you enter your access code as being authorized by you and
            intended to have legal force and effect. All transactions executed on your profile will be deemed to have been instructed by you unless
            the contrary is proven and unless you have indicated to us before honouring the instruction that the instruction does not emanate from
            you. Otherwise, you indemnify us against any loss whatsoever you may suffer as a result of an instruction being honoured by us if it did
            not emanate from you.</span
          >
        </div>
        <div class="conditions">
          <span class="conditiom-heading">15. Sufficient notice</span>
          <span class="condition-desc"
            >You permit us to issue notices required in terms of this agreement, legislation or regulation by making such notification available via
            our communication systems or sending such notification by email, SMS or similar future technologies. Any notices so issued by us, will as
            far as they contain contractual terms relating to electronic banking, also form part of this agreement.</span
          >
        </div>
        <div class="conditions">
          <span class="conditiom-heading">16. No offer, recommendation or solicitation</span>
          <span class="condition-desc"
            >Unless clearly stated, all material on the communication system is merely an invitation to do business with us. It is not an offer or
            solicitation to buy or sell, or dispose in any way of any investment, or to enter into any transaction.
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">17. Nature of information on the communication system</span>
          <span class="condition-desc">
            Information on the communication system is intended to provide you with only general information about the bank, its products, services
            and objectives. From time to time we may provide information on:<br />
            <ul>
              <li>a. projected revenues, income, earnings per share, capital expenditures, dividends, capital structure or other financial items;</li>
              <li>b. our plans, objectives and projections for future operations and services; and</li>
              <li>c. future economic performance.</li>
              <li>
                d. These will be estimates so actual events or results may differ. All information is provided "as is" and should not be treated as
                professional or investment advice of any kind. You should consult your own professional advisers before relying on any information on
                the communication system.
              </li>
            </ul>
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading"> 18. Information feeds</span>
          <span class="condition-desc"
            >We may use the services of other organisations to provide information on the communication system. We have no control over this
            information and make no representations or warranties of any nature as to its accuracy, appropriateness or correctness. You agree that
            such information is provided "as is" and we will not be directly or indirectly liable for any damages that may arise from you relying on
            it. All quotes, news and market information such as share prices or data shown on the communication system by way of live information
            feeds are delayed by at least 30 minutes unless otherwise stated. You should always select the "refresh" or similar page or screen update
            function on your Internet browser or handset to ensure that the information you are viewing is the most current.
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">19. Links to third parties' communication systems</span>
          <span class="condition-desc">
            The communication system may contain links to other communication systems that carry information and material produced by other parties.
            While we try to provide links only to reputable communication systems, we cannot accept responsibility for the information provided on
            other communication systems. A link from our communication system to any other communication system does not mean that we have scrutinised
            or endorsed the owners or administrators of such communication systems or their business or security practices and operations.
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">20. Our intellectual property</span>
          <span class="condition-desc">
            We retain all copyright and other intellectual property rights in all material, including logos and other graphics and multimedia works
            published on or via the communication system. You are authorised to view and download one copy to a local hard drive or disk, print and
            make copies of such printouts provided that:<br />
            <ul>
              <li>a. The material is used for considering or using electronic banking and for no other commercial purposes; and</li>
              <li>b. Any reproduction of any portion of our proprietary material must include our entire copyright notice.</li>
              <li>
                c. The logos and trademarks shown on our communication system are our registered and unregistered trademarks or those of third
                parties. Nothing contained on our communication system should be seen as granting any licence or right to use any trademark or other
                intellectual property without our prior written permission or that of the relevant third parties. Your application to use any content
                from our communication system must be submitted, in writing, to CCCBW@stanbic.com
              </li>
              <li>
                d. On receipt we will try to respond and enter into further discussions with you. Consider your request as declined if you don't get a
                written response from us within five business days. Irrespective of the existence of copyright, you acknowledge that we are the owner
                of all material on the communication system, whether it constitutes confidential information or not, and that you will have no right,
                title or interest in any such material.
              </li>
            </ul>
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading"> 21. Software</span>
          <span class="condition-desc"
            >You must use and maintain only hardware and software of sufficient quality and performance capability. Your failure to use such software
            or hardware may result in a higher security risk and cause the communication system not to operate properly or not at all. Software, if
            any, made available for download on or via the communication system is governed by licence conditions that establish a legal relationship
            with the licensor. You indemnify us against any breach of these licence conditions. We give no warranty and make no representation,
            whether expressly or implied, as to the quality or fitness for purpose or use of such software. No warranty, whether express or implied is
            given that any files, downloads or applications available via this communication system are free of viruses, trojans, bombs, time-locks or
            any other data or code that has the ability to corrupt or affect the operation of your computer, database, network or other information
            system.
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading"> 22. Transmission of information and security tips</span>
          <span class="condition-desc"
            >Information sent through an unsecured link over a communication system is susceptible to potential unlawful access, distortion or
            monitoring. You must comply with the security tips which are published on our website from time to time. As we do not have the ability to
            prevent unlawful activities by unscrupulous persons, you accept that we cannot be held liable for any loss, harm or damage suffered by you
            as a result thereof. To limit these risks, we may ask for independent verification of any information sent by you via our communication
            system from time to time.
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">23. Indemnity</span>
          <span class="condition-desc"> You indemnify us for all losses and costs we may incur on your behalf due to:</span> <br />
          <ul class="condition-desc" style="list-style-type: disc">
            <li>Non-payment;</li>
            <li>Any instruction or transaction exceeding the amount available in your bank account; or</li>
            <li>Unauthorised instructions executed by us that were not due to our negligence.</li>
          </ul>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">24. Unavailability of Self-Service banking</span>
          <span class="condition-desc"
            >We may upon reasonable notice to you terminate your Self Service Banking without giving any reasons thereof. Your Self Service Banking
            shall automatically terminate when your terminate your account with the Bank to which the Self-Service Banking relates. You acknowledge
            and accept that Self-Service Banking may be unavailable from time to time for any reason, including: technical failure or problems with
            the communication system itself or our communication system underlying banking systems (the bank system); technical failure or problems
            with a communication system directly or indirectly underlying Self-Service Banking that is owned or controlled by other persons (third
            party system); unavailability of telecommunication or electricity services; or other circumstances beyond our control . You undertake, in
            the event of unavailability of Self-Service Banking, to limit your potential losses by using any other means of communication with us for
            the duration of the unavailability of Self-Service Banking.
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading"> 25. Warranties and representations</span>
          <span class="condition-desc"
            >We do not warrant that the communication system or electronic banking will be error free or will meet any particular criteria of
            accuracy, completeness or reliability of information, performance or quality. We expressly disclaim all implied warranties, including,
            without limitation, warranties of merchantability, title, fitness for a particular purpose, non-infringement, compatibility, security and
            accuracy.
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">26. Disclaimer and limitation of liability</span>
          <span class="condition-desc">
            For purposes of this clause "we" or "us" or "our" includes the bank as well as its affiliates, shareholders, employees, consultants and
            agents. Although we have taken care to ensure that the content provided on the communication system is accurate and that you suffer no
            loss or damage as a result of you using it, the communication system and electronic banking are provided "as is". We are not liable for
            any damages relating to your use of the communication system or electronic banking. This includes the information contained on the
            communication system or your inability to use the communication system or electronic banking, including, without limitation, any direct,
            indirect, special, incidental or consequential damages, whether arising out of contract, law, delict or otherwise and regardless of
            whether we were expressly informed of the possibility of such loss or damage. Without derogating from the generality of the above, we are
            not liable for:</span
          >
          <ul>
            <li>a. any damages you suffer as a result of a compromise of your access codes.</li>
            <li>
              b. any interruption, malfunction, downtime or other failure of the communication system or electronic banking, our banking system,
              third-party system, databases or any component part thereof for whatever reason.
            </li>
            <li>
              c. any loss or damage which arises from your orders, investment decisions, purchases or disposal of goods and services, including
              financial instruments or currencies, from third parties based on the information provided on the communication system.
            </li>
            <li>
              d. any loss or damage with regard to your or any other data directly or indirectly caused by malfunction of our bank system, third-party
              systems, power failures, unlawful access to or theft of data, computer viruses or destructive code on the bank system or third-party
              systems; programming defects; negligence on our part.
            </li>
            <li>
              e. any interruption, malfunction, downtime or other failure of goods or services provided by third parties, including, without
              limitation, third-party systems such as the public switched telecommunication service providers, Internet service providers, electricity
              suppliers, local authorities and certification authorities; and
            </li>
            <li>f. any event over which we have no direct control.</li>
          </ul>
          <br />
          <!-- <span class="bullet-points">f.</span> any event over which we have no direct control.</span> -->
        </div>
        <div class="conditions">
          <span class="conditiom-heading"> 27. Breach</span>
          <span class="condition-desc"
            >We may terminate your access to electronic banking if you breach a material term of this agreement and fail to remedy it within five days
            of you being notified of it. We may do this without detracting from our right to take any other steps available to us at law or under this
            agreement.
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading"> 28. How disputes will be resolved</span>
          <span class="condition-desc"
            >All disputes arising as a result of your use of Self-Service Banking, the interpretation of this agreement or any matter which in terms
            of this agreement requires agreement by the parties may be obtained from a court of competent jurisdiction.
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">29. Capacity to enter into agreements</span>
          <span class="condition-desc">
            You warrant to us that you have the required legal capacity to enter into and be bound by this agreement. Anyone under the age of 18 must
            be assisted by their legal guardian when reading this agreement. If you are under 18 years of age you have to speak to your legal guardian
            or parents. If you are unsure whether you have the legal capacity to enter into agreements, you have to contact someone who is able to
            provide you with this information before you continue using electronic banking. Our Customer Care Centre will be able to help you with
            this.
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">30. Notifications</span>
          <span class="condition-desc">
            To prevent unnecessary disputes, you agree that unless you can prove otherwise, a notice signed by a manager whose appointment/capacity
            need not be proved or their representative is sufficient proof of the date of publication, withdrawal, transmission and content of:
            <ul>
              <li>a. the current version and all previous versions of this agreement,</li>
              <li>b. notices and disclaimers posted on the communication system, and</li>
              <li>c. notification sent under this agreement.</li>
            </ul></span
          >
        </div>
        <div class="conditions">
          <span class="conditiom-heading"> 31. Our address for notices and service of legal process</span>
          <span class="condition-desc"
            >For the purpose of service of any legal process we choose the following registered address: Personal and Business Banking, Stanbic Bank
            Botswana limited, Plot 50672, Off Machel Drive, Fairgrounds, Gaborone.
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">32. Law governing our relationship</span>
          <span class="condition-desc">
            This agreement will be governed and interpreted in accordance with the Republic of Botswana without reference to any conflict of law
            provisions.</span
          >
        </div>
        <div class="conditions">
          <span class="conditiom-heading"> 33. General provisions</span>
          <span class="condition-desc"
            >The headings of the clauses in this agreement are provided for convenience and ease of reference only and should not be used to
            interpret, modify or amplify this agreement. Where any dates or times need to be calculated in terms of this agreement, the international
            standard time (GMT) is used. Whenever disputes result from the peculiarities of the communication system, we both authorise a court or
            arbitrator, as the case may be, to interpret this agreement in such a manner as to facilitate normal banking without placing undue
            emphasis on technical issues. No failure or delay by us to exercise any of our rights is to be seen as a waiver of any such right, whether
            this is done expressly or is implied. It will also not affect the validity of any part of these conditions or prejudice our right to take
            subsequent action against you. If any of these terms, conditions or provisions are held to be invalid, unlawful or unenforceable to any
            extent; such term, condition or provision will be severed from the remaining terms, conditions and provisions, which will continue to be
            valid to the full extent allowed by law. If you have any questions about this agreement or do not understand anything in this agreement,
            please call our Customer Care Centre.
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="login" class="tc-boundary-border">
      <div class="conditiom-heading">Deposits</div>
      <span class="condition-desc">We will accept for deposit to your account all cash, cheques and the other items payable to you.</span>
      <div>
        <ul class="condition-desc" style="list-style-type: lower-alpha">
          <li>
            The proceeds of cheques and other similar items deposited will only be available as cash when paid. (This situation arises because when
            you deposit a cheque or other item the amount is provisionally credited to your account before we receive the actual payment).
          </li>
          <li>In the normal course, we are unable to process postdated cheques.</li>
        </ul>
      </div>
      <div class="conditiom-heading condition-heading-margin">Deposit reversed</div>
      <ul class="condition-desc" style="list-style-type: lower-alpha">
        <li>
          We will debit your account with the amount of any cheque or other item deposited that is unpaid. Your account will be debited with bank
          charges associated with these unpaid items, details of such charges are available on request.
        </li>
        <li>
          We will debit your account with the amount of any cheque or other item deposited to which you are not entitled and may and pay the amount to
          the owner thereof, whether your account is in credit or debit, and we will advise you of our action taken.
        </li>
      </ul>
      <div class="conditiom-heading condition-heading-margin">Payments</div>
      <ul class="condition-desc" style="list-style-type: lower-alpha">
        <li>We will make payments from your account on your instructions if there are sufficient funds available.</li>
        <li>
          By arrangement, you may instruct us by means of computer or other electronic equipment to make payments from your account and we will debit
          your account with the amounts concerned.
        </li>
        <li>You authorise the Bank to debit your account/s with legitimate amounts and related charges for all such payments.</li>
      </ul>
      <span class="condition-desc"
        >You agree to release and indemnify the Bank, its employees, officers or agents or its correspondent banks, from and against consequences of
        our or their failure to send or convey these funds and of any irregularities, delays, mistake, telegraphic error, ommission or
        misinterpretationhowevercaused, that may arise from and against any loss or damage which may be incurred throughour/their acting or delay in
        acting or omission to act on such instructions. You agree thatinstructionsprocessed will be at your own risk and cost in cipher or otherwise,
        it being understood that, at the Bank’s discretion, it may use the telephone, fax, other telegraphic service or any other recognizedtelephone
        or transmission system.</span
      >
      <span class="condition-desc"
        >You agree that in order to make some payments, the details of the payment (including any or allinformation relating to those involved in
        thepayment) may be sent abroad, where it could beaccessible by overseasregulators and authorities in connection with their legitimate
        duties.</span
      >
      <span class="condition-desc"
        >You understand that swift messages, internal transfers, telegraphictransfers, RTGS, and any other transfers are irreversible andacknowledge
        that the Bank account details provided are correct,complete and adequate and that the Bank its employees or officers oragents or
        correspondents will not be held liable for any losses or delayson the funds transmitted using these details.</span
      >
      <div class="conditiom-heading condition-heading-margin">Stop payments</div>
      <ul class="condition-desc" style="list-style-type: lower-alpha">
        <li>
          You may stop payment of a cheque you have issued before it is presentedfor payment unless the bank has made a commitment to pay it,
          forexample, certified it good for payment.
        </li>
        <li>
          We may acceptstop payments ofdebitorders butthe onus rests on you tocancel the underlying contract and you indemnify the Bank against
          anylegal action arising outofsuch cancellation.
        </li>
      </ul>
      <div class="conditiom-heading condition-heading-margin">Interest and charges</div>
      <ul class="condition-desc" style="list-style-type: lower-alpha">
        <li>We will charge you interest on any overdrawn balances and we shallinform you ofthe applicable rate of interest charged, upon request.</li>
        <li>We will charge you for various services provided, but details of suchcharges are available on request.</li>
        <li>
          We may vary charge and interest rates from time to time, but will give youreasonable notice to such changes before they come into effect.
        </li>
      </ul>
      <div class="conditiom-heading condition-heading-margin">Statements</div>
      <ul class="condition-desc" style="list-style-type: lower-alpha">
        <li>We will provide you with regularstatements of your account.</li>
        <li>You fail to advise us within 30 days of receipt of the statement, of any entryyou regard as incorrect.</li>
        <li>
          If you fail to notify us timeously offorged or unauthorised entries on your account and this results in losses taking place, we will be
          entitled to refuseto refund the losses to you, provided that we have not been negligent orbreached on our duty of care.
        </li>
      </ul>
      <div class="conditiom-heading condition-heading-margin">Overdrafts</div>
      <ul class="condition-desc" style="list-style-type: lower-alpha">
        <li>If your account is overdrawn without suitable arrangement, we may transfer/setoff moneyto itfrom any other accounts held by you.</li>
        <li>We may demand payment of all amounts by you at anytime.</li>
        <li>
          A certificate signed by a manager of our bank containing details of an amount, including interest, owed by you will be sufficient proof
          thereofunless the contrary is proved.
        </li>
        <li>
          We shall regard the latest postal or residential address given by you to usas the address where notices may be given and documents in
          legalproceedings may be served.
        </li>
        <li>If necessary, we maytake legalaction againstyou in an appropriate Courtof Law even if our claim exceeds its jurisdiction.</li>
        <li>
          You shall be responsible for payment of all our reasonable expenses in recovering any amounts you owe us, including legal fees on an
          attorneyand own client basis, collection fees and tracing fees or any other fees wemay incur as a resultofour efforts.
        </li>
      </ul>
      <div class="conditiom-heading condition-heading-margin">LetterofSet off</div>
      <span class="condition-desc"
        >In consideration ofus giving you financial and/or banking accommodation and other facilities, you agree that in addition to any other general
        lien or similarright to which we as bankers may be entitled by law, we may at any time andwithout notice to you combine or consolidate all or
        any of your accountswith/and liability to us and set off or transfer any sum or sums standing to the credit of any one or more of such
        accounts in or towards satisfaction of any of your liabilities to us on any other account or in any other respect whether suchliabilities be
        actual or contingent, primary or collateral and several or joint.</span
      >
      <div class="conditiom-heading condition-heading-margin">Chequebook</div>
      <span class="condition-desc"
        >You agree to look after and use any chequebook and any cheque form with theutmost care. You further agree to ensure
        <ul class="condition-desc" style="list-style-type: lower-alpha">
          <li>that all uncompleted cheque forms are kept in safe custody atall times.</li>
          <li>that we are informed immediately upon discovery by you that anycheque book or nay chequeform has been stolen, lost or mislaid;</li>
          <li>that any person preparing a cheque is authorised to do so;</li>
          <li>that any cheque is prepared and signed in ink or other indelible writingmaterial.</li>
          <li>. that the amount of the cheque is written in such a manner as to prevent any unathorised addition ofletters or figures;</li>
          <li>that any cheque and any alteration is signed by an authorised signatory;</li>
          <li>
            that no uncompleted cheque is given to any stranger orother person when you do not have reasonable grounds for believing that person to
            betrustworthy;
          </li>
          <li>
            Upon closure of any account you will return to us any remaininguncompleted cheque forms relating to that account and we will return toyou
            where possible the value of any Revenue Stamps reimbursement forthem which is obtained by us.
          </li>
          <li>
            You understand that the use and handling of your cheque book is subject to such arrangements as we may have with regards to the
            implementationof the Magnetic Ink Character Recognition system as coupled with theClearing House.
          </li>
        </ul>
      </span>
      <div class="conditiom-heading condition-heading-margin">Closing of account</div>
      <div>
        <ul class="condition-desc" style="list-style-type: lower-alpha">
          <li>
            We will close your account on receipt of a request in writing signed by you to do so, but the closure will not be effective until;
            <ul class="condition-desc" style="list-style-type: lower-roman">
              <li>have returned any unused chequesand bank cards;</li>
              <li>all cheques or otheritems deposited havebeen paid; and</li>
              <li>
                all your electronic transactions have been settled. Should any of yourelectronic transactions onlybe settled after closure of your
                account, you shall be liable forthe costs thereof.
              </li>
            </ul>
          </li>
          <li>
            The Bank shall be entitled to close your account on reasonable prior notice and shall not be obliged to give reasons for such action.
          </li>
        </ul>
      </div>
      <div class="conditiom-heading condition-heading-margin">Credit record</div>
      <div>
        <ul class="condition-desc" style="list-style-type: lower-alpha">
          <li>We may make enquiries about your record with any credit referenceagency or any other relevant parties.</li>
          <li>
            We may provide credit reference agencies with regular or any otherrelevant parties updates regarding the conduct of your account
            includingany failure on your partto meetthese terms and conditions.
          </li>
          <li>We may provide other banks with bank reports relating to the conduct ofyour account on their request.</li>
        </ul>
      </div>
      <div class="conditiom-heading condition-heading-margin">Confidentiality</div>
      <div class="condition-desc">
        We will treat all your personal information as private and confidential (evenwhen you are no longer a customer).
      </div>
      <div class="condition-desc">
        Nothing about your accounts nor your name and address will be disclosed to anyone, otherthan in fourexceptional circumstances permitted by
        law. Theseare:
      </div>
      <div>
        <ul class="condition-desc" style="list-style-type: lower-alpha">
          <li>where we are legally compelled to do so;</li>
          <li>where it is in the public interestto disclose;</li>
          <li>where our interests require disclosure;</li>
          <li>where disclosure is made atyour requestorwith yourwritten consent.</li>
        </ul>
      </div>
      <div class="conditiom-heading condition-heading-margin">General</div>
      <div>
        <ul class="condition-desc" style="list-style-type: lower-alpha">
          <li>
            We may check by reference to third parties the correctness of details given in the application form you have completed for opening the
            account.
          </li>
          <li>Bank accounts may notbe ceded to any other party.</li>
          <li>
            You must notify us immediately if you are placed under an administrationorder, sequestrated or liquidated or placed under any other form
            ofinsolvency or legal disability.
          </li>
          <li>
            You must need to notify us immediately of any change of address.
            <ul class="condition-desc" style="list-style-type: lower-roman">
              <li>
                We are entitled from time to time to request updated confirmation documents relating to Anti Money Laundering, Anti-TerrorismFinancing
                and all the required account opening documents and youagree to provide us with currentones in the premises of clause 13.4.
              </li>
              <li>We will not be held liable for losses arising from unauthorised alterations to chequeswhich are not readily detectable.</li>
              <li>
                You consent to us carrying out identity and fraud prevention checksand sharing information relating to this application with a
                financialclearance bureau or law enforcement agency. Should your accountconduct at any time in the future reasonably cause us to
                suspect thatyour accounts are being used for improper purposes, you consent tous providing details ofthis suspicion to the
                aforementioned agencies.
              </li>
            </ul>
          </li>
        </ul>
        <div class="condition-desc">
          You understand and agree that the record of this suspicion will then be available to other members of these agencies if they carry
          outcredit/other checks on your name.
        </div>
      </div>
      <div class="conditiom-heading condition-heading-margin">Governing Law</div>
      <div class="condition-desc">The relationship between the Bank and you the Customer is governed by thelaws of Zimbabwe</div>
    </div>
    <div *ngIf="viewMvpTcFirst" class="tc-boundary-border">
      <div class="conditiom-heading condition-heading-margin">Definitions</div>
      <div>
        <ul class="condition-desc" style="list-style-type: lower-alpha">
          <li>“account” means the loan account opened in our books in respect of this agreement;</li>

          <li>
            “agreement” means the loan application form, the Facility Letter, additional information forms and the letter of offer read together with
            these terms and conditions and all payments, instructions, letters, notices and appendices hereto;
          </li>

          <li>“applicant” “borrower”, “you” or “your” means the person applying for the loan in the loan application;</li>

          <li>“bank”, “we”, “us” or “our” means Stanbic Bank Botswana Limited, its successors in title or assigns:</li>

          <li>“business days” mean any days other than a Saturday, Sunday or a public holiday in Botswana.</li>

          <li>
            current account” means an active account into and from which deposits and withdrawals can be made by way of cheques, bills, repayment
            authorisations or any of our self-service channels;
          </li>

          <li>“loan” means the amount we have agreed to lend you in terms of this agreement:</li>

          <li>“margin” means the number of percentage points interest charged by us above or below the base interest rate:</li>

          <li>“minimum repayment” means the minimum instalment due under the loan as advised in the letter of offer:</li>

          <li>“payment date” or “due date”: means the due date for payment of all amounts due and payable as advised in the letter of offer</li>

          <li>“repayment” means a payment made by you to us to pay off your loan:</li>

          <li>
            “Repayment schedule” means the repayment schedule annexed to these terms setting out the total monthly instalments due under the loans:
          </li>

          <li>
            “limit”, “agreed limit”, “credit limit” or “reduced credit limit” means the amount of the loan that is available for use by you in terms
            of this agreement:
          </li>
        </ul>
      </div>

      <div class="conditiom-heading condition-heading-margin">1. Repayment</div>
      <div>
        <ul class="condition-desc" style="list-style-type: lower-alpha">
          <li>1.1 Loan repayments will be recovered from an account held with the bank</li>

          <li>
            1.2 All payments by you, whether of the principal amount, interest, fees, costs, charges or otherwise, will be made in fully cleared
            funds, without set off or counterclaim, and free and clear of any deduction or withholding on account of tax or otherwise.
          </li>

          <li>
            1.3 In all instances the onus is on you to ensure that sufficient funds are available to meet the repayments as indicated on the date
            specified in the repayment schedule.
          </li>

          <li>
            1.4 If you fail to pay the minimum repayment amount of any stipulated monthly instalment by its due date in full or at all, we may cause
            such amount or the shortfall, as the case may be, to be transferred to your account from any other account you hold with us and any
            insufficient payment instruction then held by us will be deemed to have been amended accordingly.
          </li>

          <li>1.5 You authorise us to debit any other account you held with us on any date we deem necessary to collect any repayments due.</li>

          <li>1.6 Payments of the loan over and above the monthly instalments may be made by you at any time without notice.</li>

          <li>
            1.7 If you make an early reduction of the loan, unless the repayment instalments as stipulated in the repayment schedule are rescheduled
            by written agreement between you and us, this early reduction will not affect your obligations to continue paying instalments as
            stipulated in the repayment schedule until the loan, including all interest thereon, has been repaid in full.
          </li>

          <li>1.8 You may wish to repay the outstanding balance of the loan, including accrued interest, in full before the loan’s maturity date.</li>

          <li>
            1.9 In the event of partial or full repayment in terms of clauses 1.7 or 1.8 you will be liable to pay any costs and/or losses incurred by
            us in connection with such repayment or such reduction.
          </li>

          <li>
            1.10 Notwithstanding that we may allow you to reduce the outstanding balance on your loan in monthly instalments as set out in the
            repayment schedule as may be amended from time to time, the monthly instalment payable by you does not imply that the said loan will be
            repaid in full over its initial term, as a result of redraws and/or subsequent deposits made into the loan account and/or fluctuations in
            the interest rate applicable from time to time. All payments made under this loan will be credited by us on date of receipt as follows.
          </li>

          <li>1.10.1 Firstly, to satisfy any due or unpaid interest charges;</li>

          <li>1.10.2 secondly, to satisfy any due or unpaid fees or charges; and</li>

          <li>1.10.3 thirdly, to reduce the amount of the principal debt.</li>

          <li>
            1.11 You will not be able to draw against certain deposits (for example bills, cheques and debit orders) to your account until they have
            been duly and legally paid, even if your account has already been credited. Such funds are subject to the Bank’s clearing period.
          </li>

          <li>
            1.12 The bank requires all loans to have adequate loan protection cover insurance. The Bank will effect the cover in order to secure the
            loan in event of death or permanent disability of the borrower, until such time as the loan is settled in full. The cost of the loan
            protection shall at the bank’s sole discretion either be deducted from the loan amount upon disbursement or included in the monthly
            instalments. The Loan Protection Benefit remains valid during the period of the Borrower’s full compliance with these General terms and
            conditions. A copy of the Loan Protection terms and condition is available on request.
          </li>
        </ul>
      </div>

      <div class="conditiom-heading condition-heading-margin">2. Interest</div>
      <div>
        <ul class="condition-desc" style="list-style-type: lower-alpha">
          <li>2.1 Interest payable on the loan will:</li>

          <li>
            2.1.1 be calculated on the basis of a 365-day year for loans denominated in Botswana Pula and a 360-day year for loan denominated in
            foreign currency, irrespective of whether or not the year in question is a leap year;
          </li>

          <li>2.1.2 accrue from day to day; and</li>

          <li>
            2.1.3 be calculated on the daily balance owing under the loan facility, notwithstanding that such balance may have increased by being
            debited with interest thereon;
          </li>

          <li>2.1.4 be debited to your loan account monthly in arrears.</li>

          <li>
            2.2 Rates of interest quoted or determined initially or subsequently altered by us apply to the use of loans within the limit(s) agreed to
            by us in writing. Any indebtedness to us in excess of the limit(s) agreed by us in writing may be subject to interest at such higher
            rate(s) as we may determine at our discretion at the time when such indebtedness is incurred and/or at any time thereafter. Advising this
            rate does not constitute an agreement by us to allow borrowing in excess of the approved loan limits.
          </li>

          <li>
            2.3 If any sum payable by you under any of these loans is not paid when due, such sum will attract interest at a rate to be determined by
            us from time to time as we may deem fit, from the date on which such sum fell due to the date on which it is actually paid.
          </li>

          <li>
            2.4 The interest charged under this loan an aggregate of the Bank of Botswana rate plus the margin to be determined by the Bank from time
            to time. We may, from time to time, vary the interest rate applicable to your loan if interest rate fluctuates during the agreed term of
            the loan. If we elect to do so, notice will be given to you through print media, notices in the branches and electronic channels. However,
            any delay or omission in providing the advice will not invalidate such amendment.
          </li>

          <li>
            2.5 The rate of interest payable as of the date that the loan is made available to you is set out in the repayment schedule as may be
            amended from time to time and to which these terms and conditions are attached.
          </li>

          <li>
            2.6 We reserve the right to vary the rate of interest payable on revolving/redraw of the revolving term loan in line with market
            conditions or if in our opinion the conduct on your account increases out risk regarding the debt, provided that this amended rate does
            not exceed the legal maximum permissible rate, where applicable. If we elect to do so notice will be given to you through print media,
            notices in the branches and electronic channels.
          </li>
        </ul>
      </div>

      <div class="conditiom-heading condition-heading-margin">3 Changes in the law</div>
      <div>
        <ul class="condition-desc" style="list-style-type: lower-alpha">
          <li>
            3.1 We may immediately amend the pricing structure of the loan if there is any change in law, statute, regulation, ruling, directive,
            policy or any similar event with which we are obliged to comply resulting in an increase in cost to us. We will notify you through various
            methods, this include but not limited to, the media, notices on ATM’s and inside our branches, and any other means that we may deem
            necessary from time to time.
          </li>
        </ul>
      </div>
      <div class="conditiom-heading condition-heading-margin">4 Certificate of amount owing</div>
      <div>
        <ul class="condition-desc" style="list-style-type: lower-alpha">
          <li>
            4.1 A certificate signed by any of our managers or officers whose appointment and authority to sign need not be proved, as to the amount
            of the indebtedness at any time and that such indebtedness (including any interest and the rate. and the period for which interest is
            accruable) is due, by you in respect of the loan or any other amount will be proof of the facts stated therein.
          </li>
        </ul>
      </div>
      <div class="conditiom-heading condition-heading-margin">5 Income</div>
      <div>
        <ul class="condition-desc" style="list-style-type: lower-alpha">
          <li>5.1 You undertake to maintain a current account with us into which your monthly business turnover will be deposited.</li>

          <li>
            5.2 You irrevocably authorise us to debit the account with the monthly/weekly instalments referred to in the repayment schedule as may be
            amended from time to time and in the loan agreement together with any monthly bank fees and charges incidental thereto. Such instructions
            will remain standing for the term of the loan and until we authorise cancellation.
          </li>

          <li>
            5.3 During the term of this loan, you undertake that you will not issue any contra payment instructions without our prior written consent.
          </li>
        </ul>
      </div>

      <div class="conditiom-heading condition-heading-margin">6 Suspension and termination</div>
      <div>
        <ul class="condition-desc" style="list-style-type: lower-alpha">
          <li>
            6.1 The limit of the loan may be suspended and/or terminated by us at our sole discretion, on written notice to you, in which event the
            loan will be cancelled and become due and payable:
          </li>

          <li>6.1.1 immediately, if stated in the notice; or</li>

          <li>6.1.2 on the date stated in the notice.</li>

          <li>
            6.2 All or part of your credit limit or reduced credit limit (if applicable) may be withdrawn by us on written notice to you, whether you
            are in default or not, in which event all amounts in excess of the reduced limits will immediately become due and payable.
          </li>
        </ul>
      </div>
      <div class="conditiom-heading condition-heading-margin">7 Default</div>
      <div>
        <ul class="condition-desc" style="list-style-type: lower-alpha">
          <li>
            7.1 We will not be obliged to make any transfer or revolve any amount under the loan and may convert your loan to one repayable on demand
            and/or we may revise any of the terms and conditions and/or increase the interest rate charged if any of the following events occur:
          </li>

          <li>
            7.1.1 you breach any of the terms and conditions of your loan or any other agreement between you and us and fail to remedy the breach
            within 7 (seven) days of written notice having been given to you to do so: or 7.1.2 you fail to pay any instalment due in terms of this
            agreement: or
          </li>

          <li>
            7.1.3 we are of the opinion that there has been a material deterioration in your financial position or the conduct of your transactional
            account: or
          </li>

          <li>
            7.1.4 any representation or warranty made in connection with your loan or any documents supplied by you is, in our opinion, incorrect,
            incomplete or misleading: or proceedings are initiated to declare you and/or any guarantor bankrupt or a provisional or final order is
            passed declaring you and/or any guarantor bankrupt: or
          </li>

          <li>7.1.5 any compromise or arrangement between you and your creditors is sanctioned or otherwise becomes effective: or</li>

          <li>
            7.1.6 a writ of execution issued by any competent court, body, authority or tribunal attaching any of your and/or any guarantor’s assets
            and is not discharged or stayed within 30 (thirty) days of service by the relevant officer of the court, body, authority or tribunal of
            such writ, notice or other order, or
          </li>

          <li>
            7.1.7 if at any time, the amount outstanding on your loan exceeds its maximum aggregate limit; or any guarantor, in respect of your
            indebtedness to us, commits any breach of their obligations to us, whether as guarantor or otherwise; or
          </li>

          <li>
            7.1.8 if any guarantor in respect of your indebtedness to us delivers a valid and effective notice of termination of liability under
            surety or guarantee; or
          </li>

          <li>
            7.1.9 if any security for any of your indebtedness to us becomes enforceable or subject to seizure by a court or a government body or an
            authority, whether such security is enforced or not; or
          </li>

          <li>7.1.10 full repayment of your loan and non-use of the facility for more than 3 (three) months; or</li>

          <li>
            7.1.11 you generally do or omit to do anything which may affect our rights in terms of the loan or cause us to suffer any loss or damage;
            or
          </li>

          <li>7.1.12 you fail to provide any additional security when so requested and/or called on by us.</li>

          <li>
            7.2 If a default occurs then, the full amount of your loan and any other facilities accorded to you by us, then outstanding, and all
            charges accrued thereon, together with any interest as defined under this loan and these attached general terms and conditions will
            immediately become due and payable.
          </li>

          <li>7.3 In addition, we will have the right to exercise all other remedies available to us in terms of the laws of Botswana .</li>
        </ul>
      </div>

      <div class="conditiom-heading condition-heading-margin">8 Set-off and realisation</div>
      <div>
        <ul class="condition-desc" style="list-style-type: lower-alpha">
          <li>
            8.1 In the event of default, we may at any time without further notice to you, and notwithstanding any settlement of amounts or other
            matter whatsoever, consolidate or combine all or any of your existing accounts with us, including those held in our name wherever situate
            (whether current, deposit, loan or any other accounts of any nature whatsoever whether subject to notice or not) and set off or transfer
            any sum standing to the credit of any one or more accounts in or toward satisfaction of any obligations or indebtedness to us, whether
            those liabilities be present, future, actual, contingent, security, joint or several. You waive any rights of set off that you may have,
            so far as is permitted by law.
          </li>

          <li>
            8.2 Any security provided may be, if realised, used to pay any indebtedness by you to us, at our sole discretion notwithstanding that such
            indebtedness may be expressed in a currency other than the currency received by us in realisation thereof.
          </li>
        </ul>
      </div>
      <div class="conditiom-heading condition-heading-margin">9 Costs</div>
      <div>
        <ul class="condition-desc" style="list-style-type: lower-alpha">
          <li>
            9.1 You irrevocably authorise us to debit your current/transactional account and recover (whether or not the loan is drawn) all legal
            fees, charges and other but of-pocket expenses (including registration or lodgement fees and value added tax) incurred by us in connection
            with the creation or any revaluations of security (if any) or the enforcement or preservation by us of our rights under this agreement or
            under any amendments hereto
          </li>

          <li>
            9.2 You will pay all the fees, costs and charges referred to in this agreement and all fees, costs, charges, taxes and duties we may incur
            or pay in connection with the preparation, conclusion or enforcement of the loan, including:
          </li>

          <li>
            9.2.1 Iegal costs, charges, collection commission and other fees or disbursements incidental thereto or incurred in recovering or
            endeavouring to recover all or any amounts owing us, together with value added tax, where applicable;
          </li>

          <li>9.2.2 any increase in fees, costs and charges as determined and published from time to time;</li>

          <li>
            9.2.3 all other fees and charges will be payable in accordance with our standard tariffs from time to time. The applicable rate can be
            provided to you on request;
          </li>

          <li>9.2.4 an upfront processing fee for the loan is chargeable and will be debited to your current account.</li>

          <li>9.2.5 an upfront management fee for the loan is chargeable and will be debited to your current account.</li>

          <li>
            9.3 the Borrower shall pay all costs relating to the preparation and registration of the Collateral or Security Agreement, including stamp
            duty if applicable, and shall also pay for the costs of discharge of the collateral; and
          </li>

          <li>
            9.4 the Borrower shall pay all costs and expenses of any nature (including legal costs) incurred by the Bank in taking steps to exercise
            or enforce its rights under the loan agreement and any collateral given directly or indirectly in respect of the Borrower's obligations
            under the loan agreement.
          </li>
        </ul>
      </div>
      <div class="conditiom-heading condition-heading-margin">10 Variation</div>
      <div>
        <ul class="condition-desc" style="list-style-type: lower-alpha">
          <li>
            10.1 We may, at our discretion, vary any of the terms and conditions from time to time on written notice to you. No such variation and no
            agreement inconsistent therewith will be of any force or effect unless it is recorded in writing and signed by both you and us.
          </li>

          <li>
            10.2 In the event of any legislation or extreme changes in market conditions affecting the Bank`s ability to provide or fund loans in
            foreign currency, the Bank reserves the right. at its sole discretion, to switch any foreign currency indebtedness of the applicant into
            Botswana Pula and the Bank will not be liable for any losses resulting from exchange rate fluctuations.
          </li>
        </ul>
      </div>
      <div class="conditiom-heading condition-heading-margin">11 Force majeure (events beyond our control)</div>
      <div>
        <ul class="condition-desc" style="list-style-type: lower-alpha">
          <li>
            11.1 We will not be liable for any failure to perform our obligations herein caused by reasons beyond our control or resulting directly or
            indirectly from the action or Inaction of the government, any government authority, riot, strike, boycott, blockade, act of God,
            revolution, civil strike, change in legislation or extreme change in market conditions.
          </li>
        </ul>
      </div>
      <div class="conditiom-heading condition-heading-margin">12 Assignment</div>
      <div>
        <ul class="condition-desc" style="list-style-type: lower-alpha">
          <li>
            12.1 You will not be entitled to assign all or any part of your rights, obligations or benefits hereunder without our prior written
            consent, which will not be unreasonably withheld.
          </li>

          <li>
            12.2 We will be entitled, without your consent or notice to you, to cede and/or delegate all or any part of our rights and or obligations
            under the loan and/or the security, either absolutely or as collateral security to any person (even though that cession and/or delegation
            may result in a splitting of claims against you).
          </li>

          <li>
            12.2.1 Even if your loan facility and/or security is sold or ceded, you acknowledge that the servicing and/or administration of such loan
            facility and/or security may still be performed by us on behalf of the purchaser/cessionary or
          </li>

          <li>12.2.2 cedent be contractually managed by a third party on behalf of the purchaser/cessionary or cedent; or</li>

          <li>
            12.2.3 be performed by the purchaser/cessionary or cedent itself. These services and administrations can include the giving of notices,
            realisation of any security and the recovery of amounts due under the loan agreement.
          </li>

          <li>
            12.3 Even if the loan facility (or any part thereof) is sold, ceded and/or delegated to any person (the transferee). you acknowledge that
            we may elect not to cede or sell our rights under the security and/or any other security (relevant security) to the transferee such that,
            notwithstanding the sale, session and/or delegation of the loan facility (or any part thereof) to the transferee, the relevant security
            will as between us (or our successors in title or assigns under the relevant security) and you, continue to cover your indebtedness to us
            (or our successors in title or assigns under the relevant collateral) on the terms set out in the relevant security documents.
          </li>
        </ul>
      </div>

      <div class="conditiom-heading condition-heading-margin">13 General undertakings</div>
      <div>
        <ul class="condition-desc" style="list-style-type: lower-alpha">
          <li>13.1 While the loans remain available, you undertake to ensure that:</li>

          <li>
            13.1.1 your obligations in respect of the loans will at all times rank at least equal with all your other present and future unsecured
            obligations;
          </li>

          <li>
            13.1.2 you will, immediately on becoming aware of it, notify us of any material litigation, arbitration or administrative proceedings
            pending or, to the best of your knowledge, information threatened against you which may adversely affect your ability to honour any of the
            terms and conditions in this agreement.
          </li>

          <li>13.2 You agree that we may;</li>

          <li>
            13.2.1 hold and process, by computer or otherwise, any information obtained about you as a consequence of the loan contemplated in this
            application;
          </li>

          <li>
            13.2.2 include personal data in our systems which may be accessed by other companies in our group for credit assessment, statistical
            analysis, including behaviour and scoring, and to identify products and services (including those supplied by third parties) which may be
            relevant to you: and
          </li>

          <li>
            13.2.3 permit other companies within our group to use personal data and any other information we hold about you to bring to your attention
            to products and services which may be of interest to you.
          </li>

          <li>
            13.3 You further agree that we may disclose your personal data and/or information, including data and information relating to this loan
            and any documents referred to herein, or your assets, business or affairs outside our group whether such personal data and/or information
            is obtained after you cease being a customer or during the continuance of the bank-customer relationship or before such relationship was
            in contemplation:
          </li>

          <li>13.3.1 for fraud prevention purposes;</li>

          <li>
            13.3.2 to licensed credit reference agencies or any other creditor as required by law or if you are in breach of this agreement or any
            documents referred to herein;
          </li>

          <li>13.3.3 to our external lawyers, auditors and other sub-contractors or persons acting as our agents;</li>

          <li>13.3.4 to any person who may assume our rights under this loan;</li>

          <li>13.3.5 if we have a right or duty to disclose or are permitted or compelled to do so by law; and</li>

          <li>
            13.3.6 for the purpose of exercising any power, remedy, right, authority or discretion relevant to this agreement or any other document.
          </li>

          <li>
            13.4 You acknowledge and agree that, notwithstanding the terms of any other agreement between you and us, a disclosure of information by
            us in the circumstances contemplated by this clause does not violate any duty to you, either in common law or pursuant to any agreement
            between you and us, or in the ordinary course of banking business and the customs, usage and practice related thereto. Disclosure as
            aforesaid may be made without reference to or further authority from you and without inquiry by us as to the justification for or validity
            of such disclosure.
          </li>
        </ul>
      </div>
      <div class="conditiom-heading condition-heading-margin">14 Collateral</div>
      <div>
        <ul class="condition-desc" style="list-style-type: lower-alpha">
          <li>14.1 The provision of and the aggregate of all amounts deemed by us to be outstanding under the loan will be on a secured basis.</li>

          <li>
            14.2 The initial collateral required by the Bank in respect of the loan (including any debt related to the loan) is set out in the Special
            Conditions annexure to the Facility Letter (which collateral shall, if required by the Bank, include an indemnity by the Borrower).
          </li>

          <li>
            14.3 If the value of any collateral for the loan is no longer adequate or the Borrower’s management of the Borrower’s accounts increases
            the Bank’s risk regarding the loan or any other amount owing to the Bank in terms of any other agreement, the Bank will have the right to
            ask the Borrower to provide the Bank with additional collateral to secure repayment of the debt, vary the terms and conditions of the loan
            agreement and/or to increase the interest rate above the rate currently being charged.
          </li>

          <li>
            14.4 Any collateral required by the Borrower in respect of the loan shall not prejudice any other collateral or preference of whatever
            kind in existence in favour of the Bank.
          </li>

          <li>
            14.5 We may, from time to time, require any asset over which we hold a security to be valued, at your expense, by a valuer approved by us.
            We reserve the right to instruct a valuer of our choice and to debit your account or accounts held with us with any expenses incurred.
          </li>
        </ul>
      </div>
      <div class="conditiom-heading condition-heading-margin">15 Address and Notices</div>
      <div>
        <ul class="condition-desc" style="list-style-type: lower-alpha">
          <li>
            15.1 The parties choose the addresses set out in the application as the street addresses at which you or us, as the case may be, will
            accept delivery of legal notices (the notice address). Should either party wish to change its notice address, the other party must be
            notified in writing, and this notice must be hand delivered or sent by registered post.
          </li>

          <li>
            15.2 All other notices or communications required or permitted to be given in respect of the previsions of this loan will be valid only if
            in writing and sent to either party`s notice or postal address provided in the application or any changed address advised in terms of this
            clause, provided that any documents to be delivered in respect of legal proceedings in connection with this loan may only be served at the
            parties notice address.
          </li>

          <li>15.3 Any notice:</li>

          <li>15.3.1 sent by prepaid registered post will be deemed to have been received on the 5th (fifth) business day after posting; or</li>

          <li>15.3.2 sent by ordinary mail will be deemed to have been received on the 7th (seventh) business day after posting; or</li>

          <li>15.3.3 delivered by hand will be deemed to have been received on the day of delivery;</li>
        </ul>
      </div>

      <div class="conditiom-heading condition-heading-margin">16 Statements</div>
      <div>
        <ul class="condition-desc" style="list-style-type: lower-alpha">
          <li>
            16.1 We will provide you with a statement of account periodically and in the frequency and medium selected by you during your application
            for the loan.
          </li>

          <li>
            16.2 The statement will show your outstanding balance and, where applicable, the minimum repayment owing to us and the date by which it is
            to be made.
          </li>

          <li>16.3 We may adjust debits and credit your account and account balances to reflect both your and our legal obligations correctly.</li>
        </ul>
      </div>

      <div class="conditiom-heading condition-heading-margin">17 General</div>
      <div>
        <ul class="condition-desc" style="list-style-type: lower-alpha">
          <li>
            17.1 In these terms and conditions, unless inconsistent with the content, words signifying the singular includes the plural and vice
            versa.
          </li>

          <li>
            17.2 Each provision of these terms and conditions is severable, the one from the other. If at any time any provision is or becomes or is
            found to be illegal, invalid, detective or unenforceable for any reason by any competent court, the remaining provisions will remain valid
            and enforceable.
          </li>

          <li>
            17.3 The agreement created on acceptance of the loan by you will constitute the whole agreement between you and us relating to the subject
            matter of the loan. No addition to, variation, amendment or consensual cancellation of any of the terms contained in the loan will be of
            any force or effect unless it is recorded in writing and is signed on our behalf by one of our authorised officials and accepted by you.
            Unless specifically stated and agreed, any amendment to the terms and conditions of this loan will not create a new loan.
          </li>

          <li>
            17.4 No indulgence shown or extension of time given by us will operate as an estoppel against us or waiver of any of our rights unless
            recorded in writing and signed by us.
          </li>

          <li>
            17.5 We will not be bound by any express or implied term, representation, warranty, promise or the like not recorded herein, whether it
            induced the conclusion of any agreement and/or whether it was negligent or not.
          </li>

          <li>
            17.6 This loan will be governed and construed in accordance with the laws of Botswana and the courts of Botswana will have jurisdiction to
            settle any disputes which may arise in connection with it without prejudice to our exclusive right to institute proceedings against you in
            respect thereof in any other jurisdiction. If the due date for any repayment or the charging of interest, cost, fees or charges does not
            fall on a business day, the item will be processed on the first business day thereafter.
          </li>
        </ul>
      </div>
      <div class="conditiom-heading condition-heading-margin">18 Sanctions</div>
      <div>
        <ul class="condition-desc" style="list-style-type: lower-alpha">
          <li>
            18.1 The Borrower warrants to the Bank on a continuing basis that it does not to the best of its knowledge participate in any Sanctioned
            Transactions, are not a Sanctioned Entity or a target of Sanctions or listed on any Sanctions List and are compliant with any applicable
            anti-bribery laws. The Borrower will notify the Bank as soon as reasonably possible after becoming aware that it or any related affiliate
            or entity is subject of Sanctions. The Borrower further acknowledges that the Bank shall be entitled to terminate the Loan product or any
            other facility from the Bank should the Borrower become Sanctioned. The Borrower agrees that the Bank shall be indemnified all costs and
            expenses that could arise as a result of the Borrower being Sanctioned;
          </li>

          <li>18.2 For purposes of these representations:</li>

          <li>
            18.2.1 “Sanctions” means restrictive financial or economic limitations imposed by some governments or supranational organisations such as
            the United Nations.
          </li>

          <li>18.2.2 “Sanctioned Entity” means an entity that:</li>

          <li>(a) is listed in any sanction list; and/or</li>

          <li>
            (b) is subject to some form of financial or economic limitations, or in respect of which there is some form of financial or economic
            limitation on other parties dealing with it, in terms of the applicable law; and/or
          </li>

          <li>(c) is located or incorporated in a Sanctioned Jurisdiction; and/or</li>

          <li>(d) is owned or controlled by an entity that is located or incorporated in a Sanctioned Jurisdiction; and/or</li>

          <li>(e) undertakes significant business activity in a sanctioned jurisdiction;</li>

          <li>18.2.3 “Sanctioned Jurisdiction” means a country or territory:</li>

          <li>(a) that is listed in a sanction list; and/or</li>

          <li>
            (b) in respect of which there is some form of financial or economic limitation on other persons or countries dealing with or making
            payments or deliveries to or receiving payments or deliveries from such country or territory, in terms of the applicable law;
          </li>

          <li>
            18.2.4 “Sanctions List” includes but is not limited to any of the sanctions lists of (a) HM Treasury in the United Kingdom of Britain and
            Northern Ireland, (b) the United States of America Office of Foreign Asset Control, (c) the United Nations Security Council, (d) the
            European Union; or such other similar list, each such list as amended, supplemented or substituted from time to time.
          </li>

          <li>
            18.2.5 “Sanctioned Transaction” means any payment, receipt or delivery of cash or assets to or from an entity that is a sanctioned entity
            or is located within a sanctioned jurisdiction
          </li>
        </ul>
      </div>
      <div class="conditiom-heading condition-heading-margin">19 Credit Reference Agencies</div>
      <div>
        <ul class="condition-desc" style="list-style-type: lower-alpha">
          <li>19.1 The Borrower consents to the Bank:</li>

          <li>
            19.1.1 making enquiries about their credit record with any credit reference agency and any other party to confirm the information provided
            by them;
          </li>

          <li>
            19.1.2 providing regular updates regarding the conduct of their accounts to the credit reference agencies and allowing the credit
            reference agencies to in turn make the record and details available to other credit grantors; and
          </li>

          <li>
            19.1.3 listing the Borrower's details with any credit reference agency as required by law or should the Borrower default on their
            repayment obligations to the Bank
          </li>
        </ul>
      </div>
    </div>
    <div *ngIf="internationPay" class="tc-boundary-border">
      <div class="conditions tc-wrap">
        <span class="conditiom-heading">1. Introduction</span>
        <ul class="condition-pay-international">
          <li>
            1.1 These Terms form an agreement between you and us. These Terms apply together with the Digital Banking Terms, including the definitions
            set out therein. Please make sure that you understand these Terms.
          </li>
          <li>
            1.2 In addition to these terms and the Digital Banking Terms, please note that you are also bound by Stanbic Bank’s general terms and
            conditions, and any applicable transactional or product terms and conditions, which can be found at <a
              href="https://www.stanbicbank.co.bw/"
              class="need-help-mail ip-web-link"
              target="_blank"
              >www.stanbicbank.co.bw.</a
            >
          </li>
          <li>
            1.3 Clauses which are important or which may limit our responsibility or involve some risk for you, will be in bold. You must pay special
            attention to these clauses.
          </li>
        </ul>
      </div>
      <div class="conditions tc-wrap">
        <span class="conditiom-heading">2. Definitions</span>
        <ul class="condition-pay-international">
          Singular words include the plural and plural words include the singular.
          <li>
            <span class="condition-pay-international-text">Account : </span
            ><span class="condition-desc"
              >A transactional account which can be used to make an International Payment and includes accounts such as cheque/current accounts and
              savings accounts with transactional capabilities.</span
            >
          </li>
          <li>
            <span class="condition-pay-international-text">Applicable Laws : </span
            ><span class="condition-desc"
              >All national, provincial, local and municipal laws, regulations, statutes, by-laws, consents and/or other laws of any relevant
              governmental authority and any other instrument having the force of law as may be issued and in force from time to time relating to or
              connected with the activities contemplated under these Terms.</span
            >
          </li>
          <li>
            <span class="condition-pay-international-text">Business Day : </span
            ><span class="condition-desc"
              >Any day other than a Saturday, Sunday or official public holiday in:
              <ul class="condition-pay-international tc-international-pay" style="list-style-type: disc">
                <li>the Republic of Botswana;</li>
                <li>the country in which the applicable foreign bank and Beneficiary receiving the International Payment are based; and/or</li>
                <li>any jurisdiction which is relevant to the International Payment, whether in terms of currency conversion or otherwise.</li>
              </ul></span
            >
          </li>
          <li><span class="condition-pay-international-text">Central Bank : </span><span class="condition-desc">Bank of Botswana</span></li>
          `
          <li>
            <span class="condition-pay-international-text">Correspondent Bank : </span
            ><span class="condition-desc">A financial institution that provides services on behalf of another financial institution.</span>
          </li>
          <li>
            <span class="condition-pay-international-text">Electronic Banking Terms : </span
            ><span class="condition-desc"
              >The Electronic Banking Terms and Conditions which Stanbic Bank issues from time to time for the use of its Mobile and Online Banking
              Services.</span
            >
          </li>
          <li>
            <span class="condition-pay-international-text">Exchange Rate : </span
            ><span class="condition-desc"
              >The rate of exchange that we determine, which is available at the time of making the International Payment.</span
            >
          </li>
          <li>
            <span class="condition-pay-international-text">Intermediary Bank : </span
            ><span class="condition-desc">A bank that acts on behalf of the beneficiary bank.</span>
          </li>
          <li>
            <span class="condition-pay-international-text">International Payment : </span
            ><span class="condition-desc"
              >A payment made through Stanbic Bank’s electronic banking platform to a beneficiary holding a foreign bank account.</span
            >
          </li>
          <li>
            <span class="condition-pay-international-text">Mobile and Online Banking Services : </span
            ><span class="condition-desc"
              >Stanbic Bank’s mobile banking services, retail internet banking and enterprise internet banking platforms.</span
            >
          </li>
          <li>
            <span class="condition-pay-international-text">Personal Information : </span
            ><span class="condition-desc"
              >Personal Information means information relating to an identifiable,living, natural person, and where it is applicable, an
              identifiable,existing juristic person.</span
            >
          </li>
          <li>
            <span class="condition-pay-international-text">Prohibited Activities : </span
            ><span class="condition-desc"
              >Illegal or terrorist activities, money laundering or any activities which are subject to Sanctions or are not in compliance with
              Applicable Laws.</span
            >
          </li>
          <li>
            <span class="condition-pay-international-text">Regulations : </span
            ><span class="condition-desc"
              >Electronic Payment Regulations,2019 and any other regulations applicable to International Payments and banking from time to time
              enacted.</span
            >
          </li>
          <li>
            <span class="condition-pay-international-text">Sanctioning Body : </span
            ><span class="condition-desc"
              >The United Nations Security Council (UNSC), the Office of Foreign Assets Control of the Department of Treasury of the United States of
              America (OFAC), the European Union (EU), Her Majesty’s Treasury (HMT), the Ministry of Economy, Finance and Industry (France) (MINEFI)
              or any other sanctioning body we recognise from time to time.</span
            >
          </li>
          <li>
            <span class="condition-pay-international-text">Sanctioned Entity : </span
            ><span>
              <ul class="condition-pay-international tc-international-pay" style="list-style-type: disc">
                <li>Any natural or juristic person or country listed on any Sanctions List or who is subject to any Sanctions.</li>
                <li>
                  In the case of a juristic person, this will include any person who (i) owns or controls it; or (ii) it owns or controls (and for
                  these purposes, owns means holding any percentage of ownership or beneficial interest and controls means the ability, directly or
                  indirectly and whether through the voting of shares, the appointment of directors or similar officers or through any other means, to
                  control the business or policy of the juristic person).
                </li>
                <li>In the case of any country, this will include its ministries, departments, agencies or any other governmental organisations.</li>
              </ul>
            </span>
          </li>
          <li>
            <span class="condition-pay-international-text">Sanctions : </span
            ><span class="condition-desc"
              >Any restrictions set by a Sanctioning Body, including but not limited to diplomatic, travel, trade or financial sanctions or
              embargoes.</span
            >
          </li>
          <li>
            <span class="condition-pay-international-text">Sanctions List : </span
            ><span class="condition-desc">Any list of Sanctioned Entities published by a Sanctioning Body, as updated from time to time.</span>
          </li>
          <li>
            <span class="condition-pay-international-text">SWIFT : </span
            ><span class="condition-desc">The Society for Worldwide Interbank Financial Telecommunication.</span>
          </li>
          <li>
            <span class="condition-pay-international-text">Stanbic Bank, we, us/our : </span
            ><span class="condition-desc"
              >Stanbic Bank Botswana Limited (Registration number 1993/1343), a Financial Institution duly incorporated with limited liability
              according to the company laws of the Republic of Botswana.</span
            >
          </li>
          <li>
            <span class="condition-pay-international-text">Standard Bank Group : </span
            ><span class="condition-desc"
              >Standard Bank Group Limited, its subsidiaries and their subsidiaries but excludes Liberty Holdings Limited and its subsidiaries.</span
            >
          </li>
        </ul>
      </div>
      <div class="conditions tc-wrap">
        <span class="conditiom-heading">3. International payments</span>
        <ul class="condition-pay-international">
          <li>
            3.1 Residents and non-Residents in the Republic of Uganda who are older than 18, hold an Account with us and for whom we have all
            information and documents required by Applicable Laws, can make International Payments.
          </li>
          <li>
            3.2 You may not make International Payments on the Mobile and Online Banking Services that require documents to be given to Stanbic Bank.
            Only International Payments that do not require such documents may be made.
          </li>
          <li>3.3 You may not make an International Payment without giving us the requested information and adhering to the Regulations.</li>
          <li>3.4 Please note the following when making an International Payment:</li>
          <li>
            3.4.1 your normal Electronic Account Payment (EAP) limit will apply. This means that you will not be able to make an International Payment
            if you have exceeded your daily transactional EAP limit;
          </li>
          <li>
            3.5 The Exchange Rate applicable to an International Payment is determined by us and does not include our commission fee and the SWIFT
            fee, which will be shown separately.
          </li>
          <li>
            3.6 Once you have selected the currency you wish to pay in, an indicative Exchange Rate will be displayed. The indicative Exchange Rate is
            based on the current Exchange Rate, which may change by the time you have entered all the information required. We will provide you with
            the final Exchange Rate before you confirm the International Payment.
          </li>
          <li>
            3.7 You must ensure that you have enough funds in your Account to make the International Payment. Your Account will be debited with the
            payment amount, our commission fees and the SWIFT fee when the transaction has been processed successfully. If you have accepted the quote
            (which will set out the Exchange Rate and fees) and your Account is not debited because there are not enough funds in your Account, you
            will have to pay all costs for cancelling or processing the International Payment.
          </li>
          <li>
            3.8 When you accept the Exchange Rate and these Terms, and make the declaration applicable to the International Payment, the Exchange Rate
            will be booked and you will have to pay all amounts owed to us in this regard.
          </li>
          <li>
            3.9 Please note that you will not earn any interest on the amount debited from your Account from the date that it is debited to the date
            of payment to the beneficiary.
          </li>
          <li>
            3.10 If you want to make an International Payment after hours and/or when the markets are closed, we will offer you the latest available
            Exchange Rate.
          </li>
          <li>3.11 You accept that an International Payment takes a minimum of two Business Days to be transmitted.</li>
          <li>
            3.12 This period may be extended and/or the International Payment may be cancelled due to circumstances beyond our control, including in
            terms of Applicable Laws or the laws in the jurisdiction of the beneficiary’s bank or any Correspondent Bank or Intermediary Bank.
          </li>
          <li>
            3.13 If this happens we will contact you and you must provide the information required by the beneficiary’s bank, Intermediary Bank or
            Correspondent Bank. If you do not provide the information immediately, the International Payment may be cancelled or delayed.
          </li>
          <li>
            3.14 If the International Payment is delayed by the beneficiary’s bank, a Correspondent Bank or Intermediary Bank, we cannot recall or
            reverse it.
          </li>
          <li>
            3.15 If we are required to cancel an International Payment because of any Applicable Laws or the laws in the jurisdiction of the
            beneficiary’s bank or any Correspondent Bank or Intermediary Bank after your Account has been debited, we may refund you, but you will not
            receive the full value of the International Payment due to (i) our fee charges and/or (ii) exchange rate fluctuations. We will not be
            liable (responsible) for any loss you or any third party may suffer in this regard, and you indemnify us against any loss or damage which
            we may suffer as a result thereof.
          </li>
          <li>
            3.16 An International Payment may be cancelled if you give us incorrect details about the beneficiary or the beneficiary’s bank. In this
            case, we will try to contact you to let you know that the International Payment cannot be processed. You will have to pay all costs for
            cancelling the International Payment or making it at a later date. We may need further instructions from you and you may need to submit a
            new written instruction for the International Payment to be processed.
          </li>
        </ul>
      </div>
      <div class="conditions tc-wrap">
        <span class="conditiom-heading">4. Fees</span>
        <ul class="condition-pay-international">
          <li>
            4.1 The fees that we will charge for International Payments can be found in our online pricing guide at
            <a href="https://www.stanbicbank.co.bw/" class="need-help-mail ip-web-link" target="_blank"> https://www.stanbicbank.co.bw/</a> and click
            on “Rates and Fees”.
          </li>
          <li>4.2 The following fees will apply to the International Payment:</li>
          <li>
            4.2.1 Our fees: we will charge you:
            <ul class="condition-pay-international tc-international-pay" style="list-style-type: disc">
              <li>commission fees, which are based on a percentage of the value of the International Payment at the time that it is made; and</li>
              <li>a SWIFT fee for International Payments sent using the SWIFT network.</li>
            </ul>
          </li>
          <li>
            4.2.2 Beneficiary’s bank’s fees:
            <ul class="condition-pay-international tc-international-pay" style="list-style-type: disc">
              <li>
                The beneficiary’s bank will charge a fee to process the incoming payment. You can choose to pay for the beneficiary's bank’s fees or
                you can let the beneficiary pay.
              </li>
              <li>
                If you choose to pay for the beneficiary’s bank’s fees, your account will be debited with this fee once the beneficiary’s bank has
                processed the International Payment.
              </li>
              <li>
                If you choose to let the beneficiary pay for their bank’s fees, the beneficiary’s bank will deduct its fees from the International
                Payment. This means that the beneficiary will not receive the full amount you send them.
              </li>
            </ul>
          </li>
          <li>
            4.2.3 Other foreign bank fees: Sometimes International Payments need to be routed through an Intermediary Bank or Correspondent Bank to
            reach your beneficiary’s bank. In such cases, the Intermediary Bank or Correspondent Bank will deduct their fees from the amount sent to
            the beneficiary. This means that the beneficiary will not receive the full amount you send them.
          </li>
          <li>
            4.2.4 The fees charged by the beneficiary’s bank, Correspondent Bank and Intermediary Bank are different from bank to bank and we are
            unable to predict what they will be.
          </li>
          <li>
            4.3 You authorise us to debit your Account with all amounts for the International Payment, including our fees and the fees charged by the
            beneficiary’s bank, where applicable.
          </li>
          <li>4.4 Our fees will be displayed for you to accept before you proceed with the International Payment.</li>
          <li>
            4.5 Please note that when making an International Payment using the Mobile and Online Banking Services, you will not qualify for any
            special rates or existing allowances which would otherwise be available when making payments using other platforms (for example, staff
            rates, private client rates and special pensioner rates).
          </li>
        </ul>
      </div>
      <div class="conditions tc-wrap">
        <span class="conditiom-heading">5. Recalling (cancelling) an International Payment</span>
        <ul class="condition-pay-international">
          <li>
            5.1 If you want to recall an International Payment, you can call +267 3987801 (local calls) or 0800 600 685 (international calls) on
            Business Days and we will do our best to contact the beneficiary’s bank to request that the International Payment be recalled.
          </li>
          <li>
            5.2 If the beneficiary’s bank agrees to recall the International Payment and returns the funds to us, we will credit your Account with the
            amount received from the beneficiary’s bank, less any fees we and/or the beneficiary’s bank (as applicable) charge. Please note that due
            to exchange rate fluctuations, this amount may not be the same as the value of the International Payment.
          </li>
          <li>
            5.3 If the beneficiary’s bank rejects our request to recall the International Payment, you may still have to pay fees (such as
            administration fees) charged by the beneficiary’s bank, Correspondent Bank and/or Intermediary Bank and we will debit these fees from your
            Account. In these cases, you will not receive any amounts from us for the International Payment and will need to deal directly with the
            Beneficiary if you want to be refunded.
          </li>
        </ul>
      </div>
      <div class="conditions tc-wrap">
        <span class="conditiom-heading">6. Regulations</span>
        <ul class="condition-pay-international">
          <li>
            6.1 We are required to report large or any suspicious transactions exceeding the required thresholds to the Financial Intelligence
            Authority and other Regulators and we are not obliged at law to inform you of such reports
          </li>
          <li>
            6.2 You acknowledge that we may be required by various laws to give your personal information to the Financial Intelligence Authority
            and/or the Central Bank.
          </li>
        </ul>
      </div>
      <div class="conditions tc-wrap">
        <span class="conditiom-heading">7. Sanctions</span>
        <ul class="condition-pay-international">
          <li>7.1 You must not:</li>
          <li>7.1.1 use the International Payment to finance any Sanctioned Entity;</li>
          <li>
            7.1.2 make the proceeds of the International Payment available to any person who may use or intends to use the proceeds to finance a
            Sanctioned Entity’s activities;
          </li>
          <li>7.1.3 act in a way that benefits a Sanctioned Entity;</li>
          <li>7.1.4 be involved in any Prohibited Activities; or</li>
          <li>7.1.5 use any product or service provided by the Standard Bank Group for any Prohibited Activities.</li>
          <li>7.2 You warrant (promise) that you are not;</li>
          <li>7.2.1 a Sanctioned Entity; or</li>
          <li>7.2.2 being investigated for any activities relating to Sanctions.</li>
          <li>7.3 You must let us know immediately in writing if you are being investigated for any activities relating to Sanctions.</li>
          <li>
            7.4 You indemnify us against (you are responsible for) any fines, penalties, losses, damages, costs, actions, proceedings, claims or
            demands (Losses) which we may suffer because:
          </li>
          <li>7.4.1 any funds are seized or withheld by any Sanctioning Body or any other third party (including by us); or</li>
          <li>7.4.2 you breach this clause 7.</li>
          <li>
            7.5 If we know or suspect that you are in breach of this clause 7 or you are about to become subject to Sanctions, we can immediately, in
            our sole discretion:
          </li>
          <li>7.5.1 close, restrict activity or suspend access to International Payments and any other product or service we provide you; and/or</li>
          <li>7.5.2 cancel these Terms and/or any other relationship which we have with you.</li>
          <li>
            7.6 We are not liable (responsible) to you for any Losses you suffer if we cancel these Terms or any other relationship which we have with
            you.
          </li>
        </ul>
      </div>
      <div class="conditions tc-wrap">
        <span class="conditiom-heading">8. No liability</span>
        <ul class="condition-pay-international">
          <li>
            8.1 We will not be liable (responsible) for any International Payment that is terminated, interrupted, intercepted, hacked or otherwise
            fraudulently seized, transferred or terminated due to any cause. All International Payments are made entirely at your own risk and
            discretion.
          </li>
          <li>
            8.2 We do not support International Payments to Crypto Currency Exchanges. We will not be responsible for any loss you may suffer in
            connection with these payments, including as a result of the payment failing, and you indemnify us against any loss or damage which we may
            suffer as a result thereof.
          </li>
        </ul>
      </div>
      <div class="conditions tc-wrap">
        <span class="conditiom-heading">9. Personal Information</span>
        <ul class="condition-pay-international">
          <li>
            9.1 You consent to us collecting your Personal Information from you and where lawful and reasonable, from public sources for credit, fraud
            and compliance purposes, as well as the purposes set out below.
          </li>
          <li>
            9.2 If you give us Personal Information about or on behalf of another person, you confirm that you are authorised to consent on their
            behalf to give and process their Personal Information into and outside the country where the products or services are provided and to
            generally act on their behalf.
          </li>
          <li>9.3 You consent to us Processing your Personal Information:</li>
          <li>9.3.1 to provide products and services to you in terms of these Terms and any other products and services for which you may apply;</li>
          <li>9.3.2 to carry out statistical and other analysis to identify potential markets and trends, evaluate and improve our business;</li>
          <li>9.3.3 to ask all parties that receive your Personal Information to agree to our privacy policies.</li>
          <li>9.3.4 You will find our processing practices in our privacy statement. This statement is available on our website or on request</li>
        </ul>
      </div>
      <div class="conditions tc-wrap">
        <span class="conditiom-heading">10. Customer contact information</span>
        <ul class="condition-pay-international">
          <li>10.1 If you have any queries or complaints about International Payments, you can call us on Business Days on the following numbers:</li>
          <li>10.1.1 iif the International Payment has not been made: +267 3987801 (local calls) or 0800 600 685 (international calls); or</li>
          <li>10.1.2 if the International Payment has been made: +267 3987801 (local calls) or 0800 600 685 (international calls); or</li>
          <li>10.2 You can also visit your nearest Stanbic Bank branch with a forex teller.</li>
          <li>
            10.3 We will try to resolve queries as quickly as possible, but there may be delays beyond our control, as we may be reliant on the
            beneficiary’s bank and other foreign banks.
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #smeMode>
  <div class="large-12 medium-12 small-12 medium-centered large-centered columns tc-wrap">
    <div *ngIf="!viewTcPayInternational">
      <div class="column large-12 medium-12 small-12 medium-centered large-centered">
        <div class="conditions">
          <span class="conditiom-heading">1. Introduction: about these Terms</span>
          <span class="condition-desc"
            >a) These Terms take effect (begin) when you register for Enterprise Online.<br />
            <br />
            b) We may change these Terms from time to time. The latest version of the Terms applies to you each time you use Enterprise Online.<br />
            <br />
            c) You must know, understand and comply with:<br />
            ? these Terms; and<br />
            ? the Product and General Terms that apply to your Account.<br />
            Both sets of Terms are a binding legal agreement between you and us.<br />
            <br />
            d) If there is a difference between these Terms, and the Product and General Terms , these Terms will apply.<br />
            <br />
            e) Please pay special attention to the highlighted sections. These are important clauses which explain what may limit our responsibility
            or involve some risk for you.<br />
            <br />
            f) Please note that some of the products listed in these Terms might not be available in your country of residence, if outside of South
            Africa.
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">2. Definitions</span>
          <span class="condition-desc"
            >We have defined some of the words used in this document. These words begin with a capital letter. Singular words include the plural and
            the other way round.<br />
            <br />
            Access Codes : Any of the secret characters (letters and numbers) you use for banking, including, for example: your one-time PIN (OTP),
            username, and user password. <br />
            <br />
            One time pin : A pin which contain numbers, sent to you via specifically for conducting transactions.<br />
            <br />
            One time password : A system generated password containing letters and numbers, used for logging in and is only sent once on request on
            registration, and when you have forgotten your password.<br />
            <br />
            User password : a password created by you, containing letters and numbers.<br />
            <br />
            Account : The account we open in your name, at your request, and which you use to access Enterprise Online.
            <br />
            <br />
            Mobile wallet : an electronic wallet, whereby a third party receives a voucher number on his/her mobile phone, which voucher number can be
            entered at an ATM to draw the amount of cash received by the sender. <br />
            <br />
            Bill payment : payment to any third party service provider e.g. electricity provider, prepaid service provider.<br />
            <br />
            ATM : An automated teller machine. <br />
            <br />
            Bank, we or us : Stanbic Botswana and its successors or assigns. <br />
            <br />
            Business Days : Monday to Friday ( 8:30 AM to 6 PM ) , excluding official public holidays in Botswana <br />
            <br />

            Card : Any card we issue to you that is linked to your Account.<br />
            <br />
            Communication System : The Device and the Medium together. <br />
            <br />
            Device : The equipment you use to access Enterprise Online, for example, a computer, cell phone, smartphone, tablet, smart television, or
            similar technology. <br />
            <br />
            Enterprise Direct : Our enterprise banking customer support centre;<br />
            <br />
            Enterprise Online : An online platform for enterprise banking customers<br />
            <br />
            General Terms : The general terms and conditions for non-credit transactional, savings and investment accounts. <br />
            <br />
            Group : Standard Bank Group, the holding company and any subsidiary of the holding company and all of its subsidiaries.<br />
            <br />
            Intellectual Property : <br />
            All: <br />
            ? inventions, specifications, patents, designs, trademarks, service marks, trade names and all goodwill associated with these; <br />
            ? copyright, including copyright in logos, devices, designs, multimedia works and computer software programs (in source and object code
            form), as well as programmers? or developers? notes, flow charts and design documents; <br />
            ? rights protecting goodwill and reputation; <br />
            ? proprietary material, know-how, ideas, concepts, trade secrets, methods, techniques, graphics; <br />
            ? schematics; <br />
            ? marketing;<br />
            ? sales and user data; <br />
            ? domain names and URLs; <br />
            ? databases and rights in databases<br />
            ? confidential information; <br />
            ? other intellectual property rights and similar kinds of protection that are registered or can be registered anywhere in the world;
            and<br />
            ? applications for, and rights to apply for, the protection of any of the items on this list.<br />
            <br />

            Intellectual Property Rights : All rights in and to Intellectual Property. <br />
            <br />
            CIF : an internal file containing customer?s name and other information, including all accounts he has opened with the bank<br />
            <br />
            Mandates : instruction on the roles on responsibilities of the various users who may performs actions on Enterprise Online<br />
            <br />
            Medium : The way you access Enterprise Online, which can be either via a web browser, USSD, or Mobile Application; <br />
            <br />
            Mobile Application : A software application developed specifically for use on mobile devices, such as smartphones and tablets, rather than
            desktop or laptop computers.<br />
            <br />
            Personal Information : Information about an identifiable, natural or juristic person. This includes information about:<br />
            ? race, nationality, ethnic or social origin;<br />
            ? gender or sexual orientation;<br />
            ? age;<br />
            ? marital status;<br />
            ? physical or mental health;<br />
            ? disability;<br />
            ? religion or belief;<br />
            ? language;<br />
            ? birth date;<br />
            ? education;<br />
            ? identity number;<br />
            ? telephone number;<br />
            ? email, postal or street address;<br />
            ? biometric information (for example, fingerprints);<br />
            ? financial, criminal or employment history; and<br />
            ? private correspondence sent by that person, in any form.<br />
            <br />

            PIN : A personal identification number. <br />
            <br />
            Process : An operation or activity, which could be automated, that affects Personal Information. Processes and processing include:<br />
            ? collecting; <br />
            ? receiving; <br />
            ? recording; <br />
            ? organising; <br />
            ? storing; <br />
            ? updating or changing; <br />
            ? recovering; <br />
            ? using; <br />
            ? sending; <br />
            ? merging;<br />
            ? linking;<br />
            ? blocking;<br />
            ? erasing; or <br />
            ? destroying information.<br />
            <br />

            Product Terms : The terms and conditions of a product or products linked to your Account, Card or Transaction.<br />
            <br />
            Profile : Your Enterprise Online information which is accessible using your username and password, and includes all your banking
            information and security information such as security questions and your Mandate rules. All accounts associated with your Mandate will be
            loaded onto your Profile.<br />
            <br />
            RTGS Payment : Real time gateway service payment, also referred to as instant pay, which allows for payment to other banks to settle
            within 15 minutes of submission up until 3:30pm local time <br />
            <br />
            SMS : Short Message Service.<br />
            <br />
            Transaction : Any debit or credit made on your Account using Enterprise Online and that we action on your instruction.<br />
            <br />
            USSD : Unstructured supplementary service data, which is a global system for Mobile (GSM) communication technology that is used to send
            text between a mobile phone and an application program in the network;<br />
            <br />
            you or your : The person or legal entity that registers for and uses Enterprise Online, or in whose name an Account is opened. <br />
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">3. Links to other information </span>
          <span class="condition-desc"
            >We have put links from some words in these Terms to other information that may apply to you. These are called hyperlinks. These
            hyperlinks may appear be white or blue in colour and may be underlined. You must read any links that apply to you, because they form part
            of these Terms. If you can?t read any linked information, you must visit our website at
            <a target="_blank" href="http://www.stanbicbank.co.bw" tabindex="-1" class="need-help-mail" target="_blank">
              http://www.stanbicbank.co.bw
            </a>
            or contact Enterprise Direct by phone on +267 3987820
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">4. Registering for and using various Enterprise Online services</span>
          <span class="condition-desc"
            >a) If you have any questions or would like more information about our Enterprise Online services, please visit our website at
            <a target="_blank" href="http://www.stanbicbank.co.bw" tabindex="-1" class="need-help-mail" target="_blank"
              >http://www.stanbicbank.co.bw</a
            >
            or contact Enterprise Direct.
            <br />
            b) The provisions below explain how you can register for Enterprise Online, and the fees charged for these services:<br />
            <br />
            1. Branch based registration: <br />
            <br />
            ? Present your nearest branch with your original resolution recording your decision to register for Enterprise Online, setting out the
            full names and identity numbers of the parties who may access Enterprise Online, the functions they may perform, including specifically
            their Mandates. Complete the application form provided by the branch.<br />
            <br />
            ? The branch will follow its internal verification process in regards to the resolution, make a certified copy for themselves, and file it
            for internal record-keeping.<br />
            <br />
            ? The branch will capture you application information and then confirm activation of Enterprise Online.<br />
            <br />
            2. Non branch based registration<br />
            <br />
            ? Download the application form on the Enterprise login page found at
            <a target="_blank" href="http://www.stanbicbank.co.bw" tabindex="-1" class="need-help-mail" target="_blank">
              http://www.stanbicbank.co.bw </a
            >, complete and sign it, then and email it, along with your resolution , to Enterprise Direct.<br />
            <br />
            ? Your resolution must record your decision to register for Enterprise Online, set out the full names and identity number of the parties
            who may access you Enterprise Online, what functions they perform, including their Mandates. <br />
            <br />

            c) Fees Details of our fees are contained on our website at:
            <a target="_blank" href="http://www.stanbicbank.co.bw" tabindex="-1" class="need-help-mail" target="_blank">
              http://www.stanbicbank.co.bw
            </a>
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">5. Transacting with Enterprise Online</span>
          <span class="condition-desc">
            a) When you do Enterprise Online you communicate with us using a Device, so there is no direct personal contact between you and us. <br />
            <br />
            b) We will act on all instructions that seem to come from you ? even if they are actually coming from someone pretending to be you. After
            your Access Code has been entered, we may assume that any Enterprise Online activity or instruction is genuine. So even if someone else
            used your Access Code, we may carry out an instruction as if you have authorised it.<br />
            <br />
            c) You can instruct us to transact in line with the services you have on your Account. We will treat your instructions to us in line with
            your Account, the Transaction involved and the Product Terms. <br />
            <br />
            d) We don?t always carry out instructions in the same time or in the same way. We will manage your instructions to us in line with your
            Profile, and the type of Account and Transaction.<br />
            <br />
            e) If you send a normal beneficiary payment instruction instead of an RTGS payment instruction, your payment will reflect in the
            beneficiary?s account within 2 (two) Business days. <br />
            <br />
            f) Real Time Gateway services payments are only available before 3pm local time.<br />
            <br />
            g) You cannot cancel, change or stop an instruction after you have sent it to us. A payment cannot be cancelled or reversed, even if it is
            a repeated payment (made more than once). This includes payments for prepaid products and instant money transactions . <br />
            <br />
            h) We are not responsible for any loss or damage you suffer because you repeated a payment instruction (sent it through more than once)
            and we then made more than one payment. You waive (give up) any claim against us if this happens. Please call our Customer Contact Centre
            if you have repeated a payment by mistake.<br />
            <br />
            i) We are not responsible for any loss you suffer if you enter the wrong details for your Transaction.<br />
            <br />
            j) Do not assume we have received your instruction until we have told you that we have. If you?re not sure that an instruction or payment
            has gone through, contact our Customer Contact Centre or check your bank statement. Do not give the same instruction again unless we have
            told you that we did not receive it, or else we may make the same payment twice.<br />
            <br />
            k) You may delete or change future-dated and repeat payments if they have not yet been made (been actioned by us). You can also delete or
            change an active electronic voucher in Instant Money Transactions if the person receiving the voucher hasn?t cashed it in (redeemed it)
            yet. Please call our Customer Contact Centre for help with cancelling or changing these payments.<br />
            <br />
            l) You must think about the effects of each Transaction, including the tax effects.<br />
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">6. Prepaid products <br /> </span>
          <span class="condition-desc">
            a) Prepaid purchases fall under the terms and conditions of the service provider you?re making the purchase (buying) from. If you have any
            questions about your prepaid purchase, please go directly to that service provider. We can?t be legally responsible or blamed for any
            losses you may suffer because of something your service provider has or has not done.<br />
            <br />
            b) Please visit our website at
            <a target="_blank" href="http://www.stanbicbank.co.bw" tabindex="-1" class="need-help-mail" target="_blank">
              http://www.stanbicbank.co.bw
            </a>
            or ask our Customer Contact Centre for more information about these.
          </span>
          <br />
        </div>
        <div class="conditions">
          <span class="conditiom-heading">7. Fees</span>
          <span class="condition-desc"
            >a) We charge you fees for using Enterprise Online. Details about these fees are on our website. <br />
            <br />
            b) If you do not pay our fees or do not have enough money in your Account to pay them, we may stop you from using Enterprise Online. We
            may also deduct (take off) any fees you owe us from any other Account you have with us.
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">8. Looking after your Access Codes</span>
          <span class="condition-desc"
            >a) Your Access Codes are any of your secret codes defined in clause 2 above.<br />
            <br />
            b) We may let you use the same Access Codes for all your Enterprise Online or connected services because this is easier for you. But you
            must keep your Access Codes secret and safe ? someone who knows them could get access to your Account and steal your money or use your
            private Account information illegally.<br />
            <br />
            c) It is not safe to keep your Access Codes on a computer.<br />
            <br />
            d) No person ever has a good reason to know or ask for your Access Codes, so you must never give your Access Codes to anybody, including
            the Bank?s staff, your friends and family. You must tell our Customer Contact Centre or your branch immediately if someone has asked you
            for, or may know, any of your Access Codes.<br />
            <br />
            e) We are not responsible for any loss or damage you may suffer if you have not kept your Access Codes safe or where you have given your
            Access Codes to any other person. <br />
            <br />
            f) If you ask us to stop or change any Access Code, we may:<br />
            i. ask you to prove your identity to us;<br />
            ii. reject any banking instructions we receive;<br />
            iii. stop a payment or banking instruction if it has not already been carried out;<br />
            iv. reverse payments if we can, from the time we think someone started using your Access Code without permission; or<br />
            v. deactivate the Access Code (stop it from working) immediately, without telling you.<br />
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">9. Security for Enterprise Online</span>
          <span class="condition-desc">
            a) To guard against fraud or theft on your Account, you can use these free extra security features:<br />
            ? My notifications: We tell you each time someone gets access to your Account which you can activate on your Profile.<br />
            ? Trusteer: A computer program for electronic security software which you can download from our Internet banking website.<br />
            <br />
            b) You must always run up-to-date software that is compatible (works) with these extra security features.<br />
            <br />
            c) You must always be vigilant to any potential fraud that can take place on your Account. If you believe a suspicious transaction has
            taken place on your Account, you must immediately react to the SMS received from the Bank notifying you of this transaction, by contacting
            the Bank on +267 3987820<br />
            <br />
            d) If you activated the ?My notification? function you must ensure that your cell phone/smartphone is at all times switched on an in a
            working condition. <br />
            When travelling internationally you must either ensure that the roaming service on your cell phone/smartphone is activated or you must
            advise the Bank of an alternative destination for notification of transactions on your Account while you travel.<br />
            <br />
            e) We are not responsible for any loss or damage you may suffer because someone gets into your Account and you have not:<br />
            ? run the latest software that works with the extra security features;<br />
            ? used the extra security features on your Account;<br />
            ? followed our advice about using Enterprise Online safely;<br />
            ? responded to an SMS received from the Bank notifying you of a transaction on your Account, provided the Bank can show that the SMS was
            delivered to the cell phone/smartphone number we have on record; <br />
            ? notified the Bank that your cell phone/smartphone number has changed and the SMS is delivered to the incorrect cell phone/smartphone
            number.<br />
            <br />
            f) Do not use any public Device, for example a computer at an Internet caf?. Public Devices are not safe because you do not know what
            software or hardware may be on them. For example, they may use spying technology to find out numbers or passwords you type on a keyboard.
            These could be used to get into your Account to defraud you or steal money.<br />
            <br />
            (g) The Bank will never ask you to click on a link via email or SMS. Please send an email to [insert country specific email address for
            the reporting of phishing] to report phishing or any suspicious online transactions.
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">10. Availability of Enterprise Online</span>
          <span class="condition-desc">
            a) Enterprise Online may not always be available. There are many reasons for this, including:<br />
            ? technical failure or problems with a Communication System (ours or another one) directly or indirectly involved in providing Enterprise
            Online;<br />
            ? problems with a telecommunication or electricity service; or<br />
            ? other circumstances beyond our control.<br />
            <br />
            b) If Enterprise Online is unavailable for any reason, you promise to communicate or bank with us in any other way so that you limit
            potential losses on your Account.
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">11. Ending your Enterprise Online session</span>
          <span class="condition-desc"
            >a) When you have finished using Enterprise Online, you must make sure that you log out. <br />
            <br />
            b) If you do not log out after using Enterprise Online, someone else may get into your Account without your permission and could defraud
            you or steal your money. We will not be responsible for any loss or damage you may suffer if this happens.
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">12. Information on the Communication System from us</span>
          <span class="condition-desc">
            a) Any information or other content on the Communication System only invites you to do business with us. It is not an offer to buy, sell
            or deal in an investment, or to enter into an agreement, unless we clearly state that it is.<br />
            <br />
            b) Information on the Communication System is to give you general information about us, and our products, services and goals. For example,
            we may give information about:<br />
            ? estimated income, capital expenses, returns on investment, shares or other financial items;<br />
            ? our plans, goals and estimates for our future operations and services; and<br />
            ? our estimates of the economy's future performance.<br />
            <br />
            c) Because these are only estimates, actual events or results may be different. We give all information "as is" and you must not rely on
            it or treat it as professional or investment advice. You should always ask for advice from your own professional advisers.<br />
            <br />
            d) Refresh your screen regularly to make sure that the information you see is the latest that is available on our Communication System.
            News or market information, such as share prices or data, is updated regularly but may be at least 30 (thirty) minutes old.<br />
            <br />
            e) We may use other organisations to put information on the Communication System. This information is not given by or for us. We have no
            control over it and do not confirm or guarantee that it is correct or suitable for anything.<br />
            <br />
            f) All this information is provided "as is" and we will not be responsible for any damages that may follow if you rely on it.
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">13. Links to third parties? (other) Communication Systems</span>
          <span class="condition-desc">
            a) Our Communication System may link to other Communication Systems that have information or content from other parties. Although we try
            to link only to trustworthy parties or systems, we are not responsible for any of this other information. If we give a link to any other
            information, Communication System, person or business, it does not mean that we have checked or approve of them.<br />
            <br />
            b) We do not give any guarantee about other websites, software or hardware, including their security or performance. You waive (give up)
            any claim you may have against us for any loss or damage you may suffer because you connect to another Communication System.
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">14. Security of the Communication System</span>
          <span class="condition-desc">
            a) Information sent over a Communication System can be intercepted (stopped), seen or changed unlawfully if a link is not secure. We take
            steps to limit these risks, and these steps are explained in the privacy and security statement on our website and elsewhere.<br />
            <br />
            b) You must follow the security tips published on our website from time to time. We are not responsible for any loss or damage you suffer
            because someone gained access to the Communication System illegally or without permission. To limit these risks, we may check any
            information you send using our Communication System.
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">15. Software and hardware</span>
          <span class="condition-desc"
            >a) You must use hardware (computer equipment) and software (programs) suitable for Enterprise Online. If you do not, the Communication
            System may not work properly and this could increase your security risks.<br />
            <br />
            b) If we offer software to you on or through the Communication System, the licence agreement for that software is between you and the
            software's licensor (owner).<br />
            <br />
            c) You indemnify us against a breach (going against the agreement) of a software licence. We do not guarantee that any software is good
            quality or suitable (right) for its purpose.<br />
            <br />
            d) We also do not guarantee or suggest that any file, download or application on the Communication System is safe to use on any computer.
            We cannot guarantee that the system does not have software or data that can negatively affect a computer system, such as viruses, Trojans
            and other malicious (dangerous) software.<br />
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">16. Our Intellectual Property</span>
          <span class="condition-desc">
            a) We keep the copyright and any other Intellectual Property rights in all content on or sent through the Communication System, (including
            storage media). You may print and make a paper copy of content sent through the Communication System, including logos, images or
            multimedia works. You may print this content only if:<br />
            ? it is for your personal Enterprise Online use; ? it is not used for any commercial (business) reason; and<br />
            ? the copy of the content shows our copyright notice. <br />
            <br />
            b) The logos and trademarks on our Communication System belong to us or other parties. Nothing on our Communication System gives any
            person the right to use any trademark or other Intellectual Property (our property) without our written permission.<br />
            <br />
            c) Even if any content on the Communication System is not confidential or there is no copyright in it, we own the content and you have no
            rights in it.<br />
            <br />
            d) If you want to use content from the Communication System, please email
            <a target="_blank" href="mailto:EnterpriseBW@stanbic.com" tabindex="-1" class="need-help-mail" target="_blank">
              EnterpriseBW@stanbic.com
            </a>
            . If we do not reply in writing in 5 (five) Business Days we have not agreed to let you use this content.
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">17. Using and sharing your Personal Information</span>
          <span class="condition-desc">
            a) You agree that we may collect and process your Personal Information within the Group so that we can:<br />
            ? open and manage your Profile and Account; <br />
            ? provide a combination of services, analysis or advice linked to your Account; <br />
            ? watch and analyse activities on your Profile and Account for risks like fraud and non-compliance (not following certain laws or
            regulations); and<br />
            ? analyse information to identify possible markets and trends, and develop new products and services. <br />
            <br />
            b) You agree that we may share your Personal Information with any person, locally or outside the Republic of South Africa, who:<br />
            ? provides services to us;<br />
            ? acts as our agent; or <br />
            ? has been given, or may be given, any of our rights and duties in respect of your Account or Profile.<br />
            We ask people who provide services to us to agree to our privacy policies if they need to access any Personal Information to carry out
            those services. <br />
            <br />
            c) You accept that:<br />
            ? we will always be responsible for deciding the reason for, and way to, process your Personal Information;
            <br />
            ? some laws state we must collect some of your Personal Information;<br />
            ? without your Personal Information we may be unable to open an Account or continue to offer services to you; and <br />
            ? you are giving us your Personal Information voluntarily (willingly).<br />
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading"> 18. Marketing by post, email or text messages</span>
          <span class="condition-desc"
            >If you give us permission, we may use your Personal Information to contact you about products, services, and special offers from us or
            other companies that may interest you. We will do this by post, email, or text message. If you decide that you do not want us to do this,
            you can contact us, and we will stop.
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">19. Your responsibility for our loss or damages</span>
          <span class="condition-desc">
            You will cover (pay for) any loss or damages that we may suffer on your behalf because: <br />
            <br />
            a) you don?t make certain payments;<br />
            <br />
            b) you gave us wrong instructions or information;<br />
            <br />
            c) you did not have enough money for a payment from your Account; or<br />
            <br />
            d) someone carried out an instruction on, or made a payment from, your Account without permission and this was not because of our own
            negligence (carelessness).
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">20. Warranties (promises) made by you</span>
          <span class="condition-desc">
            You confirm that:<br />
            <br />
            a) you are able to enter into a contract (you have full contractual capacity) and no court has declared you mentally unfit to do so;<br />
            <br />
            b) you are not, and will not be, located in a country that is the subject of a United States of America (US) Government embargo or that
            the US Government has named a "terrorist supporting" country; <br />
            <br />
            c) you are not listed on any US Government list of prohibited or restricted parties;<br />
            <br />
            d) you have been told, in clauses 27 and 28 below, that you can contact us with any questions you may have;<br />
            <br />
            e) you have given us the right information; and<br />
            <br />
            f) you read and understood these Terms before entering into this agreement.
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading"> 21. Disclaimer and limitation of liability (limits our responsibility to you)</span>
          <span class="condition-desc"
            >a) Your use of the Communication System and Enterprise Online depends on factors beyond our control, such as the network coverage or
            availability of your Internet service provider. We are not legally responsible and can?t be blamed for any loss or damages you may suffer
            if you cannot access Enterprise Online because of problems with your Internet service.<br />
            <br />
            b) We have taken care to make sure that content on the Communication System is accurate and that you should not suffer any loss or damage
            by using it. However, information on the Communication System and Enterprise Online is provided "as is" and we will not be responsible for
            any damages that may follow if you rely on it.<br />
            <br />
            c) We (our owners, employees, consultants, agents or any person connected to the Group) are not responsible for any direct or indirect
            loss or damages related to your use of the Communication System or Enterprise Online, for any reason, even if we were told that loss or
            damage was possible. We are not responsible for any loss or damages caused by:<br />
            i. someone finding out any of your secret Access Codes and using these codes to register for Enterprise Online and fraudulently transact
            on your account;<br />
            ii. any technical or other problems (interruption, malfunction, downtime or other failures) which affect Enterprise Online, a
            Communication System, our banking system, a third-party system or any part of any database, for any reason;<br />
            iii. you relying on any information on the Communication System;<br />
            iv. any Personal Information or other information being lost or damaged because of technical problems, power failures, unlawful acts (such
            as data theft), a harmful computer program or virus, or your own negligence (lack of care);<br />
            v. any failure or problem affecting goods or services provided by any other party, for example, a telecommunication service provider (such
            as Telkom), internet service providers, electricity suppliers (such as Eskom), or a local or other authority; or<br />
            vi. any event that we have no direct control over.
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading"> 22. Announcements about changes or updates to these Terms</span>
          <span class="condition-desc"
            >a) We may make announcements to you about changes or updates to these Terms, or any of our services. We may do this on our website, or by
            sending you an email, text message (SMS) or using another communication technology. <br />
            <br />
            b) If an announcement about Enterprise Online has contractual (legally binding) terms relating to Enterprise Online, these new or updated
            terms will be seen as being included in these (original) Terms. By continuing to use Enterprise Online, you agree to these changes.
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">23. What happens if you breach (go against) these Terms</span>
          <span class="condition-desc">
            a) We may stop you from using Enterprise Online if you do something that is against these Terms, and do not make it right within 5 (five)
            calendar days after we have asked you to. Even if you do make it right, we may still take steps against you, such as applying to court for
            an interdict (ban) or other order against you<br />
            <br />
            b) We may end our relationship with you and stop you from using Enterprise Online by giving you written notice (Termination Notice).
            However, we do not have to give you notice and may close your Account immediately if we:<br />
            i. believe or suspect your Account is being used wrongly or unlawfully (illegally); or<br />
            ii. must do this for legal reasons.
          </span>
          <br />
        </div>
        <div class="conditions">
          <span class="conditiom-heading">24. How disagreements or differences will be resolved</span>
          <span class="condition-desc">
            a) If you have a complaint about your use of Enterprise Online or these Terms, please follow our complaints process, which is available
            at: Stanbic Bank Botswana Limited, domicile address: Plot 50672, Off Machel Drive, Fairgrounds Office Park, Gaborone, Botswana, or from
            via Enterprise Direct. <br />
            <br />
            b) If you are not happy with the way the complaint is settled once you have followed this process, you may send the complaint to the
            Ombudsman for Banking Services (Ombudsman) or a similar forum . <br />
            ? The contact details for the Ombudsman can be obtained online;<br />
            ? in our branches; and<br />
            ? from our Customer Contact Centre on +267 3987820<br />
            We have also included the Ombudsman?s contact details at the end of this document.<br />
            <br />
            c) You may also approach any Botswana court of law that has jurisdiction (authority in this area).
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading"> 25. Where legal documents and notices will be sent</span>
          <span class="condition-desc"
            >a) We choose the registered address on our website at www.standardbank.com as the address where any legal document or notice must be
            served or delivered to us (our domicilium citandi et executandi).<br />
            <br />
            b) You choose your last street address you gave us as the address where any legal documents or notices may be served or delivered to you
            (your domicilium citandi et executandi).<br />
            <br />
            c) We may send other written communication or notice to your street, postal or email address.<br />
            <br />
            d) Any legal document or notice to be served in legal proceedings must be written on paper.
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading">26. Law governing our relationship</span>
          <span class="condition-desc"> Republic of Botswana law will govern these Terms. </span>
          <br />
          <!-- <span class="bullet-points">f.</span> any event over which we have no direct control.</span> -->
        </div>
        <div class="conditions">
          <span class="conditiom-heading"> 27. General provisions</span>
          <span class="condition-desc"
            >a) Headings in these Terms are only for information and may not be used to interpret these Terms.<br />
            <br />
            b) Botswana local time applies when working out any dates or times. <br />
            <br />
            c) If any dispute results from technical issues related to a Communication System, a court or arbitrator will interpret these Terms
            practically without focusing too much on technical issues.<br />
            <br />
            d) Any extension of time or other indulgence we may allow you will not affect any of our rights, whether the indulgence is express or
            implied. We do not waive (give up) any of our rights. <br />
            <br />
            e) If any clause in these Terms is invalid or illegal or cannot be enforced, the other clauses will still be valid. <br />
            <br />
            f) You must make sure you understand exchange control regulations and fully comply with them when you use Enterprise Online outside South
            Africa. <br />
            <br />
            g) You are responsible for making sure that you never use Enterprise Online for any illegal purpose. You will be legally responsible for
            any illegal transactions that you make. <br />
            <br />
            h) If you have any questions or do not understand anything about these Terms, please call our Customer Contact Centre on +267 3987820.
          </span>
        </div>
        <div class="conditions">
          <span class="conditiom-heading"> 28. Customer contact information </span>
          <span class="condition-desc"
            >a) If you have any complaints or questions about Enterprise Online, you can call us on +267 3987820.<br />
            <br />
            b) Alternatively you may direct your queries to:<br />

            Stanbic Bank Botswana Limited, domicile address: Plot 50672, Off Machel Drive, Fairgrounds Office Park, Gaborone, Botswana<br />
            <br />
            Telephone: +267 3987820,<br />
            email: <a href="mailto:EnterpriseBW@stanbic.com" tabindex="-1" class="need-help-mail"> EnterpriseBW@stanbic.com </a>
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="viewTcPayInternational" class="tc-boundary-border">SME terms and conditions for international payments</div>
  </div>
</ng-template>
