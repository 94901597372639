import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  readonly TYPE_ERROR = 'error';
  readonly TYPE_SUCCESS = 'success';
  constructor(private snackBar: MatSnackBar) {}
  open(message, type = 'success', duration = 3000) {
    const resType = type.toLowerCase();
    this.snackBar.open(message, '', {
      duration,
      panelClass: [resType + '-snackbar'],
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}
