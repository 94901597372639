import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Modules } from './masking-constants';
import { SmeHelperService } from '../../services/sme-helper/sme-helper.service';
// import { WebViewRoutes } from '..//native/lib/constants/web-view-routes.constants';

import { WebViewRoutes } from '../../native/lib/constants/web-view-routes.constants';

@Injectable({
  providedIn: 'root',
})
export class MaskingService {
  private featuresToMask = environment.config.isFeatureHide;

  constructor(private smeHelper: SmeHelperService) {}

  removeMaskedFeatures<T extends { feature: string }>(menuItems: T[], module: Modules): T[] {
    const userType = this.smeHelper.isSmeMode() ? 'sme' : 'retail';
    return menuItems.filter(
      (item) => !this.featuresToMask[module][userType].find((feature) => feature === item.feature)
    );
  }

  isFeatureEnabled(feature: string, module: Modules): boolean {
    const userType = this.smeHelper.isSmeMode() ? 'sme' : 'retail';
    return !this.featuresToMask[module][userType].find((item) => item === feature);
  }

  isModuleEnabled(targetRoute: any): boolean {
    const userType = this.smeHelper.isSmeMode() ? 'sme' : 'retail';
    switch (targetRoute) {
      case WebViewRoutes.PAY:
      case WebViewRoutes.BUY:
      case WebViewRoutes.INSTANTMONEY:
      case WebViewRoutes.TRANSFER:
      case WebViewRoutes.BENEFICIARIES:
      case WebViewRoutes.EOL_APPROVAL:
      case WebViewRoutes.ACCOUNTS:
      case WebViewRoutes.SCHEDULEDTRANSACTION:
      case WebViewRoutes.RECEIPTS:
      case WebViewRoutes.HYB_DASH:
      case WebViewRoutes.MANAGE:
        return true;
      case WebViewRoutes.SERVICE_REQUESTS:
        return !this.featuresToMask['sideNav'][userType].find((feature) => feature === 'service-requests');
      case WebViewRoutes.CARDS:
        return !this.featuresToMask['sideNav'][userType].find((feature) => feature === 'debit-cards');
      case WebViewRoutes.EAPLIMITS:
        return !this.featuresToMask['profileSettings'][userType].find((feature) => feature === 'eap-limit');
    }
  }
}
