import { BridgeContext } from '../../interfaces';
import { ChannelProfileSplit, UserProfile } from '../../models/src/lib/user-profile.model';

export class MnativeAuthAction {
  static readonly type = '[Authentication] Set mobile native context';
  constructor(public payload: BridgeContext) {}
}
export class Mstore_Auth_Token_Action {
  static readonly type = '[Authentication] Store auth token';
  constructor(public payload: string) {}
}

export class MHideLogoutWarning {
  static readonly type = '[Authentication] Hide logout warning';
}

export class MShowLogoutWarning {
  static readonly type = '[Authentication] Show logout warning';
}

export class MStoreUniqueIdentifier {
  static readonly type = '[Authentication] Store unique identifier';
  constructor(public payload: string) {}
}
export class MLoginAndSetUserProfile {
  static readonly type = '[Authentication] Login and set user profile';
  constructor(
    public payload: {
      idToken: string;
      userProfile: UserProfile;
      expiresIn: number;
    }
  ) {}
}
export class GetChannelProfiles {
  static readonly type = '[Authentication] Get Channel Profiles';
}
export class GetChannelProfilesSuccess {
  static readonly type = '[Authentication] Get Channel Profiles success';
  constructor(public payload: { channelProfiles: ChannelProfileSplit[] }) {}
}
export class GetChannelProfilesFailure {
  static readonly type = '[Authentication] Get Channel Profiles failure';
}

export class mLogOut {
  static readonly type = '[Authentication] Logout initiated action';
  constructor(public payload: { errorMessage?: string }) {}
}
export class UpdateUserProfile {
  static readonly type = '[User Profile] User Profile Updated';
  constructor(public payload: { userProfile?: UserProfile }) {}
}
export class UpdatePreferredName {
  static readonly type = '[User Profile] Preferred Name Updated';
  constructor(public payload: { preferredName: string }) {}
}

export class UpdateEmail {
  static readonly type = '[User Profile] Email Updated';
  constructor(public payload: { email: string }) {}
}

export class StartMobileNativeFlow {
  static readonly type = '[Authentication] Start mobile native flow';
  constructor(public payload: { context: BridgeContext }) {}
}

export class StartMobileNativeFlowFailed {
  static readonly type = '[Authentication] Failed to start mobile native flow';
}

export class StepUpCancelled {
  static readonly type = '[Authentication] Step up cancelled';
}

export class TearDownBridge {
  static readonly type = '[Authentication] Tear down bridge';
  constructor(public payload: { isError: boolean }) {}
}

export class SetMobileNativeContext {
  static readonly type = '[Authenticatiion] Set mobile native context';
  constructor(public payload: { bridgeContext: BridgeContext }) {}
}

export class RescopeMobilePingTokenFailed {
  static readonly type = '[Authentication] Failed to rescope mobile ping token';
}

export class GetPingUserDetails {
  static readonly type = '[Authentication] Get ping user details';
}

export class GetPingUserDetailsSuccess {
  static readonly type = '[Authentication] Get ping user details success';
  constructor(public payload: { userDetails: any }) {}
}

export class GetPingUserDetailsFailed {
  static readonly type = '[Authentication] Get ping user details failed';
}
export class SetWebViewDashboard {
  static readonly type = '[Application] Set Webview Dashboard';
  constructor(public payload: { currentDashboard: any }) {}
}
