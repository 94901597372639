export class OverDraftAction {
  static readonly type = '[Overdraft] Info';

  constructor(public payload: any) {}
}

export class OverDraftReceipt {
  static readonly type = '[Overdraft]  Receipt Data';

  constructor(public payload: any) {}
}
