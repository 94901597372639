import { Subscription } from 'rxjs';
import { Component, OnInit, Output, EventEmitter, HostListener, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { DataSharingService } from '../../../../../core/data-sharing/data-sharing.service';
import { AppConstantService } from '../../../../../core/app-constant/app-constant.service';
import { FlowsService } from '../../../../../core/flows/flows.service';
import { Store } from '@ngrx/store';
import { RouterStateService } from './../../../../../services/route-state/route-state.service';
import { Store as Ngxs } from '@ngxs/store';
import { TearDownBridge } from '../../../store/actions/mAuthAction';
@Component({
  selector: 'sbg-native-header',
  templateUrl: './native-header.component.html',
  styleUrls: ['./native-header.component.scss'],
})
export class NativeHeaderComponent implements OnInit, OnDestroy {
  @Input() goBackFunc: any;
  @Input() isDashboardBackbutton: boolean;
  @Output() showBackFunc = new EventEmitter<boolean>();

  @Input() nextButtonDisable: boolean;
  @Input() nextButtonText = '';
  @Input() backIcon: boolean;
  @Input() configure = false;
  @Output() backFunction = new EventEmitter<any>();
  @Output() nextFunction = new EventEmitter<any>();
  @Output() deleteRecipient = new EventEmitter<any>();
  @Input() showEdit_Delete: boolean;
  @Input() isNotTear = false;

  @HostListener('window:resize', ['$event'])
  steps = [];
  activeStep: string;
  activeStepIndex: number;
  mainTitle: string;
  subTitle: string;
  thisStateIndex: 2;
  showBack: boolean;
  stateIndex: number;
  subscribe: Subscription;
  headerOptionsSubscription: Subscription;
  hideButtons: boolean;
  showLogout: boolean;
  headerPaginationData: {
    module;
    showClose;
    steps;
    showBack;
    showBackFn;
    hideHeadPagination;
    mainTitle;
    showEdit;
    subTitle;
  };
  isHybrid;
  isDashboard;
  innerWidth;
  showBackCallbackPages = this.appConstant.showBackCallbackPages;
  isLoggedIn;
  progressValue = [];
  teardown_route_list: string[] = [
    '/menu/payments',
    '/menu/buy',
    '/sendMoney/cancelInstantMoney/list',
    '/payments/beneficiary/receipt',
    '/payments/internationalPayment/paymentResult',
    '/payments/selfFund/receipt',
    '/payments/unayo/receipt',
    '/payments/cma/receipt',
    '/payments/vas/receipt',
    '/buy/airtime/receipt',
    '/buy/data/receipt',
    '/buy/tvsubscription/receipt',
    '/buy/electricity/receipt',
    '/buy/ratesandtaxes/receipt',
    '/sendMoney/cancelInstantMoney/receipt',
    '/payments/schoolfees/receipt',
    '/payments/biller/details',
    '/payments/biller/receipt',
    '/payments/MobileWallet/receipt',
    '/otp',
    '/sendMoney/instantMoney/receipt',
    '/recipients-management/beneficiary/list-beneficiary',
    '/accountDetails',
    '/approvals',
    '/menu/debit',
    '/serviceRequests',
    '/serviceRequests/serviceRequestResult',
    '/scheduleTransaction',
    '/debitCards/stopDebitCards/receipt',
    '/debitCards/reactivateCards/receipt',
    '/debitCards/updateLimit/receipt',
    '/transaction-management',
  ];
  isShowDelete: boolean;
  headerDeleteSubscription: Subscription;
  constructor(
    private dataSharingService: DataSharingService,
    private router: Router,
    private routeState: RouterStateService,
    private appConstant: AppConstantService,
    private Flows: FlowsService,
    private store: Store<{ appReducer }>,
    private ngxsStore: Ngxs
  ) {}

  onResize() {
    this.innerWidth = window.innerWidth;
  }
  ngOnInit() {
    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      if (Object.prototype.hasOwnProperty.call(value, 'isMobileActive')) {
        this.isHybrid = value.isMobileActive.isHybrid;
        this.isDashboard = value.isMobileActive.dashboard;
      }
    });
    this.subscribe = this.dataSharingService.getHeadPagination().subscribe((value) => {
      this.headerPaginationData = {
        ...this.headerPaginationData,
        ...{ module: undefined },
        ...value,
      };
      this.showLogout = this.router.url === '/forcedChange/username';
      this.hideButtons = this.router.url === '/forcedChange/username/usernameChangeSuccess';

      this.headerPaginationData.showClose = this.appConstant.ReceiptPages.indexOf(this.router.url) === -1;

      this.steps =
        this.headerPaginationData.steps && this.Flows.flows[this.headerPaginationData.module]
          ? this.Flows.flows[this.headerPaginationData.module]
          : [];

      if (
        this.Flows.flowConfigurations[this.headerPaginationData.module] &&
        this.Flows.flowConfigurations[this.headerPaginationData.module].notificationType
      ) {
        const dummy = [...this.Flows.flows[this.headerPaginationData.module]];
        dummy.splice(this.Flows.flows[this.headerPaginationData.module].length - 1);
        this.steps = dummy;
      }

      // const currentRoute = this.router.url;
      this.stateIndex = this.calculateSteps();
      this.calculateProgressValue();
    });
    this.headerOptionsSubscription = this.dataSharingService.getHybridHeaderOptiosn().subscribe((value) => {
      this.showEdit_Delete = value;
    });
  }
  calculateSteps(): number {
    const currentRoute = this.router.url;
    let stateIndex = -1;
    this.steps.forEach((element, i) => {
      if (Array.isArray(element)) {
        stateIndex = element.indexOf(currentRoute) === -1 ? -1 : i;
      } else if (element === currentRoute) {
        stateIndex = i;
      }
    });
    return stateIndex;
  }

  calculateProgressValue() {
    const eachStep = 100 / this.steps.length;
    for (let i = 1; i <= this.steps.length; i++) {
      this.progressValue.push(eachStep * i);
    }
  }

  nextButtonClick(event) {
    this.nextFunction.emit(event);
  }

  backButtonClick(event) {
    const currentRoute = this.router.url;
    if (
      this.teardown_route_list.includes(currentRoute) &&
      this.isHybrid &&
      !this.isNotTear &&
      !this.isDashboard
    ) {
      this.ngxsStore.dispatch(new TearDownBridge({ isError: true }));
    } else {
      this.backFunction.emit(event);
    }
  }

  showBackFn() {
    this.showBack = this.innerWidth < 640;
  }

  closeModule() {
    const module = this.appConstant.HeadPropagationClosePromptModules.find(
      (mod) => mod.path === this.routeState.getCurrentUrl()
    );
    const currentRoute = this.router.url;
    if (this.isHybrid && !this.isDashboard) {
      if (this.teardown_route_list.includes(currentRoute) && !this.isNotTear) {
        this.ngxsStore.dispatch(new TearDownBridge({ isError: true }));
      }
    }
    if (module) {
      this.resetAll();
      this.dataSharingService.setCloseCurrentModule({
        message: {
          line1: module.message,
        },
        buttonOneText: 'Yes',
        buttonTwoText: 'No',
        routeBackTo: '/dashboard',
        showModule: true,
      });
    }
    // for UI Dashboard to navigate back to dashboard
    if (this.isDashboard && this.isHybrid) {
      this.router.navigate(['/dashboard']);
    } else {
      this.goHome();
    }
  }

  goHome() {
    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      this.isLoggedIn = value.loggedIn;
    });
    // destroy the flow and redirect back to Dashboard/Login page
    this.resetAll();
    this.dataSharingService.dataFromFirstStep = undefined;
    if (this.isLoggedIn) {
      let route = this.appConstant.ClosePages[this.headerPaginationData.module];
      route = route ? route : '/dashboard';
      if (this.routeState.getCurrentUrl() === '/otp' && this.routeState.getPreviousUrl() === '/login') {
        this.router.navigate([this.routeState.getPreviousUrl()]);
      } else {
        const currentRoute = this.router.url;
        if (this.isHybrid && this.teardown_route_list.includes(currentRoute) && !this.isDashboard) {
          this.ngxsStore.dispatch(new TearDownBridge({ isError: true }));
        }
        this.router.navigate([route]);
      }
    } else {
      this.router.navigate(['/login']);
    }
  }
  // if (this.teardown_route_list.includes(currentRoute) && this.isHybrid) {
  //   this.ngxsStore.dispatch(new TearDownBridge({ isError: true }));
  // }
  goBack() {
    if (this.headerPaginationData.showClose && !this.headerPaginationData.showBack) {
      this.closeModule();
    } else {
      if (this.headerPaginationData.showBackFn) {
        this.headerPaginationData.showBackFn();
        return;
      }
      const flow = this.Flows.flows[this.headerPaginationData.module];
      const currentRoute = this.router.url;
      if (flow && flow.indexOf(currentRoute)) {
        flow.forEach((element, index) => {
          if (element === currentRoute) {
            this.router.navigate([flow[index - 1]]);
          }
        });
      } else {
        // fallback if no conditions satisfy
        this.router.navigate([this.routeState.getPreviousUrl()]);
      }
    }
  }

  editClicked() {
    // this.editIconClick.next();
    this.dataSharingService.editIconClick.next();
    //  this.editIconClicked.emit(this.headerPaginationData.mainTitle);
  }

  deleteClicked() {
    this.deleteRecipient.emit();
  }

  resetAll() {
    this.dataSharingService.setCloseCurrentModule({});
    this.dataSharingService.setBulkFileData({});
    this.dataSharingService.selectedApprover = null;
  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
    this.headerOptionsSubscription.unsubscribe();
  }
}
