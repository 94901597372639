import { createAction, props } from '@ngrx/store';
import { TxnReceiptData, SelectedScheduledTxn } from '../interfaces/txn-receipt.model';

export const setTxnReceiptData = createAction(
  '[setTxnReceipt Component] setTxnReceiptData',
  props<{ txnReceiptData: TxnReceiptData }>()
);

export const setSelectedScheduledTxnData = createAction(
  '[setTxnReceipt Component] setSelectedScheduledTxnData',
  props<{ selectedScheduledTxn: SelectedScheduledTxn }>()
);
