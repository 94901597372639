import { createAction, props } from '@ngrx/store';

export const accountNumberEntered = createAction(
  '[Registration Component] accountNumberEntered',
  props<{ accountNumberEntered: string }>()
);
export const setRegisterSecurityQuestions = createAction(
  '[Registration Component] setRegisterSecurityQuestions',
  props<{ registerSecurityQuestions: Array<{}> }>()
);
export const userNameRegistered = createAction(
  '[Registration Component] setUserNameRegistered',
  props<{ userNameRegistered: string }>()
);
export const oldPassword = createAction(
  '[Registration Component] setOldPassword',
  props<{ oldPassword: string }>()
);
export const securityQuestionAnswers = createAction(
  '[Registration Component] securityQuestionAnswers',
  props<{ securityQuestionAnswers: object }>()
);

export const useNameDetails = createAction(
  '[Registration Component] userNameResult',
  props<{ useNameDetails: object }>()
);
