import { LogoutReset } from './../../shared/store-utilities/actions/logout.action';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SignOutService } from '../../core/sign-out/sign-out.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticateService } from '../../core/authenticate-service/authenticate-service.service';
import { DataSharingService } from '../../core/data-sharing/data-sharing.service';
import { Store as ngxsStore } from '@ngxs/store';
import { environment } from '../../../environments/environment';
import { SnackBarService } from 'src/app/services/snack-bar-service/snackBarService';
@Component({
  selector: 'sbg-exit-survey',
  styleUrls: ['./exitSurvey.component.scss'],
  templateUrl: '../templates/ModelExitSurvey.html',
})
export class ExitSurveyComponent implements OnInit {
  userName;
  initialView = true;
  showOptions = false;
  surveyOptions;
  checked = [];
  manualFeedback = false;
  userRequestedFeedback = false;
  config = environment.config;
  nps_environment = environment;
  title = 'Exit Survey';
  email;
  isSmeMode;
  logOutPayload;
  isExitSurveyOptingOut;
  constructor(
    private store: Store<any>,
    public signOutService: SignOutService,
    public ngxs: ngxsStore,
    public dialogRef: MatDialogRef<ExitSurveyComponent>,
    public authenticateService: AuthenticateService,
    private dataSharingService: DataSharingService,
    private snack: SnackBarService
  ) {}
  ngOnInit() {
    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      if (value.authenticateResponse) {
        this.userName = value.authenticateResponse.userProfile.firstName;
        this.email = window.btoa(value?.authenticateResponse?.userProfile?.emailAddress);
        this.isSmeMode = value.isSmeMode;
        this.isExitSurveyOptingOut =
          value.loginReducer.authenticateResponse?.userProfile?.isExitSurveyOptingOut;
      }
    });

    this.surveyOptions = [
      {
        label: "It's hard to do what I want to do",
        checked: false,
      },
      {
        label: "I can't find what I am looking for",
        checked: false,
      },
      {
        label: "It's too slow",
        checked: false,
      },
      {
        label: "Transactions don't go through",
        checked: false,
      },
      {
        label: 'Other: Please specify below',
        checked: false,
      },
    ];

    this.dataSharingService.getUserRequestedFeedback().subscribe((value) => {
      if (value) {
        this.userRequestedFeedback = value;
      }
    });

    this.logOutPayload = {
      userProfile: {
        isExitSurveyOptingOut: this.isExitSurveyOptingOut,
      },
    };
  }

  goToSurveyQuestions = function () {
    this.signOut('logout');
    if (this.config.npsEnabled && !this.isSmeMode) {
      window.open(this.nps_environment.nps + '?e=' + this.email + '&z=true', '_blank');
    }
  };

  goToOptions = function () {
    this.showOptions = true;
  };

  checkboxSelected = function (selection) {
    if (selection === 'Other: Please specify below') {
      this.manualFeedback = !this.manualFeedback;
    }
  };

  signOut(param) {
    if (param === 'logout') {
      this.signOutService.signout();
      this.ngxs.dispatch(new LogoutReset('Logout'));
      this.store.dispatch({ type: 'USER_LOGOUT' });
      this.authenticateService.logout(this.logOutPayload);
      this.dialogRef.close();
    } else {
      this.authenticateService.logout(this.logOutPayload);
      this.dialogRef.close();
      this.dataSharingService.setUserRequestedFeedback(false);
    }
  }

  optOutOfSurvey = function () {
    this.authenticateService.logout(this.logOutPayload).subscribe(() => {
      this.signOut('logout');
      if (this.config.countryName === 'Tanzania') {
        try {
          this.snack.open(
            'You have successfully opted out of Feedback Survey. You will not see this survey from your next visit.',
            this.snack.TYPE_SUCCESS,
            4000
          );
          this.signOut('logout');
        } catch {
          this.snack.open('Unable to update user prefrence', this.snack.TYPE_ERROR, 4000);
        }
      }
    });
  };
}
