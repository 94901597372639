import { createReducer, on } from '@ngrx/store';
// import { setProviderDetails, setSelectedBuy} from '../actions/buy.action';
import { setSelectedAccountData, setAccountData } from '../actions/dashboard.action';
// import { BuyState} from '../interfaces/buy.model';

// const providerDetails = [];
// const buyInitState: BuyState = {
//     providerDetails : [],
//     selectedIndex: 0
// };
// export const buyReducer = createReducer(
//     buyInitState,
//     on(setProviderDetails, (state, { providerDetails }) => ({...state, providerDetails})),
//     on(setSelectedBuy, (state, {selectedIndex}) => ({...state, selectedIndex}))
// );

const dashboardInitialState = {
  accounts: {},
  selectedAccount: {},
};

export const dashboardReducer = createReducer(
  dashboardInitialState,
  on(setAccountData, (state, { accounts }) => ({ ...state, accounts })),
  on(setSelectedAccountData, (state, { selectedAccount }) => ({
    ...state,
    selectedAccount,
  }))
);
