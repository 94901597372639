import { createAction, props } from '@ngrx/store';
import { ProviderDetails } from '../interfaces/buy.model';

export const setProviderDetails = createAction(
  '[Buy Component] setProviderDetails',
  props<{ providerDetails: Array<ProviderDetails> }>()
);

export const setSelectedBuy = createAction(
  '[Buy Component] setSelectedBuy',
  props<{ selectedIndex: number }>()
);
