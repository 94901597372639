import { createReducer, on } from '@ngrx/store';

import * as electricityAction from '../actions/electricity.action';
import { ElectricityState } from '../state/electricity.state';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ElectricityData } from '../interfaces/buy.model';
// {setSelectedElectricityData, setReceiptListData, setElectricityData}

const selectedElectricityInitState = {
  amount: {},
  basePrepaidProvider: {},
  date: null,
  description: '',
  fileSequenceNumber: '',
  initiatorAccount: '',
  rechargeNumber: '',
  referenceId: '',
  status: '',
  transactionId: '',
  type: '',
};

const electricityInitData: ElectricityState = {
  selectedElectricityData: selectedElectricityInitState,
  receiptList: [{}],
  electricityData: null,
  electricityReceiptData: {},
};
export const electricityReducer = createReducer(
  electricityInitData,
  on(electricityAction.setSelectedElectricityData, (state, { selectedElectricityData }) => ({
    ...state,
    selectedElectricityData,
  })),
  on(electricityAction.setElectricityReceiptListData, (state, { receiptList }) => {
    return { ...state, receiptList };
  }),
  on(electricityAction.setElectricityData, (state, { electricityData }) => {
    // state.electricityData = electricityData;
    // return state;
    return { ...state, electricityData };
  }),
  on(electricityAction.setElectricityReceiptData, (state, { electricityReceiptData }) => {
    // state.electricityData = electricityData;
    // return state;
    return { ...state, electricityReceiptData };
  })
);
