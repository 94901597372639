import { SessionStore } from './../../services/SessionStore/SessionStore';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FooterNavigationComponent } from '../../cms/footer-navigation/footer-navigation.component';
import { AuthenticateService } from '../../core/authenticate-service/authenticate-service.service';
import { TranslateService } from '../../core/translate-service/translate.service';
import { DataSharingService } from '../../core/data-sharing/data-sharing.service';
import { Store } from '@ngrx/store';
import { setRefreshFlag } from '../../shared/store-utilities/actions/refresh.action';
import * as loginAction from '../../shared/store-utilities/actions/login.action';
import { Observable, interval, Subscription, Subject, takeUntil, take } from 'rxjs';
import { TokenService } from '../../core/token/token.service';
import { ServiceEndpointsService } from '../../core/service-endpoints/service-endpoints.service';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { SnackBarService } from '../../services/snack-bar-service/snackBarService';
import { McaHeader } from '../../core/data/mca-header';

import { McaResponseType } from '@core/data/mca-response-type';

@Component({
  selector: 'sbg-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
  model = {
    userId: '',
    password: '',
  };
  isSmeMode;
  val = {};
  window: any;
  hidden = true;
  shake = false;
  errorMsg = '';
  title = 'sbg';
  SessionDataIds: any;
  dialog;
  dataSharing;
  offerDetails: Observable<any>;
  subscription: Subscription;
  loggedIn$ = new Subject<boolean>();
  showLoutMsg = false;
  config = environment.config;
  contactNumber = environment.config.localOtpCustomerCare;
  mailId = environment.config.localEmailCustomerCare;
  allowLogin = false;
  readonly Y = 'Y';
  isLogin = this.config.isShowSplashPage;
  loginMode = this.document['preLoginMode'];
  registrationFormFilePath =
    'assets/Documents/' + this.config.localCurrency + '/online-banking-application-form.pdf';
  show = false;
  preHybrid: boolean;
  is_ping: boolean;
  private readonly FORCED_CHANGE_USERNAME = 'FORCED_CHANGE_USERNAME';
  private readonly USERNAME_CHANGE_SKIP_COUNT = 'USERNAME_CHANGE_SKIP_COUNT';
  private readonly FIRST_LOGIN = 'FIRST_LOGIN';
  private readonly QUESTION_AND_ANSWER = 'QUESTION_AND_ANSWER';
  private readonly FORCED_USERNAME = 'FORCED_USERNAME';

  private router;
  constructor(
    private authenticateService: AuthenticateService,
    r: Router,
    dialog: MatDialog,
    dataSharing: DataSharingService,
    private translateService: TranslateService,
    private sessionStore: SessionStore,
    private ngrx: Store<any>,
    private activatedRoute: ActivatedRoute,
    private serviceEndpointsService: ServiceEndpointsService,
    private token: TokenService,
    private snack: SnackBarService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.router = r;
    this.dialog = dialog;
    this.dataSharing = dataSharing;
    authenticateService.startSession().subscribe((val: any) => {
      this.sessionInfo(val);
    });
    this.window = this.document.defaultView;
  }

  ngOnInit() {
    // this.isLogin = false;
    this.preHybrid = this.config.preHybrid;
    this.is_ping = this.config.is_ping;
    this.ngrx.dispatch(loginAction.isMobileAction({ isHybrid: false, dashboard: false }));
    if (this.loginMode === 'SME') {
      this.contactNumber = this.config.localSMEcontactNumber;
      this.mailId = this.config.localSMEEmail;
    }

    this.dataSharing.setHeadPagination({
      mainTitle: 'Login',
      subTitle: 'One Time Pin',
      showBack: false,
      showClose: false,
      steps: false,
      module: 'registration',
    });
    this.dataSharing.getlogOutMsg().subscribe((data) => {
      if (data === true) {
        this.showLoutMsg = true;
      }
    });
    this.dataSharing.getChangePasswordMessage().subscribe((value) => {
      if (this.showLoutMsg) {
        this.errorMsg = 'You have been successfully logged out';
      } else {
        if (value) {
          this.snack.open(value, this.snack.TYPE_SUCCESS, 4000);
        }
      }
    });
    this.dataSharing.setChangePasswordMessage('');
    if (this.config.includePinSecure) {
      window['uniPinID2'] = () => {
        this.allowLogin = true;

        window['loginBeforeTimeout'] = true;
      };
    }
    if (this.config.countryName === 'Tanzania' && !this.config.includePinSecure) {
      this.allowLogin = true;
    }
    this.dataSharing.getErrorMessage().subscribe((value) => {
      // eslint-disable-next-line no-empty
      if (typeof value === 'object') {
      } else if (value !== '') {
        this.errorMsg = value;
      }
    });

    this.ngrx
      .select('appReducer', 'loginReducer')
      .pipe(take(1))
      .subscribe((data) => {
        if (data?.userName?.userName) {
          this.model = {
            userId: data?.userName?.userName,
            password: '',
          };
          this.errorMsg = data.userName.errorMessage;
          this.isLogin = true;
        }
      });
  }

  goTologin() {
    this.isLogin = true;
  }
  passwordToggle() {
    this.show = !this.show;
  }
  goDemo() {
    this.window.open(this.config.demoSite, '_blank');
  }

  openDialog(type: string, condition: string): void {
    this.dataSharing.dialogType = type;
    this.dataSharing.modelDetails = condition;
    this.dialog.open(FooterNavigationComponent, {
      width: '56vw',
      height: '84vh',
    });
  }
  callTag() {
    this.errorMsg = null;
    this.dataSharing.setChangePasswordMessage('');
    // window.inner('login');
  }

  setLang(lang: string) {
    this.translateService.use(lang);
  }

  login(model: any, valid: boolean) {
    this.serviceEndpointsService.cancelRequests$.next(false);

    if (valid) {
      const data = {
        digitalId: {
          password: model.password,
          username: model.userId,
        },
      };
      this.postService(data);
      this.dataSharing.setPostService(this.postService.bind(this));
    } else {
      this.hidden = false;
    }
  }

  postService(payload) {
    return this.authenticateService.login(payload).subscribe(this.handleLogin.bind(this, payload));
  }

  goToRegistration = function () {
    window['callLoginTag'] = true;
    this.router.navigate(['/registration']);
  };

  goToForgotPassword = function () {
    this.router.navigate(['/forgotPassword']);
  };
  goToForgotUserName = () => {
    this.router.navigate(['/forgotUsername']);
  };
  private sessionInfo(res) {
    const statementData = {
      SmartText: res.headers.get('x-sbg-session-id'),
      PinnedText: res.headers.get('x-sbg-session-uuid'),
    };
    this.ngrx.dispatch(loginAction.setSessionData({ sessionData: statementData }));
    this.sessionStore.setItem('SessionID', statementData);
    // SECURITY SNIPPETS INTEGRATION START
    let sourcePinCall_login;
    const currentURL = window.location.href.toString();
    const isUAT = currentURL.search('uat');
    if (isUAT === -1) {
      sourcePinCall_login = this.config.sourcePinCall_Prod_login;
    } else {
      sourcePinCall_login = this.config.sourcePinCall_login;
    }

    window['uniPinID'] = () => {
      const result = {
        c: statementData.PinnedText,
      };
      return result;
    };

    if (this.config.includePinSecure) {
      if (window['callLoginTag']) {
        window['inner']('login');

        setTimeout(() => {
          this.allowLogin = true;

          window['loginAfterTimeout'] = true;
        }, 12000);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const this_ = this;
        (() => {
          const f = document;
          const e = window;
          const i = e.location.protocol;
          const b = [
            [
              'src',
              [i === 'https:' ? 'https:/' : 'http:/', sourcePinCall_login.toString() + Math.random()].join(
                '/'
              ),
            ],
            ['type', 'text/javascript'],
            ['async', true],
          ];
          const c = f.createElement('script');
          const h = f.getElementsByTagName('head')[0];

          setTimeout(() => {
            for (let d = 0, l = b.length; d < l; d++) {
              // [ ]: Type 'boolean' is not assignable to type 'string'
              // @ts-ignore
              c.setAttribute(b[d][0], b[d][1]);
            }
            h.appendChild(c);
          }, 0);
        })();
        setTimeout(() => {
          this_.allowLogin = true;

          window['loginAfterTimeout'] = true;
        }, 12000);
      }
    }
    if (statementData) {
      const result = {
        c: statementData.PinnedText,
      };
      return result;
    }
  }
  private setToken(tokenData) {
    this.token.setToken(tokenData.headers.get(McaHeader.X_SBG_TOKEN));
  }

  private handleLogin(model, res) {
    window['callLoginTag'] = true;
    const resType = res.headers.get(McaHeader.X_SBG_RESPONSE_TYPE);
    if (resType === McaResponseType.SUCCESS) {
      this.isSmeMode = res.headers.get('x-sbg-response-corporate');
      if (this.isSmeMode === 'true') {
        this.ngrx.dispatch(loginAction.isSmeMode({ isSmeMode: true }));
        // if retail login view used for SME login then sign out will land on SME login view
        this.document['preLoginMode'] = 'SME';
      } else {
        this.ngrx.dispatch(loginAction.isSmeMode({ isSmeMode: false }));
        // if SME login view used for retail login then sign out will land on retail login view
        this.document['preLoginMode'] = null;
      }
      for (let i = 0; i < res.body.keyValueMetadata.length; i++) {
        if (
          res.body.keyValueMetadata[i].value === 'OR_RET' &&
          res.body.keyValueMetadata[i].key === 'MENU_PROFILE_ID'
        ) {
          this.dataSharing.setReadOnlyMode(true);
        } else if (
          res.body.keyValueMetadata[i].value === 'OR_CORP' &&
          res.body.keyValueMetadata[i].key === 'MENU_PROFILE_ID'
        ) {
          this.dataSharing.setReadOnlyMode(true);
        }
      }
      const getSession = this.sessionStore.getItem('SessionID');
      window['uniPinID'] = () => {
        const callBackData = {
          p: {
            p: res.body?.userProfile?.permanentUserId,
          },
          u: {
            p: [model.digitalId.username.toLowerCase()],
          },
          c: getSession.PinnedText,
        };
        return callBackData;
      };
      const result = {
        p: {
          p: res.body?.userProfile?.permanentUserId,
        },
        u: {
          p: [model.digitalId.username.toLowerCase()],
        },
        c: getSession.PinnedText,
      };
      const loginData = {
        authenticateResponse: res.body,
        token: res.headers.get(McaHeader.X_SBG_TOKEN),
        loginUser: model,
      };
      this.ngrx.dispatch(
        loginAction.eapLimitScheme({
          eapLimitScheme: res.body?.userProfile?.eapLimitScheme,
        })
      );
      this.ngrx.dispatch(loginAction.userName({ userName: model.digitalId.username }));
      this.ngrx.dispatch(loginAction.setLoginData({ login: loginData }));
      this.ngrx.dispatch(setRefreshFlag({ refreshFlag: true }));

      res.body.keyValueMetadata.forEach((keyMetaVal) => {
        if (keyMetaVal.key === this.USERNAME_CHANGE_SKIP_COUNT) {
          if (keyMetaVal.value !== '0') {
            this.dataSharing.setForcedChangeUsernameNotification(model.digitalId.username);
          } else {
            this.dataSharing.setForcedChangeUsernameNotification('');
          }
        }
      });

      for (let i = 0; i < res.body.keyValueMetadata.length; i++) {
        if (
          res.body.keyValueMetadata[i].key === this.QUESTION_AND_ANSWER &&
          res.body.keyValueMetadata[i].value === this.Y
        ) {
          this.ngrx.dispatch(
            loginAction.forcedSecurityQuestionReset({
              forcedSecurityQuestionReset: true,
            })
          );
          this.router.navigate(['../forcedChange/securityQuestions'], {
            relativeTo: this.activatedRoute.parent,
          });
          return result;
        } else if (
          res.body.keyValueMetadata[i].key === this.FIRST_LOGIN &&
          res.body.keyValueMetadata[i].value === this.Y
        ) {
          for (let j = 0; j < res.body.keyValueMetadata.length; j++) {
            if (
              res.body.keyValueMetadata[j].key === this.QUESTION_AND_ANSWER &&
              res.body.keyValueMetadata[j].value === this.Y
            ) {
              this.ngrx.dispatch(
                loginAction.forcedSecurityQuestionReset({
                  forcedSecurityQuestionReset: true,
                })
              );
              this.router.navigate(['../forcedChange/securityQuestions'], {
                relativeTo: this.activatedRoute.parent,
              });
              return result;
            }
          }
          this.ngrx.dispatch(loginAction.isfirstTimeLogin({ isfirstTimeLogin: true }));
          this.router.navigate(['../forcedChange/password'], {
            relativeTo: this.activatedRoute.parent,
          });
          return result;
        } else if (
          res.body.keyValueMetadata[i].key === this.FORCED_CHANGE_USERNAME &&
          res.body.keyValueMetadata[i].value === this.Y
        ) {
          this.router.navigate(['../forcedChange/username'], {
            relativeTo: this.activatedRoute.parent,
          });
          return result;
        } else if (
          res.body.keyValueMetadata[i].key === this.FORCED_USERNAME &&
          res.body.keyValueMetadata[i].value
        ) {
          this.model = {
            userId: res.body.keyValueMetadata[i].value,
            password: '',
          };
          this.ngrx.dispatch(
            loginAction.userName({
              userName: res.body.keyValueMetadata[i].value,
              errorMessage: res.headers.get('x-sbg-response-message'),
            })
          );
          if (this.config.is_ping && this.config.displayUsername) {
            this.router.navigate(['/login']);
            this.dataSharing.setErrorMessage(res.headers.get('x-sbg-response-message'));
            this.errorMsg = res.headers.get('x-sbg-response-message');
          } else {
            const header = res.headers;
            if (header.get(McaHeader.X_SBG_RESPONSE_TYPE) === McaResponseType.SUCCESS) {
              this.snack.open(header.get(McaHeader.X_SBG_RESPONSE_MESSAGE), this.snack.TYPE_SUCCESS, 4000);
              this.router.navigate(['/dashboard']);
            } else {
              this.snack.open(header.get(McaHeader.X_SBG_RESPONSE_MESSAGE), this.snack.TYPE_ERROR, 4000);
            }
          }
          return result;
        }
      }
      this.router.navigate(['/dashboard']);
      // token rescope every 3 min to mca
      this.ngrx
        .select('appReducer', 'loginReducer')
        .pipe(takeUntil(this.loggedIn$))
        .subscribe((value) => {
          if (!value.loggedIn && this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
            this.loggedIn$.next(false);
            this.loggedIn$.complete();
          }
          if (value.loggedIn && !this.subscription) {
            this.subscription = interval(180000).subscribe(() =>
              this.authenticateService.reIssueToken().subscribe(this.setToken.bind(this))
            );
          }
        });
      return result;
    } else {
      this.errorMsg = res.headers.get(McaHeader.X_SBG_RESPONSE_MESSAGE);
      this.shake = true;
      this.model.userId = '';
      this.model.password = '';
    }
  }
}
