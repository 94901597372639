<ng-container *ngIf="isSmeMode && !isSmeSoleProprietor">
  <section class="mt-20" *ngIf="jsonData.length && !errorMessage">
    <p class="mat-hint">Choose Approver</p>
    <section class="detail-card">
      <mat-radio-group fxLayout="column" [(ngModel)]="selectedApprover">
        <mat-radio-button
          class="choose__approver__rb"
          *ngFor="let rule of jsonData"
          [value]="rule"
          (change)="setSelectedApprover($event)"
        >
          <span *ngFor="let role of rule.definedRoles; let i = index">
            {{ role.count }} {{ role.role.designation }}
            <span *ngIf="rule.definedRoles[i + 1]">+</span>
          </span>

          <span class="choose__approver__heirarchy__label">
            {{ rule.hierarchyEnabled ? 'With hierarchy' : 'Without hierarchy' }}
          </span>
        </mat-radio-button>

        <!-- <mat-radio-button value="2">Option 2</mat-radio-button> -->
      </mat-radio-group>
      <mat-form-field class="form-field mt-10">
        <input
          type="text"
          matInput
          placeholder="Notes for approvers"
          [(ngModel)]="approverNote"
          name="approverNote"
          (change)="approverNoteChange()"
        />
      </mat-form-field>
    </section>
    <!-- *ngFor="let debitcard of listOfDebitCards; let i = index" -->
  </section>
  <section class="detail-card mt-20" *ngIf="chooseApproversData.length && errorMessage">
    <p class="mat-hint">Please note:</p>
    <p class="mat-hint">{{ errorMessage }}</p>
  </section>
  <section class="detail-card mt-20" *ngIf="!jsonData.length && !errorMessage">
    <p class="mat-hint">No approvers found for this transaction</p>
  </section>
</ng-container>
