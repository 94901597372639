import { DataSharingService } from '../../../../../../core/data-sharing/data-sharing.service';
import { TransactionService } from '../../../../../../core/transaction-service/transaction.service';
import { Store } from '@ngrx/store';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import isEmpty from 'lodash/isEmpty';
import { Subscription } from 'rxjs';
@Component({
  selector: 'sbg-hybrid-choose-approver',
  templateUrl: './hybrid-choose-approver.component.html',
  styleUrls: ['./hybrid-choose-approver.component.scss'],
})
export class HybridChooseApproverComponent implements OnInit, OnChanges, OnDestroy {
  @Input() financeType: string;
  @Input() financeValue: string;
  @Input() transactionType: string;
  @Input() amountFeild: any;
  @Input() errorMessage: string;
  @Input() currency: string;
  @Input() ishybridEOLModel: boolean;
  @Output() isApproversFound = new EventEmitter<any>();
  samplePayload = {
    approverRulesType: '',
  };
  chooseApproversData = [];
  jsonDataLength = 0;

  channelProfileData;
  jsonData = [];
  selectedApprover: any = { remarks: '' };
  approverNote = '';
  flow: string;
  isSmeMode = false;
  isSmeSoleProprietor = false;
  private subscription: Subscription;

  constructor(
    private store: Store<any>,
    private transactionService: TransactionService,
    private dataSharingService: DataSharingService
  ) {}

  ngOnInit() {
    this.subscription = this.store.select('appReducer', 'loginReducer').subscribe((stateData) => {
      if (stateData) {
        this.channelProfileData = stateData.authenticateResponse ? stateData.authenticateResponse : {};
        this.isSmeMode = !!stateData.isSmeMode;
        this.isSmeSoleProprietor = !!this.channelProfileData.userProfile.isSmeSoleProprietor;
        if (this.isSmeMode && !this.isSmeSoleProprietor) {
          this.samplePayload.approverRulesType = this.financeType;
          this.selectedApprover = this.dataSharingService.selectedApprover;
          if (!isEmpty(this.channelProfileData) && !this.channelProfileData.userProfile.isSmeSoleProprietor) {
            this.transactionService.getSmeApprovalRules(this.samplePayload).subscribe((response) => {
              this.chooseApproversData = response[this.transactionType] || [];
              if (
                this.chooseApproversData.length === 0 &&
                !this.channelProfileData.userProfile.smeSoleProprietor
              ) {
                this.isApproversFound.emit(false);
              }
              if (this.financeType === 'FINANCIAL_RULES') {
                this.amountFeildChanged();
              } else {
                this.jsonData = [...this.chooseApproversData];
                if (this.dataSharingService.selectedApprover) {
                  // this.selectedApprover = {...this.dataSharingService.selectedApprover};
                  const selected: any = {
                    ...this.dataSharingService.selectedApprover,
                  };
                  //  delete selected.remarks;
                  this.setSelectedApprover({ value: selected });
                } else {
                  this.setSelectedApprover({ value: this.jsonData[0] });
                  // this.selectedApprover =
                }
              }
            });
          }
        }
      }
    });
  }
  currencyArrangments(data) {
    const tempData = [];
    data.forEach((value) => {
      // if(value)
      const amountObj = value.maximumAmount || value.minimumAmount;
      if (amountObj && amountObj.currency === this.currency) {
        tempData.push(value);
      }
    });
    return tempData;
  }
  ngOnChanges(changes) {
    const key = 'amountFeild';
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const amount = changes[key];
    if (this.financeType === 'FINANCIAL_RULES') {
      // if(amount && amount.currentValue){
      this.amountFeildChanged();
      // }else{

      // }
    }
  }
  ngOnDestroy() {
    if (this.errorMessage) {
      this.dataSharingService.selectedApprover = null;
    }
    this.subscription.unsubscribe();
  }
  amountFeildChanged() {
    const approverData = this.currencyArrangments(this.chooseApproversData);
    if (approverData.length) {
      this.amountFeild = parseFloat(this.amountFeild);
      this.jsonData = approverData.filter((value) => {
        if (
          this.amountFeild >= parseFloat(value.minimumAmount.amount) &&
          this.amountFeild <= parseFloat(value.maximumAmount.amount)
        ) {
          return true;
        }
        return false;
      });
      if (this.jsonData.length) {
        if (this.dataSharingService.selectedApprover) {
          this.setSelectedApprover({
            value: this.dataSharingService.selectedApprover,
          });
        } else {
          this.setSelectedApprover({ value: this.jsonData[0] });
        }
        this.errorMessage = '';
      } else {
        this.errorMessage = 'Please enter an amount in the approval range';
      }
    }
  }

  setData(data, json) {
    let temp;
    if (!json.length) {
      return;
    }
    json.forEach((value, index) => {
      if (value.description === data.description && value.id === data.id) {
        temp = this.jsonData[index];
      }
    });
    if (temp) {
      this.selectedApprover = temp;
    } else {
      this.selectedApprover = this.jsonData[0];
    }
    this.approverNote = data.remarks || '';
    // } else {
    //   this.selectedApprover = this.jsonData[0];
    // }
  }

  approverNoteChange() {
    let selected = this.selectedApprover;
    if (this.approverNote.trim() !== '') {
      selected = { ...selected, remarks: this.approverNote.trim() };
    }
    this.dataSharingService.selectedApprover = selected;
    // this.setSelectedApprover(this.selectedApprover);
  }
  setSelectedApprover(selectedApp) {
    // this.selectedApprover = selectedApp.value;
    this.setData(selectedApp.value, this.jsonData);
    // let selected = {...this.selectedApprover};
    this.approverNoteChange();
  }
}
