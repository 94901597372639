import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../core/data-sharing/data-sharing.service';

import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sbg-footer-navigation',
  templateUrl: './footer-navigation.component.html',
  styleUrls: ['./footer-navigation.component.scss'],
})
export class FooterNavigationComponent implements OnInit {
  dialogType = '';
  TermsData;
  SecurityData;
  HelpData;
  Disclaimer;
  info;
  exitSurvey = false;

  constructor(
    private dataSharing: DataSharingService,
    public dialogRef: MatDialogRef<FooterNavigationComponent>
  ) {}

  ngOnInit() {
    this.dialogType = this.dataSharing.dialogType;
    switch (this.dialogType) {
      case 'Terms and Conditions':
        this.TermsData = true;
        break;

      case 'Disclaimer':
        this.Disclaimer = true;
        break;
      case 'Need Help':
        this.HelpData = true;
        break;
      case 'Privacy and Security':
        this.SecurityData = true;
        break;
      case 'Online Banking Feedback Survey':
        this.exitSurvey = true;
        break;
      case 'Important Information':
        this.info = true;
        break;
      default:
        break;
    }
  }

  closeDialog = function () {
    this.dialogRef.close();
  };
}
