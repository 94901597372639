import { State, Selector, StateContext, Action } from '@ngxs/store';
import { DebitCardsCacheEvict, GetDebitCardList, GetDebitCards, GetDebitCardsSuccess } from '../actions/api.action';
import { AccountService } from '../../../core/account-service/account-service.service';
import { Injectable } from '@angular/core';

export interface ApiStateModel {
  debitCards: any;
}
@Injectable()
@State<ApiStateModel>({
  name: 'Api',
  defaults: {
    debitCards: null,
  },
})
export class ApiState {
  constructor(private accountService: AccountService) {}

  @Selector()
  static debitCards(state: ApiStateModel) {
    return state.debitCards;
  }

  @Action(GetDebitCardsSuccess)
  onGetDebitCardSuccess(ctx: StateContext<ApiStateModel>, action: GetDebitCardsSuccess) {
    ctx.patchState({
      debitCards: action.debitCards,
    });
  }

  @Action(GetDebitCards)
  onGetDebitCard(ctx: StateContext<ApiStateModel>, action: GetDebitCards) {
    const state = ctx.getState();
    if (!state.debitCards) {
      this.accountService
        .fetchDebitCards(action.payload)
        .subscribe((response) => ctx.dispatch(new GetDebitCardsSuccess(response['body']['debitCards'])));
    }
  }

  @Action(GetDebitCardList)
  onGetDebitCardList(ctx: StateContext<ApiStateModel>, action: GetDebitCardList) {
    this.accountService
      .fetchDebitCards(action.payload)
      .subscribe((response) => ctx.dispatch(new GetDebitCardsSuccess(response['body']['debitCards'])));
  }

  @Action(DebitCardsCacheEvict)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onDebitCardsEvict(ctx: StateContext<ApiStateModel>) {
    ctx.patchState({
      debitCards: null,
    });
  }
}
