import { createAction, props } from '@ngrx/store';
import { VasProvider } from '../../../core/prepaid-service/data/vas-provider';
import { TransactionsResponse } from '../../../core/transaction-service/data/transactions-response';
import { WaterModel } from '../../../components/buy/water/water.model';

const reducerHeader = '[Buy Prepaid Water]';

export const setWaterModel = createAction(
  `${reducerHeader} setWaterModel`,
  props<{ waterModel: WaterModel }>()
);

export const setProvider = createAction(
  `${reducerHeader} setProvider`,
  props<{ selectedProvider: VasProvider }>()
);

export const setProviders = createAction(
  `${reducerHeader} setProviders`,
  props<{ providers: VasProvider[] }>()
);

export const setWaterReceiptData = createAction(
  `${reducerHeader} setWaterReceiptData`,
  props<{ receiptData: TransactionsResponse }>()
);

export const resetWaterModel = createAction(`${reducerHeader} resetWaterModel`);

export const resetProvider = createAction(`${reducerHeader} resetProvider`);

export const resetWaterReceiptData = createAction(`${reducerHeader} resetWaterReceiptData`);
