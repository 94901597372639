import { createAction, props } from '@ngrx/store';
import { OnceOffPayBeneDetailData, PayBeneReviewData } from '../interfaces/once-off-pay-beneficiary.model';

export const setOnceOffPayBeneDetailData = createAction(
  '[pay bene Component] setOnceOffPayBeneDetailData',
  props<{ onceOffPayBeneDetailData: OnceOffPayBeneDetailData }>()
);

export const setPayBeneReviewData = createAction(
  '[pay bene Component] setPayBeneReviewData',
  props<{ payBeneReviewData: PayBeneReviewData }>()
);
