import { BullkFileInterface } from '../interfaces/bulkfile';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SelectedBulFile } from '../actions/bulkFile.action';
import { Injectable } from '@angular/core';

export class BulkFileModel {
  bulfileData: BullkFileInterface;
}
@Injectable()
@State<BulkFileModel>({
  name: 'bulfileDetails',
  defaults: {
    bulfileData: null,
  },
})
export class BulkFileState {
  @Selector()
  static getBulkData(state: BulkFileModel) {
    return state.bulfileData;
  }
  @Action(SelectedBulFile)
  addBulkFile({ getState, setState }: StateContext<BulkFileModel>, { payload }: SelectedBulFile) {
    const state = getState();
    setState({
      ...state,
      bulfileData: payload,
    });
  }
}
