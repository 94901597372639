import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { DataSharingService } from '@core/data-sharing/data-sharing.service';
import { Modules, SideNav, ProfileSettings } from '@core/masking-service/masking-constants';
import { MaskingService } from '@core/masking-service/masking.service';
import { SignOutService } from '@core/sign-out/sign-out.service';
import { environment } from '@environment';
import findIndex from 'lodash/findIndex';
import { Store } from '@ngrx/store';
import { Store as ngxsStore } from '@ngxs/store';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { ApiState } from '@shared/store-utilities/state/apiState';
import { GetDebitCards } from '@shared/store-utilities/actions/api.action';
import { setSelectedTab } from '@shared/store-utilities/actions/activeTab.action';
import { setSelectedDebitCardData } from '@shared/store-utilities/actions/debitCard.action';

@Component({
  selector: 'sbg-hybrid-manage-menu',
  templateUrl: './hybrid-manage-menu.component.html',
  styleUrls: ['./hybrid-manage-menu.component.scss'],
})
export class HybridManageMenuComponent implements OnInit {
  @Select(ApiState.debitCards) debitCards$: Observable<any>;

  @ViewChild(MatSidenav) sidenav: MatSidenav;
  @Input() accountHolder: any;
  @Input() accountnumber: any;
  @Input() headerName: any;
  config = environment.config;

  title;
  readOnlyMode: boolean;
  hideHeaderForPopupScreen = false;
  exitSurveyOptOut;
  isSmeMode: boolean;
  isSmall: boolean;
  headerTitlePages = [];

  headerTitle = {
    status: false,
    header: 'Manage',
    url: '',
    nav: '',
    subHeader: '',
  };

  maskOpenAcc: boolean;

  sideNavLinks: any = [
    {
      feature: SideNav.RECEIPTS,
      name: 'Receipts',
      classes: '',
      onClickNavFunction: this.goToReceipts.bind(this),
    },
    {
      feature: SideNav.RECIPIENTS,
      name: 'Recipients',
      classes: '',
      onClickNavFunction: this.goToRecipient.bind(this),
    },
    {
      feature: SideNav.DEBIT_CARDS,
      name: 'Debit Cards',
      classes: '',
      onClickNavFunction: this.debitCardsNav.bind(this),
    },
    {
      feature: SideNav.SERVICE_REQUESTS,
      name: 'Service Requests',
      classes: '',
      onClickNavFunction: this.goToServiceRequests.bind(this),
    },
    {
      feature: ProfileSettings.EAP_LIMIT,
      name: 'EAP Limits',
      type: 'EAP_LIMIT',
      classes: '',
      onClickNavFunction: this.goToEapLimits.bind(this),
    },

    // {
    //   feature: SideNav.PROFILE_AND_SETTINGS,
    //   name: 'Profile & Settings',
    //   classes: '',
    //   onClickNavFunction: this.goToProfile_Settings.bind(this),
    // },
    {
      feature: SideNav.SCHEDULED_PAYMENTS,
      name: 'Scheduled Payments',
      classes: '',
      onClickNavFunction: this.goToScheduteTxn.bind(this),
    },
    {
      feature: SideNav.APPROVAL_LIST,
      name: 'Approval List',
      classes: '',
      onClickNavFunction: this.goToApprovalList.bind(this),
    },
    // {
    //   feature: SideNav.GIVE_FEEDBACK,
    //   name: 'Give Feedback',
    //   classes: '',
    //   onClickNavFunction: () => this.handleSignout('Feedback'),
    // },
    // {
    //   feature: SideNav.OPEN_BANK_ACCOUNT,
    //   name: 'Open a Bank Account',
    //   classes: ['mt-16'],
    //   onClickNavFunction: this.goToOpenBankAccount.bind(this),
    // },
  ];
  isHybrid: boolean;
  debitCardAvailable = false;
  private debitCardsSubscription: Subscription;

  constructor(
    private signOut: SignOutService,
    private dataSharingService: DataSharingService,
    private store: Store<any>,
    private router: Router,
    private maskingService: MaskingService,
    private dataSharing: DataSharingService,
    private ngxs: ngxsStore,
  ) {}

  ngOnInit() {
    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      if (Object.prototype.hasOwnProperty.call(value, 'isMobileActive')) {
        this.isHybrid = value.isMobileActive.isHybrid;
      }
    });
    this.dataSharing.setHeadPagination({
      mainTitle: 'Manage',
      subTitle: '',
      showBack: true,
      steps: false,
      showBackFn: this.goBack.bind(this),
    });
    if(!this.config.eapHybridEnable){
      this.sideNavLinks = this.sideNavLinks.filter(link => link.feature !== ProfileSettings.EAP_LIMIT);

    }
    this.sideNavLinks = this.maskingService.removeMaskedFeatures(this.sideNavLinks, Modules.SIDE_NAV);
    this.sideNavLinks = this.maskingService.removeMaskedFeatures(this.sideNavLinks, Modules.PROFILE_SETTINGS);

    this.headerTitlePages = [
      {
        header: this.accountHolder,
        subHeader: this.accountnumber,
        url: '/accountDetails',
        nav: '/dashboard',
      },
      {
        header: 'Receipts',
        url: '/transaction-management',
        nav: '/dashboard',
      },
      {
        header: 'Schedule transactions',
        url: '/scheduleTransaction',
        nav: '/dashboard',
      },
      {
        header: 'Profile and Settings',
        url: '/profileAndSettings',
        nav: '/dashboard',
      },
    ];
    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      if (value.authenticateResponse) {
        this.exitSurveyOptOut = value.authenticateResponse.userProfile.isExitSurveyOptingOut;
      }
      this.isSmeMode = value.isSmeMode;
    });

    this.dataSharingService.getMaskFinancialFlows().subscribe((data) => {
      this.maskOpenAcc = data;
    });

    this.debitCardsSubscription = this.debitCards$.subscribe((debitCards) => {
      if (debitCards && debitCards.length > 0) {
        this.debitCardAvailable = true;
        this.store.dispatch(setSelectedDebitCardData({ selectedDebitCardData: debitCards }));
      }
    });

    this.getDebitCards();
  }

  ngOnChanges(change: any) {
    if (change.accountnumber || change.accountHolder || change.headerName) {
      this.accountHolder = this.headerName ? this.headerName : this.accountHolder;
      const headerObj = {
        header: this.accountHolder,
        subHeader: this.accountnumber,
        url: '/accountDetails',
        nav: '/dashboard',
      };

      const routerBreakdown = this.router.url.split('/');
      const index = findIndex(this.headerTitlePages, {
        url: '/' + routerBreakdown[1],
      });

      if (index >= 0) {
        const updatedAccountsArray = this.headerTitlePages.slice(0);
        updatedAccountsArray[index] = headerObj;
        this.headerTitlePages = JSON.parse(JSON.stringify(updatedAccountsArray));
        if (this.isSmall) {
          this.headerTitle = {
            ...this.headerTitlePages[index],
            ...{ status: true },
          };
        }
      }
    }
  }

  handleSignout(param) {
    this.dataSharingService.setErrorMessage(null);
    if (param === 'logout') {
      if (!this.config.showExitSurvey) {
        this.signOut.signout();
        this.store.dispatch({ type: 'USER_LOGOUT' });
      }
      this.dataSharingService.setlogOutMsg(true);
    } else {
      this.dataSharingService.setUserRequestedFeedback(true);
    }
  }

  goToRecipient(): void {
    this.router.navigate(['/recipients-management/beneficiary/list-beneficiary']);
    document.body.style.overflow = 'unset';
  }
  goToReceipts(): void {
    this.store.dispatch(setSelectedTab({ selectedTab: 0 }));
    this.router.navigate(['/transaction-management']);

    document.body.style.overflow = 'unset';
  }

  goToServiceRequests(): void {
    this.router.navigate(['/serviceRequests']);
    document.body.style.overflow = 'unset';
  }

  goToProfile_Settings(): void {
    this.router.navigate(['/profileAndSettings']);
    document.body.style.overflow = 'unset';
  }

  goToScheduteTxn(): void {
    this.store.dispatch(setSelectedTab({ selectedTab: 0 }));
    this.router.navigate(['/scheduleTransaction']);
    document.body.style.overflow = 'unset';
  }

  goToOpenBankAccount(): void {
    this.store.dispatch(setSelectedTab({ selectedTab: 0 }));
    this.router.navigate(['/open-account']);
    document.body.style.overflow = 'unset';
  }
  goToApprovalList(): void {
    this.router.navigate(['/approvals']);
    document.body.style.overflow = 'unset';
  }
  goToEapLimits(): void {
    this.router.navigate(['./profileAndSettings/changeTransactionLimit/details']);
    document.body.style.overflow = 'unset';
  }
  goBack() {
    this.dataSharingService.setHeaderTitle('');
    this.router.navigate(['/dashboard']);
  }

  dashboardNav() {
    this.router.navigate(['/dashboard']);
    this.sidenav.close();
    document.body.style.overflow = 'unset';
  }
  getIcon(manage: any) {
    switch (manage.feature) {
      case 'debit-cards':
        return 'icn_card_standard';
      case 'approvals':
        return 'icn_people_1';
      case 'recipients':
        return 'icn_people_1';
      case 'receipts':
        return 'icn_wallet';
      case 'service-requests':
        return 'icn_form_fill';
      case 'scheduled-payments':
        return 'icn_clock_standard_future';
      case 'give-feedback':
        return 'icn_people_1';
      case 'open-bank-account':
        return 'icn_people_1';
      case 'profile-and-settings':
        return 'icn_people_1';
      case 'eap-limit':
        return 'icn_gauge';
    }
  }

  getSubtitle(manage) {
    switch (manage.feature) {
      case 'debit-cards':
        return 'Stop or activate card, update limit';
      case 'approvals':
        return 'Approve, reject & recall requests';
      case 'receipts':
        return 'Transaction Receipts';
      case 'recipients':
        return 'Manage Receipients';
      case 'service-requests':
        return 'Debit card request , deposit breakage etc';
      case 'profile-and-settings':
        return 'Change Password, Change Security Questions etc';
      case 'scheduled-payments':
        return 'Edit or stop scheduled transaction';
      case 'give-feedback':
        return 'Give Feedback';
      case 'open-bank-account':
        return 'Open Bank Accounts';
      case 'eap-limit':
        return 'Change Monthly Limit';
      default:
        return '';
    }
  }

  ngOnDestroy() {
    this.debitCardsSubscription.unsubscribe();
  }

  private getDebitCards(): void {
    const debitCardsPayload = {};
    this.ngxs.dispatch(new GetDebitCards(debitCardsPayload));
  }

  debitCardsNav() {
    if(this.config.showDebitCardFlow) {      
      this.debitCardAvailable 
      ? this.router.navigate(['/menu/debit'])
      : this.router.navigate(['/debitCard/list']);
      document.body.style.overflow = 'unset';
    } else {
      this.router.navigate(['/menu/debit']);
      document.body.style.overflow = 'unset';
    }
  }
}
