import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
// import 'rxjs/Rx';
import { Observable, Subject, takeUntil } from 'rxjs';
import { RootStore } from '../state-management/state/root-state';
import { Store } from '@ngrx/store';
import * as loginAction from '../../shared/store-utilities/actions/login.action';
import { ServiceEndpointsService } from '../service-endpoints/service-endpoints.service';
import { DataSharingService } from '../data-sharing/data-sharing.service';
import { environment } from './../../../environments/environment';
import { TearDownBridge } from '../../native/lib/store/actions/mAuthAction';
import { Store as ngxStore } from '@ngxs/store';
@Injectable({
  providedIn: 'root',
})
export class SignOutService implements OnDestroy {
  authenticate: Observable<RootStore>;
  config = environment.config;
  isHybrid = false;
  isDashboard = false;
  private unsubscribe$ = new Subject<void>();
  constructor(
    private router: Router,
    private serviceEndpointsService: ServiceEndpointsService,
    private store: Store<{ appReducer }>,
    private ngxs: ngxStore,
    private dataSharing: DataSharingService
  ) {
    this.store
      .select('appReducer', 'loginReducer')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data.hasOwnProperty('isMobileActive')) {
          this.isHybrid = data.isMobileActive.isHybrid;
          this.isDashboard = data.isMobileActive.dashboard;
        }
      });
  }

  signout() {
    this.serviceEndpointsService.cancelRequests$.next(true);
    this.dataSharing.setReadOnlyMode(false);
    this.store.dispatch({ type: 'USER_LOGOUT' });
    this.store.dispatch(loginAction.setLogoutData({ login: { auth: 'log out' } }));
    this.dataSharing.setChangeLimitReview(null);
    if (!this.isHybrid && !this.isDashboard) {
      this.router.navigate(['/login']);
    } else if (this.isHybrid || this.isDashboard) {
      this.ngxs.dispatch(new TearDownBridge({ isError: true }));
    }
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
