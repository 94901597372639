import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataSharingService } from './../../../../../../core/data-sharing/data-sharing.service';

@Component({
  selector: 'sbg-hybrid-button',
  templateUrl: './hybrid-button.component.html',
  styleUrls: ['./hybrid-button.component.scss'],
})
export class HybridButtonComponent implements OnInit {
  @Input() rightBorderRadiusClear: string;
  @Input() buttonText: string;
  @Input() ariaLabel: string;
  @Input() iconLeft: string;
  @Input() faLeftIcon: string;
  @Input() faRightIcon: string;

  @Input() iconRight: string;
  @Input() leftIconFa: string;
  @Input() rightIconFa: string;
  @Input() isDisabled: boolean;
  @Input() smallButton: boolean;
  @Input() fixedWidthButton: boolean;
  @Input() closeButton: boolean;
  @Input() isRaisedButton: boolean;
  @Input() buttonStyle: string;
  @Input() disableRipple: boolean;
  @Input() buttonSelected: boolean;
  @Input() buttonType: string;
  @Input() buttonActive: boolean;
  @Output() clickFunction = new EventEmitter<any>();

  @Input() awesomeLeft: string;
  @Input() awesomeRight: string;
  @Input() faCheckCircleRight: boolean;
  @Input() hybButtonFilters: boolean;

  showIconLeft: any;
  showIconRight: any;
  maskPayButton: boolean;
  constructor(private dataSharingService: DataSharingService) {}

  ngOnInit() {
    this.dataSharingService.getMaskFinancialFlows().subscribe((data) => {
      this.maskPayButton = data;
    });
  }

  getButtonStyle() {
    return {
      'button__basic--negative-color': this.buttonStyle === 'danger',
      'button__basic--primary-color': this.buttonStyle === 'basic', // icon css added
      'button__basic--secondary-color': this.buttonStyle === 'secondary', // icon css added
      button__outline: this.buttonStyle === 'outline',
      button__transparent: this.buttonStyle === 'transparent',
      'button__navigation-link': this.buttonStyle === 'navigation-link',
      'button__warn-prompt--negative-color': this.buttonStyle === 'warn',
      button_active: this.buttonActive,
      'button__outline--primary-color': this.buttonStyle === 'outline-primary',
      'button__outline--primary-disabled': this.isDisabled && this.buttonStyle === 'outline-primary',
      'button__outline-disabled': this.isDisabled && this.buttonStyle === 'outline',
      'button__basic--primary': this.buttonStyle === 'primary',
      'hybridButton__error--secondary-color': this.buttonStyle === 'hybridError',
      'hyb_button__basic--tertiary-color': this.buttonStyle === 'tertiary',
    };
  }
  getButtonType(buttonType: string): string {
    if (buttonType === 'primary') {
      return 'submit';
    } else {
      return 'button';
    }
  }
  clickButton(event) {
    this.clickFunction.emit(event);
  }
}
