import { DataSharingService } from './../../core/data-sharing/data-sharing.service';
import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'sbg-privacy-and-security',
  styleUrls: ['./privacyAndSecurity.component.scss'],
  templateUrl: '../templates/ModelPrivacyAndSecurity.html',
})
export class PrivacyAndSecurityComponent implements OnInit {
  title = ' Privacy and security template';
  help = false;
  newAccount = false;
  preLoginSmeMode = this.document['preLoginMode'] === 'SME';
  constructor(private dataShare: DataSharingService, @Inject(DOCUMENT) private document: Document) {}

  ngOnInit() {
    if (this.dataShare.modelDetails === 'privacy') {
      this.help = true;
    } else if (this.dataShare.modelDetails === 'newAccount') {
      this.newAccount = true;
    }
  }
}
