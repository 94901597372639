import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { LoginComponent } from './login.component';
import { SharedModule } from '../../shared/shared.module';
import { TranslatePipe } from '../../shared/pipes/translate-pipe/translate.pipe';
import { FlashScreenComponent } from './flash-screen/flash-screen/flash-screen.component';

@NgModule({
  declarations: [LoginComponent, FlashScreenComponent],

  imports: [
    SharedModule,
    FormsModule,
    RouterModule.forChild([{ path: '', pathMatch: 'full', component: LoginComponent }]),
  ],

  providers: [TranslatePipe],
})
export class LoginModule {}
