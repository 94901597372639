import { createReducer, on } from '@ngrx/store';
import {
  setInstantMoneyFormData,
  setInstantMoneyReceiptData,
  setCancelInstantMoneyData,
  setInstantMoneyVoucherListData,
  setBulkReceiptData,
  setBulkInstantMoneyFormData,
  setCancelInstantMoneyReceiptData,
} from '../actions/instantMoney.action';
import { InstantMoneyState } from '../state/instantMoney.state';

const instantMoneyInitState = {};

const instantMoneyReceiptInitState = {};

const cancelInstantMoneyInitState = {};
const cancelInstantMoneyReceiptInitState = {};
const bulkReceiptDataInitState = {};
const instantMoneyVoucherListDataInitState = {};
const bulkInstantMoneyInitState = {};

const instantMoneyData: InstantMoneyState = {
  bulkInstantMoneyFormData: bulkInstantMoneyInitState,
  instantMoneyFormData: instantMoneyInitState,
  instantMoneyReceiptData: instantMoneyReceiptInitState,
  cancelInstantMoneyData: cancelInstantMoneyInitState,
  bulkReceiptData: bulkReceiptDataInitState,
  instantMoneyVoucherListData: instantMoneyVoucherListDataInitState,
  cancelInstantMoneyReceiptData: cancelInstantMoneyReceiptInitState,
};

export const instantMoenyReducer = createReducer(
  instantMoneyData,
  on(setCancelInstantMoneyData, (state, { cancelInstantMoneyData }) => ({
    ...state,
    cancelInstantMoneyData,
  })),

  on(setInstantMoneyVoucherListData, (state, { instantMoneyVoucherListData }) => ({
    ...state,
    instantMoneyVoucherListData,
  })),

  on(setBulkReceiptData, (state, { bulkReceiptData }) => ({
    ...state,
    bulkReceiptData,
  })),

  on(setCancelInstantMoneyReceiptData, (state, { cancelInstantMoneyReceiptData }) => ({
    ...state,
    cancelInstantMoneyReceiptData,
  })),

  on(setInstantMoneyFormData, (state, { instantMoneyFormData }) => ({
    ...state,
    instantMoneyFormData,
  })),
  on(setBulkInstantMoneyFormData, (state, { bulkInstantMoneyFormData }) => ({
    ...state,
    bulkInstantMoneyFormData,
  })),

  on(setInstantMoneyReceiptData, (state, { instantMoneyReceiptData }) => ({
    ...state,
    instantMoneyReceiptData,
  }))
);
