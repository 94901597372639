import { PrepaidUtilitiesService } from '../../core/prepaid-utilities/prepaid-utilities.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environment';

@Injectable()
export class ApprovalListService {
  config = environment.config;
  constructor(private router: Router, private prepaidUtilitiesService: PrepaidUtilitiesService) {}

  public getTransactionName(item, transactionType) {
    switch (transactionType) {
      case 'SCHOOL_FEE':
        return {
          title: item.institution ? item.institution.name : item.vasCustomerName,
          subTitle: 'Fees payment',
        };
      case 'UNAYO_PAYMENT':
        // title:this.modifyData('Transfer to ', item.action, item.toAccount.number)
        return {
          title: item.vasCustomerReferenceNumber,
          subTitle: 'Unayo payment',
        };
      case 'SELF_FUNDS':
        // title:this.modifyData('Transfer to ', item.action, item.toAccount.number)
        return {
          title: item.toAccount.name || item.toAccount.number,
          subTitle: 'Self Fund Transfer',
        };
      case 'BANK_BENEFICIARY':
        // title : this.modifyData('Beneficiary Payment to ', item.action, item.toBeneficiary.name),
        return {
          title: item.toBeneficiary.name,
          subTitle: 'Beneficiary Payments',
        };
      case 'ONCE_OFF_BANK_BENEFICIARY':
        return {
          title: item.toBeneficiary.name,
          subTitle: 'Once off Beneficiary Payments',
        };
      // return this.modifyData('Beneficiary Once-off Payment to ', item.action, item.toBeneficiary.name);
      case 'CMA_BENEFICIARY':
        return {
          title: item.toBeneficiary.name,
          subTitle: 'CMA Beneficiary Payments',
        };
      // return this.modifyData('CMA Beneficiary Payment to ', item.action, item.toBeneficiary.name);
      case 'ONCE_OFF_CMA_BENEFICIARY':
        return {
          title: item.toBeneficiary.name,
          subTitle: 'Once off CMA Beneficiary Payments',
        };
      // return this.modifyData('CMA Beneficiary Once-off Payment to ', item.action, item.toBeneficiary.name);
      case 'TV_SUBSCRIPTION':
        return {
          title: this.modifyData(
            item.vasServiceProvider.friendlyName + ' - ',
            item.action,
            item.vasCustomerReferenceNumber
          ),
          subTitle: 'TV Subscription',
        };
      case 'TV_BOUQUET':
        return {
          title: this.modifyData(
            item.vasServiceProvider.friendlyName + ' - ',
            item.action,
            item.vasCustomerReferenceNumber
          ),
          subTitle: 'TV Bouquet',
        };
      case 'AIRTIME_TOP_UP':
        return {
          title: this.modifyData(
            item.vasServiceProvider.friendlyName + ' - ',
            item.action,
            item.vasCustomerReferenceNumber
          ),
          subTitle: 'Airtime Top up',
        };

      // return this.modifyData('Buy ' + item.vasServiceProvider.friendlyName + ' Subscription for ',
      //         item.action, item.vasCustomerReferenceNumber);
      case 'ELECTRICITY':
        return {
          title: this.modifyData(
            item.vasServiceProvider.friendlyName + ' - ',
            item.action,
            item.vasCustomerReferenceNumber
          ),
          subTitle: 'Prepaid Electricity',
        };
      // return this.modifyData('Buy ' + item.vasServiceProvider.friendlyName +
      // ' ' + this.vasCustomerReferenceNumber(item).VasTransactionType  + ' for meter number ',
      //        item.action, item.vasCustomerReferenceNumber);
      case 'WATER':
        return {
          title: this.modifyData(
            item.vasServiceProvider.friendlyName + ' - ',
            item.action,
            item.vasCustomerReferenceNumber
          ),
          subTitle: item.vasServiceProvider.providerMode === 'POSTPAID' ? 'Postpaid Water' : 'Prepaid Water',
        };
      // return this.modifyData('Buy ' + item.vasServiceProvider.friendlyName +
      // ' ' + this.vasCustomerReferenceNumber(item).VasTransactionType  + ' for reference number ',
      //         item.action, item.vasCustomerReferenceNumber);
      case 'ACCOUNT_TO_MOBILE_WALLET':
        return {
          title: this.modifyData(
            item.vasServiceProvider.friendlyName + ' - ',
            item.action,
            item.vasCustomerReferenceNumber
          ),
          subTitle: 'Account to mobile wallet Transfer',
        };
      // return this.modifyData('Account to mobile wallet ' + item.vasServiceProvider.friendlyName +
      // ' for mobile number ', item.action, item.vasCustomerReferenceNumber);
      case 'DATA_BUNDLE_TOP_UP':
        return {
          title: this.modifyData(
            item.vasServiceProvider.friendlyName + ' - ',
            item.action,
            item.vasCustomerReferenceNumber
          ),
          subTitle: 'Data Bundle Top up',
        };
      // return this.modifyData('Buy ' + item.vasServiceProvider.friendlyName +
      // ' Mobile Data for ', item.action, item.vasCustomerReferenceNumber);
      case 'BULK_FILE_TRANSACTION':
        return {
          title: item.document.name,
          subTitle: 'Bulk File Upload Transaction',
        };
      // return 'Bulk File Upload ' + item.document.name;
      case 'BULK_INSTANT_MONEY':
        return {
          title: item.document.name,
          subTitle:
            this.config.countryName === 'Swaziland'
              ? 'Bulk Unayo Voucher Transaction'
              : 'Bulk Instant Money Transaction',
        };
      // return 'Bulk Instant Money ' + item.document.name;
      case 'BILLER':
        return {
          title: item.toBiller.name,
          subTitle: 'Biller Payments',
        };
      // return this.modifyData('Biller Payment to ', item.action, item.toBiller.name);
      case 'INSTANT_MONEY':
        return {
          title: item.instantMoneyReceipt.mobileNumber,
          subTitle:
            this.config.countryName === 'Swaziland' ? 'Unayo Voucher Transfer' : 'Instant Money Transfer',
        };
      // return this.modifyData('Send Money to ', item.action, item.instantMoneyReceipt.mobileNumber);
      case 'ADD':
        return {
          title: this.getNameByTransactionType(item),
          subTitle: 'Add ' + (item.transactionType === 'BILLER_MANAGEMENT' ? 'Biller ' : 'Beneficiary '),
        };
      // return 'Add ' + (item.transactionType === 'BILLER_MANAGEMENT' ? 'Biller ' : 'Beneficiary ')  +
      //  item.beneficiary.accountHolderFullName ;
      case 'DELETE':
        return {
          title: this.getNameByTransactionType(item),
          subTitle: 'Delete ' + (item.transactionType === 'BILLER_MANAGEMENT' ? 'Biller ' : 'Beneficiary '),
        };
      // return 'Delete ' + (item.transactionType === 'BILLER_MANAGEMENT' ? 'Biller ' : 'Beneficiary ');
      case 'EDIT':
        return {
          title: this.getNameByTransactionType(item),
          subTitle: 'Edit ' + (item.transactionType === 'BILLER_MANAGEMENT' ? 'Biller ' : 'Beneficiary '),
        };
      // return 'Edit ' + (item.transactionType === 'BILLER_MANAGEMENT' ? 'Biller ' : 'Beneficiary ')  +
      //       item.beneficiary.accountHolderFullName ;
      case 'MODIFY':
        return {
          title: this.getNameByTransactionType(item),
          subTitle: 'Modify ' + (item.transactionType === 'BILLER_MANAGEMENT' ? 'Biller ' : 'Beneficiary '),
        };
      case 'ONCE_OFF_INTERNATIONAL_PAYMENT':
        return {
          title: item.toBeneficiary.name,
          subTitle: 'International Beneficiary Payment',
        };
      // return this.modifyData('International Beneficiary Payment to ', item.action, item.toBeneficiary.name);
      case 'OPEN_NOTICE_DEPOSIT_ACCOUNT':
      case 'OPEN_PURE_SAVE_ACCOUNT':
      case 'OPEN_CALL_DEPOSIT_ACCOUNT':
      case 'OPEN_FIXED_DEPOSIT_ACCOUNT':
      case 'OPEN_CURRENT_ACCOUNT':
        return {
          title: item.servicerequest.type.key,
          subTitle: item.servicerequest.type.value,
        };
      // return 'Notice Deposit Opening';
      // return 'PureSave Account Opening';
      // return 'Call deposit Opening';
      // return 'Fixed Deposit Opening';
      // return 'Current Account Opening';

      case 'FIXED_TERM_INVESTMENT_INSTRUCTION':
        return {
          title: item.depositAccountDetails.accountFullName
            ? item.depositAccountDetails.accountFullName
            : item.depositAccountDetails.name,
          subTitle: 'Update Reinvestment Details',
        };
      // return 'Update Reinvestment Details';

      case 'EAP_LIMIT_MANAGEMENT':
        return {
          title: 'Daily Limit Update',
          subTitle: item.servicerequest.referenceId,
        };
      case 'ONCE_OFF_BORDERLESS_PAYMENT':
        return {
          title: item.toBeneficiary.name,
          subTitle: 'Borderless Beneficiary Payment',
        };
      default:
        return transactionType;
    }
  }
  private modifyData(textData, action, appendData) {
    let actionData;
    if (action === 'ADD') {
      actionData = textData + '' + appendData;
    } else if (action === 'EDIT') {
      actionData = 'Modify ' + textData + '' + appendData;
    } else if (action === 'DELETE') {
      actionData = 'Stop Next ' + textData + '' + appendData;
    } else {
      actionData = textData + appendData;
    }
    return actionData;
  }
  private vasCustomerReferenceNumber(item) {
    const vasProviderMode = item.vasServiceProvider.providerMode || 'PREPAID';
    return this.prepaidUtilitiesService.structurePrepaidDataForApprovalList(item.type, vasProviderMode);
  }

  private getNameByTransactionType(item): string {
    return 'BILLER_MANAGEMENT' === item.transactionType
      ? item.biller.name
      : item.beneficiary.accountHolderFullName;
  }
}
