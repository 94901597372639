export class EnvironmentConstants {
  static GROUP_URLS = {
    LOCAL: {
      REGISTER_URL: 'https://nsitosprbnk1.standardbank.co.za:9444/OFS2/user/reg_page.jsp',
      RESET_PW_URL: 'https://nsitosprbnk1.standardbank.co.za:9444/OFS2/user/forgotpasw_page.jsp',
    },
    DEV: {
      REGISTER_URL: 'https://nsitosprbnk1.standardbank.co.za:9444/OFS2/user/reg_page.jsp',
      RESET_PW_URL: 'https://nsitosprbnk1.standardbank.co.za:9444/OFS2/user/forgotpasw_page.jsp',
    },
    SIT: {
      REGISTER_URL: 'https://nsitosprbnk1.standardbank.co.za:9444/OFS2/user/reg_page.jsp',
      RESET_PW_URL: 'https://nsitosprbnk1.standardbank.co.za:9444/OFS2/user/forgotpasw_page.jsp',
    },
    UAT: {
      REGISTER_URL: 'https://group.uat.bankonline.sboff.com/OFS2/user/reg_page.jsp',
      RESET_PW_URL: 'https://group.uat.bankonline.sboff.com/OFS2/user/forgotpasw_page.jsp',
    },
    PRODUCTION: {
      REGISTER_URL: 'https://group.bankonline.sboff.com/OFS2/user/reg_page.jsp',
      RESET_PW_URL: 'https://group.bankonline.sboff.com/OFS2/user/forgotpasw_page.jsp',
    },
  };
}
