import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AccountService } from './account-service/account-service.service';
import { BrowserDetectorService } from './browser-detector/browser-detector.service';
import { GlobalDataUtilityService } from './global-data-utility/global-data-utility.service';
import { ServiceEndpointsService } from './service-endpoints/service-endpoints.service';
import { TokenService } from './token/token.service';
import { AccountsIteratorService } from './accounts-iterator/accounts-iterator.service';
import { AccountDetailsServiceService } from './account-details-service/account-details-service.service';
import { AuthGuardService } from './auth-guard/auth-guard.service';
import { AuthenticateService } from './authenticate-service/authenticate-service.service';
import { DataSharingService } from './data-sharing/data-sharing.service';
import { NameInitialsService } from './name-initials/name-initials.service';
import { TransactionTypeService } from './transaction-type/transaction-type.service';
import { BankingMetadataService } from './banking-metadata/banking-metadata.service';
import { TransactionService } from './transaction-service/transaction.service';
import { CreatePayloadService } from './create-payload/create-payload.service';
import { PaymentUtilitiesService } from './payment-utilities/payment-utilities.service';
import { UuidGeneratorService } from './UUID-generator/uuid-generator.service';
import { RecipientService } from './recipient-service/recipient-service.service';
import { WindowRefService } from './window-ref/window-ref.service';
import { TranslateService } from './translate-service/translate.service';
import { FlowControllerService } from './flow-controller/flow-controller.service';
import { ServiceRequestService } from './service-request/service-request.service';
import { CommonServiceService } from './common-service/common-service.service';
import { TransactionListService } from './transaction-list/transaction-list.service';
import { GroupUtilitiesService } from '../core/utilities/group-utilities/group-utilities.service';
import { RouterStateService } from '../services/route-state/route-state.service';
import { ArrayFilterService } from '../services/array-filters/array-filters.service';
import { ApprovalListService } from '../services/approval-list-service/approval-list.service';
import { AppUtilitiesService } from './utilities/app-utilities/app-utilities.service';
import { ApproverDataService } from './../services/approver-data/approver-data.service';
import { RegistrationService } from './../services/RegistrationService/registration.service';
import { CmaRecipientService } from './recipient-service/cma-recipient.service';
import { FormService } from './form-service/form-service.service';
import { ConfirmationScreenService } from './confirmation-screen-service/confirmation-screen.service';
import { PaymentLimitService } from './payment-limit/payment-limit.service';

@NgModule({
  declarations: [],
  providers: [
    ServiceEndpointsService,
    TokenService,
    AccountService,
    AccountsIteratorService,
    AccountDetailsServiceService,
    AuthGuardService,
    AuthenticateService,
    DataSharingService,
    NameInitialsService,
    TransactionTypeService,
    BankingMetadataService,
    TransactionService,
    CreatePayloadService,
    RecipientService,
    BrowserDetectorService,
    GlobalDataUtilityService,
    PaymentUtilitiesService,
    WindowRefService,
    UuidGeneratorService,
    TranslateService,
    FlowControllerService,
    ServiceRequestService,
    CommonServiceService,
    TransactionListService,
    GroupUtilitiesService,
    RegistrationService,
    RouterStateService,
    ArrayFilterService,
    ApproverDataService,
    ApprovalListService,
    AppUtilitiesService,
    CmaRecipientService,
    FormService,
    ConfirmationScreenService,
    PaymentLimitService,
  ],
  exports: [
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    FormsModule,
    HttpClientModule,
  ],
  imports: [HttpClientModule],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Preventing CoreModule from being imported again elsewhere
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
