// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AccountState {}

import { createAction, props } from '@ngrx/store';
export const setAccountData = createAction(
  '[Dashboard Component] saveAccount',
  props<{ accounts: Array<AccountState> }>()
);
export const setSelectedAccountData = createAction(
  '[Dashboard Component] selectedAccount',
  props<{ selectedAccount: AccountState }>()
);
