<ng-container *ngIf="!isHybrid">
  <sbg-header></sbg-header>
  <section class="module-wrap menu-container">
    <sbg-head-pagination></sbg-head-pagination>
    <div>
      <div class="navigation-list">
        <ul>
          <li
            *ngFor="let payment of list; let i = index"
            class="navigation-list__li"
            (click)="
              mobileView = false; listItemClicked(i); subListOpened = payment.hasSubMenu && !subListOpened
            "
          >
            {{ payment?.name || payment?.feature || payment }}
            <div class="pl-20" *ngIf="subListOpened && payment.name === 'Bill Payments'">
              <div
                class="navigation-list__li"
                *ngFor="let subItem of listOfApplicableVasPayment; let j = index"
                (click)="subListItemClicked(j)"
              >
                {{ subItem.name }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</ng-container>

<!--  ***********************************Hybrid App****************************************** -->
<ng-container *ngIf="isHybrid">
  <sbg-native-header [backIcon]="true" (backFunction)="goBack()"></sbg-native-header>

  <section class="hybrid-card-container padding-horizontal-0">
    <div>
      <div class="navigation-list">
        <ul>
          <li
            *ngFor="let payment of list; let i = index"
            (click)="
              mobileView = false; listItemClicked(i); subListOpened = payment.hasSubMenu && !subListOpened
            "
          >
            <div class="hybrid_navigation-list__li">
              <div
                class="hybrid_navigation-list__li--headIcon"
                *ngIf="selectedFlow.name !== 'debitCardFlows'"
              >
                <mat-icon [svgIcon]="getIcon(payment)"></mat-icon>
              </div>
              <div
                [ngClass]="
                  selectedFlow.name === 'debitCardFlows'
                    ? 'hybrid_navigation-list__li--title ml-0'
                    : 'hybrid_navigation-list__li--title ml-12'
                "
              >
                <div>{{ payment?.name || payment?.feature || payment }}</div>
                <div class="hybrid_navigation-list__li--subtitle">
                  {{ getSubtitle(payment) }}
                </div>
              </div>

              <div class="hybrid_navigation-list__li--tailIcon">
                <mat-icon *ngIf="payment.name !== 'Bill Payments'" svgIcon="icn_chevron_right"></mat-icon>
                <mat-icon
                  *ngIf="!subListOpened && payment.name === 'Bill Payments'"
                  svgIcon="icn_chevron_right"
                ></mat-icon>
                <mat-icon
                  *ngIf="subListOpened && payment.name === 'Bill Payments'"
                  svgIcon="icn_chevron_down"
                ></mat-icon>
              </div>
            </div>
            <div *ngIf="subListOpened && payment.name === 'Bill Payments'">
              <div
                class="hybrid_navigation-list__li subList"
                *ngFor="let subItem of listOfApplicableVasPayment; let j = index"
                (click)="subListItemClicked(j)"
              >
                <div class="hybrid_navigation-list__li--title billerSubList">
                  {{ subItem.name }}
                </div>
                <mat-icon class="hybrid_navigation-list__li--tailIcon" svgIcon="icn_chevron_right"></mat-icon>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</ng-container>
