import { createAction, props } from '@ngrx/store';

export const flow = createAction('[Borderless Payment Component] flow ', props<{ flow: string }>());

export const exchangeRateData = createAction(
  '[Borderless Payment Component] ExchangeRateData ',
  props<{ exchangeRateData: object }>()
);
export const exchangeRateType = createAction(
  '[Borderless Payment Component] setExchangeRateTypes',
  props<{ exchangeRateType: object }>()
);
export const beneDetails = createAction(
  '[Borderless Payment Component] setBeneDetails',
  props<{ beneDetails: object }>()
);
export const beneData = createAction(
  '[Borderless Payment Component] beneData',
  props<{ beneData: object }>()
);
export const transactionDetails = createAction(
  '[Borderless Payment Component] transactionDetails',
  props<{ transactionDetails: object }>()
);
export const receiptData = createAction(
  '[Borderless Payment Component] receiptData',
  props<{ receiptData: object }>()
);
export const countryCodesData = createAction(
  '[Borderless Payment Component] choseExchangeRate',
  props<{ countryCodes: [] }>()
);
