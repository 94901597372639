import { LoaderService } from './components/loader/loader.service';
import {
  Component,
  OnInit,
  OnDestroy,
  AfterContentChecked,
  ChangeDetectorRef,
  Inject,
  HostListener,
} from '@angular/core';

import { LoaderState } from './components/loader/loader.model';

import { RouterStateService } from '../app/services/route-state/route-state.service';

import { SignOutService } from '../app/core/sign-out/sign-out.service';
import { DataSharingService } from '../app/core/data-sharing/data-sharing.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Store } from '@ngrx/store';
import { filter, Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { DOCUMENT } from '@angular/common';
import { NavigationStart, Router } from '@angular/router';
import { TearDownBridge } from './native/lib/store/actions/mAuthAction';
import { Store as ngxsStore } from '@ngxs/store';
import { AuthenticateService } from './core/authenticate-service/authenticate-service.service';
@Component({
  selector: 'sbg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [SignOutService],
})
export class AppComponent implements OnInit, OnDestroy, AfterContentChecked {
  title = 'sbg';
  currentState: any;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  timer = 0;
  show = false;
  envConfig = environment.config;
  window: any;
  isHybrid = false;
  isExitSurveyOptingOut = false;
  private subscription: Subscription;

  constructor(
    private signOut: SignOutService,
    private routerState: RouterStateService,
    private idle: Idle,
    public keepalive: Keepalive,
    private store: Store<any>,
    private ngxs: ngxsStore,
    private loaderService: LoaderService,
    private dataSharingService: DataSharingService,
    private ref: ChangeDetectorRef,
    private titleService: Title,
    private route: Router,
    private authenticateService: AuthenticateService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.window = this.document.defaultView;
    const preLoginState =
      this.window.location.href.toString().includes('enterprise') ||
      this.window.location.href.toString().includes('Enterprise');
    if (preLoginState) {
      this.document['preLoginMode'] = 'SME';
    }
    if (this.window.location.href.toString().includes('mobile')) {
      this.route.navigate(['/mobile']);
    }
    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      if (Object.prototype.hasOwnProperty.call(value, 'isMobileActive')) {
        this.isHybrid = value.isMobileActive.isHybrid;
        if (this.isHybrid) {
          this.route.events
            .pipe(filter((e) => e instanceof NavigationStart && e.navigationTrigger === 'popstate'))
            .subscribe(() => {
              this.ngxs.dispatch(new TearDownBridge({ isError: true }));
            });
        }
      }
    });
    this.store.select('appReducer').subscribe((value) => {
      this.isExitSurveyOptingOut =
        value.loginReducer.authenticateResponse?.userProfile?.isExitSurveyOptingOut;
    });

    // carbon copy snippet
    if (this.envConfig.includePinSecure) {
      let carbon_print;
      let cookieID;

      const currentURL = window.location.href.toString();
      const isUAT = currentURL.search('uat');
      if (isUAT === -1) {
        carbon_print = this.envConfig.carbon_copy_prod;
        cookieID = this.envConfig.cookieID_Prod;
      } else {
        carbon_print = this.envConfig.carbon_copy;
        cookieID = this.envConfig.cookieID;
      }

      // [ ] :how to stop
      document.cookie =
        cookieID.toString() +
        encodeURIComponent(Math.random()) +
        this.envConfig.domainID.toString() +
        ';secure';

      (() => {
        const c = window;
        const d = document;
        const g = c.location.protocol;
        const f = d.getElementsByTagName('head')[0];
        const b = d.createElement('script');
        b.src = (g === 'https:' ? 'https://' : 'http://') + carbon_print.toString() + Math.random();
        b.async = true;
        setTimeout(() => {
          b.type = 'text/javascript';
          f.appendChild(b);
        }, 0);
      })();
    }

    // idleTimeoutHandling
    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      if (value.loggedIn) {
        // sets an idle timeout of 5 seconds, for testing purposes.
        idle.setIdle(300);
        // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
        idle.setTimeout(60);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        idle.onIdleEnd.subscribe(() => {
          this.idleState = 'No longer idle.';
          this.timer = 0;
          this.reset();
        });

        idle.onTimeout.subscribe(() => {
          this.idleState = 'Timed out!';
          this.timedOut = true;
        });
        if (!this.isHybrid) {
          // idle timing is 5 min
          idle.onIdleStart.subscribe(() => {
            this.idleState = "You've gone idle!";
            this.timer = this.timer + 1;
            if (this.timer === 1) {
              this.dataSharingService.setIdleTimeout(true);
            }
          });
        }

        idle.onTimeoutWarning.subscribe((countdown) => {
          this.idleState = 'You will time out in ' + countdown + ' seconds!';
        });

        // sets the ping interval to 15 seconds
        keepalive.interval(15);

        keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

        this.reset();

        // idleTimer implementation ends
      } else {
        idle.stop();
        this.timer = 0;
      }
    });

    this.routerState.loadRouting();
    /* eslint-disable @typescript-eslint/no-empty-function */
    window.onload = () => {};
    window['inner'] = () => {};
    /* eslint-disable @typescript-eslint/no-empty-function */
  }

  @HostListener('window:beforeunload')
  callLogoutService(): boolean {
    this.authenticateService
      .logout({
        userProfile: {
          isExitSurveyOptingOut: this.isExitSurveyOptingOut,
        },
      })
      .subscribe();
    return true;
  }

  ngOnInit() {
    this.subscription = this.loaderService.loaderState.subscribe((state: LoaderState) => {
      this.show = state.show;
    });
    this.setTitle(this.envConfig.title);
  }
  ngAfterContentChecked() {
    this.ref.detectChanges();
  }
  handleSignout() {
    this.signOut.signout();
  }
  setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
